import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import resumoAbi from '../resumo-abi/store';
import atendimentosAbi from '../atendimentos/store';
import documentosGerados from '../documentos-gerados/store';
import protocolosEletronicos from '../protocolos-eletronicos/store';
import solicitacaoDocumentos from '../solicitacao-documentos/store';
import lancamentosContabeis from '../lancamentos-contabeis/store';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    resumoAbi,
    atendimentosAbi,
    documentosGerados,
    protocolosEletronicos,
    solicitacaoDocumentos,
    lancamentosContabeis,
  },
};
