export default () => ({
  loadingTableProcedimentosTussSigtap: false,
  loadingModalImportarCSV: false,
  procedimentosTussSigtap: [],
  hasUpdatedProcedimento: false,
  hasClickedOnIncluirNovo: false,
  totalElements: 0,
  showModalImportarCSV: false,
  dadosUploadProcedimento: {
    anexo: null,
    errorMessages: [],
  },
  initialState: [],
});
