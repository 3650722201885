import { helpPages } from '@/constants';

export default [
  {
    path: '/relatorio-contabil',
    name: 'relatorio-contabil',
    component: () =>
      import(
        /* webpackChunkName: "indicadores-ressarcimento" */
        './index.vue'
      ),
    meta: {
      title: 'Relatórios',
      subtitle: 'Lançamentos contábeis',
      helpPage: helpPages['relatorio-contabil'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
