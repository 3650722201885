import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import initialState from './state';
import cobranca from '../cobranca/store';

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
  modules: {
    cobranca,
  },
};
