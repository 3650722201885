import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import ListarSolicitacaoDocumentos from './queries/listar-solicitacao-documentos';

const getSolicitacaoDocumentos = (queryParams) =>
  createGraphQLService(ListarSolicitacaoDocumentos(queryParams));

const getDestinatariosService = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/grupo_trabalho/destinatarios`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const enviarSolicitacaoDocumentosService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/solicitacao_documento`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getSolicitacaoDocumentoByIdService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/solicitacao_documento/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const uploadFileService = ({ solicitacaoId, payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/solicitacao_documento/anexar_documentos/${solicitacaoId}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const reenviarSolicitacaoDocumentoService = ({
  solicitacaoId,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/solicitacao_documento/reenviar/${solicitacaoId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getSolicitacaoDocumentos,
  enviarSolicitacaoDocumentosService,
  getSolicitacaoDocumentoByIdService,
  uploadFileService,
  getDestinatariosService,
  reenviarSolicitacaoDocumentoService,
};
