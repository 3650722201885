import Vue from 'vue';
import initialState from './state';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.detalhamentoMotivo, property, value);
  },
  SHOW_ALERT(state, { value }) {
    state.alert.show = value;
  },
};
