<template>
  <div :class="{ loading }">
    <span ref="infinite-scroll" data-test="span-infinite-scroll" />
  </div>
</template>

<script>
export default {
  name: 'ReInfiniteScroll',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    handleIntersect() {
      return this.$emit('limit-intersected');
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        this.handleIntersect;
      }
    });

    this.observer.observe(this.$refs['infinite-scroll']);
  },
  destroyed() {
    this.observer.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.loading {
  height: 20%;
  left: 0.5rem;
  position: absolute;
  top: 25.688rem;
  width: 39%;
}
</style>
