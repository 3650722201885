const withModal = (state) => state?.startParams?.withModal;

const endStep = (state) => state?.endParams?.endStep;

const canImmediateFinish = (state) => state?.endParams?.immediate || !state?.iterationIsActive;

const canEndRenderAnimation = (state) => !state?.startParams?.withModal;

const canRenderCurrentStep = (state) => (currentStep) =>
  currentStep && (!currentStep?.hideAfterProcessFinished || !state?.finishProcessWasCalled);

const DEFAULT_PERCENTATE_TO_SLOWDOWN_PROGRESS_BAR = 70;
const DEFAULT_DELAY_FACTOR = 1.3;

const getDelay = (state) => (lastDelay, percentage) => {
  if (!state?.finishProcessWasCalled && percentage >= DEFAULT_PERCENTATE_TO_SLOWDOWN_PROGRESS_BAR) {
    return lastDelay * DEFAULT_DELAY_FACTOR;
  }

  return state?.endParams?.delayByPercentageUnit ?? state?.startParams?.delayByPercentageUnit;
};

const step = ({ currentStep = {} }) => ({
  ...currentStep,
  iconClass: currentStep?.iconClass ?? 'success',
  confirmButtonClass:
    currentStep?.confirmButtonClass ?? 'bg-branding-primary-lemon color-branding-primary-tech',
});

const isCompleteProgress = ({ percentage }) => percentage === 100;

export default {
  withModal,
  endStep,
  canImmediateFinish,
  canEndRenderAnimation,
  canRenderCurrentStep,
  getDelay,
  step,
  isCompleteProgress,
};
