const forms = {
  nome: '',
  usuarios: [],
  quantidadeParticipantes: 0,
  ativo: true,
  notificarIncorporacaoCusto: false,
  notificarLancamentosContabeis: false,
  notificarSolicitacaoDocumentos: false,
};

export default () => ({
  gruposTrabalho: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingForm: false,
  grupoTrabalho: { ...forms },
  initialState: { ...forms },
  participantes: [],
});
