import { isEqual } from 'lodash';
import { formatDateTime } from '@/utils/date';

const isInitialStateDadosDockModal = (state) =>
  isEqual(state.dadosDockModal, state.initialStateDadosDockModal);

const dadosSolicitacaoDocumentos = ({ solicitacaoDocumentos }) =>
  solicitacaoDocumentos?.map((solicitacao) => ({
    ...solicitacao,
    destinatario: solicitacao?.destinatarioNome || solicitacao?.destinatarioGrupoNome,
    dataSolicitacao: formatDateTime(solicitacao?.dataSolicitacao),
  }));

export default { isInitialStateDadosDockModal, dadosSolicitacaoDocumentos };
