<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    no-data-text="Nenhum registro encontrado"
    loading-text="Carregando registros..."
    class="elevation-0"
    :show-select="showSelect"
    :class="{ 'border-box-none': !items.length }"
    :item-key="itemKey"
    :headers="headers"
    :items="items"
    :items-per-page="size"
    :page="page"
    :server-items-length="totalElements"
    :loading="loading"
    :hide-default-footer="!items.length"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :footer-props="{
      itemsPerPageText: 'Linhas por página:',
      itemsPerPageAllText: 'Todos',
      itemsPerPageOptions: [10, 20, 50, 100],
      pageText: '{0}-{1} de {2}',
      showFirstLastPage: true,
      firstIcon: 'mdi-chevron-double-left',
      lastIcon: 'mdi-chevron-double-right',
    }"
    @update:sort-by="$emit('onSortBy', $event)"
    @update:sort-desc="$emit('onSortDesc', $event)"
    @update:page="$emit('onChangePage', $event)"
    @update:items-per-page="$emit('onChangeItemsPerPage', $event)"
    @item-selected="$emit('onItemSelected', $event)"
    @toggle-select-all="$emit('onToggleSelectAll', $event)"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot v-bind="slotData" :name="scopedSlotName" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'UiTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'codigo',
    },
    items: {
      type: Array,
      required: true,
    },
    loading: Boolean,
    page: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 10,
    },
    showSelect: Boolean,
    sortDesc: Boolean,
    sortBy: {
      type: String,
    },
    totalElements: {
      type: Number,
      default: -1,
    },
  },
  watch: {
    loading: {
      handler() {
        this.$nextTick(() => {
          this.handleFirstHeaderOver();
        });
      },
    },
  },
  destroyed() {
    window.removeEventListener('mouseover', this.handleFirstHeaderOver(), false);
    window.removeEventListener('mouseout', this.handleFirstHeaderOver(), false);
  },
  methods: {
    handleFirstHeaderOver() {
      const firstTh = document.querySelector('thead th:first-child i');
      const firstColumnTds = document.querySelectorAll('tbody td:first-child > div');

      firstTh.addEventListener('mouseover', () => {
        firstColumnTds.forEach((td) =>
          td.setAttribute('style', 'width: 12px !important; transition: all 0.3s ease;'),
        );
      });

      firstTh.addEventListener('mouseout', () => {
        firstColumnTds.forEach((td) => td.setAttribute('style', 'width: 5px !important;'));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(tr td:first-child > div) {
  width: 5px !important;
}

:deep(thead th:first-child) {
  padding: 0 !important;
}

:deep(tbody tr:nth-of-type(odd)) {
  background-color: theme('colors.neutral-gray-low');
}

:deep(thead th) {
  color: theme('colors.primary-blue-base') !important;
  font-size: 12px !important;
  border: none !important;
  padding: 0 4px !important;

  .v-data-table__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-input--selection-controls__input {
      margin: 0;

      i {
        color: theme('colors.primary-blue-base');
      }
    }
  }
}

:deep(tbody td) {
  color: theme('colors.primary-blue-base') !important;
  padding: 0 12px !important;
  font-size: 12px !important;
  border: none !important;
  border-right: 1px solid theme('colors.neutral-gray-disabled') !important;

  &:first-child,
  &:last-child {
    border: none !important;
  }

  .v-data-table__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-input--selection-controls__input {
      margin: 0;

      i {
        color: theme('colors.primary-blue-base');
      }
    }
  }
}

:deep(tbody tr) {
  &:hover {
    background-color: theme('colors.primary-blue-disabled') !important;
  }
}

:deep(.v-data-table__selected) {
  background-color: theme('colors.state-medium-select') !important;
}

:deep(.v-data-footer) {
  color: theme('colors.primary-blue-base') !important;
  border-top: none !important;
}
</style>
