import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { pagination } from '@/constants';
import { ReToastLink } from '@/components';
import {
  getProcedimentosTussSigtap,
  createProcedimentosTussSigtapService,
  deleteProcedimentosTussSigtapService,
  updateProcedimentosTussSigtapService,
  uploadFileCSVService,
} from '../services';

const loadProcedimentosTussSigtap = async ({ commit }, query) => {
  commit('RESET_STATE');
  commit('GENERIC_MUTATION', {
    property: 'loadingTableProcedimentosTussSigtap',
    payload: true,
  });

  const { payload } = await getProcedimentosTussSigtap(query);

  commit('GENERIC_MUTATION', {
    property: 'procedimentosTussSigtap',
    payload: payload?.listarProcedimentosTussSigtap.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarProcedimentosTussSigtap.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTableProcedimentosTussSigtap',
    payload: false,
  });

  commit('GENERIC_MUTATION', {
    property: 'initialState',
    payload: cloneDeep(payload?.listarProcedimentosTussSigtap.content),
  });
};

const createProcedimentosTussSigtap = ({ commit, dispatch }, { payload, query }) =>
  createProcedimentosTussSigtapService({
    payload,
    onSuccess: () => {
      Vue.$toast('Procedimento(s) salvo(s) com sucesso', { type: 'success' });

      dispatch('loadProcedimentosTussSigtap', query);
      dispatch('setHasUpdatedProcedimento', false);

      commit('GENERIC_MUTATION', {
        property: 'hasClickedOnIncluirNovo',
        payload: false,
      });
    },
    onCustomError: ({ response }) => {
      const errors = response?.data?.errors || [response?.data?.message];

      errors.forEach((error) => {
        Vue.$toast(error?.defaultMessage || error, { type: 'error' });
      });
    },
  });

const deleteProcedimentosTussSigtap = ({ commit, dispatch, state }, { id, query }) =>
  deleteProcedimentosTussSigtapService({
    id,
    onSuccess: () => {
      Vue.$toast('Procedimento excluído com sucesso', { type: 'success' });

      commit('GENERIC_MUTATION', {
        property: 'hasClickedOnIncluirNovo',
        payload: false,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => {
      if (state.procedimentosTussSigtap.length === 1) {
        Vue.router.replace({
          name: 'procedimentos-tuss-sigtap',
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }

      dispatch('loadProcedimentosTussSigtap', query);
    },
  });

const updateProcedimentosTussSigtap = ({ dispatch }, { payload, query }) => {
  updateProcedimentosTussSigtapService({
    payload,
    onSuccess: () => {
      if (query.pesquisa) Vue.$toast('Procedimento(s) salvo(s) com sucesso', { type: 'success' });

      dispatch('setHasUpdatedProcedimento');
    },
    onCustomError: ({ response }) => {
      const errors = response?.data?.errors || [response?.data?.message];

      errors.forEach((error) => {
        Vue.$toast(error?.defaultMessage || error, { type: 'error' });
      });
    },
  });
};

const updateProcedimentosField = ({ commit }, { item, property, value }) =>
  commit('UPDATE_PROCEDIMENTOS_FIELD', {
    item,
    property,
    value,
  });

const addNewProcedimento = ({ commit, state }) => {
  commit('ADD_NEW_PROCEDIMENTO');
  commit('GENERIC_MUTATION', {
    property: 'hasClickedOnIncluirNovo',
    payload: true,
  });
  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: state.totalElements + 1,
  });
};

const deleteNewProcedimento = ({ commit, state }) => {
  commit('DELETE_NEW_PROCEDIMENTO');
  commit('GENERIC_MUTATION', {
    property: 'hasClickedOnIncluirNovo',
    payload: false,
  });
  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: state.totalElements - 1,
  });
};

const setHasUpdatedProcedimento = ({ commit }, value = true) =>
  commit('GENERIC_MUTATION', {
    property: 'hasUpdatedProcedimento',
    payload: value,
  });

const uploadFile = async ({ dispatch, commit, state }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingModalImportarCSV',
    payload: true,
  });

  dispatch('updateField', { property: 'errorMessages', value: [] });

  const payload = new FormData();

  payload.append(
    'file',
    new Blob([state.dadosUploadProcedimento.anexo], {
      type: 'text/csv',
    }),
    state.dadosUploadProcedimento.anexo.name,
  );

  await uploadFileCSVService({
    payload,
    onSuccess: ({ data }) => {
      if (data.codigoSusDuplicado) {
        Vue.$toast(
          {
            component: ReToastLink,
            props: {
              description:
                'Somente os procedimentos SUS duplicados não foram salvos. Para consultar,',
              linkDescription: 'clique aqui.',
            },
            listeners: {
              onClickLink: () => Vue.router.push({ name: 'documentos-gerados' }),
            },
          },
          { type: 'info' },
        );
      } else {
        Vue.$toast('Procedimento(s) salvo(s) com sucesso', { type: 'success' });
      }

      dispatch('toggleModalImportarCSV');

      dispatch('loadProcedimentosTussSigtap', {
        query: { page: pagination.page, size: pagination.size, pesquisa: '' },
      });
    },
    onCustomError: ({ response }) => {
      Vue.$toast('Problemas com o arquivo', { type: 'error' });

      dispatch('updateField', {
        property: 'errorMessages',
        value: [response?.data?.message],
      });

      dispatch('updateField', {
        property: 'anexo',
        value: null,
      });
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingModalImportarCSV',
        payload: false,
      }),
  });
};

const toggleModalImportarCSV = async ({ state, commit, dispatch }) => {
  dispatch('updateField', { property: 'errorMessages', value: [] });

  commit('GENERIC_MUTATION', {
    property: 'showModalImportarCSV',
    payload: !state.showModalImportarCSV,
  });

  dispatch('updateField', { property: 'anexo', value: null });
};

const resetState = ({ commit }) => commit('RESET_STATE');

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

export default {
  loadProcedimentosTussSigtap,
  createProcedimentosTussSigtap,
  deleteProcedimentosTussSigtap,
  updateProcedimentosField,
  updateProcedimentosTussSigtap,
  addNewProcedimento,
  deleteNewProcedimento,
  setHasUpdatedProcedimento,
  uploadFile,
  toggleModalImportarCSV,
  updateField,
  resetState,
};
