import { createGraphQLService, createCommandService } from '@/services';
import { ressusURI, apiDomainStorageURI } from '@/api';
import listarProtocolos from './queries/listar-protocolos';

const getProtocolos = async (queryParameters) =>
  createGraphQLService(listarProtocolos(queryParameters));

const atendimentoProtocolarService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/atendimento/protocolar`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateDataContestacaoService = ({
  abiId,
  tipoFormulario,
  payload,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/abi/${abiId}/data_contestacao/${tipoFormulario}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteDataContestacaoService = ({
  abiId,
  tipoFormulario,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/abi/${abiId}/remover_data_contestacao/${tipoFormulario}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDataFilterService = ({
  entidade,
  nomeCampo,
  abiId,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/${entidade}/valores/campo/${nomeCampo}/abi/${abiId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDocumentoService = ({ chave, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${apiDomainStorageURI}/document/${chave}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  atendimentoProtocolarService,
  getProtocolos,
  updateDataContestacaoService,
  deleteDataContestacaoService,
  getDataFilterService,
  getDocumentoService,
};
