import moment from 'moment';
import { formatDateRestore, currentDate } from '@/utils/date';
import { formatMoneyRestore } from '@/utils/currency';

const required = (value) => !!value || 'Campo obrigatório';

const requiredLength = (value) => !!value?.length || 'Campo obrigatório';

const email = (value) => /.+@.+\..+/.test(value) || 'Informe um e-mail válido';

const maxLength = (value, length) =>
  (value || '').length <= length || `Tamanho máximo ${length} caracteres`;

const minDate = (startDate, endDate, { acceptEmptyStartDate, acceptEmptyEndDate } = false) =>
  new Date(startDate) <= new Date(endDate) ||
  (acceptEmptyStartDate && !startDate) ||
  (acceptEmptyEndDate && !endDate) ||
  'Precisa ser menor ou igual a data final';

const maxDate = (startDate, endDate, { acceptEmptyStartDate, acceptEmptyEndDate } = false) =>
  new Date(endDate) >= new Date(startDate) ||
  (acceptEmptyStartDate && !startDate) ||
  (acceptEmptyEndDate && !endDate) ||
  'Precisa ser maior ou igual a data inicial';

const maxCurrentDate = (date) =>
  new Date(formatDateRestore(date)) <= new Date(currentDate()) ||
  'Precisa ser menor ou igual a data atual';

const validDate = (value, { acceptEmptyData } = false) => {
  const isValidDate =
    value?.length === 10 && moment(formatDateRestore(value), 'YYYY-MM-DD').isValid();

  return isValidDate || (acceptEmptyData && !value) || 'Data inválida';
};

const validCompetency = (value, { acceptEmptyData } = false) => {
  const fullDate = `01/${value}`;

  const isValidDate =
    fullDate?.length === 10 && moment(formatDateRestore(fullDate), 'YYYY-MM-DD').isValid();

  return isValidDate || (acceptEmptyData && !value) || 'Data inválida';
};

const minCompetency = (
  startDate,
  endDate,
  { acceptEmptyStartDate, acceptEmptyEndDate } = false,
) => {
  const fullStartDate = `01/${startDate}`;
  const fullEndDate = `01/${endDate}`;

  return (
    new Date(fullStartDate) <= new Date(fullEndDate) ||
    (acceptEmptyStartDate && !startDate) ||
    (acceptEmptyEndDate && !endDate) ||
    'Precisa ser menor ou igual a data final'
  );
};

const maxCompetency = (
  startDate,
  endDate,
  { acceptEmptyStartDate, acceptEmptyEndDate } = false,
) => {
  const fullStartDate = `01/${startDate}`;
  const fullEndDate = `01/${endDate}`;

  return (
    new Date(fullEndDate) >= new Date(fullStartDate) ||
    (acceptEmptyStartDate && !startDate) ||
    (acceptEmptyEndDate && !endDate) ||
    'Precisa ser maior ou igual a data inicial'
  );
};

const minValue = (startValue, endValue) => {
  const restoredStartValue = formatMoneyRestore(startValue);
  const restoredEndValue = formatMoneyRestore(endValue);

  return restoredStartValue <= restoredEndValue || 'Precisa ser menor ou igual ao valor final';
};

const maxValue = (startValue, endValue) => {
  const restoredStartValue = formatMoneyRestore(startValue);
  const restoredEndValue = formatMoneyRestore(endValue);

  return restoredEndValue >= restoredStartValue || 'Precisa ser maior ou igual ao valor inicial';
};

const number = (value) => /^\d+$/.test(value) || 'Somente números';

const espacoVazioInicio = (value) =>
  /^[^\s]+(\s+[^\s]+)*$/.test(value) || 'Não pode ter somente espaço em branco';

const numeroOficioRule = (value) =>
  value ? /^[0-9/-]+$/.test(value) || 'Apenas números, / ou -' : true;

export {
  required,
  requiredLength,
  email,
  minDate,
  maxDate,
  maxCurrentDate,
  maxLength,
  validDate,
  validCompetency,
  minCompetency,
  maxCompetency,
  minValue,
  maxValue,
  number,
  espacoVazioInicio,
  numeroOficioRule,
};
