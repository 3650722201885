import { capitalize, isEqual } from 'lodash';
import { formatPrefixedMoney, formatMoney } from '@/utils/currency';
import { formatDateCompetency, formatDate } from '@/utils/date';
import omitByAndNil from '@/utils/compare-values';
import { capitalizeName } from '@/utils/string';
import { instanciasDecisao } from '@/views/processos/constants';
import { enumAcaoJudicial, enumSituacaoJudicial } from '../constants';

const dadosGeral = ({ atendimento }) => ({
  competencia: atendimento?.competencia ? formatDateCompetency(atendimento?.competencia) : '',
  contagem: atendimento?.contagem,
  tipo: atendimento?.tipo,
  periodoAtendimento:
    atendimento?.dataInicio && atendimento?.dataFim
      ? `${formatDate(atendimento?.dataInicio)} a ${formatDate(atendimento?.dataFim)}`
      : '',
  numero: atendimento?.numero,
  valorTotal: formatPrefixedMoney(atendimento?.valorTotal || 0),
  nomeCompleto: `${atendimento?.beneficiario?.codigo} - ${capitalizeName(
    atendimento?.beneficiario?.nomeCompleto,
  )}`,
  codigoCco: atendimento?.beneficiario?.codigoCco,
  cpf: atendimento?.beneficiario?.cpf,
  dataNascimento: atendimento?.beneficiario?.dataNascimento
    ? formatDate(atendimento?.beneficiario?.dataNascimento)
    : '',
  listagemCritica: atendimento?.listagemCritica,
  listagemMotivoImpugnacao: atendimento?.listagemMotivoImpugnacao,
  naoImpugnado: atendimento?.naoImpugnado,
  naoRecursado: atendimento?.naoRecursado,
  formularioImpugnacao: { ...atendimento?.formularioImpugnacao } || null,
  formularioRecursao: { ...atendimento?.formularioRecursao } || null,
});

const dadosContratoPlano = ({ atendimento }) => ({
  unimedContrato: `${atendimento?.beneficiario?.unimedResponsavel || ''} / ${
    atendimento?.beneficiario?.contrato || ''
  }`,
  contratoFisico: atendimento?.beneficiario?.contratoFisico,
  empresa: atendimento?.beneficiario?.empresaContratante
    ? `${atendimento?.beneficiario?.empresaContratante} - ${atendimento?.beneficiario?.nomeEmpresaContratante}`
    : '',
  cnpj: atendimento?.beneficiario?.cnpjEmpresaContratante,
  registroProdutoAns: atendimento?.beneficiario?.registroProdutoAns,
  nomeProdutoAns: atendimento?.beneficiario?.nomeProdutoAns || '',
  tipoProduto: atendimento?.beneficiario?.tipoProduto,
  dataAdmissao: atendimento?.beneficiario?.admissao
    ? formatDate(atendimento?.beneficiario?.admissao)
    : '',
  dataAdesao: atendimento?.beneficiario?.adesao
    ? formatDate(atendimento?.beneficiario?.adesao)
    : '',
  dataExclusao: atendimento?.beneficiario?.exclusao
    ? formatDate(atendimento?.beneficiario?.exclusao)
    : '',
  dataReinclusao: atendimento?.beneficiario?.reinclusao
    ? formatDate(atendimento?.beneficiario?.reinclusao)
    : '',
  formacaoPreco: atendimento?.beneficiario?.formacaoPrecoProduto,
  tipoContratacao: atendimento?.beneficiario?.tipoContratacaoProduto,
  segmentacao: `${atendimento?.beneficiario?.segmentacaoProduto || ''} - ${
    atendimento?.beneficiario?.descricaoSegmentacaoProduto || ''
  }`,
  coparticipacao: atendimento?.beneficiario?.descricaoCoparticipacao,
  vigencia: formatDate(atendimento?.beneficiario?.vigencia) || '',
  dataTerminoCpt: formatDate(atendimento?.beneficiario?.dataFinalCoberturaParcialTemporaria) || '',
  dataCarencia: formatDate(atendimento?.beneficiario?.dataFinalCarencia) || '',
});

const dadosProcedimento = ({ atendimento }) => ({
  id: atendimento?.id,
  cnes: `${atendimento?.prestador?.cnes || ''} / ${atendimento?.prestador?.nomeUps || ''}`,
  caraterAtendimento: `${atendimento?.carater?.codigo || ''} - ${
    atendimento?.carater?.descricaoOficial || ''
  }`,
  endereco: atendimento?.prestador?.endereco?.rua,
  municipio: `${atendimento?.prestador?.endereco?.municipio} - ${atendimento?.prestador?.endereco?.codigoUf}`,
  cep: atendimento?.prestador?.endereco?.cep || '',
  codigoIbge: atendimento?.beneficiario?.codigoIbge || '',
  quantidadeTotal: atendimento?.quantidadeTotal,
  valorTotal: formatMoney(atendimento?.valorTotal || 0),
  valorDeducao: formatMoney(atendimento?.valorDeducao || 0),
  valorRetificado: formatMoney(atendimento?.valorRetificado || 0),
  procedimentos: atendimento?.procedimentos?.map((procedimento) => ({
    id: procedimento?.procedimento?.id,
    codigo: procedimento?.procedimento?.codigo,
    descricao: capitalize(procedimento?.procedimento?.descricao),
    tipo: procedimento?.tipo,
    quantidade: procedimento?.quantidade,
    valorProcedimento: formatMoney(procedimento?.valor || 0),
  })),
});

const dadosDecisao = ({ atendimentosDecididos }) =>
  atendimentosDecididos.map((atendimento) => ({
    ...atendimento,
    dataRecebimento: formatDate(atendimento.dataRecebimento),
    valorDeducaoDeferida: formatMoney(atendimento.valorDeducaoDeferida || 0),
  }));

const dadosCobranca = ({ dadosCobrancaAtendimento }) => ({
  id: dadosCobrancaAtendimento?.id,
  instancia: instanciasDecisao[dadosCobrancaAtendimento?.tipoInstancia]?.title,
  numeroOficio: dadosCobrancaAtendimento?.numeroOficio,
  dataRecebimento: formatDate(dadosCobrancaAtendimento?.dataRecebimento),
  gru: dadosCobrancaAtendimento?.gru,
  acaoJudicial: enumAcaoJudicial[dadosCobrancaAtendimento?.acaoJudicial],
  situacao: enumSituacaoJudicial[dadosCobrancaAtendimento?.situacao],
  vencimentoGru: formatDate(dadosCobrancaAtendimento?.vencimentoGru),
  numeroProcesso: dadosCobrancaAtendimento?.numeroProcesso,
  valorTotalSemJuros: formatMoney(dadosCobrancaAtendimento?.valorTotalSemJuros || 0),
  dataIncorporacaoCusto: formatDate(dadosCobrancaAtendimento?.dataIncorporacaoCusto),
  numeroFatura: dadosCobrancaAtendimento?.numeroFatura,
});

const payloadDadosGerais = ({ payloadDadosGerais }) => ({
  ...payloadDadosGerais,
  motivosImpugnacao: payloadDadosGerais.motivosImpugnacao.map((motivo) => ({
    motivoImpugnacaoId: motivo.id,
    ativo: motivo.ativo,
  })),
});

const payloadDadosGeraisToSave = ({ payloadDadosGerais }) => ({
  ...payloadDadosGerais,
  motivosImpugnacao: payloadDadosGerais.motivosImpugnacao
    .filter((motivo) => motivo.id)
    .map((motivo) => ({
      motivoImpugnacaoId: motivo.id,
      ativo: motivo.ativo,
    })),
});

const payloadDadosProcedimentos = ({ atendimento }) => ({
  id: atendimento.id,
  valorDeducao: formatMoney(atendimento.valorDeducao || 0),
});

const isInitialStateDadosGeral = ({ initialStateDadosGeral, payloadDadosGerais }) =>
  isEqual(initialStateDadosGeral, payloadDadosGerais);

const initialStateMotivosImpugnacao = ({ initialStateDadosGeral, payloadDadosGerais }) =>
  isEqual(initialStateDadosGeral.motivosImpugnacao, payloadDadosGerais.motivosImpugnacao);

const isInitialStateDadosProcedimento = (
  { initialStateDadosProcedimento },
  { payloadDadosProcedimentos },
) => isEqual(initialStateDadosProcedimento, payloadDadosProcedimentos);

const isInitialStateDadosDecisao = ({ initialStateAtendimentosDecididos, atendimentosDecididos }) =>
  isEqual(omitByAndNil(initialStateAtendimentosDecididos), omitByAndNil(atendimentosDecididos));

const hasEmptyMotivos = ({ payloadDadosGerais }) =>
  payloadDadosGerais.motivosImpugnacao.some((motivo) => motivo.id === null);

const hasMotivosAtivos = ({ payloadDadosGerais }) =>
  payloadDadosGerais.motivosImpugnacao.some((motivo) => motivo.ativo === true);

const dataOnlyView = ({ atendimento }) => ({
  somenteVisualizacao: atendimento?.somenteVisualizacao,
  messageToast: `Atendimento número ${
    atendimento?.numero
  } com status ${atendimento?.statusProtocolo?.toLowerCase()}`,
});

const hasNaoRecursadoOuNaoImpugnado = ({ atendimento }) =>
  atendimento?.naoRecursado || atendimento?.naoImpugnado;

const maisDeUmMotivoAlegacao = ({ parametros }) =>
  parametros?.find((parametro) => parametro?.nome === 'MAIS_DE_UM_MOTIVO_ALEGACAO')?.valor ===
  'SIM';

export default {
  dadosGeral,
  dadosContratoPlano,
  dadosProcedimento,
  dadosDecisao,
  dadosCobranca,
  payloadDadosGerais,
  payloadDadosGeraisToSave,
  payloadDadosProcedimentos,
  isInitialStateDadosGeral,
  initialStateMotivosImpugnacao,
  isInitialStateDadosDecisao,
  isInitialStateDadosProcedimento,
  hasEmptyMotivos,
  hasMotivosAtivos,
  dataOnlyView,
  hasNaoRecursadoOuNaoImpugnado,
  maisDeUmMotivoAlegacao,
};
