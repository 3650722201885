export default ({ abiId }) => `{
  listarVisualizacaoFiltro(
		filter: {
      abiId: ${abiId || null}
		}
	) {
		id
		contexto
		descricao
    abiId
		filtros {
      filtroId
      nome
      entidade
      descricao
      secao
      valores
    }
	}
}`;
