import Vue from 'vue';
import initialState from './state';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.dadosRelatorio, property, value);
  },
  UPDATE_VALUE_INITIAL_STATE(state, { property, value }) {
    Vue.set(state.initialState, property, value);
  },
  UPDATE_IMPRIMIR_INFOS(state, { index, property, value }) {
    Vue.set(state.dadosRelatorio.imprimir[index], property, value);
    Vue.set(state.initialState.imprimir[index], property, value);
  },
};
