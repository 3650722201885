<template>
  <loader
    v-if="loading"
    name="spinning"
    size="12"
    speed="1.5"
    opacity="60"
    disableScrolling="true"
    data-test="overlay-spinner"
    :object="fullConfig.theme.colors['secondary-lemon-medium']"
    :objectbg="fullConfig.theme.colors['primary-blue-base']"
    :bg="fullConfig.theme.colors['white']"
    :style="cssPosition"
  />
</template>

<script>
import fullConfig from '@/utils/full-config-tailwind';

export default {
  name: 'ReLoader',
  props: {
    loading: Boolean,
    position: {
      type: String,
      default: 'absolute',
    },
  },
  data() {
    return {
      fullConfig,
    };
  },
  computed: {
    cssPosition() {
      return { '--position': this.position };
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(#overlay-spinner) {
  position: var(--position);
  z-index: 3;
}

:deep(.loader-spinner) {
  width: 100px;
  height: 100px;
}
</style>
