const payloadParametros = ({ parametros }) =>
  parametros.map((parametro) => ({
    nome: parametro.nome,
    valor: parametro.valor || '',
  }));

const maisDeUmMotivoAlegacao = ({ parametrosInternos }) =>
  parametrosInternos?.find((parametro) => parametro?.nome === 'MAIS_DE_UM_MOTIVO_ALEGACAO')
    ?.valor === 'SIM';

export default {
  payloadParametros,
  maisDeUmMotivoAlegacao,
};
