<template>
  <div class="re-footer">
    <v-spacer />
    <div class="re-footer__right">
      <v-btn
        v-for="(btn, index) in configButtons[mode]"
        :key="index"
        :outlined="btn.outlined"
        :class="btn.class"
        :data-test="btn.dataTest"
        :disabled="loading"
        @click="$emit(btn.event, btn.saveAndNew)"
      >
        {{ btn.name }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReFooter',
  props: {
    mode: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      configButtons: {
        create: [
          {
            name: 'Salvar e incluir novo',
            outlined: true,
            class: 'btn btn__outlined',
            dataTest: 'btn-salvar-incluir-novo',
            event: 'save',
            saveAndNew: true,
          },
          {
            name: 'Salvar',
            class: 'btn btn__primary',
            dataTest: 'btn-salvar',
            event: 'save',
          },
        ],
        view: [
          {
            name: 'Voltar',
            class: 'btn btn__primary',
            dataTest: 'btn-voltar',
            event: 'back',
          },
        ],
        edit: [
          {
            name: 'Cancelar',
            outlined: true,
            class: 'btn btn__outlined',
            dataTest: 'btn-cancelar',
            event: 'cancel',
          },
          {
            name: 'Salvar',
            class: 'btn btn__primary',
            dataTest: 'btn-salvar',
            event: 'save',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.re-footer {
  display: flex;
  align-items: center;
  width: 100%;

  &__right {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
</style>
