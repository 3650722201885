import alegacaoAns from '../alegacao-ans/store';
import caraterAtendimento from '../carater-atendimento/store';
import detalhamentoMotivo from '../detalhamento-motivo/store';
import empresa from '../empresa/store';
import motivoImpugnacao from '../motivo-impugnacao/store';
import grupoTrabalho from '../grupo-trabalho/store';
import eventoContabil from '../evento-contabil/store';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import state from './state';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    alegacaoAns,
    caraterAtendimento,
    detalhamentoMotivo,
    empresa,
    motivoImpugnacao,
    eventoContabil,
    grupoTrabalho,
  },
};
