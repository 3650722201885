const forms = {
  nome: '',
  ativo: true,
  descricaoMotivo: '',
  motivos: [{ id: 4, codigo: 1, descricao: 'Beneficiário em carência' }],
};

export default () => ({
  detalhamentoMotivos: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingForm: false,
  detalhamentoMotivo: { ...forms },
  initialState: { ...forms },
  allMotivos: [],
});
