import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import createQueryAtendimentosCobranca from './queries/atendimentos-cobranca';

const instanciarCobrancaService = ({
  notificacaoAnsId,
  payload,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/notificacao_ans/${notificacaoAnsId}/instanciar`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDadosCobranca = ({ cobrancaId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/cobranca/${cobrancaId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getAtendimentosCobranca = async (queryParameters) =>
  createGraphQLService(createQueryAtendimentosCobranca(queryParameters));

const getResumoCobranca = ({ cobrancaId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/cobranca_atendimento/resumo/${cobrancaId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateRacunhoCobrancaService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/cobranca_atendimento`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const incorporarCustoService = ({ cobrancaId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/cobranca/incorporar/${cobrancaId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateValorDeducaoService = ({ payload, cobrancaId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/cobranca_atendimento/valor_deducao/${cobrancaId}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const removeAtendimentoCobrancaService = ({
  cobrancaId,
  payload,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/cobranca_atendimento/${cobrancaId}/atendimento`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  instanciarCobrancaService,
  getDadosCobranca,
  getAtendimentosCobranca,
  getResumoCobranca,
  updateRacunhoCobrancaService,
  incorporarCustoService,
  updateValorDeducaoService,
  removeAtendimentoCobrancaService,
};
