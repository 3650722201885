import { ressusURI } from '@/api';
import { createCommandService } from '@/services';

const declaracaoBeneficiariosService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/documento/declaracao_beneficiarios`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

export default declaracaoBeneficiariosService;
