<template>
  <v-expansion-panels v-model="panel" multiple flat>
    <v-expansion-panel>
      <v-expansion-panel-header
        hide-actions
        :class="{
          border: isBordered,
        }"
        @click="onClick"
      >
        <template v-slot:default="{ open }">
          <div class="flex items-center justify-between font-semibold">
            <div>
              {{ title }}
              <v-badge
                v-if="badge > 0"
                inline
                class="mt-0 font-normal"
                :value="badge"
                :content="badge"
              />
            </div>
            <v-icon class="float-right">
              <template v-if="open">{{ collapseIcon }}</template>
              <template v-else>{{ expandIcon }}</template>
            </v-icon>
          </div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'ReCollapse',
  props: {
    title: {
      type: String,
      required: true,
    },
    expandIcon: {
      type: String,
      default: 'mdi-chevron-down',
    },
    collapseIcon: {
      type: String,
      default: 'mdi-chevron-up',
    },
    badge: {
      type: Number,
      default: 0,
    },
    isExpanded: {
      type: Number,
    },
    isBordered: Boolean,
  },
  data() {
    return {
      panel: [this.isExpanded],
    };
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  margin-bottom: 18px;
}

.v-expansion-panel-header {
  color: theme('colors.primary-blue-base');
  background-color: theme('colors.white');
  padding: 0 12px;
  font-size: 14px;
  border-bottom: 1px solid theme('colors.primary-blue-base');

  .v-icon {
    color: theme('colors.primary-blue-base');
  }
}

.border {
  border: 1px solid theme('colors.primary-blue-base');

  + .v-expansion-panel-content {
    border: 1px solid theme('colors.primary-blue-base');
    border-top: 0;
  }
}

:deep(.v-expansion-panel-content) {
  .v-expansion-panel-content__wrap {
    padding: 16px;
    font-size: 16px;
    color: theme('colors.primary-blue-base');
  }
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
  color: theme('colors.white');
  background-color: theme('colors.primary-blue-base');

  .v-icon {
    color: theme('colors.white');
  }
}

:deep(.v-expansion-panel .v-badge__badge) {
  background-color: theme('colors.primary-blue-base') !important;
  color: theme('colors.white') !important;
}

:deep(.v-item--active .v-badge__badge) {
  background-color: theme('colors.secondary-lemon-medium') !important;
  color: theme('colors.primary-blue-base') !important;
}
</style>
