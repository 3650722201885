export default () => ({
  loading: false,
  initialStateDadosGeral: {},
  motivosImpugnacaoCadastrados: [],
  payloadDadosGerais: {},
  motivosImpugnacao: [],
  atendimento: {},
  atendimentosDecididos: [],
  initialStateAtendimentosDecididos: [],
  payloadDadosProcedimentos: {},
  initialStateDadosProcedimento: {},
  dadosCobrancaAtendimento: {},
  tabIndex: 0,
  activeTab: 'GERAL',
  situacaoProcesso: [],
  parametros: [],
});
