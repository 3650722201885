import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import ListarCarateresAtendimento from './queries/listar-carateres-atendimento';

const getCaraterAtendimento = (queryParams) =>
  createGraphQLService(ListarCarateresAtendimento(queryParams));

const getCaraterAtendimentoById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/carater_atendimento/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createCaraterAtendimentoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/carater_atendimento`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateCaraterAtendimentoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/carater_atendimento/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteCaraterAtendimentoService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/carater_atendimento/${id}`,
    onSuccess,
    onCustomError,
  });

export {
  getCaraterAtendimento,
  getCaraterAtendimentoById,
  createCaraterAtendimentoService,
  updateCaraterAtendimentoService,
  deleteCaraterAtendimentoService,
};
