import Vue from 'vue';
import { orderBy, cloneDeep } from 'lodash';
import { enumErrors } from '@/constants';
import { instanciasDecisao } from '@/views/processos/constants';
import {
  getResumoAbi,
  getResumoDecisao,
  getResumoCobranca,
  updateFieldsResumoAbi,
  cancelarCobrancaService,
} from '../services';

const loadResumoAbi = async ({ commit, dispatch }, id) => {
  dispatch('setLoading');

  await getResumoAbi({
    id,
    onSuccess: async ({ data }) => {
      await commit('GENERIC_MUTATION', {
        property: 'resumoAbi',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: cloneDeep(data),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const loadDadosDecisao = async ({ commit, dispatch }, id) => {
  dispatch('setLoading');

  await getResumoDecisao({
    id,
    onSuccess: ({ data }) => {
      const orderedData = orderBy(
        data.map((item) => ({
          ...item,
          order: instanciasDecisao[item.tipoDecisao].order,
        })),
        'order',
      );

      commit('GENERIC_MUTATION', {
        property: 'dadosDecisao',
        payload: orderedData,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateField = ({ commit }, { property, value, index }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
    index,
  });
};

const updateResumoAbi = async ({ state, dispatch }) => {
  dispatch('setLoading');

  const id = Vue.router.history.current.params?.id;

  const payload = {
    id,
    prazoFinalImpugnacao: state.resumoAbi[0].prazoFinalImpugnacao,
    resolucaoNormativa: state.resumoAbi[0].resolucaoNormativa,
    vigenciaInicialRolAns: state.resumoAbi[0].vigenciaInicialRolAns,
    vigenciaFinalRolAns: state.resumoAbi[0].vigenciaFinalRolAns,
  };

  await updateFieldsResumoAbi({
    payload,
    onSuccess: () => {
      dispatch('loadResumoAbi', id);

      Vue.$toast('Resumo ABI alterado com sucesso.', { type: 'success' });
    },
    onCustomError: ({ response }) => {
      dispatch('setLoading', false);

      response?.data?.errors.map((error) => Vue.$toast(error?.defaultMessage, { type: 'error' }));
    },
  });
};

const loadDadosCobranca = async ({ commit, dispatch }, id) => {
  dispatch('setLoading');

  await getResumoCobranca({
    id,
    onSuccess: ({ data }) => {
      const orderedData = orderBy(
        data.map((item) => ({
          ...item,
          order: instanciasDecisao[item.tipoInstancia].order,
        })),
        'order',
      );

      commit('GENERIC_MUTATION', {
        property: 'dadosCobranca',
        payload: orderedData,
      });
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'dadosCobranca',
        payload: [],
      });

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const cancelarCobranca = ({ dispatch }, cobrancaId) => {
  dispatch('setLoading');

  cancelarCobrancaService({
    cobrancaId,
    onSuccess: ({ data }) => {
      Vue.$toast(data.status, {
        type: 'success',
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response.status],
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

export default {
  loadResumoAbi,
  updateField,
  updateResumoAbi,
  loadDadosDecisao,
  loadDadosCobranca,
  cancelarCobranca,
  setLoading,
};
