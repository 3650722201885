const fields = {
  imprimir: {
    imprimirPrestador: { descricao: 'Prestador', checked: true },
    imprimirEmpresa: { descricao: 'Empresa', checked: true },
    imprimirProcedimentos: { descricao: 'Procedimentos', checked: true },
    imprimirInstancias: { descricao: 'Instâncias', checked: true },
  },
  idAbi: null,
  alegacaoAns: [],
  motivosId: [],
  situacao: [],
  competenciaInicial: null,
  competenciaFinal: null,
  numeroAtendimento: '',
  codigoProcedimento: '',
  descricaoProcedimento: '',
  codigoBeneficiario: '',
  gru: '',
  numeroOficio: '',
  nomeUnidade: '',
  codigoUnidade: '',
  codigoEmpresa: '',
  contrato: '',
};

export default fields;
