import { isEqual } from 'lodash';
import { formatPrefixedMoney, formatMoney, formatMoneyRestore } from '@/utils/currency';

const dadosAtendimentos = ({ atendimentos }) =>
  atendimentos?.map((atendimento) => ({
    id: atendimento.id,
    statusAtendimento: 'FORMULARIO_ASSINADO',
    contagem: atendimento?.contagem,
    tipo: atendimento?.tipo,
    beneficiario: atendimento?.beneficiario,
    numero: atendimento?.numero,
    valorDeducao: formatMoney(atendimento?.valorDeducao),
    valorDeducaoDeferida: formatMoney(atendimento?.valorDeducaoDeferida),
    valorRessarcimento: formatPrefixedMoney(atendimento?.valorRessarcimento || 0),
    valorTotal: formatPrefixedMoney(atendimento?.valorTotal),
    vinculadoCobranca: atendimento?.vinculadoCobranca,
  }));

const dadosCadastroGru = ({ dadosCadastroGru }) => ({
  ...dadosCadastroGru,
  valorDesconto: formatMoney(dadosCadastroGru?.valorDesconto || 0),
  valorJuros: formatMoney(dadosCadastroGru?.valorJuros || 0),
  valorMulta: formatMoney(dadosCadastroGru?.valorMulta || 0),
});

const initialStateDadosCadastroGru = ({ initialStateDadosCadastroGru }) => ({
  ...initialStateDadosCadastroGru,
  valorDesconto: formatMoney(initialStateDadosCadastroGru?.valorDesconto || 0),
  valorJuros: formatMoney(initialStateDadosCadastroGru?.valorJuros || 0),
  valorMulta: formatMoney(initialStateDadosCadastroGru?.valorMulta || 0),
});

const atendimentosSelecionados = ({ atendimentosSelecionados }) =>
  atendimentosSelecionados?.map((atendimento) => ({
    ...atendimento,
    valorDeducaoDeferida: formatMoney(atendimento.valorDeducaoDeferida || 0),
  }));

const payloadCadastroGru = (_, { dadosCadastroGru, atendimentosSelecionados }) => ({
  gru: {
    ...dadosCadastroGru,
    valorJuros: formatMoneyRestore(dadosCadastroGru?.valorJuros || 0),
    valorMulta: formatMoneyRestore(dadosCadastroGru?.valorMulta || 0),
    valorDesconto: formatMoneyRestore(dadosCadastroGru?.valorDesconto || 0),
  },
  atendimentos: atendimentosSelecionados.map((atendimento) => ({
    id: atendimento?.id,
    valorDeducaoDeferida: formatMoneyRestore(atendimento?.valorDeducaoDeferida || 0),
  })),
});

const dadosResumoCobranca = ({ resumoCobranca }) => ({
  ...resumoCobranca,
  valorTotalSemJuros: formatPrefixedMoney(resumoCobranca?.valorTotalSemJuros || 'R$ 0,00'),
});

const isInitialState = (state, { initialStateDadosCadastroGru, dadosCadastroGru }) =>
  isEqual(initialStateDadosCadastroGru, dadosCadastroGru);

export default {
  dadosAtendimentos,
  dadosCadastroGru,
  initialStateDadosCadastroGru,
  payloadCadastroGru,
  dadosResumoCobranca,
  isInitialState,
  atendimentosSelecionados,
};
