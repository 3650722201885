<template>
  <v-tabs slider-size="4" :value="tabSelectedIndex">
    <v-tab
      v-for="tab in tabs"
      :key="tab.value"
      :data-test="`tab-${tab.id}`"
      :disabled="disabled || tab.disabled"
      @click="onClick(tab.value)"
    >
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
const FIRST_INDEX = 0;

export default {
  name: 'UiStatusTab',
  props: {
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },
    disabled: Boolean,
  },
  computed: {
    tabSelectedIndex() {
      const tabFiltered = this.tabs.filter((tab) => tab.value === this.$route?.query?.tab);

      return tabFiltered[FIRST_INDEX]?.id;
    },
  },
  methods: {
    onClick(tabValue) {
      this.$emit('onClick', tabValue);
    },
  },
};
</script>
