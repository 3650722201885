const enumTabs = {
  ATIVOS: true,
  INATIVOS: false,
};

const tooltipContent = {
  EDITAR: 'Editar',
  EXCLUIR: 'Excluir',
  VISUALIZAR: 'Visualizar',
  ARQUIVAR: 'Arquivar',
};

const enumTabsAtendimentos = {
  EM_ANALISE: 'EM_ANALISE',
  EM_PROCESSO: 'EM_PROCESSO',
  AGUARDANDO_COBRANCA: 'AGUARDANDO_COBRANCA',
  ENCERRADO: 'ENCERRADO',
  CANCELADO_PELA_ANS: 'CANCELADO_PELA_ANS',
};

const MASKS = {
  phone: '!(##!)! #####!-####',
  cpf: '###!.###!.###!-##',
  hora: '##!:##',
  currency: {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false,
  },
};

const debounceTimeMs = 1000;
const debounceTimeMs2000 = 2000;
const pagination = { page: 1, size: 10, maxSize: 1000 };

const getPaginationSize = (size = 10) => (+size === -1 ? 9999 : size);

const enumErrors = {
  404: 'error',
  409: 'warning',
  406: 'info',
};

const PRIMEIRA_PAGINA = 1;

const confluenceURI = 'https://confluence.zitrus.com.br';

const helpPages = {
  home: `${confluenceURI}/display/SGURESSUS/Painel+do+Ressarcimento+ao+SUS`,
  'alegacao-ans': `${confluenceURI}/pages/viewpage.action?pageId=101779029`,
  'carater-atendimento': `${confluenceURI}/pages/viewpage.action?pageId=115365247`,
  'detalhamento-motivo': `${confluenceURI}/display/SGURESSUS/Cadastros+-+Detalhamento+do+motivo`,
  empresa: `${confluenceURI}/display/SGURESSUS/Cadastros+-+Empresa`,
  'motivo-impugnacao': `${confluenceURI}/pages/viewpage.action?pageId=106630371`,
  'evento-contabil': `${confluenceURI}/pages/viewpage.action?pageId=115346100`,
  'grupo-trabalho': `${confluenceURI}/display/SGURESSUS/Cadastros+-+Grupo+de+trabalho`,
  instancia: `${confluenceURI}/pages/viewpage.action?pageId=101777518`,
  integracao: `${confluenceURI}/pages/viewpage.action?pageId=106630884`,
  'procedimentos-tuss-sigtap': `${confluenceURI}/pages/viewpage.action?pageId=106641713`,
  'criticas-validacao': `${confluenceURI}/pages/viewpage.action?pageId=106631361`,
  contabilidade: `${confluenceURI}/pages/viewpage.action?pageId=115348885`,
  'oficios-notificacao': `${confluenceURI}/pages/viewpage.action?pageId=115358444`,
  'resumo-abi': `${confluenceURI}/pages/viewpage.action?pageId=115358918`,
  'atendimentos-abi': `${confluenceURI}/display/SGURESSUS/Processos+-+Atendimentos`,
  'protocolos-eletronicos': `${confluenceURI}/pages/viewpage.action?pageId=115351119`,
  'documentos-gerados': `${confluenceURI}/display/SGURESSUS/Processos+-+Documentos+gerados`,
  'lancamentos-contabeis': `${confluenceURI}/pages/viewpage.action?pageId=115349006`,
  'solicitacao-documentos': `${confluenceURI}/pages/viewpage.action?pageId=115349018`,
  'indicadores-ressarcimento': `${confluenceURI}/pages/viewpage.action?pageId=115356989`,
  'relatorio-contabil': `${confluenceURI}/pages/viewpage.action?pageId=115350707`,
  'declaracao-beneficiarios': `${confluenceURI}/pages/viewpage.action?pageId=115357033`,
  'relatorio-atendimentos': `${confluenceURI}/pages/viewpage.action?pageId=115357257`,
};

export {
  enumTabs,
  tooltipContent,
  enumTabsAtendimentos,
  MASKS,
  debounceTimeMs,
  debounceTimeMs2000,
  pagination,
  getPaginationSize,
  enumErrors,
  PRIMEIRA_PAGINA,
  helpPages,
};
