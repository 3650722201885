import Vue from 'vue';
import { enumErrors } from '@/constants';
import { ReToastLink } from '@/components';
import indicadoresRessarcimentoService from '../services';

const gerarRelatorio = ({ dispatch, getters }) => {
  dispatch('setLoading');

  const { payloadDadosRelatorio } = getters;

  indicadoresRessarcimentoService({
    payload: payloadDadosRelatorio,
    onSuccess: async ({ data }) => {
      await dispatch(
        'ressus/processos/documentosGerados/setFilters',
        { id: data.id },
        { root: true },
      );

      Vue.$toast(
        {
          component: ReToastLink,
          props: {
            description: 'O documento está sendo gerado. Para consultar o status do documento, ',
            linkDescription: 'clique aqui.',
          },
          listeners: {
            onClickLink: () =>
              Vue.router.push({
                name: 'documentos-gerados',
              }),
          },
        },
        { type: 'info' },
      );
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response?.status],
      }),
    onFinally: () => {
      dispatch('setLoading', false);
    },
  });
};

const updateInitialState = ({ commit }, { property, value }) => {
  commit('UPDATE_INITIAL_STATE', {
    property,
    value,
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  gerarRelatorio,
  updateField,
  setLoading,
  resetState,
  updateInitialState,
};
