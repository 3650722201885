const forms = {
  codigo: null,
  ativo: true,
  motivoOficialAns: null,
  tiposDocumentos: null,
  descricaoAbreviada: null,
  alegacaoAnsDeferida: null,
  alegacaoAnsIndeferida: null,
};

export default () => ({
  motivosImpugnacao: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingForm: false,
  motivoImpugnacao: { ...forms },
  initialState: { ...forms, codigo: 0 },
  allMotivos: [],
  alegacoesAns: [],
  tiposDocumentos: [],
});
