import { isUndefined } from 'lodash';
import { enumTabs, pagination } from '@/constants';

const listarMotivoImpugnacao = ({
  page = pagination.page,
  size = pagination.size,
  sort = 'id',
  tab,
  pesquisar = '',
  codigos = [],
}) => `{
	listarMotivoImpugnacao(
		page: ${page - 1}
		size: ${size}
		sort: ["${sort}"]
		filter: {
			ativo: ${isUndefined(tab) ? null : enumTabs[tab]}
			removerMotivoOficialAnsCodigo: [${codigos}]
			pesquisar: "${pesquisar}"
		}) {
		content {
			id
			codigo
			descricaoAbreviada
			motivoOficialAns {
				id
				codigo
				descricao
			}
			tiposDocumentos {
				id
				codigo
				nome
				ativo
			}
			alegacaoAnsIndeferida {
				id
				codigo
				descricao
				processoAbi
			}
			alegacaoAnsDeferida {
				id
				codigo
				descricao
				processoAbi
			}
			ativo
		}
		totalPages
		totalElements
		size
		first
		last
	}
}`;

export default listarMotivoImpugnacao;
