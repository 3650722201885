import { pagination } from '@/constants';

const listarTipoDocumento = ({ page = pagination.page, size = pagination.size }) => `{
	listarTipoDocumento(
		page: ${page - 1}
		size: ${size}) {
		content {
			id
      codigo
      nome
      ativo
		}
	}
}`;

export default listarTipoDocumento;
