import { required } from '@/utils/validation-rules';

export default {
  data() {
    return {
      valid: true,
      showModalAtencao: false,
      controlModalAtencao: true,
      to: null,
      from: null,
      required,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setFromRoute(from);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.to = to;

    if (this.controlModalAtencao && !this.isInitialState && to.name !== 'empty-state') {
      this.onToggleModalAtencao();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    onToggleModalAtencao(closeWhenPressingX = false) {
      this.showModalAtencao = !this.showModalAtencao;

      if (closeWhenPressingX) {
        this.to = null;
      }
    },
    onCancel() {
      if (!this.to) {
        this.onLoad();
      } else {
        this.controlModalAtencao = false;

        this.resetState();
        this.goTo(this.to.name);
      }
    },
    async onSave() {
      await this.$refs.form.validate();

      if (this.valid) {
        await this.updateParametros();

        if (this.to) {
          this.controlModalAtencao = false;
          this.goTo(this.to);
        } else {
          this.controlModalAtencao = true;
        }
      }
    },
    setFromRoute(from) {
      this.from = from;
    },
    goTo(router = this.redirectRoute) {
      this.$router
        .push({
          name: router,
          params: { ...this.from?.params },
          query: { ...this.from?.query },
        })
        .catch(() => {});
    },
    isLastItem(index, list = 'parametros') {
      return index === this[list].length - 1;
    },
  },
};
