import Vue from 'vue';
import { ReToastLink } from '@/components';
import omitByAndNil from '@/utils/compare-values';
import { enumErrors } from '@/constants';
import {
  getAtendimentos,
  getAtendimentosAssinaveis,
  atendimentoNaoImpugnarService,
  atendimentoNaoRecursarService,
  updateDefinirMotivosService,
  gerarLaudoService,
  exportarRelatorioAtendimentosService,
  exportarRelatorioDocumentosService,
  validateMotivoNecessitaValorDeducao,
} from '../services';
import { generateFilteredValues } from '../drawer-filtros-colunas/constants';

const mountFilters = ({ idAbi, situacaoProcesso, parse = false }) => {
  const visualizacaoFiltro = JSON.parse(localStorage.getItem('visualizacaoFiltro'));
  const generatedFilters = generateFilteredValues(visualizacaoFiltro?.filtros);

  const configItems = (items, type = 'string') => {
    if (items) {
      if (type === 'string') {
        return items.filter((item) => item && item !== 'null' && item.replace(/,/g, ''));
      }

      if (type === 'number') {
        return items.filter((item) => item && item !== 'null' && item).map((item) => +item);
      }

      if (type === 'enum') {
        if (Array.isArray(items)) {
          return items.map((item) => item && item !== 'null' && `%${item}%`);
        }

        return [`%${items}%`];
      }
    }

    return null;
  };

  const mappedFilters = {
    idAbi: +idAbi || null,
    acaoJudicial: configItems(generatedFilters?.acaoJudicial),
    alegacaoAns: configItems(generatedFilters?.alegacaoAns, 'enum'),
    cepPrestador: configItems(generatedFilters?.cepPrestador),
    cnes: configItems(generatedFilters?.cnes),
    cnpjEmpresaContratante: configItems(generatedFilters?.cnpjEmpresaContratante),
    codigoBeneficiario: configItems(generatedFilters?.codigoBeneficiario),
    codigoCaraterAtendimento: configItems(generatedFilters?.codigoCaraterAtendimento, 'number'),
    codigoCco: configItems(generatedFilters?.codigoCco),
    codigoEmpresaContratante: configItems(generatedFilters?.codigoEmpresaContratante),
    codigoIbgePrestador: configItems(generatedFilters?.codigoIbgePrestador, 'number'),
    codigoProcedimento: configItems(generatedFilters?.codigoProcedimento),
    codigoSegmentacaoProduto: configItems(generatedFilters?.codigoSegmentacaoProduto),
    codigoUfPrestador: configItems(generatedFilters?.codigoUfPrestador),
    competencia: configItems(generatedFilters?.competencia),
    contagem: configItems(generatedFilters?.contagem),
    contrato: configItems(generatedFilters?.contrato),
    contratoFisico: configItems(generatedFilters?.contratoFisico),
    cpf: configItems(generatedFilters?.cpf),
    critica: configItems(generatedFilters?.critica, 'enum'),
    dataAdesao: configItems(generatedFilters?.dataAdesao),
    dataAdmissao: configItems(generatedFilters?.dataAdmissao),
    dataExclusao: configItems(generatedFilters?.dataExclusao),
    dataFim: configItems(generatedFilters?.dataFim),
    dataIncorporacaoCusto: configItems(generatedFilters?.dataIncorporacaoCusto),
    dataInicio: configItems(generatedFilters?.dataInicio),
    dataNascimento: configItems(generatedFilters?.dataNascimento),
    dataRecebimentoGru: configItems(generatedFilters?.dataRecebimentoGru),
    dataReinclusao: configItems(generatedFilters?.dataReinclusao),
    dataVencimentoGru: configItems(generatedFilters?.dataVencimentoGru),
    descricaoAbreviadaCaraterAtendimento: configItems(
      generatedFilters?.descricaoAbreviadaCaraterAtendimento,
    ),
    descricaoCoparticipacao: configItems(generatedFilters?.descricaoCoparticipacao),
    descricaoProcedimento: configItems(generatedFilters?.descricaoProcedimento),
    descricaoSegmentacaoProduto: configItems(generatedFilters?.descricaoSegmentacaoProduto),
    gru: configItems(generatedFilters?.gru),
    motivoImpugnacao: configItems(generatedFilters?.motivoImpugnacao),
    municipioPrestador: configItems(generatedFilters?.municipioPrestador),
    nomeCompleto: configItems(generatedFilters?.nomeCompleto),
    nomeEmpresaContratante: configItems(generatedFilters?.nomeEmpresaContratante),
    nomeProdutoAns: configItems(generatedFilters?.nomeProdutoAns),
    nomeUps: configItems(generatedFilters?.nomeUps),
    numero: configItems(generatedFilters?.numero),
    numeroProcessoJudicial: configItems(generatedFilters?.numeroProcessoJudicial),
    observacoes: configItems(generatedFilters?.observacoes),
    opiniaoAns: configItems(generatedFilters?.opiniaoAns),
    quantidadeProcedimento: configItems(generatedFilters?.quantidadeProcedimento, 'number'),
    registroProdutoAns: configItems(generatedFilters?.registroProdutoAns),
    ruaPrestador: configItems(generatedFilters?.ruaPrestador),
    situacaoJudicial: configItems(generatedFilters?.situacaoJudicial),
    situacaoProcesso: configItems(situacaoProcesso, 'enum'),
    statusAtendimento: configItems(generatedFilters?.statusAtendimento, 'enum'),
    tipo: configItems(generatedFilters?.tipo, 'enum'),
    tipoContratacaoProduto: configItems(generatedFilters?.tipoContratacaoProduto),
    tipoProduto: configItems(generatedFilters?.tipoProduto, 'enum'),
    unimed: configItems(generatedFilters?.unimed, 'number'),
    valorDeducao: configItems(generatedFilters?.valorDeducao, 'number'),
    valorProcedimento: configItems(generatedFilters?.valorProcedimento, 'number'),
    valorRessarcimento: configItems(generatedFilters?.valorRessarcimento, 'number'),
    valorRetificado: configItems(generatedFilters?.valorRetificado, 'number'),
    valorTotalAtendimento: configItems(generatedFilters?.valorTotalAtendimento, 'number'),
    formacaoPrecoProduto: configItems(generatedFilters?.formacaoPrecoProduto),
    numeroOficioDecisao: configItems(generatedFilters?.numeroOficioDecisao),
    numeroOficioGru: configItems(generatedFilters?.numeroOficioGru),
    valorDeducaoDeferida: configItems(generatedFilters?.valorDeducaoDeferida, 'number'),
    dataRecebimentoDecisao: configItems(generatedFilters?.dataRecebimentoDecisao),
    numeroFatura: configItems(generatedFilters?.numeroFatura),
    descricaoInstanciaGru: configItems(generatedFilters?.descricaoInstanciaGru),
    descricaoInstanciaDecisao: configItems(generatedFilters?.descricaoInstanciaDecisao),
    dataVigencia: configItems(generatedFilters?.dataVigencia),
    dataFinalCarencia: configItems(generatedFilters?.dataFinalCarencia),
    dataFinalCoberturaParcialTemporaria: configItems(
      generatedFilters?.dataFinalCoberturaParcialTemporaria,
    ),
  };

  const stringFilters = JSON.stringify(omitByAndNil(mappedFilters));

  const formattedEnums = stringFilters
    .replace(/"(\w+)":/g, '$1:')
    .replace(/"%([^%]+)%"/g, (match, p1) => p1);

  const formattedParseEnums = stringFilters.replace(/"%([^%]+)%"/g, (_, p1) => `"${p1}"`);

  return parse ? JSON.parse(formattedParseEnums) : formattedEnums;
};

const loadAtendimentos = async ({ commit, dispatch }, query) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });
  commit('GENERIC_MUTATION', {
    property: 'atendimentos',
    payload: [],
  });

  const { payload, error } = await getAtendimentos({
    ...query,
    filters: mountFilters({
      idAbi: query.idAbi,
      situacaoProcesso: query.tab,
      parse: false,
    }),
  });

  if (error) {
    Vue.$toast(error, { type: 'error' });
  } else {
    commit('GENERIC_MUTATION', {
      property: 'atendimentos',
      payload: payload?.listarAtendimentos?.content,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalElements',
      payload: payload?.listarAtendimentos?.totalElements,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalPages',
      payload: payload?.listarAtendimentos?.totalPages,
    });
  }

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });

  await dispatch(
    'ressus/processos/atendimentosAbi/filtrosAvancados/loadListagemFiltro',
    { abiId: query?.idAbi },
    {
      root: true,
    },
  );
};

const loadAtendimentosAssinaveis = async ({ commit }, queryParams) => {
  const { payload } = await getAtendimentosAssinaveis(queryParams);

  commit('GENERIC_MUTATION', {
    property: 'atendimentosAssinaveis',
    payload: payload?.listarAtendimentosAssinaveis,
  });
};

const atendimentoNaoImpugnar = (
  { commit, dispatch },
  { todosAtendimentos, atendimentos, idAbi, situacaoProcesso, urlQueries },
) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const payload = todosAtendimentos
    ? {
        abiId: idAbi,
        situacao: situacaoProcesso,
        todosAtendimentos: true,
      }
    : {
        atendimentoIds: atendimentos,
        todosAtendimentos: false,
      };

  atendimentoNaoImpugnarService({
    payload,
    onSuccess: async () => {
      Vue.$toast('Ação concluída com sucesso', { type: 'success' });

      Vue.router
        .replace({
          name: 'atendimentos-abi',
          params: { id: idAbi },
          query: { ...urlQueries, page: 1 },
        })
        .catch(() => {});

      dispatch('loadAtendimentos', {
        ...urlQueries,
        idAbi,
      });
    },
    onCustomError: ({ response }) => {
      const errors = response?.data?.errors;

      if (errors) {
        errors.map((error) =>
          Vue.$toast(error.defaultMessage, {
            type: 'error',
          }),
        );
      } else {
        Vue.$toast(response?.data?.message, {
          type: 'error',
        });
      }
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const atendimentoNaoRecursar = (
  { commit, dispatch },
  { todosAtendimentos, atendimentos, idAbi, situacaoProcesso, urlQueries },
) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const payload = todosAtendimentos
    ? {
        abiId: idAbi,
        situacao: situacaoProcesso,
        todosAtendimentos: true,
      }
    : {
        atendimentoIds: atendimentos,
        todosAtendimentos: false,
      };

  atendimentoNaoRecursarService({
    payload,
    onSuccess: async () => {
      Vue.$toast('Ação concluída com sucesso', { type: 'success' });

      Vue.router
        .replace({
          name: 'atendimentos-abi',
          params: { id: idAbi },
          query: { ...urlQueries, page: 1 },
        })
        .catch(() => {});

      dispatch('loadAtendimentos', {
        ...urlQueries,
        idAbi,
      });
    },
    onCustomError: ({ response }) => {
      const { errors } = response.data;

      if (errors) {
        errors.map((error) =>
          Vue.$toast(error.defaultMessage, {
            type: 'error',
          }),
        );
      } else {
        Vue.$toast(response?.data?.message, {
          type: 'error',
        });
      }
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const definirMotivos = (
  { commit, dispatch },
  { todosAtendimentos, atendimentos, motivosImpugnacao, idAbi, situacaoProcesso, urlQueries },
) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const payload = todosAtendimentos
    ? {
        abiId: idAbi,
        situacao: situacaoProcesso,
        todosAtendimentos: true,
        motivoImpugnacaoIds: motivosImpugnacao,
      }
    : {
        atendimentoIds: atendimentos,
        motivoImpugnacaoIds: motivosImpugnacao,
        todosAtendimentos: false,
      };

  updateDefinirMotivosService({
    payload,
    onSuccess: ({ data: { avisoFormularioAlterado } }) => {
      if (avisoFormularioAlterado?.formularioAlterado)
        Vue.$toast(avisoFormularioAlterado?.mensagemAvisoFormularioAlterado, {
          type: 'info',
        });

      Vue.$toast('Ação concluída com sucesso', { type: 'success' });

      dispatch('loadAtendimentos', {
        ...urlQueries,
        idAbi,
      });
    },
    onCustomError: ({ response }) => {
      const errors = response?.data?.errors;

      if (errors) {
        errors.map((error) =>
          Vue.$toast(error.defaultMessage, {
            type: 'error',
          }),
        );
      } else {
        Vue.$toast(response?.data?.message, {
          type: 'error',
        });
      }
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const gerarLaudo = async (
  { commit, dispatch },
  { todosAtendimentos, atendimentos, idAbi, situacaoProcesso },
) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const payload = todosAtendimentos
    ? {
        abiId: idAbi,
        situacao: situacaoProcesso,
        todosAtendimentos: true,
      }
    : {
        atendimentoIds: atendimentos,
        todosAtendimentos: false,
      };

  await gerarLaudoService({
    payload,
    onSuccess: async ({ data }) => {
      await dispatch(
        'ressus/processos/documentosGerados/setFilters',
        { id: data.id },
        { root: true },
      );

      Vue.$toast(
        {
          component: ReToastLink,
          props: {
            description:
              'O documento em lote está sendo gerado. Para consultar o status do documento, ',
            linkDescription: 'clique aqui.',
          },
          listeners: {
            onClickLink: () => Vue.router.push({ name: 'documentos-gerados' }),
          },
        },
        { type: 'info' },
      );
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const exportarRelatorioAtendimentos = ({ dispatch }, payload) => {
  const payloadData =
    payload.key === 'TODAS_ABAS'
      ? { idAbi: payload.idAbi }
      : mountFilters({
          idAbi: payload.idAbi,
          situacaoProcesso: payload.situacaoProcesso,
          parse: true,
        });

  exportarRelatorioAtendimentosService({
    path: payload.path,
    id: payload.atendimentoIds,
    payload: payloadData,
    onSuccess: async ({ data }) => {
      await dispatch(
        'ressus/processos/documentosGerados/setFilters',
        { id: data.id },
        { root: true },
      );
      Vue.$toast(
        {
          component: ReToastLink,
          props: {
            description: 'A planilha está sendo gerada. Para consultar, ',
            linkDescription: 'clique aqui.',
          },
          listeners: {
            onClickLink: () => Vue.router.push({ name: 'documentos-gerados' }),
          },
        },
        { type: 'info' },
      );
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response?.status],
      }),
  });
};

const exportarRelatorioDocumentos = ({ dispatch }, payload) => {
  exportarRelatorioDocumentosService({
    payload,
    onSuccess: async ({ data }) => {
      await dispatch(
        'ressus/processos/documentosGerados/setFilters',
        { id: data.id },
        { root: true },
      );
      Vue.$toast(
        {
          component: ReToastLink,
          props: {
            description: 'A planilha está sendo gerada. Para consultar, ',
            linkDescription: 'clique aqui.',
          },
          listeners: {
            onClickLink: () => Vue.router.push({ name: 'documentos-gerados' }),
          },
        },
        { type: 'info' },
      );
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response?.status],
      }),
  });
};

const validateMotivo = (_, atendimentoId) => {
  let isValidateMotivos = null;

  return validateMotivoNecessitaValorDeducao({
    atendimentoId,
    onSuccess: () => {
      isValidateMotivos = true;

      return isValidateMotivos;
    },
    onCustomError: ({ response }) => {
      isValidateMotivos = false;

      Vue.$toast(response?.data?.message, {
        type: 'warning',
      });

      return isValidateMotivos;
    },
  });
};

const resetState = ({ commit }) => commit('RESET_STATE');

export default {
  loadAtendimentos,
  loadAtendimentosAssinaveis,
  atendimentoNaoImpugnar,
  atendimentoNaoRecursar,
  definirMotivos,
  gerarLaudo,
  exportarRelatorioAtendimentos,
  exportarRelatorioDocumentos,
  validateMotivo,
  resetState,
};
