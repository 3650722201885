import Vue from 'vue';
import { groupBy, find, cloneDeep, isEmpty } from 'lodash';
import {
  ID_VISUALIZACAO_PADRAO,
  ID_ACAO_CRIAR,
  itemsCheckbox,
  applyFormat,
  generateFilteredValues,
} from '../constants';
import {
  getVisualizacoesColunas,
  getVisualizacaoById,
  getVisualizacaoFiltrosSalvos,
  getListagemFiltros,
  createVisualizacaoColuna,
  updateVisualizacaoColuna,
  deleteVisualizacaoColuna,
  saveVisualizacaoFiltro,
  updateVisualizacaoFiltroAvancadoService,
  deleteVisualizacaoFiltroAvancadoService,
  getDataFilterService,
} from '../services';

const loadListagemFiltro = async ({ commit, dispatch }, { abiId, isCloseDrawer = false }) => {
  if (!isCloseDrawer) {
    const { payload } = await getListagemFiltros();

    const filtrosAvancados = payload?.listarFiltro?.map((filtro) => ({
      ...filtro,
      countSubitemsSelected: 0,
      valores: [],
      options: [],
    }));

    commit('GENERIC_MUTATION', {
      property: 'filtrosAvancados',
      payload: filtrosAvancados,
    });

    dispatch('cloneFiltrosAvancados', filtrosAvancados);
    dispatch('loadFiltrosSalvos', { abiId });
  }
};

const loadFiltrosSalvos = async ({ commit, state, dispatch }, { action, abiId }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingFiltros',
    payload: true,
  });

  const visualizacaoFiltro = JSON.parse(localStorage.getItem('visualizacaoFiltro'));
  const { payload } = await getVisualizacaoFiltrosSalvos({
    abiId,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingFiltros',
    payload: false,
  });

  commit('GENERIC_MUTATION', {
    property: 'listaVisualizacoesFiltros',
    payload: payload?.listarVisualizacaoFiltro,
  });

  const visualizacaoFiltroSelecionado = payload?.listarVisualizacaoFiltro.find(
    (item) => item.descricao === visualizacaoFiltro?.descricao,
  );

  commit('GENERIC_MUTATION', {
    property: 'visualizacaoFiltrosAvancadoSelecionado',
    payload: visualizacaoFiltroSelecionado || visualizacaoFiltro,
  });

  if (action === ID_ACAO_CRIAR) {
    const ultimaVisualizacaoCriada = await state.listaVisualizacoesFiltros?.pop();

    commit('GENERIC_MUTATION', {
      property: 'visualizacaoFiltrosAvancadoSelecionado',
      payload: ultimaVisualizacaoCriada,
    });
  }

  dispatch('onClearFilter');
};

const updateVisualizacaoFiltroAvancado = async ({ dispatch }, payload) => {
  dispatch('setLoading');
  const { filtros } = payload;
  const { query, params } = Vue.router.history.current;

  const newFiltros = filtros.map((item) => ({
    filtroId: item.id,
    nome: item.nome,
    valores: item.valores.map((value) => value),
  }));

  const newPayload = {
    ...payload,
    filtros: newFiltros,
  };

  await updateVisualizacaoFiltroAvancadoService({
    payload: { ...newPayload },
    onSuccess: async () => {
      Vue.$toast('Filtro atualizado com sucesso', { type: 'success' });

      const filter = newFiltros.reduce((acc, item) => {
        const { nome, valores } = item;
        acc[nome] = valores.map((value) => applyFormat(value, nome));
        return acc;
      }, {});

      localStorage.setItem('visualizacaoFiltro', JSON.stringify(payload));

      await dispatch(
        'ressus/processos/atendimentosAbi/loadAtendimentos',
        {
          ...query,
          idAbi: params?.id,
          ...filter,
        },
        { root: true },
      );
      dispatch('onClearFilter');
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });

  dispatch('closeDrawerFiltrosColunas');
};

const applyVisualizacaoFiltro = async ({ dispatch }, visualizacaoFiltroSelecionado) => {
  const { query, params } = Vue.router.history.current;
  const { optionActionValue, abiId } = visualizacaoFiltroSelecionado;

  query.page = 1;
  dispatch('setLoading');

  if (!isEmpty(optionActionValue)) {
    const filtrosPayload = visualizacaoFiltroSelecionado?.filtros.map((item) => ({
      filtroId: item.id,
      valores: item.valores,
    }));

    await saveVisualizacaoFiltro({
      payload: {
        ...visualizacaoFiltroSelecionado,
        abiId,
        filtros: filtrosPayload,
      },
      onSuccess: async () => {
        Vue.$toast('Filtro salvo com sucesso', { type: 'success' });
        await dispatch('loadFiltrosSalvos', { action: optionActionValue?.id });
      },
      onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
      onFinally: () => dispatch('setLoading', false),
    });
  }

  if (localStorage.getItem('visualizacaoFiltro')) localStorage.removeItem('visualizacaoFiltro');

  localStorage.setItem('visualizacaoFiltro', JSON.stringify(visualizacaoFiltroSelecionado));

  dispatch('closeDrawerFiltrosColunas');

  const atendimentosParams = {
    ...query,
    idAbi: params?.id,
    ...generateFilteredValues(visualizacaoFiltroSelecionado?.filtros),
  };

  await dispatch('ressus/processos/atendimentosAbi/loadAtendimentos', atendimentosParams, {
    root: true,
  });
};

const itemSelected = ({ commit }, payload) => {
  commit('ITEM_SELECTED', payload);
};

const loadVisualizacoes = async ({ commit, dispatch }) => {
  dispatch('setLoading');

  commit('GENERIC_MUTATION', {
    property: 'listaVisualizacoesColunas',
    payload: [],
  });

  const { payload } = await getVisualizacoesColunas();

  commit('GENERIC_MUTATION', {
    property: 'listaVisualizacoesColunas',
    payload: payload?.listarVisualizacaoColuna,
  });

  await dispatch('loadVisualizacao', {});

  dispatch('setLoading', false);
};

const loadVisualizacao = async ({ commit, dispatch }, { visualizacaoPadrao = false }) => {
  let payload = JSON.parse(localStorage.getItem('visualizacaoColunas'));
  const visualizacao = await dispatch('findVisualizacao', payload?.id);

  if (visualizacaoPadrao || !visualizacao) {
    await getVisualizacaoById({
      id: ID_VISUALIZACAO_PADRAO,
      onSuccess: ({ data }) => {
        payload = { ...data, id: data?.id.toString() };

        dispatch('applyVisualizacao', {
          visualizacao: payload,
          closeDrawer: false,
        });
      },
      onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    });
  }

  commit('GENERIC_MUTATION', {
    property: 'visualizacaoSelecionada',
    payload,
  });

  dispatch('loadSecoes', payload?.colunas);
};

const findVisualizacao = ({ state }, id) => find(state.listaVisualizacoesColunas, { id });

const loadSecoes = ({ commit }, colunas) =>
  commit('GENERIC_MUTATION', {
    property: 'listaSecoesColunas',
    payload: groupBy(colunas, 'secao'),
  });

const updateStateVisualizacao = ({ commit }, visualizacao) =>
  commit('GENERIC_MUTATION', {
    property: 'visualizacaoSelecionada',
    payload: visualizacao,
  });

const applyVisualizacao = async ({ dispatch }, { visualizacao, closeDrawer = true }) => {
  localStorage.setItem('visualizacaoColunas', JSON.stringify(visualizacao));

  closeDrawer && dispatch('closeDrawerFiltrosColunas');
};

const createVisualizacao = ({ dispatch }, visualizacao) => {
  dispatch('setLoading');

  createVisualizacaoColuna({
    payload: visualizacao,
    onSuccess: ({ data }) => {
      Vue.$toast('Visualização salva com sucesso', { type: 'success' });

      dispatch('applyVisualizacao', {
        visualizacao: {
          ...data,
          id: data?.id.toString(),
        },
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateVisualizacao = ({ dispatch }, visualizacao) => {
  if (visualizacao.id === ID_VISUALIZACAO_PADRAO) {
    Vue.$toast('Não é possível alterar a visualização padrão', { type: 'error' });
    return;
  }

  dispatch('setLoading');

  updateVisualizacaoColuna({
    payload: visualizacao,
    onSuccess: ({ data }) => {
      Vue.$toast('Visualização salva com sucesso', { type: 'success' });

      dispatch('applyVisualizacao', {
        visualizacao: {
          ...data,
          id: data?.id.toString(),
        },
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const deleteVisualizacao = ({ dispatch }, { id }) => {
  if (id === ID_VISUALIZACAO_PADRAO) {
    Vue.$toast('Não é possível excluir a visualização padrão', { type: 'error' });
    return;
  }

  dispatch('setLoading');

  deleteVisualizacaoColuna({
    id,
    onSuccess: async () => {
      Vue.$toast('Visualização excluída com sucesso', { type: 'success' });

      localStorage.removeItem('visualizacaoColunas');

      await dispatch('closeDrawerFiltrosColunas');
      dispatch('loadVisualizacoes');
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const closeDrawerFiltrosColunas = (_, to) => {
  Vue.router
    .replace({
      name: to ? to.name : 'atendimentos-abi',
      params: to ? to.params : { ...Vue.router.history.current.params },
      query: to ? to.query : { ...Vue.router.history.current.query, drawer: undefined },
    })
    .catch(() => {});
};

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

const setOptionFilterSelected = ({ commit, dispatch, state }, { value, cloneFiltro }) => {
  const { filtrosAvancados } = state;
  const { filtros } = value;

  const updatedFiltrosAvancados = (filtrosAvancados || []).map((item) => {
    const updatedItem = { ...item };
    const filtro = filtros?.find((item) => item.nome === updatedItem.nome);

    if (filtro) {
      updatedItem.valores = filtro.valores.map((value) => value);
      updatedItem.options = updatedItem.options.map((option) => ({
        ...option,
        checked: filtro.valores.includes(option.value),
      }));
      updatedItem.countSubitemsSelected = itemsCheckbox.includes(filtro.nome)
        ? filtro.valores.length
        : 1;
    }

    return updatedItem;
  });

  commit('GENERIC_MUTATION', {
    property: 'filtrosAvancados',
    payload: updatedFiltrosAvancados,
  });

  if (cloneFiltro) dispatch('cloneFiltrosAvancados', updatedFiltrosAvancados);
};

const deleteVisualizacaoFiltroAvancado = async ({ dispatch }, { id }) => {
  const { query, params } = Vue.router.history.current;

  dispatch('setLoading');

  await deleteVisualizacaoFiltroAvancadoService({
    id,
    onSuccess: async () => {
      Vue.$toast('Filtro excluído com sucesso', { type: 'success' });

      localStorage.removeItem('visualizacaoFiltro');

      await dispatch('closeDrawerFiltrosColunas');

      await dispatch(
        'ressus/processos/atendimentosAbi/loadAtendimentos',
        {
          ...query,
          idAbi: params?.id,
        },
        { root: true },
      );
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const onClearFilter = ({ commit, dispatch, state: { filtrosAvancados } }) => {
  dispatch('setLoading', true);

  const updatedFiltrosAvancados = filtrosAvancados?.map((item) => ({
    ...item,
    options: item?.options?.map((option) => ({ ...option, checked: false })),
    valores: [],
    countSubitemsSelected: 0,
  }));

  commit('GENERIC_MUTATION', {
    property: 'filtrosAvancados',
    payload: updatedFiltrosAvancados,
  });

  dispatch('setLoading', false);
};

const updatedOptionsBySearch = (
  { commit, state: { filtrosAvancados }, dispatch },
  { search, subitem },
) => {
  if (search?.length >= 1) {
    const findSearch = subitem.options.filter((option) =>
      option?.descricao?.toLowerCase().includes(search),
    );

    const updatedFiltrosAvancados = filtrosAvancados?.map((item) => {
      if (item.nome === subitem.nome) {
        return {
          ...item,
          options: findSearch,
        };
      }

      return item;
    });

    commit('GENERIC_MUTATION', {
      property: 'filtrosAvancados',
      payload: updatedFiltrosAvancados,
    });
  } else {
    dispatch('getDataFilter', subitem);
  }
};

const getDataFilter = async ({ commit, state, dispatch }, payload) => {
  dispatch('setLoading');

  const { nome, nomeCampo, entidade, valores } = payload;
  const {
    params: { id },
  } = Vue.router.history.current;

  if (itemsCheckbox.includes(nome)) {
    await getDataFilterService({
      payload: {
        idAbi: id,
        entidade,
        filter: nomeCampo,
      },
      onSuccess: ({ data }) => {
        const filtrosAvancados = state.filtrosAvancados.map((item) => {
          if (item.nome === nome) {
            return {
              ...item,
              options: data?.map((option) => ({
                descricao: option?.toString()?.replace(/[-_]/g, ' ') || option,
                value: option?.toString(),
                checked: valores?.includes(option?.toString()) || false,
              })),
            };
          }

          return item;
        });

        commit('GENERIC_MUTATION', {
          property: 'filtrosAvancados',
          payload: filtrosAvancados,
        });
      },
      onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    });
  }

  dispatch('setLoading', false);
};

const cloneFiltrosAvancados = ({ commit }, payload) => {
  commit('GENERIC_MUTATION', {
    property: 'initialFilters',
    payload: cloneDeep(payload),
  });
};

const resetStateFiltrosColunas = ({ commit }) => commit('RESET_STATE');

const updateField = ({ commit }, { property, payload }) =>
  commit('GENERIC_MUTATION', {
    property,
    payload,
  });

export default {
  loadListagemFiltro,
  loadFiltrosSalvos,
  updateVisualizacaoFiltroAvancado,
  applyVisualizacaoFiltro,
  itemSelected,
  loadVisualizacoes,
  loadVisualizacao,
  findVisualizacao,
  loadSecoes,
  updateStateVisualizacao,
  applyVisualizacao,
  createVisualizacao,
  updateVisualizacao,
  deleteVisualizacao,
  closeDrawerFiltrosColunas,
  setLoading,
  setOptionFilterSelected,
  deleteVisualizacaoFiltroAvancado,
  onClearFilter,
  updatedOptionsBySearch,
  getDataFilter,
  cloneFiltrosAvancados,
  resetStateFiltrosColunas,
  updateField,
};
