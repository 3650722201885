import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import ListarEmpresas from './queries/listar-empresas';
import ListarDocumentosComprobatoriosPorEmpresa from './queries/listar-documento-por-empresa';

const getEmpresaById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/empresa/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createEmpresaService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/empresa`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateEmpresaService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/empresa/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteEmpresaService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/empresa/${id}`,
    onSuccess,
    onCustomError,
  });

const getEmpresas = async (queryParams) => createGraphQLService(ListarEmpresas(queryParams));

const uploadDocumentoComprobatorioService = ({
  payload,
  formularioId,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/empresa/${formularioId}/documento_comprobatorio`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const listarDocumentoComprobatorioPorEmpresaService = async (queryParams) =>
  createGraphQLService(ListarDocumentosComprobatoriosPorEmpresa(queryParams));

const deleteDocumentoComprobatorioEmpresaService = ({
  empresaId,
  anexoId,
  onSuccess,
  onCustomError,
  onFinally,
}) => {
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/empresa/${empresaId}/documento_comprobatorio/${anexoId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

export {
  createEmpresaService,
  deleteEmpresaService,
  getEmpresaById,
  getEmpresas,
  updateEmpresaService,
  uploadDocumentoComprobatorioService,
  listarDocumentoComprobatorioPorEmpresaService,
  deleteDocumentoComprobatorioEmpresaService,
};
