import { pagination } from '@/constants';
import { isAllCheckedItems } from '../../constants';

export default ({
  page = pagination.page,
  size = pagination.size,
  sort = 'id',
  codigoBeneficiario = '',
  competencia = '',
  dataLancamentoInicial = '',
  dataLancamentoFinal = '',
  dataLancamentoIntegracao = '',
  dedutivel = false,
  estorno = false,
  idAbi = '',
  nomeBeneficiario = '',
  numeroAtendimento = '',
  percentualProvisionamento,
  pesquisar = '',
  processarEm = '',
  sigla = '',
  tipoAtendimento = '',
  valorLancamentoInicial,
  valorLancamentoFinal,
}) =>
  `{
    listarLancamentosContabeis(
      page: ${page - 1}
      size: ${size}
      sort: ["${sort}"]
      filter : {
        ${codigoBeneficiario ? `codigoBeneficiario: "${codigoBeneficiario}"` : ``}
        ${competencia ? `competencia: "${competencia}"` : ``}
        ${
          dataLancamentoInicial && dataLancamentoFinal
            ? `dataLancamento: ["${dataLancamentoInicial}", "${dataLancamentoFinal}"]`
            : ``
        }
        ${dataLancamentoIntegracao ? `dataLancamentoIntegracao: "${dataLancamentoIntegracao}"` : ``}
        ${dedutivel && !isAllCheckedItems(dedutivel, 'dedutivel') ? `dedutivel: ${dedutivel}` : ``}
        ${estorno && !isAllCheckedItems(estorno, 'estorno') ? `estorno: ${estorno}` : ``}
        ${idAbi ? `idAbi: ${idAbi}` : ``}
        ${nomeBeneficiario ? `nomeBeneficiario: "${nomeBeneficiario}"` : ``}
        ${numeroAtendimento ? `numeroAtendimento: "${numeroAtendimento}"` : ``}
        ${
          percentualProvisionamento ? `percentualProvisionamento: ${percentualProvisionamento}` : ``
        }
        ${pesquisar ? `pesquisar: "${pesquisar}"` : ``}
        ${processarEm ? `processarEm: [${processarEm}]` : ``}
        ${sigla ? `sigla: "${sigla}"` : ``}
        ${tipoAtendimento ? `tipoAtendimento: [${tipoAtendimento}]` : ``}
        ${
          valorLancamentoInicial > 0 || valorLancamentoFinal > 0
            ? `valor: [${valorLancamentoInicial}, ${valorLancamentoFinal}]`
            : []
        }
      }) {
      content {
        id
        dataLancamento
        sigla
        estorno
        dedutivel
        valor
        numeroAtendimento
        competencia
        tipoAtendimento
        codigoBeneficiario
        nomeBeneficiario
        percentualProvisionamento
        eventoContabil {
          processarEm
        }
      }
      totalPages
      totalElements
      size
      first
      last
    }
  }`;
