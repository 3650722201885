import { mapState, mapActions } from 'vuex';
import { find } from 'lodash';
import { required } from '@/utils/validation-rules';

export default {
  data() {
    return {
      showModalAtencao: false,
      to: null,
      from: null,
      valid: true,
      required,
    };
  },
  computed: {
    ...mapState('ressus', ['controlModalAtencao']),
    routeId() {
      return this.$route.params?.id;
    },
    routeName() {
      return this.$route.name;
    },
    isEdit() {
      return !!this.routeId;
    },
    isViewMode() {
      return !!this.routeId && this.routeName === this.viewRoute;
    },
    mode() {
      const modes = [
        { name: 'edit', value: this.isEdit && !this.isViewMode },
        { name: 'view', value: this.isViewMode },
        { name: 'create', value: !this.isEdit && !this.isViewMode },
      ];

      return find(modes, ['value', true]).name;
    },
  },
  watch: {
    routeId: {
      immediate: true,
      handler() {
        if (this.isEdit) this.onLoadById(this.routeId);
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setFromRoute(from);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.to = to;

    if (this.controlModalAtencao && !this.isInitialState) {
      this.onToggleModalAtencao();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    ...mapActions('ressus', ['updateControlModalAtencao']),
    onToggleModalAtencao(closeWhenPressingX = false) {
      this.showModalAtencao = !this.showModalAtencao;

      if (closeWhenPressingX) {
        this.to = null;
      }
    },
    onConfirm() {
      this.resetState();
      this.goTo(this.to.name);
    },
    async onSave(saveAndNew = false) {
      await this.$refs.form.validate();

      const route = {
        name: this.to?.name || this.redirectRoute,
        params: { ...this.from?.params },
        query: { ...this.from?.query },
      };

      if (this.valid) {
        this.isEdit
          ? this.onUpdate({
              saveAndNew,
              route,
              resetValidation: this.$refs.form.resetValidation,
            })
          : this.onCreate({
              saveAndNew,
              route,
              resetValidation: this.$refs.form.resetValidation,
            });
      } else {
        this.updateControlModalAtencao(true);
      }

      this.showModalAtencao = false;
    },
    setFromRoute(from) {
      this.from = from;
    },
    goTo(router = this.redirectRoute) {
      this.$router
        .push({
          name: router,
          params: { ...this.from?.params },
          query: { ...this.from?.query },
        })
        .catch(() => {});
    },
  },
};
