import { filter, groupBy, forEach } from 'lodash';
import { removeAspasDuplas } from '@/utils/string';

const colunasSelecionadas = ({ visualizacaoSelecionada }) =>
  filter(visualizacaoSelecionada.colunas, 'ativo');

const counterColunasSelecionadas = (state, { colunasSelecionadas }) => {
  const selecionadosBySecao = groupBy(colunasSelecionadas, 'secao');
  const ativosPorSecao = {};

  forEach(selecionadosBySecao, (itens, secao) => {
    let contadorAtivos = 0;

    forEach(itens, (item) => {
      if (item.ativo) contadorAtivos += 1;
    });

    ativosPorSecao[secao] = contadorAtivos;
  });

  return ativosPorSecao;
};

const getAllFiltrosSelecteds = ({ filtrosAvancados }) =>
  filtrosAvancados
    ?.filter((filtro) =>
      filtro?.valores?.some((valor) => removeAspasDuplas(valor) && valor !== '0,00'),
    )
    ?.map((filtro) => ({
      ...filtro,
      valores: filtro?.valores?.map((valor) => removeAspasDuplas(valor)),
    }));

const isFilterChanged = ({ filtrosAvancados, initialFilters }) => {
  const filtrosSelecionados = getAllFiltrosSelecteds({ filtrosAvancados });
  const filtrosIniciais = getAllFiltrosSelecteds({ filtrosAvancados: initialFilters });

  return JSON.stringify(filtrosSelecionados) !== JSON.stringify(filtrosIniciais);
};

const getFiltroAvancadoByNome =
  ({ filtrosAvancados }) =>
  (nome = '') => {
    const filtrosAgrupadosPorSecao = groupBy(filtrosAvancados, 'secao');

    const filtros = Object.entries(filtrosAgrupadosPorSecao).map(([secao, itens]) => ({
      secao,
      subitems: itens.map((item) => ({ ...item, countItemsSelected: item.valores.length })),
      countItemsSelected: itens.reduce(
        (acc, item) => acc + item.valores.filter((valor) => valor).length,
        0,
      ),
    }));

    if (!nome) return filtros;

    const filtro = filtros
      .map((item) => ({
        ...item,
        subitems: item.subitems.filter((subitem) =>
          subitem.descricao.toLowerCase().includes(nome.toLowerCase()),
        ),
      }))
      .filter((item) => item.subitems.length);

    return filtro;
  };

const filterListaSecoesColunas =
  ({ listaSecoesColunas }) =>
  (search = '') => {
    if (!search) return listaSecoesColunas;

    const result = Object.entries(listaSecoesColunas).reduce((accumulator, [section, columns]) => {
      const filteredColumns = columns.filter((column) =>
        column.descricao.toLowerCase().includes(search.toLowerCase()),
      );
      if (filteredColumns.length > 0) {
        accumulator[section] = filteredColumns;
      }
      return accumulator;
    }, {});

    return result;
  };

export default {
  colunasSelecionadas,
  counterColunasSelecionadas,
  getAllFiltrosSelecteds,
  isFilterChanged,
  getFiltroAvancadoByNome,
  filterListaSecoesColunas,
};
