export default (sort = 'ordem') => `{
  listarFiltro(
      sort: "${sort}"
      filter: {
          contexto: "atendimento"
      }
  ) {
      id
      nome
      entidade
      descricao
      contexto
      nomeCampo
      predefinido
      secao
  }
}`;
