var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-7"},[(!_vm.dadosDecisao.length)?_c('re-placeholder-tabs',{attrs:{"config-tabs":_vm.tabsDrawer,"tab-index":_vm.tabIndex}}):_c('div',_vm._l((_vm.dadosDecisao),function(item,index){return _c('re-collapse',{key:index,attrs:{"title":_vm.instanciasDecisao[item.instancia].title},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"attach":"","persistent-placeholder":"","label":"Alegação ANS","item-text":"text","item-value":"value","items":_vm.tiposAlegacaoAns,"value":item.tipoAlegacaoAns,"readonly":_vm.isReadonly(index) || _vm.isFormularioAtendimento,"append-icon":_vm.isReadonly(index) || _vm.isFormularioAtendimento ? '' : 'mdi-menu-down',"menu-props":{ offsetY: true }},on:{"change":(value) =>
                    _vm.updateFieldDecisao({
                      field: 'tipoAlegacaoAns',
                      item,
                      value: value || null,
                    })}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('re-popover-text-field',_vm._b({attrs:{"label":"Opinião ANS","breakpoint":"sm","nudge-left":"160","element-ref":`opiniao-ans-${index}`,"data-test":`text-field-opiniao-ans-${index}`,"value":item.opiniao}},'re-popover-text-field',{ dataOnlyView: _vm.dataOnlyView },false))],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"readonly":"","persistent-placeholder":"","hide-details":"","label":"Ofício","data-test":`text-field-oficio-${index}`,"value":item.numeroOficio}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"readonly":"","persistent-placeholder":"","hide-details":"","label":"Decisão recebida em","data-test":`text-field-decisao-recebida-${index}`,"value":item.dataRecebimento}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(!_vm.loading)?_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"persistent-placeholder":"","hide-details":"","maxlength":"18","prefix":"R$","label":"Dedução deferida R$","data-test":"text-field-deducao-deferida","readonly":_vm.isEncerrado || _vm.isFormularioAtendimento,"value":item.valorDeducaoDeferida},on:{"keyup":function($event){return _vm.updateFieldDecisao({
                    field: 'valorDeducaoDeferida',
                    item,
                    value: $event.target.value,
                  })},"keydown":_vm.onPreventNegativeNumbers}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h1',{staticClass:"section-title-default"},[_vm._v("Pareceres")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"border-primary-blue-base p-3 text-primary-blue-base",attrs:{"flat":""}},[(!item.pareceres.length)?_c('span',[_vm._v("Nenhum parecer encontrado")]):_c('div',_vm._l((item.pareceres),function(parecer,index){return _c('p',{key:index,staticClass:"last:mb-0 whitespace-pre-line"},[_vm._v(" "+_vm._s(parecer.descricao)+" ")])}),0)])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h1',{staticClass:"section-title-default"},[_vm._v("Observações")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","hide-details":"","maxlength":"250","placeholder":"Preenchimento opcional","data-test":`textarea-observacoes-${index}`,"value":item.observacoes,"readonly":_vm.isFormularioAtendimento},on:{"input":(value) =>
                    _vm.updateFieldDecisao({
                      field: 'observacoes',
                      item,
                      value: value || null,
                    })}})],1)],1)],1)]},proxy:true}],null,true)})}),1),(_vm.showFooterActions)?_c('portal',{attrs:{"to":"footer-drawer-atendimento"}},_vm._l((_vm.footerActions),function(action){return _c('v-btn',{key:action.key,class:action.class,attrs:{"outlined":action.outlined,"text":action.text,"data-test":`btn-${action.key}`},on:{"click":function($event){return _vm.handleClick(action)}}},[_vm._v(" "+_vm._s(action.label)+" ")])}),1):_vm._e(),_c('ui-modal',{attrs:{"type":"salvar","show":_vm.showModalAtencao},on:{"close":_vm.onClose,"exit":_vm.onExit,"save":_vm.onSaveDadosDecisao}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }