import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getParametroContabilidade = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/evento_contabil/parametro`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateParametroContabilidadeService = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/evento_contabil/parametro`,
    onSuccess,
    onCustomError,
  });

export { getParametroContabilidade, updateParametroContabilidadeService };
