import Vue from 'vue';
import { formatDateRestore } from '@/utils/date';
import { getListarDocumentos, reiniciarGeracaoDocumentoService } from '../services';

const loadDocumentos = async ({ state, commit, dispatch }, query) => {
  const queryParams = {
    ...query,
    filters: {
      ...state.filters,
      dataInicial: state.filters?.dataInicial ? formatDateRestore(state.filters.dataInicial) : '',
      dataFinal: state.filters?.dataFinal ? formatDateRestore(state.filters.dataFinal) : '',
    },
  };

  commit('GENERIC_MUTATION', {
    property: 'queryParams',
    payload: queryParams,
  });

  commit('GENERIC_MUTATION', {
    property: 'showDrawerFiltros',
    payload: false,
  });

  dispatch('setLoading');

  const { payload, error } = await getListarDocumentos(queryParams);

  if (error) {
    Vue.$toast(error.message || error, { type: 'error', id: 'error-documentos' });

    commit('GENERIC_MUTATION', {
      property: 'documentos',
      payload: [],
    });
  } else {
    commit('GENERIC_MUTATION', {
      property: 'documentos',
      payload: payload?.listarDocumentos?.content,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalElements',
      payload: payload?.listarDocumentos?.totalElements,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalPages',
      payload: payload?.listarDocumentos?.totalPages,
    });
  }

  dispatch('setLoading', false);
};

const reiniciarGeracaoDocumento = ({ state, dispatch }, id) => {
  dispatch('setLoading');

  reiniciarGeracaoDocumentoService({
    id,
    onSuccess: () => dispatch('loadDocumentos', state.queryParams),
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

const setFilters = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'filters',
    payload,
  });

const updateFieldStatus = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'documentos',
    payload,
  });

const resetFilters = ({ commit }) => commit('RESET_STATE');

export default {
  loadDocumentos,
  reiniciarGeracaoDocumento,
  setLoading,
  setFilters,
  updateFieldStatus,
  resetFilters,
};
