/* eslint-disable */

import Vue from 'vue';

const showGraphqlError = JSON.parse(localStorage?.showGraphqlError ?? false);
const showAllGraphqlStrings = JSON.parse(localStorage?.showAllGraphqlStrings ?? false);
export const applyCatchError = (error) => error;

export const resolveGraphqlResponse = (showErrorToast, queryString) => (response) => {
  const data = response?.data;
  const error = data?.errors?.shift();
  const message = error?.message;

  showAllGraphqlStrings && console.warn('\n', queryString);

  if (message) {
    console.error('Error message: ', message);
    showErrorToast;
    showGraphqlError && console.error('GraphQL string error:\n', queryString);
  }

  return {
    payload: data?.data,
    error: message,
    isSuccess: !message,
  };
};

export const applyGraphqlCatch = (error) => {
  const message = error?.message;
  const status = error?.response?.status;

  console.error('Error message: ', error, message, status);

  return {
    isSuccess: false,
    error,
  };
};

export const injectAxiosInterceptors = () => {
  !Vue.prototype.$http.definedInterceptors &&
    Vue.prototype.$http.interceptors.request.use(
      async (config) => {
        if (config?.delay) {
          const startTime = new Date().getTime();
          console.warn('[interceptor sleeper - start]: ', startTime);

          await new Promise((resolve) =>
            setTimeout(() => {
              const endTime = new Date().getTime();
              console.warn('[interceptor sleeper - end]: ', endTime);
              console.warn('[interceptor sleeper - delay time]: ', endTime - startTime);

              return resolve(config);
            }, config?.delay),
          );
        }

        return config;
      },
      async (error) => await Promise.reject(error),
    );

  Vue.prototype.$http.interceptors.response.use(async (response) => {
    if (response?.config?.mockError?.status >= 300) {
      throw {
        response: response?.config?.mockError,
      };
    }

    return response;
  });

  Vue.prototype.$http.definedInterceptors = true;
};
