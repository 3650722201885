import { helpPages } from '@/constants';

export default [
  {
    path: '/carater-atendimento',
    name: 'carater-atendimento',
    component: () => import(/* webpackChunkName: "carater-atendimento" */ './index.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Caráter de atendimento',
      helpPage: helpPages['carater-atendimento'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
  {
    path: '/carater-atendimento/create',
    name: 'create-carater-atendimento',
    component: () => import(/* webpackChunkName: "form-carater-atendimento" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Novo caráter de atendimento',
      helpPage: helpPages['carater-atendimento'],
      applicationKey: 'ressus',
      action: 'create',
    },
    props: true,
  },
  {
    path: '/carater-atendimento/edit/:id',
    name: 'edit-carater-atendimento',
    component: () => import(/* webpackChunkName: "form-carater-atendimento" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Editar caráter de atendimento',
      helpPage: helpPages['carater-atendimento'],
      applicationKey: 'ressus',
      action: 'edit',
    },
    props: true,
  },
];
