import { formatMoneyRestore } from '@/utils/currency';
import getLancamentoContabil from '../services';

const loadLancamentoContabil = async ({ commit, state }, query) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  commit('GENERIC_MUTATION', {
    property: 'lancamentosContabeis',
    payload: [],
  });

  const queryParams = {
    ...query,
    numeroAtendimento: state.filters?.numeroAtendimento || query?.numeroAtendimento,
    competencia: state.filters?.competenciaAtendimento || query?.competencia,
    valorLancamentoInicial: query?.valorLancamentoInicial
      ? formatMoneyRestore(query?.valorLancamentoInicial)
      : 0.0,
    valorLancamentoFinal: query?.valorLancamentoFinal
      ? formatMoneyRestore(query?.valorLancamentoFinal)
      : 0.0,
  };

  const { payload } = await getLancamentoContabil(queryParams);

  commit('GENERIC_MUTATION', {
    property: 'lancamentosContabeis',
    payload: payload?.listarLancamentosContabeis?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarLancamentosContabeis?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarLancamentosContabeis?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const setFilters = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'filters',
    payload,
  });

export default {
  loadLancamentoContabil,
  setFilters,
};
