const tiposProcessamentos = [
  { id: 1, descricao: 'Recebimento ABI', value: 'RECEBIMENTO_ABI' },
  { id: 2, descricao: 'Cancelamento ABI', value: 'CANCELAMENTO_ABI' },
  { id: 3, descricao: 'Cancelamento atendimento', value: 'CANCELAMENTO_ATENDIMENTO' },
  { id: 4, descricao: 'Aguardando cobrança', value: 'AGUARDANDO_COBRANCA' },
  { id: 5, descricao: 'Processo concluído', value: 'PROCESSO_CONCLUIDO' },
  { id: 6, descricao: 'Recebimento GRU', value: 'RECEBIMENTO_GRU' },
  { id: 7, descricao: 'Alegação ANS', value: 'ALEGACAO_ANS' },
  { id: 8, descricao: 'Deferido Parcial', value: 'DEFERIDO_PARCIAL' },
  { id: 9, descricao: 'Deferido Total', value: 'DEFERIDO_TOTAL' },
  { id: 10, descricao: 'Indeferido', value: 'INDEFERIDO' },
];

const selectAcaoJudicial = [
  { id: 1, descricao: 'Sim', value: 'S' },
  { id: 2, descricao: 'Não', value: 'N' },
];

const formsLancamentoContabil = {
  idAbi: null,
  dataCompetenciaInicial: null,
  dataCompetenciaFinal: null,
  processoEventoIds: [...tiposProcessamentos],
  dataPeriodoInicial: null,
  dataPeriodoFinal: null,
  acaoJudicial: [],
  gru: null,
  instancia: [],
  contrato: null,
  alegacaoAns: [],
};

export { tiposProcessamentos, formsLancamentoContabil, selectAcaoJudicial };
