const apiDomain =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_API : process.env.RESSUS_URL;

const apiDomainNotificacoes =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_NOTIFICACOES
    : process.env.NOTIFICACOES_URL;

const apiDomainaAssinaturaDigital =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_ASSINATURA
    : process.env.ASSINATURA_URL;

const apiDomainStorage =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_STORAGE : process.env.STORAGE_URL;

const apiDomainWorkspace =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_WORKSPACE : process.env.WORKSPACE_URL;

const apiDomainConfluence =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_CONFLUENCE
    : process.env.CONFLUENCE_URL;

const zPermissionApi =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_ZPERMISSION
    : process.env.ZPERMISSION_URL;

export const ressusURI = apiDomain;
export const graphQLServiceURI = `${ressusURI}/graphql`;
export const notificacoesURI = apiDomainNotificacoes;
export const graphQLServiceNotificacoesURI = `${notificacoesURI}/graphql`;
export const assinaturaDigitalURI = apiDomainaAssinaturaDigital;
export const apiDomainStorageURI = apiDomainStorage;
export const workspaceURI = apiDomainWorkspace;
export const confluenceURI = apiDomainConfluence;
export const permissionsURI = zPermissionApi;
