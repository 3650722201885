import Vue from 'vue';
import omitByAndNil from '@/utils/compare-values';
import {
  createEventoContabilService,
  deleteEventoContabilService,
  getEventoContabil,
  getEventoContabilById,
  updateEventoContabilService,
  loadCamposFormulaEventoContabilService,
} from '../services';
import redirectRouteUpdate from '../../utils';

const loadEventoContabil = async ({ commit }, query) => {
  commit('RESET_STATE');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  commit('GENERIC_MUTATION', {
    property: 'eventosContabeis',
    payload: [],
  });

  const { payload } = await getEventoContabil(query);

  commit('GENERIC_MUTATION', {
    property: 'eventosContabeis',
    payload: payload?.listarEventosContabeis?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarEventosContabeis?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarEventosContabeis?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadEventoContabilById = async ({ commit, dispatch }, id) => {
  dispatch('setLoadingForm', true);
  dispatch('ressus/updateControlModalAtencao', true, { root: true });

  await getEventoContabilById({
    id,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'eventoContabil',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: omitByAndNil(data),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const createEventoContabil = ({ getters, dispatch, commit }, { saveAndNew, resetValidation }) => {
  dispatch('setLoadingForm', true);

  const { payloadEventoContabil } = getters;

  createEventoContabilService({
    payload: payloadEventoContabil,
    onSuccess: async () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      !saveAndNew && Vue.router.push({ name: 'evento-contabil' });
      Vue.$toast('Evento contábil incluído com sucesso.', { type: 'success' });

      await resetValidation();
      await commit('RESET_STATE');

      dispatch('loadCamposFormulaEventoContabil');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateEventoContabil = (
  { getters, dispatch, commit },
  { saveAndNew, resetValidation, route },
) => {
  dispatch('setLoadingForm', true);

  const { payloadEventoContabil } = getters;

  updateEventoContabilService({
    payload: payloadEventoContabil,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      redirectRouteUpdate(saveAndNew, 'create-evento-contabil', route);
      Vue.$toast('Evento contábil alterado com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteEventoContabil = ({ state, commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  deleteEventoContabilService({
    id,
    onSuccess: () => {
      Vue.$toast('Evento contábil excluído com sucesso.', { type: 'success' });

      if (state.eventoContabil.length === 1) {
        Vue.router.replace({
          name: 'evento-contabil',
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }

      dispatch('loadEventoContabil', query);
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoadingForm = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: value,
  });

const loadCamposFormulaEventoContabil = async ({ commit, dispatch }) => {
  dispatch('setLoadingForm');

  await loadCamposFormulaEventoContabilService({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'camposFormula',
        payload: data,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

export default {
  createEventoContabil,
  deleteEventoContabil,
  loadEventoContabil,
  loadEventoContabilById,
  resetState,
  setLoadingForm,
  updateEventoContabil,
  updateField,
  loadCamposFormulaEventoContabil,
};
