import initialState from './state';
import { itemsCheckbox } from '../constants';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  ITEM_SELECTED(state, payload) {
    const { secao, nome, checkedOrValue, value, label } = payload;
    const secaoSelecionada = state.filtrosAvancados.find(
      (item) => item.secao === secao && item.nome === nome,
    );

    if (checkedOrValue) {
      secaoSelecionada.valores.push(value);
    } else {
      secaoSelecionada.valores.splice(secaoSelecionada.valores.indexOf(value), 1);
    }

    if (label === 'Inicial') {
      secaoSelecionada.valores = [value, checkedOrValue];
    } else if (label === 'Final') {
      secaoSelecionada.valores = [checkedOrValue, value];
    }

    if (!checkedOrValue && !secaoSelecionada.valores[0] && !secaoSelecionada.valores[1]) {
      secaoSelecionada.valores = [];
    }

    secaoSelecionada.countSubitemsSelected = itemsCheckbox?.includes(nome)
      ? secaoSelecionada.valores.length
      : secaoSelecionada.valores.filter((item) => item && item !== '0,00').length;
  },
};
