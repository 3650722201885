import { createGraphQLService, createCommandService } from '@/services';
import { ressusURI } from '@/api';
import ListarProcedimentosTussSigtap from './queries/listar-procedimentos-tuss-sigtap';

const getProcedimentosTussSigtap = async (query) =>
  createGraphQLService(ListarProcedimentosTussSigtap(query));

const createProcedimentosTussSigtapService = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/procedimento_tuss_sigtap`,
    onSuccess,
    onCustomError,
  });

const deleteProcedimentosTussSigtapService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/procedimento_tuss_sigtap/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateProcedimentosTussSigtapService = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/procedimento_tuss_sigtap/${payload.id}`,
    onSuccess,
    onCustomError,
  });

const uploadFileCSVService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/procedimento_tuss_sigtap/importar`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getProcedimentosTussSigtap,
  createProcedimentosTussSigtapService,
  deleteProcedimentosTussSigtapService,
  updateProcedimentosTussSigtapService,
  uploadFileCSVService,
};
