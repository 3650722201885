import Vue from 'vue';
import { getGrupoTrabalhoById } from '@/views/cadastros/grupo-trabalho/services';
import {
  getSolicitacaoDocumentos,
  enviarSolicitacaoDocumentosService,
  getSolicitacaoDocumentoByIdService,
  uploadFileService,
  getDestinatariosService,
  reenviarSolicitacaoDocumentoService,
} from '../services';

const loadSolicitacoes = async ({ dispatch, commit }, query) => {
  dispatch('setLoading');
  dispatch('resetStateDockModal');

  commit('GENERIC_MUTATION', {
    property: 'queryParams',
    payload: query,
  });

  const { payload } = await getSolicitacaoDocumentos(query);

  commit('GENERIC_MUTATION', {
    property: 'solicitacaoDocumentos',
    payload: payload?.listarSolicitacaoDocumentos?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarSolicitacaoDocumentos?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarSolicitacaoDocumentos?.totalPages,
  });

  dispatch('setLoading', false);
};

const toggleDockModal = ({ state, commit, getters }) => {
  if (!getters.isInitialStateDadosDockModal) {
    commit('GENERIC_MUTATION', {
      property: 'showModalConfirmacao',
      payload: !state.showModalConfirmacao,
    });
  } else {
    commit('GENERIC_MUTATION', {
      property: 'showDockModal',
      payload: !state.showDockModal,
    });
  }
};

const updateFieldDockModal = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD_DOCK_MODAL', {
    property,
    value,
  });

const resetStateDockModal = ({ commit }) => {
  commit('RESET_STATE_DOCK_MODAL');

  commit('GENERIC_MUTATION', {
    property: 'showDockModal',
    payload: false,
  });
};

const loadDestinatarios = ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingDockModal',
    payload: true,
  });

  getDestinatariosService({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'destinatarios',
        payload: data,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingDockModal',
        payload: false,
      }),
  });
};

const enviarSolicitacaoDocumento = ({ commit, dispatch, state }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingDockModal',
    payload: true,
  });

  const { assunto, descricao, destinatario } = state.dadosDockModal;

  const payload = {
    destinatarioId: !destinatario.isGrupo ? destinatario.id : null,
    destinatarioNome: !destinatario.isGrupo ? destinatario.nome : null,
    destinatarioGrupoId: destinatario.isGrupo ? destinatario.id : null,
    destinatarioGrupoNome: destinatario.isGrupo ? destinatario.nome : null,
    assunto,
    descricao,
  };

  enviarSolicitacaoDocumentosService({
    payload,
    onSuccess: () => {
      Vue.$toast(`Solicitação enviada para ${destinatario.nome}`, {
        type: 'success',
      });

      dispatch('loadSolicitacoes', state.queryParams);
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingDockModal',
        payload: false,
      }),
  });
};

const toggleModalConfirmacao = ({ state, commit }) =>
  commit('GENERIC_MUTATION', {
    property: 'showModalConfirmacao',
    payload: !state.showModalConfirmacao,
  });

const updateState = ({ commit }, { property, payload }) =>
  commit('GENERIC_MUTATION', {
    property,
    payload,
  });

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

const enviarSolicitacao = async ({ dispatch }, payload) => {
  dispatch('setLoading');

  await enviarSolicitacaoDocumentosService({
    payload,
    onSuccess: () => {
      Vue.$toast('Solicitação enviada com sucesso');
    },
    onCustomError: (error) => {
      Vue.$toast(error?.message || 'Erro ao enviar solicitação', { type: 'error' });
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const getSolicitacaoDocumentoById = async ({ dispatch, commit }, id) => {
  dispatch('setLoading');

  await getSolicitacaoDocumentoByIdService({
    id,
    onSuccess: ({ data }) => {
      const temDocumentos = data?.documentos?.length > 0;

      commit('GENERIC_MUTATION', {
        property: 'temDocumentos',
        payload: temDocumentos,
      });

      commit('GENERIC_MUTATION', {
        property: 'formSolicitacao',
        payload: data,
      });
    },
    onCustomError: (error) => {
      Vue.$toast(error?.message || 'Erro ao buscar solicitação', { type: 'error' });

      Vue.router.push({
        name: 'solicitacao-documentos',
      });
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateField = ({ commit }, { object = null, property, value }) => {
  if (property === 'documentos') {
    commit('UPDATE_FIELD_DOCUMENTOS', {
      property,
      value,
    });
  } else {
    commit('UPDATE_FIELD', {
      object,
      property,
      value,
    });
  }
};

const uploadFile = async ({ commit, state: { formSolicitacao } }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingDocumentos',
    payload: true,
  });

  if (!formSolicitacao.documentos.length) {
    commit('GENERIC_MUTATION', {
      property: 'loadingDocumentos',
      payload: false,
    });

    commit('GENERIC_MUTATION', {
      property: 'formSolicitacao',
      payload: {
        ...formSolicitacao,
        errorMessages: ['É obrigatório o envio de pelo menos um arquivo.'],
      },
    });
    return;
  }

  const { documentos } = formSolicitacao;
  const { solicitacaoId } = Vue.router.history.current.params;
  const payload = new FormData();

  for (let i = 0; i < documentos.length; i++) {
    payload.append('files', documentos[i].arquivo);
  }

  await uploadFileService({
    solicitacaoId,
    payload,
    onSuccess: () => {
      Vue.$toast('Arquivo(s) enviado(s) com sucesso', { type: 'success' });
      Vue.router.push({
        name: 'solicitacao-documentos',
      });
    },
    onCustomError: (error) => {
      commit('GENERIC_MUTATION', {
        property: 'formSolicitacao',
        payload: {
          ...formSolicitacao,
          errorMessages: [error?.response?.data?.message],
        },
      });
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingDocumentos',
        payload: false,
      });
    },
  });
};

const deleteItemDocumentos = ({ commit, state }, index) => {
  const { documentos } = state.formSolicitacao;
  documentos.splice(index, 1);

  commit('GENERIC_MUTATION', {
    property: 'formSolicitacao',
    payload: {
      ...state.formSolicitacao,
      documentos,
    },
  });
};

const reenviarSolicitacaoDocumento = async ({ dispatch }) => {
  dispatch('setLoading');

  const { solicitacaoId } = Vue.router.history.current.params;

  await reenviarSolicitacaoDocumentoService({
    solicitacaoId,
    onSuccess: () => {
      Vue.$toast('Solicitação reenviada com sucesso', { type: 'success' });

      Vue.router.push({
        name: 'solicitacao-documentos',
      });
    },
    onCustomError: (error) => {
      Vue.$toast(error?.message || 'Erro ao reenviar solicitação', { type: 'error' });
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const loadGrupoTrabalho = async ({ commit, dispatch }, { grupoId, usuarioId }) => {
  dispatch('setLoading');

  await getGrupoTrabalhoById({
    id: grupoId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'pertenceGrupo',
        payload: data.usuarios.some((usuario) => usuario.usuarioId === usuarioId),
      });
    },
    onCustomError: ({ response }) => {
      Vue.$toast(response?.data?.message, {
        type: 'error',
      });
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

export default {
  loadSolicitacoes,
  toggleDockModal,
  updateFieldDockModal,
  resetStateDockModal,
  loadDestinatarios,
  enviarSolicitacaoDocumento,
  toggleModalConfirmacao,
  updateState,
  setLoading,
  enviarSolicitacao,
  getSolicitacaoDocumentoById,
  updateField,
  uploadFile,
  deleteItemDocumentos,
  reenviarSolicitacaoDocumento,
  loadGrupoTrabalho,
};
