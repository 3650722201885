import { helpPages } from '@/constants';

export default [
  {
    path: '/evento-contabil',
    name: 'evento-contabil',
    component: () => import(/* webpackChunkName: "evento-contabil" */ './index.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Eventos contábeis',
      helpPage: helpPages['evento-contabil'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
  {
    path: '/evento-contabil/create',
    name: 'create-evento-contabil',
    component: () => import(/* webpackChunkName: "form-evento-contabil" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Novo evento contábil',
      helpPage: helpPages['evento-contabil'],
      applicationKey: 'ressus',
      action: 'create',
    },
    props: true,
  },
  {
    path: '/evento-contabil/edit/:id',
    name: 'edit-evento-contabil',
    component: () => import(/* webpackChunkName: "form-evento-contabil" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Editar evento contábil',
      helpPage: helpPages['evento-contabil'],
      applicationKey: 'ressus',
      action: 'edit',
    },
    props: true,
  },
];
