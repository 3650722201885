import { formatMoney, formatMoneyRestore } from '@/utils/currency';

const dadosContabilidade = ({ contabilidade }) => ({
  provisionamentoInicial: formatMoney(contabilidade?.provisionamentoInicial || 0),
  provisionamentoRecursados: formatMoney(contabilidade?.provisionamentoRecursados || 0),
  gerarLancamentoInstancias: contabilidade?.gerarLancamentoInstancias,
});

const payloadContabilidade = (_, { dadosContabilidade }) => ({
  provisionamentoInicial: formatMoneyRestore(dadosContabilidade?.provisionamentoInicial || 0),
  provisionamentoRecursados: formatMoneyRestore(dadosContabilidade?.provisionamentoRecursados || 0),
  gerarLancamentoInstancias: dadosContabilidade?.gerarLancamentoInstancias,
});

export default {
  dadosContabilidade,
  payloadContabilidade,
};
