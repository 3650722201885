export default () => ({
  loading: false,
  dadosCadastroGru: {},
  initialStateDadosCadastroGru: {},
  atendimentos: [],
  atendimentosSelecionados: [],
  resumoCobranca: {},
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
});
