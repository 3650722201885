import { createGraphQLService, createCommandService } from '@/services';
import { ressusURI } from '@/api';
import createQueryMotivoImpugnacao from './queries/motivo-impugnacao';
import createQueryTipoDocumento from './queries/tipo-documento';

const getMotivoImpugnacao = async (queryParams) =>
  createGraphQLService(createQueryMotivoImpugnacao(queryParams));

const getMotivoImpugnacaoById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/motivo_impugnacao/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createMotivoImpugnacaoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/motivo_impugnacao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateMotivoImpugnacaoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/motivo_impugnacao/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getAllMotivos = () =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/motivo_oficial_ans`,
  });

const deleteMotivoImpugnacaoService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/motivo_impugnacao/${id}`,
    onSuccess,
    onCustomError,
  });

const getTiposDocumento = async (queryParams) =>
  createGraphQLService(createQueryTipoDocumento(queryParams));

export {
  getMotivoImpugnacao,
  getMotivoImpugnacaoById,
  createMotivoImpugnacaoService,
  updateMotivoImpugnacaoService,
  deleteMotivoImpugnacaoService,
  getAllMotivos,
  getTiposDocumento,
};
