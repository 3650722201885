import indicadoresRessarcimento from './indicadores-ressarcimento/routes';
import atendimentos from './atendimentos/routes';
import lancamentosContabeis from './lancamentos-contabeis/routes';
import declaracaoBeneficiarios from './declaracao-beneficiarios/routes';

export default [
  ...indicadoresRessarcimento,
  ...atendimentos,
  ...lancamentosContabeis,
  ...declaracaoBeneficiarios,
];
