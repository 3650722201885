export const headers = [
  {
    text: '',
    value: 'status',
    cellClass: 'pa-0',
    sortable: false,
  },
  { text: 'CÓDIGO DA EMPRESA', value: 'codigo', width: 288 },
  { text: 'NOME DA EMPRESA', value: 'nome' },
  {
    text: 'VER',
    value: 'ver',
    align: 'center',
    width: 80,
    sortable: false,
  },
  {
    text: 'EDITAR',
    value: 'editar',
    align: 'center',
    width: 80,
    sortable: false,
  },
  {
    text: 'EXCLUIR',
    value: 'excluir',
    align: 'center',
    width: 80,
    sortable: false,
  },
];

export const formHeaders = [
  { text: 'CONTRATO', value: 'contrato', width: 288 },
  { text: 'MOTIVO', value: 'motivoImpugnacao.descricaoAbreviada' },
  {
    text: 'VER',
    value: 'ver',
    align: 'center',
    width: 80,
    sortable: false,
  },
  {
    text: 'EDITAR',
    value: 'editar',
    align: 'center',
    width: 80,
    sortable: false,
  },
  {
    text: 'EXCLUIR',
    value: 'excluir',
    align: 'center',
    width: 80,
    sortable: false,
  },
];

export const formHeadersView = [
  { text: 'CONTRATO', value: 'contrato', width: 288 },
  { text: 'MOTIVO', value: 'motivoImpugnacao.descricaoAbreviada' },
  {
    text: 'VER',
    value: 'ver',
    align: 'center',
    width: 80,
    sortable: false,
  },
];

export const formularioPadrao = {
  id: null,
  contrato: null,
  motivoImpugnacao: { id: null },
  detalhamento: null,
  memoriaCalculo: null,
  documentosComprobatorios: null,
  tipo: null,
};

export const formEmpresa = {
  codigo: null,
  nome: null,
  ativo: true,
};

export const tabsCadastroEmpresa = [
  {
    id: 0,
    title: 'Formulário',
    value: 'Formulario',
    component: 'Formulario',
  },
  {
    id: 1,
    title: 'Documentos',
    value: 'Documentos',
    component: 'Documentos',
  },
];

export const formUploadDocumento = {
  tipo: null,
  data: null,
  assunto: '',
  anexo: null,
  errorMessages: [],
};

export const tipos = [
  { id: 1, text: 'Administrativo', value: 'ADMINISTRATIVO' },
  { id: 2, text: 'Técnico', value: 'TECNICO' },
];
