<template>
  <v-card class="border-0 shadow-none">
    <v-navigation-drawer
      v-model="value"
      permanent
      class="rounded-none"
      mini-variant-width="48"
      height="calc(100vh - 192px)"
      :mini-variant.sync="mini"
      :width="width"
    >
      <div class="flex">
        <div class="min-w-[48px] w-[48px] bg-feedback-green-low">
          <v-btn icon class="w-[48px] h-[48px] rounded-full" @click.stop="drawerExpand">
            <v-icon size="24">
              {{ mini ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right' }}
            </v-icon>
          </v-btn>
          <v-tabs v-if="!mini" vertical slider-size="0" :value="activeTab">
            <v-tab
              v-for="tab in tabsDrawer"
              v-tooltip.right="tab.title"
              :id="tab.value"
              :tabindex="tab.id"
              :key="tab.value"
              :href="`#${tab.value}`"
              @click="updateTabIndex(tab.id)"
            >
              <v-icon size="18">
                {{ tab.icon }}
              </v-icon>
            </v-tab>
          </v-tabs>
        </div>
        <div class="w-full pl-11 pr-4 py-6">
          <re-loader v-bind="{ loading }" />
          <v-tabs-items v-model="activeTab" position="fixed">
            <v-tab-item v-for="tab in tabsDrawer" :key="tab.value" :value="`${tab.value}`">
              <h1 class="drawer-title-default">
                {{ tab.title }}
              </h1>
              <h2 class="section-title-default font-normal mb-7">Detalhamento do atendimento</h2>
              <component
                v-bind="{ closeDrawer, isFormularioAtendimento }"
                class="h-[calc(100vh-332px)] overflow-auto overflow-x-hidden"
                ref="tabs"
                :is="tab.component"
                @close="onClose"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ReLoader } from '@/components';
import { tabsDrawer } from '../drawer-detalhamento-atendimento/constants';
import TabGeral from '../drawer-detalhamento-atendimento/tab-geral.vue';
import TabContratoPlano from '../drawer-detalhamento-atendimento/tab-contrato-plano.vue';
import TabProcedimento from '../drawer-detalhamento-atendimento/tab-procedimento.vue';
import TabDecisao from '../drawer-detalhamento-atendimento/tab-decisao.vue';
import TabCobranca from '../drawer-detalhamento-atendimento/tab-cobranca.vue';

export default {
  name: 'DrawerFormularioAtendimento',
  components: {
    TabGeral,
    TabContratoPlano,
    TabProcedimento,
    TabDecisao,
    TabCobranca,
    ReLoader,
  },
  data() {
    return {
      isFormularioAtendimento: true,
      width: 724,
      value: true,
      mini: true,
      tabsDrawer,
      closeDrawer: false,
      isClosedByDrawer: false,
    };
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'loading',
      'tabIndex',
      'activeTab',
    ]),
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler({ atendimentoId }) {
        this.detalhesAtendimento({
          id: atendimentoId,
        });
      },
    },
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'detalhesAtendimento',
      'updateTabIndex',
      'updateActiveTab',
    ]),
    onClose() {
      this.closeDrawer = !this.closeDrawer;
      this.isClosedByDrawer = true;
    },
    drawerExpand() {
      this.mini = !this.mini;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  height: auto !important;
}

:deep(.v-navigation-drawer) {
  z-index: 0 !important;
}

:deep(.v-navigation-drawer__content) {
  overflow: hidden !important;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 28px;
}

:deep(.v-tabs--vertical > .v-tabs-bar .v-tabs-bar__content) {
  align-items: center;
  gap: 30px;
  background-color: theme('colors.feedback-green-low');
}

.v-tabs {
  margin-top: 24px;

  .v-tab {
    min-width: 28px;
    max-width: 28px;

    &--active {
      border-radius: 4px;
      background-color: theme('colors.feedback-green-high');
    }

    .v-icon {
      color: theme('colors.primary-blue-base') !important;
    }
  }
}
</style>
