<template>
  <div class="re-container">
    <div v-if="showBackButton" class="re-container__btn-back">
      <v-btn plain @click="$emit('back')">
        <v-icon>mdi-keyboard-return</v-icon>
        <span class="ml-1 font-bold">Voltar</span>
      </v-btn>
    </div>
    <div v-if="$route.meta.title || $route.meta.subtitle" class="re-container__header">
      <h1>{{ $route.meta.title }}</h1>
      <h2 v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</h2>
    </div>
    <div v-if="showActions" class="re-container__actions">
      <slot name="actions" />
    </div>
    <div class="re-container__content">
      <slot name="content" />
      <re-loader v-bind="{ loading }" />
    </div>
    <div v-if="showFooter" class="re-container__footer">
      <v-footer app height="72" elevation="5" color="white" class="px-[56px]">
        <slot name="footer" />
      </v-footer>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ReLoader from './re-loader.vue';

export default {
  name: 'ReContainer',
  props: {
    showBackButton: Boolean,
    showActions: Boolean,
    showFooter: Boolean,
    loading: Boolean,
  },
  components: { ReLoader },
  data() {
    return {
      meta: this.$route?.meta,
      menuOpenClose: '',
    };
  },
  computed: {
    ...mapState('ressus', ['renderFooter']),
  },
  watch: {
    showFooter: {
      handler() {
        this.onUpdateRenderFooter();
      },
    },
  },
  mounted() {
    this.onUpdateRenderFooter(false);
  },
  methods: {
    ...mapMutations('ressus', ['GENERIC_MUTATION']),
    onUpdateRenderFooter(initialState) {
      this.GENERIC_MUTATION({
        property: 'renderFooter',
        payload: initialState || this.showFooter,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.re-container {
  padding: 44px;

  &__btn-back {
    margin: -14px 0 14px 0;

    :deep(.v-btn) {
      height: 0 !important;
      padding: 0 !important;
      color: theme('colors.primary-blue-medium');

      .v-btn__content {
        opacity: 1 !important;

        &:hover {
          opacity: 0.62 !important;
        }
      }
    }
  }

  &__header {
    margin-bottom: 14px;
    color: theme('colors.primary-blue-base');

    h1 {
      font-size: 20px;
      font-weight: 600;
    }

    h2 {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    margin-bottom: 12px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
