export const tabsConfig = {
  'notificacao-ans': {
    titulo: 'ANS',
    ordem: 0,
    itemsConfig: {
      ABI: {
        avatarColor: 'bg-branding-primary-tech',
        icon: 'mdi-file-search-outline',
        route: {
          name: 'oficios-notificacao',
        },
      },
      DECISAO: {
        avatarColor: 'bg-primary-blue-medium',
        icon: 'mdi-file-outline',
        route: {
          name: 'oficios-notificacao',
        },
      },
      COBRANCA: {
        avatarColor: 'bg-branding-primary-lemon',
        icon: 'mdi-currency-usd',
        route: {
          name: 'resumo-abi',
          query: 'tipoNotificacao',
        },
      },
      INCORPORACAO_CUSTO_ATENDIMENTO: {
        avatarColor: 'bg-feedback-red-high',
        icon: 'mdi-alert-circle-outline',
        route: {
          name: 'oficios-notificacao',
          query: 'tab',
        },
      },
      XML: {
        avatarColor: 'bg-branding-primary-kiwi',
        icon: 'mdi-check-bold',
        route: {
          name: 'resumo-abi',
          params: 'idAbi',
        },
      },
      ERRO_PROTOCOLAR: {
        avatarColor: 'bg-feedback-red-high',
        icon: 'mdi-alert-circle-outline',
        route: {
          name: 'protocolos-eletronicos',
          params: 'idAbi',
          query: 'tipoFormulario',
        },
      },
      AVISO_PRAZO: {
        avatarColor: 'bg-feedback-blue-high',
        icon: 'mdi-bell-outline',
        route: {
          name: 'oficios-notificacao',
        },
      },
      PRAZO_EXPIRADO: {
        avatarColor: 'bg-feedback-yellow',
        icon: 'mdi-alert-outline',
        route: {
          name: 'oficios-notificacao',
        },
      },
      EVENTO_CONTABIL: {
        avatarColor: 'bg-feedback-blue-high',
        icon: 'mdi-alert-circle-outline',
        route: {
          name: 'lancamentos-contabeis',
          params: 'idAbi',
        },
      },
      DECISAO_ATUALIZADA: {
        avatarColor: 'bg-branding-primary-kiwi',
        icon: 'mdi-check-bold',
        tab: 'DECISAO',
        route: {
          name: 'resumo-abi',
          params: 'idAbi',
        },
      },
      ERRO_ATUALIZAR_DECISAO: {
        avatarColor: 'bg-feedback-red-high',
        icon: 'mdi-alert-circle-outline',
        route: {
          name: 'oficios-notificacao',
          query: 'tab',
        },
        filterOnlyById: true,
      },
      INCORPORACAO_CUSTO_COBRANCA: {
        avatarColor: 'bg-branding-primary-kiwi',
        icon: 'mdi-check-bold',
        tab: 'COBRANCA',
        route: {
          name: 'resumo-abi',
          params: 'idAbi',
        },
      },
    },
  },
  documento: {
    titulo: 'Documentos',
    ordem: 1,
    itemsConfig: {
      XML: {
        avatarColor: 'bg-branding-primary-kiwi',
        icon: 'mdi-tray-arrow-down',
        route: {
          name: 'documentos-gerados',
          params: 'documentoId',
        },
      },
      DOCUMENTO_GERADO: {
        avatarColor: 'bg-branding-primary-kiwi',
        icon: 'mdi-tray-arrow-down',
        route: {
          name: 'documentos-gerados',
          params: 'documentoId',
        },
      },
      DOCUMENTO_ERRO_GERAR: {
        avatarColor: 'bg-feedback-red-high',
        icon: 'mdi-alert-circle-outline',
        route: {
          name: 'documentos-gerados',
          params: 'documentoId',
        },
      },
      SOLICITACAO_DOCUMENTO: {
        avatarColor: 'bg-feedback-blue-high',
        icon: 'mdi-file-outline',
        route: {
          name: 'solicitacao',
          modalType: 'recebida',
        },
      },
      RETORNO_SOLICITACAO_DOCUMENTO: {
        avatarColor: 'bg-feedback-blue-high',
        icon: 'mdi-file-outline',
        route: {
          name: 'solicitacao',
          modalType: 'enviada',
        },
      },
    },
  },
  arquivadas: {
    titulo: 'Arquivadas',
    ordem: 2,
    placeholder: 'Não há mensagens arquivadas.',
  },
};

export const MAX_ITEMS_ON_DISPLAY = 6;
export const CLIENT_X_ADJUST = 400;
export const CLIENT_Y_ADJUST = 34;
export const INITIAL_TAB_INDEX = 0;
export const INITIAL_PAGE = -1;
