import Vue from 'vue';
import { formUploadDocumento } from '@/views/processos/constants';
import { formularioAtendimento } from '../constants';

export default {
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD_FORMULARIO(state, { property, value }) {
    Vue.set(state.dadosFormulario, property, value);
  },
  RESET_DADOS_FORMULARIO(state) {
    Object.assign(state.dadosFormulario, {
      ...formularioAtendimento,
    });
  },
  UPDATE_FIELD_MOTIVO(state, { index, property, value }) {
    Vue.set(state.dadosMotivos[index], property, value);
  },
  UPDATE_FIELD_UPLOAD_DOCUMENTO(state, { property, value }) {
    Vue.set(state.dadosUploadDocumento, property, value);
  },
  RESET_FORM_UPLOAD_DOCUMENTO(state) {
    Object.assign(state.dadosUploadDocumento, {
      ...formUploadDocumento,
    });
  },
};
