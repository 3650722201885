import { isEqual } from 'lodash';
import omitByAndNil from '@/utils/compare-values';

const payload = ({ motivoImpugnacao }) => ({
  ...motivoImpugnacao,
  codigo: Number(motivoImpugnacao?.codigo),
});

const payloadToSave = ({ motivoImpugnacao }) => ({
  ...motivoImpugnacao,
  codigo: Number(motivoImpugnacao?.codigo),
  tipoDocumentoIds: motivoImpugnacao?.tiposDocumentos?.map((item) => parseInt(item.id, 10)),
});

const motivos = ({ allMotivos }) => ({
  ...allMotivos,
  motivos: allMotivos?.map((motivo) => ({
    id: motivo?.id,
    codigo: motivo?.codigo,
    descricao: `${motivo?.codigo} - ${motivo?.descricao}`,
  })),
});

const motivosImpugnacaoTable = ({ motivosImpugnacao }) =>
  motivosImpugnacao?.map((motivoImpugnacao) => ({
    ...motivoImpugnacao,
    status: motivoImpugnacao.ativo ? 'ATIVO' : 'INATIVO',
  }));

const isInitialState = ({ initialState }, { payload }) =>
  isEqual(initialState, payload.id ? omitByAndNil(payload) : payload);

export default {
  payload,
  payloadToSave,
  motivos,
  motivosImpugnacaoTable,
  isInitialState,
};
