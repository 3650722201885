import Vue from 'vue';
import { ReToastLink } from '@/components';
import { formatDateRestore } from '@/utils/date';
import { formatMoneyRestore } from '@/utils/currency';
import { enumErrors, debounceTimeMs2000 } from '@/constants';
import {
  getOficiosNotificacao,
  getOficioById,
  uploadFileService,
  getInstancias,
  updateOficioDecisaoService,
  exportarRelatorioService,
} from '../services';

const loadOficiosNotificacao = async ({ state, commit }, query) => {
  await commit('GENERIC_MUTATION', {
    property: 'queryParams',
    payload: query,
  });

  const queryParams = {
    ...query,
    filters: {
      ...state.filters,
      tipoNotificacao: state.queryParams.tab || state.filters?.tipoNotificacao,
      dataEmissaoInicial: state.filters?.dataEmissaoInicial
        ? formatDateRestore(state.filters.dataEmissaoInicial)
        : null,
      dataEmissaoFinal: state.filters?.dataEmissaoFinal
        ? formatDateRestore(state.filters.dataEmissaoFinal)
        : null,
      dataRecebimentoInicial: state.filters?.dataRecebimentoInicial
        ? formatDateRestore(state.filters.dataRecebimentoInicial)
        : null,
      dataRecebimentoFinal: state.filters?.dataRecebimentoFinal
        ? formatDateRestore(state.filters.dataRecebimentoFinal)
        : null,
      valorTotalInicial: state.filters?.valorTotalInicial
        ? formatMoneyRestore(state.filters.valorTotalInicial)
        : 0.0,
      valorTotalFinal: state.filters?.valorTotalFinal
        ? formatMoneyRestore(state.filters.valorTotalFinal)
        : 0.0,
    },
  };

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  commit('GENERIC_MUTATION', {
    property: 'oficiosNotificacao',
    payload: [],
  });

  const { payload, error } = await getOficiosNotificacao(queryParams);

  if (error) {
    Vue.$toast(error.message || error, { type: 'error', id: 'error-oficios-notificados' });

    commit('GENERIC_MUTATION', {
      property: 'oficiosNotificacao',
      payload: [],
    });
  } else {
    commit('GENERIC_MUTATION', {
      property: 'oficiosNotificacao',
      payload: payload?.listarNotificacaoAns?.content,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalElements',
      payload: payload?.listarNotificacaoAns?.totalElements,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalPages',
      payload: payload?.listarNotificacaoAns?.totalPages,
    });
  }

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadOficio = async ({ commit, dispatch }, idOficio) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingImportacaoXml',
    payload: true,
  });

  dispatch('toggleModal');

  await getOficioById({
    idOficio,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'oficioNotificado',
        payload: data,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingImportacaoXml',
        payload: false,
      }),
  });
};

const updateField = ({ commit }, { object = null, property, value }) => {
  commit('UPDATE_FIELD', {
    object,
    property,
    value,
  });
};

const uploadFile = async ({ commit, dispatch, getters }, notificacaoAnsId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingImportacaoXml',
    payload: true,
  });

  const { anexo, errorMessages, ...rest } = getters.dadosImportacao;

  const payload = new FormData();

  payload.append(
    'file',
    new Blob([anexo], {
      type: 'application/xml',
    }),
    anexo.name,
  );

  payload.append(
    'envioArquivoRequest',
    new Blob([JSON.stringify(rest)], {
      type: 'application/json',
    }),
  );

  await uploadFileService({
    notificacaoAnsId,
    payload,
    onSuccess: () => {
      Vue.$toast(
        'Arquivo carregado e em processo de importação. Uma notificação será enviada após a conclusão',
        { type: 'success' },
      );

      setTimeout(() => {
        dispatch('toggleModal');

        commit('GENERIC_MUTATION', {
          property: 'loadingImportacaoXml',
          payload: false,
        });

        const urlQueries = Vue.router.history.current.query;

        dispatch('loadOficiosNotificacao', {
          ...urlQueries,
          sort:
            urlQueries.sort === undefined || urlQueries.sort === 'id'
              ? 'dataEmissao,desc'
              : urlQueries.sort,
        });
      }, debounceTimeMs2000);
    },
    onCustomError: ({ response }) => {
      dispatch('updateField', { property: 'anexo', value: null });
      dispatch('updateField', {
        property: 'errorMessages',
        value: [response?.data?.message],
      });

      commit('GENERIC_MUTATION', {
        property: 'loadingImportacaoXml',
        payload: false,
      });
    },
  });
};

const toggleModal = ({ state, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'showModalImportacaoXml',
    payload: !state.showModalImportacaoXml,
  });
};

const updateFieldInstancia = ({ state, commit }, { item, field, value }) => {
  const payload = state.oficiosNotificacao.map((oficio) => ({
    ...oficio,
    [field]: oficio.id === item.id ? value : oficio[field],
  }));

  commit('GENERIC_MUTATION', {
    property: 'oficiosNotificacao',
    payload,
  });
};

const loadInstancias = ({ commit }) => {
  getInstancias({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'instancias',
        payload: data,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => {},
  });
};

const setFilters = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'filters',
    payload,
  });

const updateOficioDecisao = async ({ commit }, payload) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const { id } = payload;

  await updateOficioDecisaoService({
    id,
    onSuccess: ({ data }) => {
      commit('UPDATE_OFICIO_NOTIFICADO', {
        property: 'status',
        value: data.status,
        item: data,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response.status],
      }),
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });
    },
  });
};

const exportarRelatorio = ({ commit, dispatch }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  exportarRelatorioService({
    onSuccess: async ({ data }) => {
      await dispatch(
        'ressus/processos/documentosGerados/setFilters',
        { id: data.id },
        { root: true },
      );

      Vue.$toast(
        {
          component: ReToastLink,
          props: {
            description: 'A planilha está sendo gerada. Para consultar, ',
            linkDescription: 'clique aqui.',
          },
          listeners: {
            onClickLink: () => Vue.router.push({ name: 'documentos-gerados' }),
          },
        },
        { type: 'info' },
      );
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const updateFieldStatus = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'oficiosNotificacao',
    payload,
  });

const resetLocalFilters = ({ commit }) => commit('RESET_STATE_FILTERS');

const updateClickNotificacao = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'clickNotificacao',
    payload,
  });

export default {
  loadOficiosNotificacao,
  loadOficio,
  updateField,
  uploadFile,
  toggleModal,
  updateFieldInstancia,
  loadInstancias,
  setFilters,
  updateOficioDecisao,
  exportarRelatorio,
  updateFieldStatus,
  resetLocalFilters,
  updateClickNotificacao,
};
