import { helpPages } from '@/constants';
import CriticasValidacao from './index.vue';

export default [
  {
    path: '/criticas-validacao',
    name: 'criticas-validacao',
    component: CriticasValidacao,
    meta: {
      title: 'Parâmetros',
      subtitle: 'Críticas de validação',
      helpPage: helpPages['criticas-validacao'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
