import Vue from 'vue';
import omitByAndNil from '@/utils/compare-values';
import {
  getCaraterAtendimento,
  getCaraterAtendimentoById,
  createCaraterAtendimentoService,
  updateCaraterAtendimentoService,
  deleteCaraterAtendimentoService,
} from '../services';
import redirectRouteUpdate from '../../utils';

const loadCaraterAtendimento = async ({ commit }, query) => {
  commit('RESET_STATE');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const { payload } = await getCaraterAtendimento(query);

  commit('GENERIC_MUTATION', {
    property: 'caraterAtendimentos',
    payload: payload?.listarCarateresAtendimento?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarCarateresAtendimento?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarCarateresAtendimento?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadCaraterAtendimentoById = async ({ commit, dispatch }, id) => {
  dispatch('setLoadingForm', true);
  dispatch('ressus/updateControlModalAtencao', true, { root: true });

  await getCaraterAtendimentoById({
    id,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'caraterAtendimento',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: omitByAndNil(data),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const createCaraterAtendimento = (
  { getters, commit, dispatch },
  { saveAndNew, resetValidation },
) => {
  dispatch('setLoadingForm', true);

  const { payload } = getters;

  createCaraterAtendimentoService({
    payload,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      !saveAndNew && Vue.router.push({ name: 'carater-atendimento' });

      Vue.$toast('Caráter de atendimento incluído com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateCaraterAtendimento = (
  { getters, commit, dispatch },
  { saveAndNew, resetValidation, route },
) => {
  dispatch('setLoadingForm', true);

  const { payload } = getters;

  updateCaraterAtendimentoService({
    payload,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      redirectRouteUpdate(saveAndNew, 'create-carater-atendimento', route);
      Vue.$toast('Caráter de atendimento alterado com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteCaraterAtendimento = ({ state, commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  deleteCaraterAtendimentoService({
    id,
    onSuccess: () => {
      Vue.$toast('Caráter de atendimento excluído com sucesso.', { type: 'success' });

      if (state.caraterAtendimentos.length === 1) {
        Vue.router.replace({
          name: 'carater-atendimento',
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }

      dispatch('loadCaraterAtendimento', query);
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoadingForm = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: value,
  });

export default {
  loadCaraterAtendimento,
  loadCaraterAtendimentoById,
  createCaraterAtendimento,
  updateCaraterAtendimento,
  deleteCaraterAtendimento,
  updateField,
  resetState,
  setLoadingForm,
};
