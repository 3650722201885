const payloadParametros = ({ parametros }) =>
  parametros.map((parametro) => ({
    id: parametro.id,
    motivoImpugnacaoId: parametro?.motivoImpugnacao?.id || null,
    ativo: parametro.ativo,
  }));

const countMotivosDuplicados = ({ parametros }) => {
  const motivosDuplicados = {};

  parametros.forEach((parametro) => {
    const motivoId = parametro?.motivoImpugnacao?.id;

    if (!motivoId) return;

    motivosDuplicados[motivoId]
      ? (motivosDuplicados[motivoId] += 1)
      : (motivosDuplicados[motivoId] = 1);
  });

  const duplicados = parametros.filter(
    (parametro) => motivosDuplicados[parametro?.motivoImpugnacao?.id] > 1,
  );

  return duplicados.length;
};

export default {
  payloadParametros,
  countMotivosDuplicados,
};
