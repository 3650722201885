import { isEqual } from 'lodash';
import { processosAbi } from '../constants';

const payloadAlegacaoAns = ({ alegacaoAns }) => ({
  ...alegacaoAns,
  codigo: Number(alegacaoAns?.codigo),
});

const alegacoesAnsTable = ({ alegacoesAns }) =>
  alegacoesAns.map((alegacao) => ({
    ...alegacao,
    status: alegacao.ativo ? 'ATIVO' : 'INATIVO',
    processoAbi: processosAbi[alegacao.processoAbi].label,
  }));

const isInitialState = (state, getters) => isEqual(state.initialState, getters.payloadAlegacaoAns);

export default { payloadAlegacaoAns, alegacoesAnsTable, isInitialState };
