<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="filters[propertyInicial]"
        v-mask="'##/##/####'"
        clearable
        persistent-placeholder
        label="Inicial"
        placeholder="00/00/0000"
        :rules="validatePropertyInicial"
        :data-test="`text-field-${propertyInicial}`"
        @input="
          (value) =>
            updateField({
              property: propertyInicial,
              value,
            })
        "
        @click:clear="
          (value) =>
            updateField({
              property: propertyFinal,
              value: '',
            })
        "
      />
    </v-col>
    <v-col>
      <v-text-field
        v-model="filters[propertyFinal]"
        v-mask="'##/##/####'"
        clearable
        persistent-placeholder
        label="Final"
        placeholder="(Opcional)"
        :rules="validatePropertyFinal"
        :data-test="`text-field-${propertyFinal}`"
        @input="
          (value) =>
            updateField({
              property: propertyFinal,
              value,
            })
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, validDate, minDate, maxDate } from '@/utils/validation-rules';
import { formatDateRestore } from '@/utils/date';

export default {
  name: 'DateRange',
  props: {
    propertyInicial: String,
    propertyFinal: String,
  },
  data() {
    return {
      required,
      validDate,
      minDate,
      maxDate,
    };
  },
  computed: {
    ...mapState('ressus/drawerFiltros', ['filters']),
    restoreInitialDate() {
      return formatDateRestore(this.filters[this.propertyInicial]);
    },
    restoreFinalDate() {
      return formatDateRestore(this.filters[this.propertyFinal]);
    },
    validatePropertyInicial() {
      if (this.restoreInitialDate && this.restoreFinalDate) {
        return [validDate, minDate(this.restoreInitialDate, this.restoreFinalDate)];
      }

      if (this.restoreFinalDate) {
        return [required];
      }

      return this.filters[this.propertyInicial] ? [validDate] : [];
    },
    validatePropertyFinal() {
      if (this.restoreInitialDate && this.restoreFinalDate) {
        return [validDate, maxDate(this.restoreInitialDate, this.restoreFinalDate)];
      }

      return this.filters[this.propertyFinal] ? [validDate] : [];
    },
  },
  methods: {
    ...mapActions('ressus/drawerFiltros', ['updateField']),
  },
};
</script>
