export default ({
  abiId,
  competencia,
  numeroAtendimento,
  codigoProcedimento,
  codigoBeneficiario,
  descricaoProcedimento,
  gru,
  numeroOficio,
  situacao,
  alegacaoAns,
  motivosId,
  nomeUnidade,
  codigoUnidade,
  codigoEmpresa,
  contrato,
}) => `{
  listarIdsAtendimentosParaRelatorio(
    filter: {
      ${abiId ? `abiId: ${abiId}` : ``}
      ${competencia ? `competencia: [${competencia}]` : ``}
      ${numeroAtendimento ? `numeroAtendimento: "${numeroAtendimento}"` : ``}
      ${codigoProcedimento ? `codigoProcedimento: "${codigoProcedimento}"` : ``}
      ${codigoBeneficiario ? `codigoBeneficiario: "${codigoBeneficiario}"` : ``}
      ${descricaoProcedimento ? `descricaoProcedimento: "${descricaoProcedimento}"` : ``}
      ${gru ? `gru: "${gru}"` : ``}
      ${numeroOficio ? `numeroOficio: "${numeroOficio}"` : ``}
      ${situacao ? `situacao: [${situacao}]` : ``}
      ${alegacaoAns ? `alegacaoAns: [${alegacaoAns}]` : ``}
      ${motivosId ? `motivosId: [${motivosId}]` : ``}
      ${nomeUnidade ? `nomeUnidade: "${nomeUnidade}"` : ``}
      ${codigoUnidade ? `codigoUnidade: ${codigoUnidade}` : ``}
      ${codigoEmpresa ? `codigoEmpresa: "${codigoEmpresa}"` : ``}
      ${contrato ? `contrato: "${contrato}"` : ``}
    }
	)
}`;
