<template>
  <v-file-input
    v-bind="{ label, placeholder, clearable, disabled, value, rules, hideDetails }"
    persistent-placeholder
    filled
    color="primary-blue-base"
    @change="onChange"
  >
    <template #append>
      <v-icon v-if="showIconDelete" class="text-neutral-gray-medium" @click="onDelete">
        mdi-close-circle
      </v-icon>
    </template>
  </v-file-input>
</template>

<script>
export default {
  name: 'ReFileInput',
  props: {
    label: {
      type: String,
      default: 'Anexo',
    },
    placeholder: {
      type: String,
      default: 'Selecionar',
    },
    value: [File, Object],
    clearable: Boolean,
    disabled: Boolean,
    showIconDelete: {
      type: Boolean,
      default: true,
    },
    rules: Array,
    hideDetails: Boolean,
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
    onDelete() {
      this.$emit('delete', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-input__slot:before) {
  border: none !important;
}

:deep(.v-input__slot) {
  display: flex !important;
  align-items: center !important;
  height: 65px;
  box-shadow: 0px 4px 4px theme('colors.neutral-low-light') !important;
  background: theme('colors.neutral-gray-low') !important;

  .v-file-input__text {
    margin: 10px 0;
    color: theme('colors.primary-blue-base') !important;
  }
}

:deep(.v-input__icon--prepend) {
  .v-icon {
    color: theme('colors.primary-blue-base') !important;
  }
}

:deep(.theme--light.v-icon.v-icon.v-icon--disabled) {
  color: theme('colors.primary-blue-base') !important;
}

:deep(.v-input__append-inner) {
  margin: 0 !important;
  height: 100%;
}
</style>
