import resumoAbi from './resumo-abi/routes';
import atendimentosAbi from './atendimentos/routes';
import protocolosEletronicos from './protocolos-eletronicos/routes';
import documentosGerados from './documentos-gerados/routes';
import solicitacaoDocumentos from './solicitacao-documentos/routes';
import lancamentosContabeis from './lancamentos-contabeis/routes';

export default [
  ...resumoAbi,
  ...atendimentosAbi,
  ...protocolosEletronicos,
  ...documentosGerados,
  ...solicitacaoDocumentos,
  ...lancamentosContabeis,
];
