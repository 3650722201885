<template>
  <section>
    <v-row>
      <v-col>
        <re-input-search
          v-model="search"
          class="max-w-none mb-2"
          @search="onSearch"
          @clear-search="onClearSearch"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <div v-if="filteredItems.length">
          <v-checkbox
            v-for="item in filteredItems"
            v-model="filters[property]"
            multiple
            hide-details
            class="py-2 m-0"
            :key="item.key"
            :label="item.label"
            :value="item.value"
            :data-test="`checkbox-${item.key}`"
            @change="onChange"
          />
        </div>
        <div
          v-else
          class="flex items-center justify-center text-primary-blue-base bg-neutral-gray-low h-[50px] text-xs"
        >
          Nenhum registro encontrado
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ReInputSearch } from '@/components';

export default {
  name: 'CheckboxItems',
  props: {
    label: String,
    property: String,
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: { ReInputSearch },
  data() {
    return {
      search: '',
      filteredItems: this.items,
    };
  },
  computed: {
    ...mapState('ressus/drawerFiltros', ['filters']),
  },
  methods: {
    ...mapActions('ressus/drawerFiltros', ['updateField']),
    onChange() {
      this.updateField({
        property: this.property,
        value: this.filters[this.property],
      });
    },
    onSearch() {
      this.filteredItems = this.items.filter((item) =>
        item.label.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
    onClearSearch() {
      this.filteredItems = this.items;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input--is-label-active.v-input--checkbox {
  font-weight: bold;
}
</style>
