import Vue from 'vue';
import omitByAndNil from '@/utils/compare-values';
import { pagination } from '@/constants';
import {
  getMotivoImpugnacao,
  getMotivoImpugnacaoById,
  createMotivoImpugnacaoService,
  updateMotivoImpugnacaoService,
  deleteMotivoImpugnacaoService,
  getAllMotivos,
  getTiposDocumento,
} from '../services';
import redirectRouteUpdate from '../../utils';

const sharedMotivosImpugnacao = async (_, { size = pagination.maxSize, codigos = [] }) => {
  const { payload } = await getMotivoImpugnacao({ size, codigos });

  const motivos = payload?.listarMotivoImpugnacao?.content?.map((motivo) => ({
    ...motivo,
    id: +motivo.id,
    descricaoCompleta: `${motivo.codigo} - ${motivo.descricaoAbreviada}`,
  }));

  return motivos;
};

const loadMotivoImpugnacao = async ({ commit }, query) => {
  commit('RESET_STATE');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  commit('GENERIC_MUTATION', {
    property: 'motivosImpugnacao',
    payload: [],
  });

  const { payload } = await getMotivoImpugnacao(query);

  commit('GENERIC_MUTATION', {
    property: 'motivosImpugnacao',
    payload: payload?.listarMotivoImpugnacao?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarMotivoImpugnacao?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarMotivoImpugnacao?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadMotivoImpugnacaoById = async ({ commit, dispatch }, id) => {
  dispatch('setLoadingForm', true);
  dispatch('ressus/updateControlModalAtencao', true, { root: true });

  await getMotivoImpugnacaoById({
    id,
    onSuccess: async ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'motivoImpugnacao',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: omitByAndNil(data),
      });

      await dispatch('loadMotivos');
      await dispatch('loadTiposDocumento');
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const loadMotivos = async ({ commit }) => {
  const { data } = await getAllMotivos();

  commit('GENERIC_MUTATION', {
    property: 'allMotivos',
    payload: data,
  });
};

const createMotivoImpugnacao = ({ getters, dispatch, commit }, { saveAndNew, resetValidation }) => {
  dispatch('setLoadingForm', true);

  const { payloadToSave } = getters;

  createMotivoImpugnacaoService({
    payload: payloadToSave,
    onSuccess: async () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      !saveAndNew && Vue.router.push({ name: 'motivo-impugnacao' });
      Vue.$toast('Motivo incluído com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');

      if (saveAndNew) {
        await Promise.all([
          dispatch('loadMotivos'),
          dispatch('loadTiposDocumento'),
          dispatch('ressus/cadastros/alegacaoAns/loadAlegacoesAns', true, { root: true }),
        ]);
      }
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateMotivoImpugnacao = (
  { getters, dispatch, commit },
  { saveAndNew, resetValidation, route },
) => {
  dispatch('setLoadingForm', true);

  const { payloadToSave } = getters;

  updateMotivoImpugnacaoService({
    payload: payloadToSave,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      redirectRouteUpdate(saveAndNew, 'create-motivo-impugnacao', route);
      Vue.$toast('Motivo alterado com sucesso.', { type: 'success' });

      saveAndNew && dispatch('loadMotivos');
      saveAndNew && dispatch('loadAlegacoesAns');

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteMotivoImpugnacao = ({ state, commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  deleteMotivoImpugnacaoService({
    id,
    onSuccess: () => {
      Vue.$toast('Motivo excluído com sucesso.', { type: 'success' });

      if (state.motivosImpugnacao.length === 1) {
        Vue.router.replace({
          name: 'motivo-impugnacao',
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }

      dispatch('loadMotivoImpugnacao', query);
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const loadTiposDocumento = async ({ commit }) => {
  const { payload } = await getTiposDocumento({ size: pagination.maxSize });

  commit('GENERIC_MUTATION', {
    property: 'tiposDocumentos',
    payload: payload?.listarTipoDocumento?.content,
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoadingForm = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: value,
  });

export default {
  sharedMotivosImpugnacao,
  loadMotivoImpugnacao,
  loadMotivoImpugnacaoById,
  loadMotivos,
  createMotivoImpugnacao,
  updateMotivoImpugnacao,
  deleteMotivoImpugnacao,
  loadTiposDocumento,
  updateField,
  resetState,
  setLoadingForm,
};
