export default () => `{
  listarAlegacaoAns(
    page: 0
    size: 1000
    filter : { ativo: true})
  {
    content {
      id
			codigo
      tipo
			descricao
    }
    totalPages
    totalElements
    size
    first
    last
  }
}`;
