import Vue from 'vue';
import { ReToastLink } from '@/components';
import { formatMoneyRestore } from '@/utils/currency';
import { formatDateRestore } from '@/utils/date';
import {
  getDadosFormularioByIdService,
  uploadDocumentoComprobatorioService,
  excluirDocumentoComprobatorioService,
  enviarDocumentosService,
} from '@/views/processos/atendimentos/formulario-impugnacao/services';
import { enumErrors } from '@/constants';
import {
  atendimentoProtocolarService,
  getProtocolos,
  updateDataContestacaoService,
  deleteDataContestacaoService,
  getDataFilterService,
} from '../services';
import { itemsFilter } from '../constants';

const loadProtocolos = async ({ state, commit }, query) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  commit('GENERIC_MUTATION', {
    property: 'atendimentos',
    payload: [],
  });

  const queryParams = {
    ...query,
    filters: {
      ...state.filters,
      idAbi: query.idAbi,
      tipoFormulario: query.tab,
      statusFormulario: query.statusFormulario,
      valorTotalInicial: state.filters?.valorTotalInicial
        ? formatMoneyRestore(state.filters.valorTotalInicial)
        : null,
      valorTotalFinal: state.filters?.valorTotalFinal
        ? formatMoneyRestore(state.filters.valorTotalFinal)
        : null,
      periodoInicial: state.filters?.periodoInicial
        ? formatDateRestore(state.filters.periodoInicial)
        : null,
      periodoFinal: state.filters?.periodoFinal
        ? formatDateRestore(state.filters.periodoFinal)
        : null,
      numeroProtocoloImpugnacao: query.tab === 'IMPUGNACAO' ? state.filters.protocoloAns : null,
      numeroProtocoloRecursao: query.tab === 'RECURSAO' ? state.filters.protocoloAns : null,
      dataProtocoloImpugnacaoInicial:
        query.tab === 'IMPUGNACAO' && state.filters?.dataProtocoloInicial
          ? formatDateRestore(state.filters.dataProtocoloInicial)
          : null,
      dataProtocoloImpugnacaoFinal:
        query.tab === 'IMPUGNACAO' && state.filters?.dataProtocoloFinal
          ? formatDateRestore(state.filters.dataProtocoloFinal)
          : null,
      dataProtocoloRecursaoInicial:
        query.tab === 'RECURSAO' && state.filters?.dataProtocoloInicial
          ? formatDateRestore(state.filters.dataProtocoloInicial)
          : null,
      dataProtocoloRecursaoFinal:
        query.tab === 'RECURSAO' && state.filters?.dataProtocoloFinal
          ? formatDateRestore(state.filters.dataProtocoloFinal)
          : null,
      dataContestacaoImpugnacaoInicial:
        query.tab === 'IMPUGNACAO' && state.filters?.dataContestacaoInicial
          ? formatDateRestore(state.filters.dataContestacaoInicial)
          : null,
      dataContestacaoImpugnacaoFinal:
        query.tab === 'IMPUGNACAO' && state.filters?.dataContestacaoFinal
          ? formatDateRestore(state.filters.dataContestacaoFinal)
          : null,
      dataContestacaoRecursaoInicial:
        query.tab === 'RECURSAO' && state.filters?.dataContestacaoInicial
          ? formatDateRestore(state.filters.dataContestacaoInicial)
          : null,
      dataContestacaoRecursaoFinal:
        query.tab === 'RECURSAO' && state.filters?.dataContestacaoFinal
          ? formatDateRestore(state.filters.dataContestacaoFinal)
          : null,
    },
  };

  const { payload, error } = await getProtocolos(queryParams);

  if (error) {
    Vue.$toast(error.message || error, { type: 'error', id: 'error-protocolos-eletronicos' });

    commit('GENERIC_MUTATION', {
      property: 'atendimentos',
      payload: [],
    });
  } else {
    commit('GENERIC_MUTATION', {
      property: 'atendimentos',
      payload: payload?.listarAtendimentos?.content,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalElements',
      payload: payload?.listarAtendimentos?.totalElements,
    });

    commit('GENERIC_MUTATION', {
      property: 'totalPages',
      payload: payload?.listarAtendimentos?.totalPages,
    });
  }

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const atendimentoProtocolar = (
  { commit, dispatch },
  { todosAtendimentos, atendimentos, query },
) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const enumSituacao = {
    IMPUGNACAO: 'EM_ANALISE',
    RECURSAO: 'EM_PROCESSO',
  };

  const payload = todosAtendimentos
    ? {
        abiId: query.idAbi,
        situacao: enumSituacao[query.tab],
        todosAtendimentos: true,
        statusAtendimento: 'FORMULARIO_ASSINADO',
      }
    : {
        atendimentoIds: atendimentos,
        todosAtendimentos: false,
      };

  atendimentoProtocolarService({
    payload,
    onSuccess: async () => {
      Vue.$toast(
        'Protocolos enviados com sucesso. Aguarde a ANS realizar a atualização do status dos atendimentos',
        { type: 'success' },
      );

      dispatch('loadProtocolos', query);
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const updateDataContestacao = async (
  { commit, dispatch },
  { abiId, payload, tipoFormulario, query },
) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  updateDataContestacaoService({
    abiId,
    payload,
    tipoFormulario,
    onSuccess: async () => {
      Vue.$toast(
        `Contestação ${
          tipoFormulario === 'IMPUGNACAO' ? 'da impugnação' : 'do recurso'
        } concluída.`,
        { type: 'success' },
      );

      dispatch('loadProtocolos', query);
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response?.status],
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const deleteDataContestacao = async ({ commit, dispatch }, { abiId, tipoFormulario, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  deleteDataContestacaoService({
    abiId,
    tipoFormulario,
    onSuccess: async () => {
      Vue.$toast(
        `Contestação reaberta para ${tipoFormulario === 'IMPUGNACAO' ? 'impugnação' : 'recurso'}`,
        { type: 'success' },
      );

      dispatch('loadProtocolos', query);
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response.status],
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const getDadosFiltraveis = async ({ commit }, { entidade, nomeCampo, abiId }) => {
  commit(
    'ressus/drawerFiltros/GENERIC_MUTATION',
    {
      property: 'loading',
      payload: true,
    },
    { root: true },
  );

  await getDataFilterService({
    entidade,
    nomeCampo,
    abiId,
    onSuccess: ({ data }) => {
      const objectData = data.map((item, index) => ({
        key: index,
        label: item,
        value: item,
      }));

      const objectToModify = itemsFilter.find((item) => item.id === 4);

      objectToModify.props.items = objectData;
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => {
      commit(
        'ressus/drawerFiltros/GENERIC_MUTATION',
        {
          property: 'loading',
          payload: false,
        },
        { root: true },
      );
    },
  });
};

const loadVisualizacaoDocumento = ({ commit, dispatch }, payload) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  dispatch('ressus/drawerVisualizarDocumento/loadVisualizacaoDocumento', payload, { root: true });
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const toggleDrawerVisualizarDocumento = ({ state, commit }) =>
  commit('GENERIC_MUTATION', {
    property: 'showDrawerVisualizarDocumento',
    payload: !state.showDrawerVisualizarDocumento,
  });

const setFilters = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'filters',
    payload,
  });

const loadDadosFormulario = ({ commit }, formularioId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingDocumentosComprobatorios',
    payload: true,
  });

  getDadosFormularioByIdService({
    formularioId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'dadosFormulario',
        payload: data,
      });

      Vue.router.history.current.meta.title = `Processos - Atendimento Nº ${data.numeroAtendimento}`;
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingDocumentosComprobatorios',
        payload: false,
      }),
  });
};

const toggleModalUploadDocumento = ({ state, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'showModalUploadDocumento',
    payload: !state.showModalUploadDocumento,
  });

  commit('RESET_FORM_UPLOAD_DOCUMENTO');
};

const updateFieldUploadDocumento = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD_UPLOAD_DOCUMENTO', {
    property,
    value,
  });
};

const uploadDocumento = ({ state, commit, dispatch }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingUploadDocumento',
    payload: true,
  });

  const formularioId = state.dadosFormulario.id;
  const { anexo, assunto, data, tipo } = state.dadosUploadDocumento;

  const request = {
    tipoDocumentoId: +tipo.id,
    assunto,
    dataDocumento: data,
  };

  const payload = new FormData();

  payload.append(
    'file',
    new Blob([anexo], {
      type: anexo.type,
    }),
    anexo.name,
  );

  payload.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  uploadDocumentoComprobatorioService({
    formularioId,
    payload,
    onSuccess: () => {
      Vue.$toast('Documento adicionado com sucesso', { type: 'success' });
    },
    onCustomError: ({ response }) => {
      if (response?.data?.errors) {
        response.data.errors.map((error) =>
          Vue.$toast(error.defaultMessage, {
            type: 'error',
          }),
        );
      } else {
        Vue.$toast(response?.data?.message, {
          type: 'error',
        });
      }
    },
    onFinally: async () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingUploadDocumento',
        payload: false,
      });

      await dispatch('toggleModalUploadDocumento');

      dispatch('loadDadosFormulario', formularioId);
    },
  });
};

const excluirDocumentoComprobatorio = ({ commit, dispatch }, { formularioId, documentoId }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingDocumentosComprobatorios',
    payload: true,
  });

  excluirDocumentoComprobatorioService({
    formularioId,
    documentoId,
    onSuccess: async () => {
      Vue.$toast('Documento excluído com sucesso', { type: 'success' });

      dispatch('loadDadosFormulario', formularioId);
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingDocumentosComprobatorios',
        payload: false,
      }),
  });
};

const enviarDocumentos = ({ commit, dispatch }, formularioId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingDocumentosComprobatorios',
    payload: true,
  });

  enviarDocumentosService({
    formularioId,
    onSuccess: () => {
      Vue.$toast('Documentos enviados com sucesso', { type: 'success' });
      dispatch('loadDadosFormulario', formularioId);
    },
    onCustomError: ({ response }) =>
      Vue.$toast(
        {
          component: ReToastLink,
          props: { description: response?.data?.message },
        },
        { type: 'error' },
      ),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingDocumentosComprobatorios',
        payload: false,
      }),
  });
};

const updateFieldStatus = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'atendimentos',
    payload,
  });

const resetFilters = ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'filters',
    payload: {},
  });

  commit('ressus/drawerFiltros/RESET_STATE_FILTERS', null, { root: true });
};

export default {
  loadProtocolos,
  atendimentoProtocolar,
  updateDataContestacao,
  deleteDataContestacao,
  getDadosFiltraveis,
  loadVisualizacaoDocumento,
  toggleDrawerVisualizarDocumento,
  setFilters,
  loadDadosFormulario,
  toggleModalUploadDocumento,
  updateFieldUploadDocumento,
  uploadDocumento,
  excluirDocumentoComprobatorio,
  enviarDocumentos,
  updateFieldStatus,
  resetFilters,
};
