import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { getAtendimentoById } from '../../drawer-detalhamento-atendimento/services';
import {
  salvarFormularioService,
  getMotivosByAtendimento,
  updateFormularioService,
  iniciarProtocoloService,
  cancelarProtocoloService,
  getDadosFormularioByIdService,
  excluirDocumentoComprobatorioService,
  uploadDocumentoComprobatorioService,
  gerarFormularioService,
  enviarDocumentosService,
} from '../services';
import { tiposFormulario } from '../constants';

const loadAtendimento = ({ commit, dispatch }) => {
  dispatch('setLoading');

  commit('GENERIC_MUTATION', {
    property: 'dadosFormulario',
    payload: {},
  });

  const { atendimentoId } = Vue.router.history.current.params;

  getAtendimentoById({
    id: atendimentoId,
    onSuccess: async ({ data }) => {
      const { tipoFormulario } = Vue.router.history.current.query;

      Vue.router.history.current.meta.title = `Processos - Atendimento Nº ${data.numero}`;
      Vue.router.history.current.meta.subtitle = `Formulário de ${tiposFormulario[tipoFormulario].label}`;

      const payload = data[tiposFormulario[tipoFormulario].object];

      if (payload) {
        await dispatch('loadDadosFormulario', payload.id);
      } else {
        dispatch('gerarFormulario', {
          atendimentoId,
          tipoFormulario,
        });
      }

      commit('GENERIC_MUTATION', {
        property: 'isSomenteVisualizacao',
        payload: data?.somenteVisualizacao,
      });

      dispatch('loadDadosMotivos', {
        atendimentoId,
        formularioAtendimento: payload,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const loadDadosFormulario = async ({ commit }, formularioId) => {
  await getDadosFormularioByIdService({
    formularioId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'dadosFormulario',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialStateDadosFormulario',
        payload: cloneDeep(data),
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
  });
};

const loadDadosMotivos = ({ commit }, { atendimentoId, formularioAtendimento }) => {
  getMotivosByAtendimento({
    atendimentoId,
    onSuccess: ({ data }) => {
      const motivosImpugnacaoAtivo = [];

      data.map(({ detalhamentoPreDefinido, motivoImpugnacao }, index) => {
        const formulario = formularioAtendimento?.listagemFormularioMotivos?.find(
          (form) => form?.motivoImpugnacao?.id === motivoImpugnacao?.motivoImpugnacao?.id,
        );

        if (motivoImpugnacao?.motivoImpugnacao?.id === formulario?.motivoImpugnacao?.id) {
          return motivosImpugnacaoAtivo.push({
            motivoImpugnacaoId: formulario?.motivoImpugnacao?.id,
            descricaoAbreviada: formulario?.motivoImpugnacao?.descricaoAbreviada,
            codigo: formulario?.motivoImpugnacao?.codigo,
            detalhamento: formulario?.detalhamento || '',
            memoriaCalculo: formulario?.memoriaCalculo || '',
            detalhamentoPreDefinido,
          });
        }

        return motivosImpugnacaoAtivo?.push({
          motivoImpugnacaoId: motivoImpugnacao?.motivoImpugnacao?.id,
          descricaoAbreviada: motivoImpugnacao?.motivoImpugnacao?.descricaoAbreviada,
          codigo: motivoImpugnacao?.motivoImpugnacao?.codigo,
          detalhamento: formularioAtendimento ? formularioAtendimento[index]?.detalhamento : '',
          memoriaCalculo: formularioAtendimento ? formularioAtendimento[index]?.memoriaCalculo : '',
          detalhamentoPreDefinido,
        });
      });

      commit('GENERIC_MUTATION', {
        property: 'dadosMotivos',
        payload: motivosImpugnacaoAtivo,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialStateDadosMotivos',
        payload: cloneDeep(motivosImpugnacaoAtivo),
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
  });
};

const gerarFormulario = ({ commit, dispatch }, { atendimentoId, tipoFormulario }) => {
  dispatch('setLoading');

  const payload = {
    id: null,
    pedidoAlegacao: 'ANULACAO',
    tipoFormulario,
  };

  gerarFormularioService({
    atendimentoId,
    onSuccess: async ({ data }) => {
      const { listagemFormularioMotivos } = data;
      const payloadData = { ...payload, ...data };

      commit('GENERIC_MUTATION', {
        property: 'dadosFormulario',
        payload: payloadData,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialStateDadosFormulario',
        payload: cloneDeep(payloadData),
      });

      dispatch('loadDadosMotivos', {
        atendimentoId,
        formularioAtendimento: listagemFormularioMotivos,
      });
    },
    onCustomError: () => {
      commit('GENERIC_MUTATION', {
        property: 'dadosFormulario',
        payload,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialStateDadosFormulario',
        payload: cloneDeep(payload),
      });
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateFieldFormulario = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD_FORMULARIO', {
    property,
    value,
  });
};

const updateFieldMotivo = ({ commit }, { index, property, value }) => {
  commit('UPDATE_FIELD_MOTIVO', {
    index,
    property,
    value,
  });
};

const saveFormulario = ({ dispatch, getters }, atendimentoId) => {
  dispatch('setLoading');

  salvarFormularioService({
    atendimentoId,
    payload: getters.payloadUpdateFormulario,
    onSuccess: ({ data }) => {
      if (data.temDocumentosPendentes) {
        Vue.$toast(data.mensagemAlerta, { type: 'info' });
      }

      Vue.$toast('Formulário salvo com sucesso', {
        type: 'success',
      });

      dispatch('loadAtendimento');
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateFormulario = ({ getters, dispatch }, formularioId) => {
  dispatch('setLoading');

  updateFormularioService({
    formularioId,
    payload: getters.payloadUpdateFormulario,
    onSuccess: ({ data }) => {
      if (data.temDocumentosPendentes) {
        Vue.$toast(data.mensagemAlerta, { type: 'info' });
      }

      Vue.$toast('Formulário atualizado com sucesso', {
        type: 'success',
      });

      dispatch('loadAtendimento');
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const toggleModalUploadDocumento = ({ state, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'showModalUploadDocumento',
    payload: !state.showModalUploadDocumento,
  });

  commit('RESET_FORM_UPLOAD_DOCUMENTO');
};

const updateFieldUploadDocumento = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD_UPLOAD_DOCUMENTO', {
    property,
    value,
  });
};

const uploadDocumento = ({ state, commit, dispatch }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingUploadDocumento',
    payload: true,
  });

  const formularioId = state.dadosFormulario.id;
  const { anexo, assunto, data, tipo } = state.dadosUploadDocumento;

  const request = {
    tipoDocumentoId: +tipo.id,
    assunto,
    dataDocumento: data,
  };

  const payload = new FormData();

  payload.append(
    'file',
    new Blob([anexo], {
      type: anexo.type,
    }),
    anexo.name,
  );

  payload.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  uploadDocumentoComprobatorioService({
    formularioId,
    payload,
    onSuccess: () => {
      Vue.$toast('Documento adicionado com sucesso', { type: 'success' });
    },
    onCustomError: ({ response }) => {
      if (response?.data?.errors) {
        response.data.errors.map((error) =>
          Vue.$toast(error.defaultMessage, {
            type: 'error',
          }),
        );
      } else {
        Vue.$toast(response?.data?.message, {
          type: 'error',
        });
      }
    },
    onFinally: async () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingUploadDocumento',
        payload: false,
      });

      await dispatch('toggleModalUploadDocumento');

      dispatch('loadAtendimento');
    },
  });
};

const excluirDocumentoComprobatorio = ({ dispatch }, { formularioId, documentoId }) => {
  dispatch('setLoading');

  excluirDocumentoComprobatorioService({
    formularioId,
    documentoId,
    onSuccess: async () => {
      Vue.$toast('Documento excluído com sucesso', { type: 'success' });

      dispatch('loadAtendimento');
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const iniciarProtocolo = ({ dispatch }, formularioId) => {
  dispatch('setLoading');

  iniciarProtocoloService({
    formularioId,
    onSuccess: () => {
      Vue.$toast('Protocolo iniciado com sucesso', { type: 'success' });
      dispatch('loadAtendimento');
    },
    onCustomError: ({ response }) => {
      Vue.$toast(response?.data?.message, {
        type: 'error',
      });

      dispatch('loadAtendimento');
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const cancelarProtocolo = ({ dispatch }, formularioId) => {
  dispatch('setLoading');

  cancelarProtocoloService({
    formularioId,
    onSuccess: () => {
      Vue.$toast('Protocolo cancelado com sucesso', { type: 'success' });
      dispatch('loadAtendimento');
    },
    onCustomError: ({ response }) => {
      Vue.$toast(response?.data?.message, { type: 'error' });

      dispatch('loadAtendimento');
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const enviarDocumentos = ({ dispatch }, formularioId) => {
  dispatch('setLoading');

  enviarDocumentosService({
    formularioId,
    onSuccess: () => {
      Vue.$toast('Documentos enviados com sucesso', { type: 'success' });
      dispatch('loadAtendimento');
    },
    onCustomError: () => {
      Vue.$toast('Erro no envio do(s) documento(s)', { type: 'error' });
      dispatch('loadAtendimento');
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const resetState = ({ commit }) => {
  commit('RESET_FORM_IMPUGNACAO');
};

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

const loadVisualizacaoDocumento = ({ dispatch }, payload) => {
  dispatch('setLoading');

  dispatch('ressus/drawerVisualizarDocumento/loadVisualizacaoDocumento', payload, { root: true });

  dispatch('setLoading', false);
};

export default {
  loadAtendimento,
  loadDadosFormulario,
  loadDadosMotivos,
  gerarFormulario,
  updateFieldFormulario,
  updateFieldMotivo,
  saveFormulario,
  updateFormulario,
  toggleModalUploadDocumento,
  updateFieldUploadDocumento,
  uploadDocumento,
  excluirDocumentoComprobatorio,
  iniciarProtocolo,
  cancelarProtocolo,
  enviarDocumentos,
  resetState,
  setLoading,
  loadVisualizacaoDocumento,
};
