import { ressusURI } from '@/api';
import { createCommandService } from '@/services';

const indicadoresRessarcimentoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/documento/indicadores_ressarcimento`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

export default indicadoresRessarcimentoService;
