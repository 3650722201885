import { helpPages } from '@/constants';

export default [
  {
    path: '/lancamentos-contabeis/:id?',
    name: 'lancamentos-contabeis',
    component: () => import(/* webpackChunkName: "lancamentos-contabeis" */ './index.vue'),
    meta: {
      title: 'Processos',
      subtitle: 'Lançamentos contábeis',
      helpPage: helpPages['lancamentos-contabeis'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
