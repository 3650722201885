import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import ListarAlegacaoAns from './queries/listar-alegacao-ans';

const getAlegacoesAns = (queryParams) => createGraphQLService(ListarAlegacaoAns(queryParams));

const getAlegacaoAnsById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/alegacao_ans/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createAlegacaoAnsService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/alegacao_ans`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteAlegacaoAnsService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/alegacao_ans/${id}`,
    onSuccess,
    onCustomError,
  });

const updateAlegacaoAnsService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/alegacao_ans/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getAlegacoesAns,
  getAlegacaoAnsById,
  createAlegacaoAnsService,
  updateAlegacaoAnsService,
  deleteAlegacaoAnsService,
};
