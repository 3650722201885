import { isEqual } from 'lodash';
import { formatDate } from '@/utils/date';
import omitByAndNil from '@/utils/compare-values';

const empresasTable = ({ empresas }) =>
  empresas?.map((empresa) => ({
    ...empresa,
    status: empresa.ativo ? 'ATIVO' : 'INATIVO',
  }));

const empresa = ({ empresa }) => ({
  ...empresa,
  situacao: empresa?.ativo ? 'Ativo' : 'Inativo',
});

const isInitialState = ({ initialStateEmpresa, empresa }) =>
  isEqual(omitByAndNil(initialStateEmpresa), omitByAndNil(empresa));

const dadosDocumentosEmpresa = ({ documentosEmpresa }) =>
  documentosEmpresa.map((documento) => ({
    ...documento,
    data: formatDate(documento.dataDocumento),
  }));

export default {
  empresa,
  empresasTable,
  isInitialState,
  dadosDocumentosEmpresa,
};
