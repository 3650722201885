import { formUploadDocumento } from '@/views/processos/constants';

export default () => ({
  atendimentos: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  filters: {},
  showDrawerVisualizarDocumento: false,
  fileURL: null,
  loadingDocumentosComprobatorios: false,
  loadingUploadDocumento: false,
  showModalUploadDocumento: false,
  dadosFormulario: {},
  dadosUploadDocumento: { ...formUploadDocumento },
});
