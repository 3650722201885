import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import ressus from './main';

Vue.use(Vuex);

export default new Store({
  namespaced: true,
  modules: { ressus },
});
