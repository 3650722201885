<template>
  <v-select
    v-bind="{
      clearable,
      disabled,
      items,
      label,
      multiple,
      outlined,
      placeholder,
      value,
    }"
    persistent-placeholder
    return-object
    item-value="id"
    item-text="descricao"
    :rules="validationRules"
    :error-count="validationRules.length"
    @change="onChange"
  >
    <template v-if="multiple" #selection="{ item, index }">
      <v-chip v-if="index === 0" dark color="bg-branding-primary-lemon">
        <span>{{ item.descricao }}</span>
      </v-chip>
      <span v-if="index === 1" class="text-xs text-grey">
        {{ moreItems }}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'ReSelect',
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    multiple: Boolean,
    outlined: Boolean,
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    required: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, Object],
      default: () => [],
    },
  },
  computed: {
    validationRules() {
      const requiredMessage = this.required
        ? [(v) => (this.multiple ? !!v.length : !!v) || 'Campo obrigatório']
        : [];

      return [...requiredMessage, ...this.rules];
    },
    moreItems() {
      return `+${this.value.length - 1} (${this.value.length - 1 === 1 ? 'outro' : 'outros'})`;
    },
  },
  methods: {
    onChange(value) {
      this.$emit('selectedOption', value);
    },
  },
};
</script>
