import Vue from 'vue';

const redirectRouteUpdate = (saveAndNew, createRouteName, route) =>
  Vue.router.push({
    name: saveAndNew ? createRouteName : route?.name,
    query: route?.query,
    params: route?.params,
  });

export default redirectRouteUpdate;
