import { ressusURI } from '@/api';
import { createCommandService, createGraphQLService } from '@/services';
import createQueryListarDocumentos from './queries/listar-documentos';

const getListarDocumentos = async (queryParameters) =>
  createGraphQLService(createQueryListarDocumentos(queryParameters));

const reiniciarGeracaoDocumentoService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    id,
    method: 'post',
    uri: `${ressusURI}/documento/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export { getListarDocumentos, reiniciarGeracaoDocumentoService };
