import { formatDate } from '@/utils/date';
import { formatPrefixedMoney } from '@/utils/currency';
import { configStatus } from '../constants';

const oficiosNotificacaoTable = ({ oficiosNotificacao }) =>
  oficiosNotificacao?.map((oficio) => ({
    ...oficio,
    abi: {
      id: oficio?.abi?.id,
      numero: oficio?.abi?.numero,
    },
    decisao: {
      id: oficio?.decisao?.id,
      instancia: {
        id: oficio?.decisao?.instancia?.id,
        descricao: oficio?.decisao?.instancia?.descricao,
      },
    },
    cobranca: {
      id: oficio?.cobranca?.id,
      instancia: {
        id: oficio?.cobranca?.instancia?.id,
        descricao: oficio?.cobranca?.instancia?.descricao,
      },
    },
    dataEmissao: formatDate(oficio?.dataEmissao),
    dataRecebimento: formatDate(oficio?.dataRecebimento),
    valorTotal: formatPrefixedMoney(oficio?.valorTotal || 0),
    action: configStatus[oficio.status],
  }));

const dadosImportacao = ({ oficioNotificado }) => ({
  numeroOficioAbertura: oficioNotificado?.numeroOficio,
  dataOficioAbertura: oficioNotificado?.dataEmissao,
  prazoFinalImpugnacao: oficioNotificado?.abi?.prazoFinalImpugnacao || '',
  resolucaoNormativa: oficioNotificado?.abi?.resolucaoNormativa || '',
  vigenciaInicialRolAns: oficioNotificado?.abi?.vigenciaInicialRolAns || '',
  vigenciaFinalRolAns: oficioNotificado?.abi?.vigenciaFinalRolAns || '',
  anexo: oficioNotificado?.anexo || null,
  errorMessages: oficioNotificado?.errorMessages || [],
});

export default { oficiosNotificacaoTable, dadosImportacao };
