import { formatPrefixedMoney } from '@/utils/currency';
import { formatDateCompetency, formatDate } from '@/utils/date';
import { configHeader } from '../constants';

const concatTextFields = (firstField, lastField, separator = '-') => {
  const first = firstField || '';
  const last = lastField || '';

  if (!firstField && !lastField) return '';

  return `${first} ${separator} ${last}`;
};

const concatMotivos = (motivos) => {
  if (!motivos.length) return { joinMotivos: '' };

  const countMotivos =
    motivos.length > 1 ? `${motivos.length} Motivos` : `${motivos.length} Motivo`;

  const joinMotivos = motivos
    ?.map(
      (motivo) =>
        `${motivo?.motivoImpugnacao?.codigo}. ${motivo?.motivoImpugnacao?.descricaoAbreviada}`,
    )
    .join('<br/>');

  return { countMotivos, joinMotivos };
};

const dadosAtendimentos = ({ atendimentos }) =>
  atendimentos?.map((atendimento) => ({
    id: atendimento.id,
    status: atendimento?.statusAtendimento,
    statusAtendimento: atendimento?.descricaoStatusAtendimento,
    statusAtendimentoOrder: atendimento?.descricaoStatusAtendimento,
    contagem: atendimento?.contagem,
    tipo: atendimento?.tipo,
    motivos: concatMotivos(atendimento?.listagemMotivoImpugnacao),
    competencia: formatDateCompetency(atendimento?.competencia),
    numero: atendimento?.numero,
    beneficiario: {
      codigo: atendimento?.beneficiario?.codigo,
      nomeCompleto: atendimento?.beneficiario?.nomeCompleto,
      codigoCco: atendimento?.beneficiario?.codigoCco,
      cpf: atendimento?.beneficiario?.cpf,
      dataNascimento: formatDate(atendimento?.beneficiario?.dataNascimento),
      unimedContrato: concatTextFields(
        atendimento?.beneficiario?.contrato,
        atendimento?.beneficiario?.unimedResponsavel,
        '/',
      ),
      contratoFisico: atendimento?.beneficiario?.contratoFisico,
      empresa: concatTextFields(
        atendimento?.beneficiario?.empresaContratante,
        atendimento?.beneficiario?.nomeEmpresaContratante,
      ),
      cnpjEmpresaContratante: atendimento?.beneficiario?.cnpjEmpresaContratante,
      registroProdutoAns: atendimento?.beneficiario?.registroProdutoAns,
      tipoProduto: atendimento?.beneficiario?.tipoProduto,
      vigencia: formatDate(atendimento?.beneficiario?.vigencia),
      adesao: formatDate(atendimento?.beneficiario?.adesao),
      exclusao: formatDate(atendimento?.beneficiario?.exclusao),
      reinclusao: formatDate(atendimento?.beneficiario?.reinclusao),
      formacaoPrecoProduto: atendimento?.beneficiario?.formacaoPrecoProduto,
      tipoContratacaoProduto: atendimento?.beneficiario?.tipoContratacaoProduto,
      segmentacao: concatTextFields(
        atendimento?.beneficiario?.segmentacaoProduto,
        atendimento?.beneficiario?.descricaoSegmentacaoProduto,
      ),
      descricaoCoparticipacao: atendimento?.beneficiario?.descricaoCoparticipacao,
      nomeProdutoAns: atendimento?.beneficiario?.nomeProdutoAns,
    },
    valorTotal: formatPrefixedMoney(atendimento?.valorTotal || 0),
    naoImpugnado: atendimento?.naoImpugnado,
    naoRecursado: atendimento?.naoRecursado,
    periodoAtendimento: `
      ${formatDate(atendimento?.dataInicio)} -
      ${formatDate(atendimento?.dataFim)}
    `,
    critica: atendimento?.nomeCriticasValidacao,
    prestador: {
      cnesPrestador: concatTextFields(
        atendimento?.prestador?.cnes,
        atendimento?.prestador?.nomeUps,
        '/',
      ),
      endereco: atendimento?.prestador?.endereco?.rua,
      cidadeUf: concatTextFields(
        atendimento?.prestador?.endereco?.municipio,
        atendimento?.prestador?.endereco?.codigoUf,
        '/',
      ),
      cepCodIbge: concatTextFields(
        atendimento?.prestador?.endereco?.cep,
        atendimento?.beneficiario?.codigoIbge,
        '/',
      ),
    },
    caraterAtendimento: concatTextFields(
      atendimento?.caraterAtendimento?.codigo,
      atendimento?.caraterAtendimento?.descricaoAbreviada,
    ),
    procedimentoPrincipal: {
      valor: formatPrefixedMoney(atendimento?.procedimentoPrincipal?.valor || 0),
      quantidade: atendimento?.procedimentoPrincipal?.quantidade,
      procedimento: {
        codigo: atendimento?.procedimentoPrincipal?.procedimento?.codigo,
        descricao: atendimento?.procedimentoPrincipal?.procedimento?.descricao,
      },
    },
    valorDeducao: formatPrefixedMoney(atendimento?.valorDeducao || 0),
    valorRetificado: formatPrefixedMoney(atendimento?.valorRetificado || 0),
    valorDeducaoDeferida: formatPrefixedMoney(atendimento?.valorDeducaoDeferida || 0),
    documentos: atendimento?.quantidadeDocumentosComprobatorios,
    formularioImpugnacao: {
      ...(atendimento?.formularioImpugnacao || null),
    },
    formularioRecursao: {
      ...(atendimento?.formularioRecursao || null),
    },
    alegacaoAns:
      atendimento?.decisoesAtendimento[atendimento.decisoesAtendimento.length - 1]?.tipoAlegacaoAns,
    opiniao: '',
    numeroOficioDecisao:
      atendimento?.decisoesAtendimento[atendimento.decisoesAtendimento.length - 1]?.decisao
        ?.numeroOficio,
    instanciaProcesso:
      atendimento?.decisoesAtendimento[atendimento.decisoesAtendimento.length - 1]?.decisao
        ?.instancia?.descricao,
    dataRecebimento: formatDate(
      atendimento?.decisoesAtendimento[atendimento.decisoesAtendimento.length - 1]?.decisao
        ?.dataRecebimento,
    ),
    observacoes: '',
    gru: atendimento?.cobrancasAtendimento[0]?.cobranca?.gru,
    acaoJudicial: atendimento?.cobrancasAtendimento[0]?.cobranca?.acaoJudicial,
    vencimentoGru: formatDate(atendimento?.cobrancasAtendimento[0]?.cobranca?.vencimentoGru),
    dataCobranca: formatDate(atendimento?.cobrancasAtendimento[0]?.cobranca?.dataRecebimento),
    numeroOficioCobranca: atendimento?.cobrancasAtendimento[0]?.cobranca?.numeroOficio,
    instanciaCobranca: atendimento?.cobrancasAtendimento[0]?.cobranca?.instancia?.descricao,
    numeroProcesso: atendimento?.cobrancasAtendimento[0]?.cobranca?.numeroProcesso,
    valorDesconto: formatPrefixedMoney(
      atendimento?.cobrancasAtendimento[0]?.cobranca?.valorDesconto || 0,
    ),
    valorRessarcimento: formatPrefixedMoney(atendimento?.valorRessarcimento || 0),
    dataIncorporacaoCusto: atendimento?.cobrancasAtendimento[0]?.cobranca?.dataIncorporacaoCusto,
    numeroFatura: atendimento?.numeroFatura,
    temDocumentosPendentes: atendimento?.temDocumentosPendentes,
    mensagemDocumentosPendentes: atendimento?.mensagemDocumentosPendentes,
    dataVigencia: formatDate(atendimento?.beneficiario?.vigencia),
    dataFinalCarencia: formatDate(atendimento?.beneficiario?.dataFinalCarencia),
    dataFinalCoberturaParcialTemporaria:
      atendimento?.beneficiario?.dataFinalCoberturaParcialTemporaria,
    admissao: formatDate(atendimento?.beneficiario?.admissao),
  }));

const headers = (_, rootGetters) => {
  const colunas = rootGetters['filtrosAvancados/colunasSelecionadas'] || [];

  const configColumns = colunas?.map((coluna) => ({
    text: configHeader[coluna.nome]?.hideText || coluna?.tituloTabela?.toUpperCase(),
    value: configHeader[coluna.nome]?.value,
    sortable: configHeader[coluna.nome]?.sortable,
    width: configHeader[coluna.nome]?.width,
    align: configHeader[coluna.nome]?.align,
    class: configHeader[coluna.nome]?.class,
    cellClass: configHeader[coluna.nome]?.cellClass,
  }));

  const lastColumn = {
    text: '',
    sortable: false,
    width: 50,
    value: 'data-table-select',
    align: 'center',
  };

  if (colunas.length > 0) {
    return [...configColumns, { ...lastColumn }];
  }

  return [];
};

export default { dadosAtendimentos, headers };
