var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',_vm._b({attrs:{"persistent-placeholder":"","return-object":"","item-value":"id","item-text":"descricao","rules":_vm.validationRules,"error-count":_vm.validationRules.length},on:{"change":_vm.onChange},scopedSlots:_vm._u([(_vm.multiple)?{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"dark":"","color":"bg-branding-primary-lemon"}},[_c('span',[_vm._v(_vm._s(item.descricao))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"text-xs text-grey"},[_vm._v(" "+_vm._s(_vm.moreItems)+" ")]):_vm._e()]}}:null],null,true)},'v-select',{
    clearable: _vm.clearable,
    disabled: _vm.disabled,
    items: _vm.items,
    label: _vm.label,
    multiple: _vm.multiple,
    outlined: _vm.outlined,
    placeholder: _vm.placeholder,
    value: _vm.value,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }