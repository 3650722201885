import { defaultFilters } from '../constants';

export default () => ({
  lancamentosContabeis: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  filters: {
    ...defaultFilters,
  },
});
