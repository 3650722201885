import Vue from 'vue';
import initialState from './state';
import { formDockModal } from '../constants';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD_DOCK_MODAL(state, { property, value }) {
    Vue.set(state.dadosDockModal, property, value);
  },
  RESET_STATE_DOCK_MODAL(state) {
    Object.assign(state.dadosDockModal, formDockModal);
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.formSolicitacao, property, value);
  },
  UPDATE_FIELD_DOCUMENTOS(state, { property, value }) {
    const nome = value.name;
    const { size } = value;

    state.formSolicitacao[property].push({
      nome,
      arquivo: value,
      size,
    });
  },
};
