const fields = {
  idAbi: null,
  dataPeriodoInicial: null,
  dataPeriodoFinal: null,
  nomeEmpresa: null,
  codigoEmpresa: null,
};

export default () => ({
  loading: false,
  dadosRelatorio: { ...fields },
  initialState: structuredClone({ ...fields }),
});
