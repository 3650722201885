export const headers = [
  {
    value: 'codigoTuss',
  },
  {
    value: 'termosTuss',
  },
  {
    value: 'codigoSus',
  },
  {
    value: 'procedimentoSus',
  },
  { value: 'actions' },
];

export const footerProps = {
  itemsPerPageText: 'Linhas por página:',
  itemsPerPageAllText: 'Todas',
  itemsPerPageOptions: [10, 20, 50, 100],
  pageText: '{0}-{1} de {2}',
  showFirstLastPage: true,
  firstIcon: 'mdi-chevron-double-left',
  lastIcon: 'mdi-chevron-double-right',
};
