import { cloneDeep } from 'lodash';
import Vue from 'vue';
import initialState from './state';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = cloneDeep(payload);
  },
  UPDATE_PROCEDIMENTOS_FIELD(state, { item, property, value }) {
    state.procedimentosTussSigtap = state.procedimentosTussSigtap.map((procedimento) => ({
      ...procedimento,
      [property]: procedimento?.id === item?.id ? value : procedimento[property],
    }));
  },
  ADD_NEW_PROCEDIMENTO(state) {
    state.procedimentosTussSigtap.splice(0, 0, {
      id: '',
      codigoTuss: '',
      termosTuss: '',
      codigoSus: '',
      procedimentoSus: '',
    });
  },
  DELETE_NEW_PROCEDIMENTO(state) {
    state.procedimentosTussSigtap.splice(0, 1);
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.dadosUploadProcedimento, property, value);
  },
};
