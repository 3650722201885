import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getMotivosByAtendimento = ({ atendimentoId, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/atendimento/${atendimentoId}/listagem_formulario_motivo`,
    onSuccess,
    onCustomError,
  });

const salvarFormularioService = ({ atendimentoId, payload, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/formulario_atendimento/salvar_formulario/${atendimentoId}`,
    payload,
    onSuccess,
    onCustomError,
  });

const updateFormularioService = ({ formularioId, payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    payload,
    uri: `${ressusURI}/formulario_atendimento/${formularioId}/atualizar_formulario`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDadosFormularioByIdService = ({ formularioId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/formulario_atendimento/${formularioId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const uploadDocumentoComprobatorioService = ({
  payload,
  formularioId,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/formulario_atendimento/${formularioId}/documento_comprobatorio`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const excluirDocumentoComprobatorioService = ({
  formularioId,
  documentoId,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/formulario_atendimento/${formularioId}/documento_comprobatorio/${documentoId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const iniciarProtocoloService = ({ formularioId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/formulario_atendimento/${formularioId}/iniciar_protocolo`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const cancelarProtocoloService = ({ formularioId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/formulario_atendimento/${formularioId}/cancelar_protocolo`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const gerarFormularioService = ({ atendimentoId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/formulario_atendimento/gerar_formulario/${atendimentoId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const enviarDocumentosService = ({ formularioId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/formulario_atendimento/${formularioId}/documento_comprobatorio/vincular_protocolo_ans`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  salvarFormularioService,
  getMotivosByAtendimento,
  updateFormularioService,
  getDadosFormularioByIdService,
  uploadDocumentoComprobatorioService,
  excluirDocumentoComprobatorioService,
  iniciarProtocoloService,
  cancelarProtocoloService,
  gerarFormularioService,
  enviarDocumentosService,
};
