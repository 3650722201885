import { helpPages } from '@/constants';

const fatherRouteName = 'solicitacao-documentos';

export default [
  {
    path: '/solicitacao-documentos',
    name: fatherRouteName,
    component: () => import(/* webpackChunkName: "solicitacao-documentos" */ './index.vue'),
    meta: {
      title: 'Processos',
      subtitle: 'Solicitação de documentos',
      helpPage: helpPages['solicitacao-documentos'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
    children: [
      {
        path: 'solicitacao/:solicitacaoId/:modalType',
        name: 'solicitacao',
        component: () =>
          import(/* webpackChunkName: "solicitacao" */ './components/modal-solicitacao.vue'),
        meta: {
          title: 'Processos',
          subtitle: 'Solicitação de documentos',
          helpPage: helpPages['solicitacao-documentos'],
          applicationKey: 'ressus',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        props: true,
      },
    ],
  },
];
