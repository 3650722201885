import Vue from 'vue';

class fullscreen {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    this.fullscreen = false;
  }

  handle() {
    const editorContainer = document.getElementById(this.quill.options.id);

    this.fullscreen = !this.fullscreen;

    if (this.quill.options.hideMenu) {
      Vue.router.replace({
        query: { hideMenu: this.fullscreen || undefined },
      });
    }

    if (this.fullscreen) {
      editorContainer?.classList.add('fullscreen');
    } else {
      editorContainer?.classList.remove('fullscreen');
    }
  }
}

export default fullscreen;
