<template>
  <v-menu
    v-model="show"
    v-bind="{ zIndex, maxWidth, nudgeLeft, attach, right, left }"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-mask="'##/####'"
        v-bind="{ label, rules, disabled }"
        persistent-placeholder
        ref="input"
        placeholder="00/0000"
        data-test="text-field-competencia"
        :value="value"
        @click="onToggleDatePicker"
        @input="emitInput"
      >
        <template slot="append">
          <v-icon class="text-primary-blue-base" @click="onToggleDatePicker"> mdi-calendar </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-bind="{ min, max }"
      no-title
      type="month"
      locale="pt-br"
      :value="date"
      @input="onToggleDatePicker"
      @change="emitChange"
    />
  </v-menu>
</template>

<script>
const FIELD_SIZE_COMPETENCIA = 6;

export default {
  name: 'ReInputCompetencia',
  props: {
    disabled: Boolean,
    label: {
      type: String,
      default: 'Competência',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    nudgeLeft: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: Number,
      default: 290,
    },
    zIndex: {
      type: Number,
      default: 0,
    },
    attach: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    min: String,
    max: String,
    left: Boolean,
    right: Boolean,
    callEventEmptyValue: Boolean,
  },
  data() {
    return {
      date: '',
      show: false,
      FIELD_SIZE_COMPETENCIA,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (!newValue) {
          this.date = this.getToday();
          this.$refs.input.$el.querySelector('input').value = '';
        }
      },
    },
  },
  methods: {
    onToggleDatePicker() {
      this.show = !this.show;
    },
    formatDate(value, separatorFrom, separatorTo) {
      return value?.replace(separatorFrom, '-')?.split('-')?.reverse()?.join(separatorTo) || '';
    },
    emitChange($event) {
      this.date = $event;
      this.$emit('input', this.formatDate($event, '-', '/'));
    },
    emitInput($event) {
      this.$emit('input', $event);
      if ($event?.length > FIELD_SIZE_COMPETENCIA) {
        this.date = this.formatDate($event, '/', '-');
      }
    },
    getToday() {
      return new Date()?.toISOString()?.substr(0, 10);
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.v-date-picker-header) {
  button {
    color: theme('colors.primary-blue-base') !important;

    &:active,
    &:focus {
      background-color: transparent !important;
    }
  }
}

:deep(.v-date-picker-table) {
  table {
    tr {
      td {
        button {
          color: theme('colors.primary-blue-base') !important;

          &:active,
          &:focus {
            background-color: theme('colors.primary-blue-base') !important;
          }
        }

        button.accent {
          color: white !important;
          background-color: theme('colors.primary-blue-base') !important;
        }
      }
    }
  }
}

:deep(.v-date-picker-years) {
  color: theme('colors.primary-blue-base') !important;

  .active.primary--text {
    color: theme('colors.primary-blue-base') !important;
  }
}
</style>
