import Vue from 'vue';
import { uniqBy } from 'lodash';
import { formatPrefixedMoney } from '@/utils/currency';
import { formatDateCompetency, formatDate } from '@/utils/date';
import { capitalizeName } from '@/utils/string';

const concatMotivos = (motivos) => {
  if (!motivos.length) return { joinMotivos: '' };

  const countMotivos =
    motivos.length > 1 ? `${motivos.length} Motivos` : `${motivos.length} Motivo`;

  const joinMotivos = motivos
    ?.map(
      (motivo) =>
        `${motivo?.motivoImpugnacao?.codigo}. ${motivo?.motivoImpugnacao?.descricaoAbreviada}`,
    )
    .join('<br/>');

  return { countMotivos, joinMotivos };
};

const tipoFormulario = () => {
  const formulario = {
    IMPUGNACAO: 'formularioImpugnacao',
    RECURSAO: 'formularioRecursao',
  };

  const { tab } = Vue.router.history.current.query;

  return formulario[tab];
};

const dadosProtocolos = ({ atendimentos }) =>
  atendimentos?.map((atendimento) => ({
    id: atendimento?.id,
    status: atendimento[tipoFormulario()]?.foiContestado
      ? 'FINALIZADO'
      : atendimento?.statusProtocolo,
    contagem: atendimento?.contagem,
    tipo: atendimento?.tipo,
    motivos: concatMotivos(atendimento?.listagemMotivoImpugnacao),
    competencia: formatDateCompetency(atendimento?.competencia),
    numero: atendimento?.numero,
    quantidadeDocumentosComprobatorios: atendimento?.quantidadeDocumentosComprobatorios,
    temDocumentosPendentes: atendimento?.temDocumentosPendentes,
    mensagemDocumentosPendentes: atendimento?.mensagemDocumentosPendentes || '',
    temDocumentosNaoAdicionadoAoProtocolo: atendimento?.temDocumentosNaoAdicionadoAoProtocolo,
    beneficiario: {
      codigo: atendimento?.beneficiario?.codigo,
      nomeCompleto: capitalizeName(atendimento?.beneficiario?.nomeCompleto) || `Não informado`,
      codigoCco: atendimento?.beneficiario?.codigoCco,
    },
    valorTotal: formatPrefixedMoney(atendimento?.valorTotal || 0),
    naoImpugnado: atendimento?.naoImpugnado,
    naoRecursado: atendimento?.naoRecursado,
    periodoAtendimento: `
      ${formatDate(atendimento?.dataInicio)} -
      ${formatDate(atendimento?.dataFim)}
    `,
    formulario: {
      ...atendimento[tipoFormulario()],
      dataAtualizacaoAns:
        atendimento[tipoFormulario()]?.situacaoAns === 'CONCLUIDO'
          ? formatDate(atendimento[tipoFormulario()]?.dataAtualizacaoAns)
          : '',
      dataContestacao: formatDate(atendimento[tipoFormulario()]?.dataContestacao),
    },
  }));

const dadosDocumentosComprobatorios = ({ dadosFormulario }) =>
  dadosFormulario.listagemDocumentosComprobatorios?.map((documento) => ({
    documentoId: documento.documentoComprobatorio.id,
    formularioId: documento.formularioAtendimentoId,
    tipo: documento.documentoComprobatorio.tipoDocumento.nome,
    data: formatDate(documento.documentoComprobatorio.dataDocumento),
    assunto: documento.documentoComprobatorio.assunto,
    file: {
      name: documento.documentoComprobatorio.documento.nome,
    },
    documentoAdicionadoAoProtocolo: documento.documentoAdicionadoAoProtocolo,
    url: documento.documentoComprobatorio.documento.url,
  }));

const numeroAbi = ({ atendimentos }) => {
  const numeroAbi = uniqBy(atendimentos, 'abi.numero');

  return numeroAbi[0]?.abi?.numero;
};

const payloadMotivosImpugnacao = ({ motivosImpugnacao }) =>
  motivosImpugnacao.map((motivo) => ({
    ...motivo,
  }));

export default {
  dadosProtocolos,
  dadosDocumentosComprobatorios,
  numeroAbi,
  payloadMotivosImpugnacao,
};
