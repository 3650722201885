import { helpPages } from '@/constants';

export default [
  {
    path: '/empresa',
    name: 'empresa',
    component: () => import(/* webpackChunkName: "empresa-contratante" */ './index.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Empresa',
      helpPage: helpPages.empresa,
      applicationKey: 'ressus',
      action: 'view',
    },

    props: true,
  },
  {
    path: '/empresa/create',
    name: 'create-empresa',
    component: () => import(/* webpackChunkName: "form-empresa" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Nova empresa',
      helpPage: helpPages.empresa,
      applicationKey: 'ressus',
      action: 'create',
    },
    props: true,
  },
  {
    path: '/empresa/edit/:id',
    name: 'edit-empresa',
    component: () => import(/* webpackChunkName: "form-empresa" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Editar empresa',
      helpPage: helpPages.empresa,
      applicationKey: 'ressus',
      action: 'edit',
    },
    props: true,
  },
  {
    path: '/empresa/view/:id',
    name: 'view-empresa',
    component: () => import(/* webpackChunkName: "form-empresa" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Visualização da empresa',
      helpPage: helpPages.empresa,
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
