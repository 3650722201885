import Vue from 'vue';
import initialState from './state';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.dadosCadastroGru, property, value);
  },
  UPDATE_FIELD_VALOR_DEDUCAO(state, { property, value, item }) {
    state.atendimentos = state.atendimentos.map((atendimento) => ({
      ...atendimento,
      [property]: atendimento.id === item.id ? value : atendimento[property],
    }));
  },
};
