<template>
  <div v-bind="{ id }" class="editor-container" :style="cssProps">
    <v-input v-bind="{ value, rules, label }" @focusout="onChange(value)">
      <vue-editor
        v-bind="{ editorOptions, value, placeholder }"
        :class="{ 'pointer-events-none': disabled }"
        @input="onInput"
      />
    </v-input>
  </div>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import fullscreen from './fullscreen';

Quill.register('modules/fullscreen', fullscreen);

const Bold = Quill.import('formats/bold');
Bold.tagName = 'B';
Quill.register(Bold, true);

const Italic = Quill.import('formats/italic');
Italic.tagName = 'I';
Quill.register(Italic, true);

const icons = Quill.import('ui/icons');
icons.fullscreen = '<span class="material-symbols-outlined">fullscreen</span>';

export default {
  name: 'UiEditor',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: '200px',
    },
    placeholder: {
      type: String,
      default: 'Preencher',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: String,
    showOnlyContent: Boolean,
    disabled: Boolean,
    fullSize: Boolean,
    hideMenu: Boolean,
  },
  components: { VueEditor },
  data() {
    return {
      withoutToolbar: {
        modules: {
          toolbar: false,
        },
      },
      defaultOptions: {
        id: this.id,
        hideMenu: this.hideMenu,
        modules: {
          toolbar: {
            container: [
              [{ font: [] }],
              ['clean'],
              ['bold', 'italic', 'underline'],
              [{ color: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['fullscreen'],
            ],
            handlers: {
              fullscreen() {
                this.quill.getModule('fullscreen').handle();
              },
            },
          },
          fullscreen: true,
        },
      },
    };
  },
  computed: {
    editorOptions() {
      return this.showOnlyContent ? this.withoutToolbar : this.defaultOptions;
    },
    cssProps() {
      return {
        '--min-height': this.height,
        '--top': this.fullSize ? 0 : '108px',
        '--left': this.fullSize ? 0 : '12px',
        '--right': this.fullSize ? 0 : '12px',
        '--calc-height': this.fullSize ? '0px' : '190px',
      };
    },
  },
  methods: {
    onChange(value) {
      this.$emit('onChange', value);
    },
    onInput(text) {
      this.$emit('input', text);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.material-symbols-outlined) {
  font-size: 20px;
  padding: 5px 0;
}

:deep(label) {
  display: none !important;
}

:deep(.quillWrapper) {
  width: 100%;
}

.fullscreen {
  position: fixed;
  top: var(--top);
  left: var(--left);
  right: var(--right);

  height: calc(100% - var(--calc-height));
  padding: 20px;
  background-color: white;
  z-index: 3;

  :deep(.ql-container) {
    height: 100%;
  }

  :deep(.quillWrapper) {
    height: 100%;
  }

  :deep(.v-input),
  :deep(.v-input__control),
  :deep(.v-input__slot) {
    height: calc(100% - 24px);
    margin: 0;
    flex-direction: column;
    align-items: start;
  }

  :deep(label) {
    display: block !important;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  :deep(.v-messages) {
    position: absolute;
    left: 30px;
    bottom: 30px;
  }
}

:deep(.quillWrapper .ql-toolbar.ql-snow .ql-formats) {
  vertical-align: middle;
}

:deep(.ql-fullscreen) {
  padding: 0 !important;
}

:deep(.ql-editor) {
  min-height: var(--min-height);
  background: white !important;
}

:deep(.ql-editor.ql-blank::before) {
  font-style: normal !important;
  color: theme('colors.primary-blue-medium') !important;
}

:deep(.ql-toolbar),
:deep(.ql-container) {
  @apply border-[1px]
  border-primary-blue-base
  text-primary-blue-base #{!important};
}

.error--text {
  :deep(.ql-toolbar),
  :deep(.ql-container) {
    @apply border-[1px] border-red-accent-2 duration-150 #{!important};
  }
}

:deep(.quillWrapper .ql-snow .ql-stroke),
:deep(.ql-fill),
:deep(.ql-picker-label),
:deep(.ql-fullscreen) {
  color: theme('colors.primary-blue-base') !important;
  stroke: theme('colors.primary-blue-base') !important;
}
</style>
