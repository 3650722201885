import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import initialState from './state';
import indicadoresRessarcimento from '../indicadores-ressarcimento/store';
import lancamentosContabeis from '../lancamentos-contabeis/store';
import declaracaoBeneficiarios from '../declaracao-beneficiarios/store';
import atendimentos from '../atendimentos/store';

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
  modules: {
    indicadoresRessarcimento,
    lancamentosContabeis,
    declaracaoBeneficiarios,
    atendimentos,
  },
};
