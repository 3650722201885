import Vue from 'vue';
import initialState from './state';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  RESET_STATE_FILTERS(state) {
    Object.assign(state.filters, state.initialFiltersState);
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.filters, property, value);
  },
};
