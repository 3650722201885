const formularioAtendimento = {
  id: null,
  tipoFormulario: null,
  listagemFormularioMotivos: [
    {
      motivoImpugnacaoId: null,
      codigo: null,
      descricaoAbreviada: null,
      detalhamento: null,
      memoriaCalculo: null,
    },
  ],
  tempestividade: null,
  documentosComprobatorios: null,
  pedidoAlegacao: 'ANULACAO',
  valorPedidoAlegacao: null,
  codigoProtocoloAns: null,
  situacaoAns: null,
  dataAtualizacaoAns: null,
};

const tiposFormulario = {
  IMPUGNACAO: {
    label: 'impugnação',
    object: 'formularioImpugnacao',
  },
  RECURSAO: {
    label: 'recurso',
    object: 'formularioRecursao',
  },
};

export { formularioAtendimento, tiposFormulario };
