<template>
  <v-menu
    v-model="show"
    v-bind="{ zIndex, maxWidth, nudgeLeft, attach, right, left }"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-mask="'##/##/####'"
        v-bind="{ label, rules, placeholder, readonly, disabled, maxlength }"
        v-model="formatedDate"
        persistent-placeholder
        ref="input"
        :data-test="dataTest"
        :class="{ 'pointer-events-none': readonly }"
        @click="onToggleDatePicker"
        @keydown.tab="show = false"
        @input="(value) => updateField(value)"
        @focus="$emit('focus', $event)"
      >
        <template slot="append">
          <v-icon v-if="isEditMode" class="text-primary-blue-base"> mdi-calendar </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-bind="{ min, max }"
      no-title
      locale="pt-br"
      :value="date"
      @input="onToggleDatePicker"
      @change="$emit('change', $event)"
    />
  </v-menu>
</template>

<script>
import { formatDate, formatDateRestore } from '@/utils/date';

const DEFAULT_BRAZILIAN_FORMAT_LENGTH = 10;

export default {
  name: 'ReDatePicker',
  props: {
    label: {
      type: String,
      required: true,
    },
    dataTest: {
      type: String,
      required: true,
    },
    nudgeLeft: {
      type: Number,
      default: 0,
    },
    zIndex: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: Number,
      default: 290,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    maxlength: {
      type: String,
      default: '10',
    },
    attach: {
      type: Boolean,
      default: true,
    },
    min: String,
    max: String,
    left: Boolean,
    right: Boolean,
    isEditMode: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    callEventEmptyValue: Boolean,
  },
  data() {
    return {
      show: false,
      formatedDate: '',
    };
  },
  watch: {
    date: {
      immediate: true,
      handler(value) {
        this.formatedDate = formatDate(this.date);

        if (!value) {
          if (this.$refs.input) {
            this.$refs.input.$el.querySelector('input').value = '';
          }
        }
      },
    },
  },
  methods: {
    onToggleDatePicker() {
      this.show = !this.show;
    },
    updateField(value) {
      this.show = false;

      if (
        (this.callEventEmptyValue && !value) ||
        value?.length === DEFAULT_BRAZILIAN_FORMAT_LENGTH
      ) {
        this.$emit('input', formatDateRestore(value));
        this.$emit('change', formatDateRestore(value));
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.v-date-picker-header) {
  button {
    color: theme('colors.primary-blue-base') !important;

    &:active,
    &:focus {
      background-color: transparent !important;
    }
  }
}

:deep(.v-date-picker-table) {
  table {
    th {
      color: theme('colors.primary-blue-disabled') !important;
    }
    tr {
      td {
        button {
          color: theme('colors.primary-blue-base') !important;

          &:active,
          &:focus {
            background-color: theme('colors.primary-blue-base') !important;
          }
        }

        button.v-date-picker-table__current {
          background-color: transparent !important;
        }

        button.v-btn--active {
          color: theme('colors.white') !important;
          background-color: theme('colors.primary-blue-base') !important;
        }
      }
    }
  }
}

:deep(.v-date-picker-years) {
  color: theme('colors.primary-blue-base') !important;

  .active {
    color: theme('colors.primary-blue-base') !important;
  }
}
</style>
