import { pagination } from '@/constants';

const listarEventoContabil = ({
  page = pagination.page,
  size = pagination.size,
  sort = 'id',
  pesquisar = '',
}) => `{
	listarEventosContabeis(
		page: ${page - 1}
		size: ${size}
    sort: ["${sort}"]
		filter: {
      pesquisar: "${pesquisar}"
		}
	) {
		content {
			id
			processarEm
			siglaEvento
			siglaEventoEstorno
			dedutivel
			estornaEventoAnterior
			formulaValor
      formulaValorFormatado
		}
		number
		totalPages
		totalElements
		size
		first
		last
	}
}`;

export default listarEventoContabil;
