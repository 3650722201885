import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

const preset = require('./preset');

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: preset.theme.extend.colors['secondary-lemon-medium'],
        'primary-blue-base': preset.theme.extend.colors['primary-blue-base'],
        'primary-blue-medium': preset.theme.extend.colors['primary-blue-medium'],
      },
    },
  },
});
