import { helpPages } from '@/constants';

export default [
  {
    path: '/oficios-notificacao',
    name: 'oficios-notificacao',
    component: () => import(/* webpackChunkName: "oficios-notificacao" */ './index.vue'),
    meta: {
      title: 'Ofícios notificados',
      helpPage: helpPages['oficios-notificacao'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
  {
    path: '/oficios-notificacao/cobranca/:id',
    name: 'cobranca',
    component: () => import(/* webpackChunkName: "cobranca" */ './cobranca/index.vue'),
    meta: {
      title: 'Ofícios notificados',
      helpPage: helpPages['oficios-notificacao'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
