import { helpPages } from '@/constants';

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard-view" */ './dashboard.vue'),
    meta: {
      title: 'Painel do Ressarcimento ao SUS',
      helpPage: helpPages.home,
      applicationKey: 'ressus',
      action: 'view',
      ignorePermission: true,
    },
    props: true,
  },
];
