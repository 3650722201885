import Vue from 'vue';
import omitByAndNil from '@/utils/compare-values';
import {
  getAlegacoesAns,
  getAlegacaoAnsById,
  createAlegacaoAnsService,
  updateAlegacaoAnsService,
  deleteAlegacaoAnsService,
} from '../services';
import redirectRouteUpdate from '../../utils';

const loadAlegacoesAns = async ({ commit }, query) => {
  commit('RESET_STATE');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const { payload } = await getAlegacoesAns(query);

  commit('GENERIC_MUTATION', {
    property: 'alegacoesAns',
    payload: payload?.listarAlegacaoAns?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarAlegacaoAns?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarAlegacaoAns?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadAlegacaoAnsById = async ({ commit, dispatch }, id) => {
  dispatch('setLoadingForm', true);

  await getAlegacaoAnsById({
    id,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'alegacaoAns',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: omitByAndNil(data),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const createAlegacaoAns = ({ getters, commit, dispatch }, { saveAndNew, resetValidation }) => {
  dispatch('setLoadingForm', true);

  const { payloadAlegacaoAns } = getters;

  createAlegacaoAnsService({
    payload: payloadAlegacaoAns,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      !saveAndNew && Vue.router.push({ name: 'alegacao-ans' });
      Vue.$toast('Alegação ANS incluída com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateAlegacaoAns = async (
  { getters, commit, dispatch },
  { saveAndNew, resetValidation, route },
) => {
  dispatch('setLoadingForm', true);

  const { payloadAlegacaoAns } = getters;

  updateAlegacaoAnsService({
    payload: payloadAlegacaoAns,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      redirectRouteUpdate(saveAndNew, 'create-alegacao-ans', route);
      Vue.$toast('Alegação ANS alterada com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteAlegacaoAns = async ({ state, commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await deleteAlegacaoAnsService({
    id,
    onSuccess: () => {
      Vue.$toast('Alegação ANS excluída com sucesso.', { type: 'success' });

      if (state.alegacoesAns.length === 1) {
        Vue.router.replace({
          name: 'alegacao-ans',
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }

      dispatch('loadAlegacoesAns', query);
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoadingForm = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: value,
  });

export default {
  loadAlegacoesAns,
  loadAlegacaoAnsById,
  createAlegacaoAns,
  updateAlegacaoAns,
  deleteAlegacaoAns,
  updateField,
  resetState,
  setLoadingForm,
};
