import { isEqual } from 'lodash';
import { competencyFullDate } from '@/utils/date';

const payloadDadosRelatorio = ({ dadosRelatorio }) => ({
  idsAbi: dadosRelatorio?.idsAbi?.map((abi) => +abi.id),
  dataPeriodoInicial: dadosRelatorio.dataPeriodoInicial
    ? competencyFullDate(dadosRelatorio.dataPeriodoInicial)
    : '',
  dataPeriodoFinal: dadosRelatorio.dataPeriodoFinal
    ? competencyFullDate(dadosRelatorio.dataPeriodoFinal)
    : '',
});

const isInitialState = (state) => isEqual(state.initialState, state.dadosRelatorio);

export default { payloadDadosRelatorio, isInitialState };
