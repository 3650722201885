import { isEqual } from 'lodash';
import { enumBoolean, enumProcessamento } from '../constants';

const payloadEventoContabil = ({ eventoContabil }) => ({
  ...eventoContabil,
});

const eventosContabeisTable = ({ eventosContabeis }) =>
  eventosContabeis?.map((eventoContabil) => ({
    ...eventoContabil,
    dedutivel: enumBoolean[eventoContabil?.dedutivel],
    estornaEventoAnterior: enumBoolean[eventoContabil?.estornaEventoAnterior],
    processarEm: enumProcessamento[eventoContabil?.processarEm],
  }));

const isInitialState = (state, getters) =>
  isEqual(state.initialState, getters.payloadEventoContabil);

export default {
  payloadEventoContabil,
  eventosContabeisTable,
  isInitialState,
};
