const toggleDrawerFiltros = async ({ state, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'showDrawerFiltros',
    payload: !state.showDrawerFiltros,
  });
};

const setFilterData = ({ commit }, { initialFiltersState, defaultFilters, itemsFilter }) => {
  commit('GENERIC_MUTATION', {
    property: 'initialFiltersState',
    payload: initialFiltersState,
  });

  commit('GENERIC_MUTATION', {
    property: 'filters',
    payload: defaultFilters,
  });

  commit('GENERIC_MUTATION', {
    property: 'itemsFilter',
    payload: itemsFilter,
  });
};

const resetFilters = ({ commit }) => commit('RESET_STATE_FILTERS');

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

export default {
  toggleDrawerFiltros,
  setFilterData,
  resetFilters,
  updateField,
  setLoading,
};
