import { formUploadDocumento } from '@/views/processos/constants';

export default () => ({
  loading: false,
  initialStateDadosFormulario: {},
  dadosFormulario: {},
  dadosMotivos: [],
  initialStateDadosMotivos: [],
  dadosUploadDocumento: { ...formUploadDocumento },
  loadingUploadDocumento: false,
  showModalUploadDocumento: false,
  isSomenteVisualizacao: false,
});
