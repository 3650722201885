export default () => ({
  loadingTable: false,
  oficiosNotificacao: [],
  oficioNotificado: {},
  totalElements: 0,
  totalPages: 0,
  showModalImportacaoXml: false,
  loadingImportacaoXml: false,
  instancias: [],
  queryParams: {},
  filters: {},
  initialState: {},
  clickNotificacao: false,
});
