import { formatDateRestore, competencyFullDate } from '@/utils/date';
import { formatMoneyRestore } from '@/utils/currency';
import { numeroOficioRule } from '@/utils/validation-rules';

const tabsDrawer = [
  {
    id: 0,
    title: 'Filtros avançados',
    icon: 'mdi-filter-cog-outline',
    value: 'FILTRO',
    component: 'TabFiltros',
  },
  {
    id: 1,
    title: 'Colunas',
    icon: 'mdi-table-column',
    value: 'COLUNA',
    component: 'TabColunas',
  },
];

const actions = (value) => [
  { id: 1, descricao: 'Criar', metodo: 'onClickAdd', disabled: false },
  { id: 2, descricao: 'Editar', metodo: 'onClickEdit', disabled: value },
  { id: 3, descricao: 'Excluir', metodo: 'onClickDelete', disabled: value },
];

const hiddenColumns = {
  SELECIONADAS: ['statusAtendimentoOrder'],
  GERAL: ['statusAtendimentoOrder'],
};

const ID_VISUALIZACAO_PADRAO = '1';

const ID_ACAO_CRIAR = 1;
const ID_ACAO_EDITAR = 2;
const ID_ACAO_EXCLUIR = 3;

const itemsCheckbox = [
  'motivoImpugnacao',
  'statusAtendimento',
  'tipo',
  'formacaoPrecoProduto',
  'codigoCaraterAtendimento',
  'cpf',
  'tipoProduto',
  'tipoContratacaoProduto',
  'descricaoSegmentacaoProduto',
  'codigoSegmentacaoProduto',
  'descricaoCoparticipacao',
  'critica',
  'alegacaoAns',
  'numeroInstanciaDecisao',
  'acaoJudicial',
  'situacaoJudicial',
  'numeroInstanciaGru',
  'descricaoInstanciaGru',
  'descricaoInstanciaDecisao',
];

const itemsData = [
  'dataInicio',
  'dataFim',
  'dataNascimento',
  'dataAdmissao',
  'dataAdesao',
  'dataExclusao',
  'dataReinclusao',
  'dataRecebimentoDecisao',
  'dataVencimentoGru',
  'dataIncorporacaoCusto',
  'dataRecebimentoGru',
  'dataVigencia',
  'cptDataFinal',
  'dataFinalCarencia',
  'dataAdmissao',
  'vigencia',
  'dataAdesao',
  'dataFinalCoberturaParcialTemporaria',
];

const itemsValor = [
  'valorTotalAtendimento',
  'valorProcedimento',
  'valorDeducao',
  'valorRetificado',
  'valorRessarcimento',
  'valorDeducaoDeferida',
];

const itemsInput = [
  {
    nome: 'contagem',
    rules: {
      maxLength: 18,
      type: 'números',
    },
  },
  {
    nome: 'numero',
    rules: {
      maxLength: 13,
      type: 'números',
    },
  },
  {
    nome: 'codigoBeneficiario',
    rules: {
      maxLength: 20,
      type: 'números',
    },
  },
  {
    nome: 'nomeCompleto',
    rules: {
      maxLength: 100,
      type: 'texto',
    },
  },
  {
    nome: 'descricaoAbreviadaCaraterAtendimento',
    rules: {
      maxLength: 100,
      type: 'texto',
    },
  },
  {
    nome: 'codigoProcedimento',
    rules: {
      maxLength: 15,
      type: 'números',
    },
  },
  {
    nome: 'quantidadeProcedimento',
    rules: {
      maxLength: 10,
      type: 'números',
    },
  },
  {
    nome: 'descricaoProcedimento',
    rules: {
      maxLength: 100,
      type: 'texto',
    },
  },
  {
    nome: 'cnes',
    rules: {
      maxLength: 20,
      type: 'números',
    },
  },
  {
    nome: 'nomeUps',
    rules: {
      maxLength: 100,
      type: 'texto',
    },
  },
  {
    nome: 'ruaPrestador',
    rules: {
      maxLength: 200,
      type: 'texto',
    },
  },
  {
    nome: 'municipioPrestador',
    rules: {
      maxLength: 200,
      type: 'texto',
    },
  },
  {
    nome: 'codigoUfPrestador',
    rules: {
      maxLength: 30,
      type: 'texto',
    },
  },
  {
    nome: 'cepPrestador',
    rules: {
      maxLength: 8,
      type: 'números',
    },
    mask: '########',
  },
  {
    nome: 'codigoIbgePrestador',
    rules: {
      maxLength: 10,
      type: 'números',
    },
    mask: '##########',
  },
  {
    nome: 'codigoCco',
    rules: {
      maxLength: 12,
      type: 'números',
    },
    mask: '##########',
  },
  {
    nome: 'unimed',
    rules: {
      maxLength: 12,
      type: 'números',
    },
    mask: '###########',
  },
  {
    nome: 'contrato',
    rules: {
      maxLength: 15,
    },
  },
  {
    nome: 'contratoFisico',
    rules: {
      maxLength: 20,
      type: 'texto',
    },
  },
  {
    nome: 'codigoEmpresaContratante',
    rules: {
      maxLength: 10,
    },
  },
  {
    nome: 'nomeEmpresaContratante',
    rules: {
      maxLength: 100,
      type: 'texto',
    },
  },
  {
    nome: 'cnpjEmpresaContratante',
    rules: {
      maxLength: 14,
      type: 'números',
    },
    mask: '##############',
  },
  {
    nome: 'registroProdutoAns',
    rules: {
      maxLength: 15,
    },
  },
  {
    nome: 'opiniaoAns',
    rules: {
      maxLength: 400,
      type: 'texto',
    },
  },
  {
    nome: 'valorDeducaoDeferida',
    rules: {
      maxLength: 18,
      type: 'números',
    },
  },
  {
    nome: 'observacoes',
    rules: {
      maxLength: 250,
      type: 'texto',
    },
  },
  {
    nome: 'gru',
    rules: {
      maxLength: 50,
      type: 'números',
    },
  },
  {
    nome: 'numeroProcessoJudicial',
    rules: {
      maxLength: 50,
      type: 'números',
    },
  },
  {
    nome: 'numeroOficioGru',
    rules: {
      maxLength: 15,
      customRule: numeroOficioRule,
    },
  },
  {
    nome: 'numeroOficioDecisao',
    rules: {
      maxLength: 15,
      customRule: numeroOficioRule,
    },
  },
  {
    nome: 'numeroFatura',
    rules: {
      maxLength: 50,
      type: 'números',
    },
  },
  {
    nome: 'nomeProdutoAns',
    rules: {
      maxLength: 50,
      type: 'texto',
    },
  },
];

const applyFormat = (value, nome) => {
  if (itemsData.includes(nome)) {
    return formatDateRestore(value);
  }
  if (itemsValor.includes(nome)) {
    return formatMoneyRestore(value);
  }
  if (nome === 'competencia' && value) {
    return competencyFullDate(value);
  }
  return value || null;
};

const generateFilteredValues = (filtros) =>
  filtros?.reduce((acc, item) => {
    const { nome, valores } = item;
    acc[nome] = valores?.map((value) => applyFormat(value, nome));
    return acc;
  }, {});

export {
  tabsDrawer,
  actions,
  hiddenColumns,
  ID_VISUALIZACAO_PADRAO,
  ID_ACAO_CRIAR,
  ID_ACAO_EDITAR,
  ID_ACAO_EXCLUIR,
  itemsCheckbox,
  itemsData,
  itemsValor,
  itemsInput,
  applyFormat,
  generateFilteredValues,
};
