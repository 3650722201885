import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getParametros = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/critica_validacao/paged`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateParametrosService = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/critica_validacao`,
    onSuccess,
    onCustomError,
  });

export { getParametros, updateParametrosService };
