import cadastros from '@/views/cadastros/routes';
import oficiosNotificacao from '@/views/oficios-notificacao/routes';
import processos from '@/views/processos/routes';
import parametros from '@/views/parametros/routes';
import relatorios from '@/views/relatorios/routes';
import painel from '@/views/painel/routes';

export default [
  ...cadastros,
  ...oficiosNotificacao,
  ...processos,
  ...parametros,
  ...relatorios,
  ...painel,
];
