import { isEqual } from 'lodash';
import { formatMoney, formatMoneyRestore } from '@/utils/currency';
import { formatDate, formatDateTime } from '@/utils/date';

const dadosFormularioAtendimento = ({ dadosFormulario }) => ({
  id: dadosFormulario?.id,
  tipoFormulario: dadosFormulario?.tipoFormulario,
  listagemFormularioMotivos: dadosFormulario?.listagemFormularioMotivos?.map((motivo) => ({
    motivoImpugnacaoId: motivo?.motivoImpugnacao?.id,
    codigo: motivo?.motivoImpugnacao?.codigo,
    descricaoAbreviada: motivo?.motivoImpugnacao?.descricaoAbreviada,
    detalhamento: motivo?.detalhamento,
    memoriaCalculo: motivo?.memoriaCalculo,
  })),
  tempestividade: dadosFormulario?.tempestividade,
  descricaoDocumentosComprobatorios: dadosFormulario?.descricaoDocumentosComprobatorios,
  pedidoAlegacao: dadosFormulario?.pedidoAlegacao,
  valorPedidoAlegacao: formatMoney(dadosFormulario?.valorPedidoAlegacao) || null,
  codigoProtocoloAns: dadosFormulario?.codigoProtocoloAns,
  situacaoAns: dadosFormulario?.situacaoAns,
  dataAtualizacaoAns: formatDateTime(dadosFormulario?.dataAtualizacaoAns),
});

const dadosMotivosAtendimento = ({ dadosMotivos }) => dadosMotivos;

const payloadUpdateFormulario = ({ dadosMotivos }, { dadosFormularioAtendimento }) => ({
  tipoFormulario: dadosFormularioAtendimento.tipoFormulario,
  listagemFormularioMotivos: dadosMotivos?.map((motivo) => ({
    motivoImpugnacaoId: +motivo.motivoImpugnacaoId,
    detalhamento: motivo.detalhamento,
    memoriaCalculo: motivo.memoriaCalculo,
  })),
  tempestividade: dadosFormularioAtendimento.tempestividade,
  descricaoDocumentosComprobatorios: dadosFormularioAtendimento.descricaoDocumentosComprobatorios,
  pedidoAlegacao: dadosFormularioAtendimento.pedidoAlegacao,
  valorPedidoAlegacao: formatMoneyRestore(dadosFormularioAtendimento.valorPedidoAlegacao || '0'),
});

const dadosDocumentosComprobatorios = ({ dadosFormulario }) =>
  dadosFormulario.listagemDocumentosComprobatorios?.map((documento) => ({
    documentoId: documento.documentoComprobatorio.id,
    formularioId: documento.formularioAtendimentoId,
    tipo: documento.documentoComprobatorio.tipoDocumento.nome,
    data: formatDate(documento.documentoComprobatorio.dataDocumento),
    assunto: documento.documentoComprobatorio.assunto,
    file: {
      name: documento.documentoComprobatorio.documento.nome,
    },
    documentoAdicionadoAoProtocolo: documento.documentoAdicionadoAoProtocolo,
    url: documento.documentoComprobatorio.documento.url,
    erroAns: documento.erroAns || 'Não recebido pela ANS, enviar documentos novamente.',
  }));

const isInitialStateFormulario = ({ initialStateDadosFormulario, dadosFormulario }) => {
  if (!Object.keys(dadosFormulario).length) return true;

  return isEqual(initialStateDadosFormulario, dadosFormulario);
};

const isInitialStateMotivos = ({ initialStateDadosMotivos, dadosMotivos }) =>
  isEqual(initialStateDadosMotivos, dadosMotivos);

export default {
  dadosFormularioAtendimento,
  dadosMotivosAtendimento,
  payloadUpdateFormulario,
  dadosDocumentosComprobatorios,
  isInitialStateFormulario,
  isInitialStateMotivos,
};
