import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getAtendimentoById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/atendimento/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDadosAtendimento = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/atendimento/drawer/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getAtendimentoDecidido = ({ atendimento, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/atendimento_decidido/${atendimento.numero}/${atendimento.competencia}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDadosCobranca = ({ atendimento, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/cobranca/atendimento/${atendimento.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateDadosDecisaoService = ({
  atendimentoId,
  payload,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/atendimento_decidido/${atendimentoId}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateAtendimentoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/atendimento/${payload.id}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateValorDeducaoService = ({ id, payload, onSuccess, onCustomError, onFinally }) => {
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/atendimento/valor_deducao/${id}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

export {
  getAtendimentoById,
  getDadosAtendimento,
  getAtendimentoDecidido,
  getDadosCobranca,
  updateAtendimentoService,
  updateDadosDecisaoService,
  updateValorDeducaoService,
};
