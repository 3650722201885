import { createGraphQLService, createCommandService } from '@/services';
import { ressusURI, assinaturaDigitalURI } from '@/api';
import listarAtendimentos from './queries/listar-atendimentos';
import listarAtendimentosAssinaveis from './queries/listar-atendimentos-assinaveis';

export const getAtendimentos = async (queryParameters) =>
  createGraphQLService(listarAtendimentos(queryParameters));

export const getAtendimentosAssinaveis = async (queryParameters) =>
  createGraphQLService(listarAtendimentosAssinaveis(queryParameters));

export const atendimentoNaoImpugnarService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/atendimento/nao_impugnar`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

export const atendimentoNaoRecursarService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/atendimento/nao_recursar`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

export const gerarLaudoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/documento/laudo_auditor`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export const updateDefinirMotivosService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/atendimento/definir_motivo_impugnacao`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

export const startAssinaturaDigital = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'post',
    uri: `${assinaturaDigitalURI}/signature/start`,
    payload,
    onSuccess,
    onCustomError,
  });

export const completeAssinaturaDigital = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'post',
    uri: `${assinaturaDigitalURI}/signature/complete`,
    payload,
    onSuccess,
    onCustomError,
  });

export const exportarRelatorioAtendimentosService = ({
  path,
  id,
  payload,
  onSuccess,
  onCustomError,
}) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/documento/${path}`,
    payload: { id, ...payload },
    onSuccess,
    onCustomError,
  });

export const exportarRelatorioDocumentosService = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/documento/${payload.path}/${payload.idAbi}`,
    payload: payload.atendimentoIds.length ? { atendimentoIds: payload.atendimentoIds } : null,
    onSuccess,
    onCustomError,
  });

export const validateMotivoNecessitaValorDeducao = ({ atendimentoId, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/atendimento/${atendimentoId}/motivo_necessita_valor_deducao`,
    onSuccess,
    onCustomError,
  });
