const headers = [
  { text: '', class: 'p-0', cellClass: 'pa-0', width: 0, sortable: false },
  { text: 'TIPO', value: 'tipoAtendimento' },
  { text: 'NÚMERO', value: 'numeroAtendimento' },
  { text: 'COMPETÊNCIA', value: 'competencia' },
  { text: 'BENEFICIÁRIO', value: 'beneficiario', sortable: false },
  { text: 'PROVISÃO', value: 'percentualProvisionamento' },
  { text: 'SIGLA', value: 'sigla' },
  { text: 'EVENTO', value: 'eventoContabil.processarEm' },
  { text: 'DATA LANÇAMENTO', value: 'dataLancamento' },
  { text: 'DEDUTÍVEL', value: 'dedutivel' },
  { text: 'ESTORNO', value: 'estorno' },
  { text: 'VALOR LANÇAMENTO', value: 'valor' },
];

const enumProcessamento = {
  RECEBIMENTO_ABI: 'Recebimento ABI',
  CANCELAMENTO_ABI: 'Cancelamento ABI',
  CANCELAMENTO_ATENDIMENTO: 'Cancelamento atendimento',
  AGUARDANDO_COBRANCA: 'Aguardando cobrança',
  PROCESSO_CONCLUIDO: 'Processo concluído',
  RECEBIMENTO_GRU: 'Recebimento GRU',
  ALEGACAO_ANS: 'Alegação ANS',
  DEFERIDO_PARCIAL: 'Deferido parcial',
  DEFERIDO_TOTAL: 'Deferido Total',
  INDEFERIDO: 'Indeferido',
};

const enumBoolean = {
  true: 'Sim',
  false: 'Não',
};

const defaultFilters = {
  idAbi: null,
  tipoAtendimento: null,
  numeroAtendimento: null,
  competencia: null,
  nomeBeneficiario: null,
  percentualProvisionamento: null,
  sigla: null,
  dataLancamentoInicial: null,
  dataLancamentoFinal: null,
  dedutivel: null,
  estorno: null,
  valorLancamentoInicial: null,
  valorLancamentoFinal: null,
  processarEm: null,
};

const itemsFilter = [
  {
    id: 1,
    title: 'Tipo',
    component: 'CheckboxItems',
    props: {
      label: 'Tipo',
      property: 'tipoAtendimento',
      items: [
        { key: 1, label: 'APAC', value: 'APAC' },
        { key: 2, label: 'AIH', value: 'AIH' },
      ],
    },
  },
  {
    id: 2,
    title: 'Número',
    component: 'InputData',
    props: {
      label: 'Número',
      property: 'numeroAtendimento',
      type: 'number',
      maxLength: 13,
    },
  },
  {
    id: 3,
    title: 'Competência',
    component: 'InputData',
    props: {
      label: 'Competência',
      property: 'competencia',
      type: 'text',
      maxLength: 7,
    },
  },
  {
    id: 4,
    title: 'Beneficiário',
    component: 'InputData',
    props: {
      label: 'Beneficiário',
      property: 'nomeBeneficiario',
      type: 'text',
      maxLength: 30,
    },
  },
  {
    id: 5,
    title: 'Provisão',
    component: 'InputData',
    props: {
      label: 'Provisão',
      property: 'percentualProvisionamento',
      type: 'text',
      maxLength: 7,
    },
  },
  {
    id: 6,
    title: 'Sigla',
    component: 'InputData',
    props: {
      label: 'Sigla',
      property: 'sigla',
      type: 'text',
    },
  },
  {
    id: 7,
    title: 'Data de lançamento',
    component: 'DateRange',
    props: {
      propertyInicial: 'dataLancamentoInicial',
      propertyFinal: 'dataLancamentoFinal',
    },
  },
  {
    id: 8,
    title: 'Dedutível',
    component: 'CheckboxItems',
    props: {
      label: 'Dedutível',
      property: 'dedutivel',
      items: [
        { key: 1, label: 'Sim', value: true },
        { key: 2, label: 'Não', value: false },
      ],
    },
  },
  {
    id: 9,
    title: 'Estorno',
    component: 'CheckboxItems',
    props: {
      label: 'Estorno',
      property: 'estorno',
      items: [
        { key: 1, label: 'Sim', value: true },
        { key: 2, label: 'Não', value: false },
      ],
    },
  },
  {
    id: 10,
    title: 'Valor de lançamento',
    component: 'DecimalRange',
    props: {
      propertyInicial: 'valorLancamentoInicial',
      propertyFinal: 'valorLancamentoFinal',
    },
  },
  {
    id: 11,
    title: 'Evento',
    component: 'CheckboxItems',
    props: {
      label: 'Evento',
      property: 'processarEm',
      items: [
        { key: 1, label: 'Recebimento ABI', value: 'RECEBIMENTO_ABI' },
        { key: 2, label: 'Cancelamento atendimento', value: 'CANCELAMENTO_ATENDIMENTO' },
        { key: 3, label: 'Aguardando cobrança', value: 'AGUARDANDO_COBRANCA' },
        { key: 4, label: 'Processo concluído', value: 'PROCESSO_CONCLUIDO' },
        { key: 5, label: 'Recebimento GRU', value: 'RECEBIMENTO_GRU' },
        { key: 6, label: 'Alegação ANS', value: 'ALEGACAO_ANS' },
        { key: 7, label: 'Deferido parcial', value: 'DEFERIDO_PARCIAL' },
        { key: 8, label: 'Deferido Total', value: 'DEFERIDO_TOTAL' },
        { key: 9, label: 'Indeferido', value: 'INDEFERIDO' },
        { key: 10, label: 'Cancelamento ABI', value: 'CANCELAMENTO_ABI' },
      ],
    },
  },
];

const isAllCheckedItems = (filterValues, propertyLabel) => {
  const itemFilter = itemsFilter.find(({ props }) => props?.property === propertyLabel);

  return itemFilter?.props?.items?.length === filterValues?.length;
};

export { headers, enumProcessamento, enumBoolean, defaultFilters, itemsFilter, isAllCheckedItems };
