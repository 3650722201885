import { formatPrefixedMoney } from '@/utils/currency';
import { formatDateCompetency, formatDate } from '@/utils/date';
import { enumBoolean, enumProcessamento } from '../constants';

const lancamentosContabeisTable = ({ lancamentosContabeis }) =>
  lancamentosContabeis?.map((lancamentoContabil) => ({
    ...lancamentoContabil,
    dataLancamento: formatDate(lancamentoContabil?.dataLancamento),
    competencia: formatDateCompetency(lancamentoContabil?.competencia),
    valor: formatPrefixedMoney(lancamentoContabil?.valor || 0),
    eventoContabil: {
      processarEm: enumProcessamento[lancamentoContabil?.eventoContabil?.processarEm],
    },
    dedutivel: enumBoolean[lancamentoContabil?.dedutivel],
    estorno: enumBoolean[lancamentoContabil?.estorno],
    percentualProvisionamento: lancamentoContabil?.percentualProvisionamento
      ? `${lancamentoContabil?.percentualProvisionamento?.toFixed(2)}%`
      : '0.00%',
    beneficiario: {
      codigoBeneficiario: lancamentoContabil?.codigoBeneficiario,
      nomeBeneficiario: lancamentoContabil?.nomeBeneficiario,
    },
  }));

export default { lancamentosContabeisTable };
