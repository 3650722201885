<template>
  <ui-modal v-bind="{ title, width, show }" @close="onCloseModal">
    <re-loader :loading="loadingModalImportarCSV" />
    <v-form v-model="valid" lazy-validation ref="form" @submit.prevent="onSubmit">
      <div class="px-6 py-10">
        <ui-upload
          v-if="!dadosUploadProcedimento.anexo"
          accept=".csv"
          :error-messages="dadosUploadProcedimento.errorMessages"
          @drop-files="(value) => updateField({ property: 'anexo', value })"
          @error-format="(value) => updateField({ property: 'errorMessages', value: [value] })"
        />
        <re-input-file
          v-else
          v-model="dadosUploadProcedimento.anexo"
          disabled
          label="Arquivo anexado"
          @delete="() => updateField({ property: 'anexo', value: null })"
        />
      </div>
      <v-divider class="bg-neutral-gray-medium" />
      <div class="px-6 py-3 flex justify-end gap-6">
        <v-btn outlined data-test="btn-cancelar" class="btn btn__outlined" @click="onCloseModal">
          Cancelar
        </v-btn>
        <v-btn type="submit" data-test="btn-adicionar" class="btn btn__primary"> Adicionar </v-btn>
      </div>
    </v-form>
  </ui-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ReLoader, ReInputFile, UiUpload, UiModal } from '@/components';

export default {
  name: 'ModalImportarCSV',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: { ReLoader, ReInputFile, UiUpload, UiModal },
  data() {
    return {
      title: 'Importar arquivo CSV',
      width: '704px',
      valid: true,
    };
  },
  computed: {
    ...mapState('ressus/parametros/procedimentosTussSigtap', [
      'loadingModalImportarCSV',
      'dadosUploadProcedimento',
      'showModalImportarCSV',
    ]),
  },
  watch: {
    showModalImportarCSV: {
      handler() {
        this.$refs.form?.resetValidation();
      },
    },
  },
  methods: {
    ...mapActions('ressus/parametros/procedimentosTussSigtap', [
      'toggleModalImportarCSV',
      'uploadFile',
      'updateField',
    ]),
    onCloseModal() {
      this.toggleModalImportarCSV();
    },
    async onSubmit() {
      await this.$refs.form.validate();

      if (!this.dadosUploadProcedimento.anexo) {
        this.updateField({
          property: 'errorMessages',
          value: ['Anexo obrigatório'],
        });
        return;
      }

      if (this.valid) {
        this.uploadFile();
      }
    },
  },
};
</script>
