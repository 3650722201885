import { createCommandService } from '@/services';
import { notificacoesURI } from '@/api';
import { MAX_ITEMS_ON_DISPLAY } from '../constants';

const getQuantidadeNaoLidasPorTipo = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${notificacoesURI}/Ressus/quantidade_nao_lidas_por_tipo`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getNotificacoes = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${notificacoesURI}/Ressus/${payload.tipo}/paged?size=${MAX_ITEMS_ON_DISPLAY}&page=${payload.page}&arquivada=false&sort=dataCriacao,desc`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getNotificacoesArquivadas = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${notificacoesURI}/Ressus/paged?size=${MAX_ITEMS_ON_DISPLAY}&page=${payload.page}&arquivada=true`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const arquivarNotificacaoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${notificacoesURI}/arquivar/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const lerNotificacaoService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${notificacoesURI}/lida/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const lerTodasNotificacoesService = ({ tipo, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${notificacoesURI}/Ressus/${tipo}/todas_lidas`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getQuantidadeNaoLidasPorTipo,
  getNotificacoes,
  getNotificacoesArquivadas,
  arquivarNotificacaoService,
  lerNotificacaoService,
  lerTodasNotificacoesService,
};
