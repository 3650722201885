import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getParametros = ({ type, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/parametro/${type}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateParametrosService = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/parametro`,
    onSuccess,
    onCustomError,
  });

export { getParametros, updateParametrosService };
