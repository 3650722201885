const forms = {
  codigo: null,
  ativo: true,
  tipo: null,
  descricao: null,
  processoAbi: 'EM_PROCESSO',
};

export default () => ({
  alegacoesAns: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingForm: false,
  alegacaoAns: { ...forms },
  initialState: { ...forms, codigo: 0 },
});
