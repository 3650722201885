export default () => ({
  filtrosAvancados: [],
  listaVisualizacoesFiltros: [],
  listaFiltroAplicado: [],
  optionsFiltros: [],
  listaVisualizacoesColunas: [],
  listaSecoesColunas: [],
  visualizacaoSelecionada: {},
  isLoading: false,
  loading: false,
  visualizacaoFiltrosAvancadoSelecionado: {},
  initialFilters: [],
  loadingFiltros: false,
  searchFilter: '',
  nameFilter: '',
  optionActionValue: {},
});
