import { pagination } from '@/constants';

const getFilters = ({
  id,
  status,
  abi,
  abiId,
  numeroOficio,
  numeroProcesso,
  tipoNotificacao,
  instancia,
  dataEmissaoInicial,
  dataEmissaoFinal,
  dataRecebimentoInicial,
  dataRecebimentoFinal,
  valorTotalInicial,
  valorTotalFinal,
}) => `{
  ${id ? `id: ${id}` : ``}
  ${status ? `status: [${status}]` : ``}
  ${abi ? `numeroAbi: "${abi}"` : ``}
  ${abiId ? `abiId: ${abiId}` : ``}
  ${numeroOficio ? `numeroOficio: "${numeroOficio}"` : ``}
  ${numeroProcesso ? `numeroProcesso: "${numeroProcesso}"` : ``}
	${tipoNotificacao ? `tipoNotificacao: ${tipoNotificacao}` : ``}
	${instancia ? `instancia: [${instancia?.map((item) => `"${item}"`)}]` : ``}
	${
    dataEmissaoInicial && dataEmissaoFinal
      ? `dataEmissao: ["${dataEmissaoInicial}", "${dataEmissaoFinal}"]`
      : ``
  }
	${dataEmissaoInicial && !dataEmissaoFinal ? `dataEmissao: ["${dataEmissaoInicial}", null]` : ``}
	${
    dataRecebimentoInicial && dataRecebimentoFinal
      ? `dataRecebimento: ["${dataRecebimentoInicial}", "${dataRecebimentoFinal}"]`
      : ``
  }
	${
    dataRecebimentoInicial && !dataRecebimentoFinal
      ? `dataRecebimento: ["${dataRecebimentoInicial}", null]`
      : ``
  }
	${
    valorTotalInicial > 0 || valorTotalFinal > 0
      ? `valorTotal: [${valorTotalInicial}, ${valorTotalFinal}]`
      : []
  }
}`;

export default ({
  page = pagination.page,
  size = pagination.size,
  sort = 'dataEmissao',
  filters,
}) => `{
  listarNotificacaoAns(
		page: ${page - 1}
		size: ${size}
		sort: ["${sort}"]
		filter: ${getFilters(filters)}
	) {
		content {
			id
			status
			idOficio
			numeroProcesso
			numeroOficio
			tipoNotificacao
			dataEmissao
			dataRecebimento
			valorTotal
			numeroInstancia
			descricaoInstancia
      abi {
        id
        numero
      }
			decisao {
				id
        instancia {
          id
					descricao
        }
			}
			cobranca {
				id
				instancia {
					id
					descricao
				}
			}
		}
		number
		totalPages
		totalElements
		size
		first
		last
	}
}`;
