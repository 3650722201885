import { pagination } from '@/constants';

export default ({ page = pagination.page, size = pagination.size, pesquisa = '' }) => `{
  listarProcedimentosTussSigtap(
    page: ${page - 1}
    size: ${size}
		sort: ["id,desc"]
    filter: {
			pesquisar: "${pesquisa}"
		}) {
		content {
			id
			codigoTuss
			termosTuss
			codigoSus
			procedimentoSus
		}
		totalPages
		totalElements
	}
}`;
