<template>
  <v-text-field
    v-money="money"
    v-bind="{ label, placeholder, hideDetails, rules, value, disabled, readonly }"
    persistent-placeholder
    maxlength="18"
    prefix="R$"
    data-test="text-field-money"
    @keydown="onPreventNegativeNumbers"
    @keyup="$emit('keyup', $event.target.value)"
    @input="$emit('input', $event)"
    @click:clear="$emit('clear', null)"
    @focusout="$emit('focusout', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import { VMoney } from 'v-money';
import { MASKS } from '@/constants';

export default {
  name: 'ReInputMoney',
  directives: { money: VMoney },
  props: {
    label: String,
    value: String,
    hideDetails: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    placeholder: {
      type: String,
      default: '00,00',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      money: MASKS.currency,
    };
  },
  methods: {
    onPreventNegativeNumbers(event) {
      return event.key === '-' ? event.preventDefault() : null;
    },
  },
};
</script>
