import { helpPages } from '@/constants';

export default [
  {
    path: '/motivo-impugnacao',
    name: 'motivo-impugnacao',
    component: () => import(/* webpackChunkName: "motivo-impugnacao" */ './index.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Motivo de impugnação/recurso',
      helpPage: helpPages['motivo-impugnacao'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
  {
    path: '/motivo-impugnacao/create',
    name: 'create-motivo-impugnacao',
    component: () => import(/* webpackChunkName: "form-motivo-impugnacao" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Novo motivo de impugnação/recurso',
      helpPage: helpPages['motivo-impugnacao'],
      applicationKey: 'ressus',
      action: 'create',
    },
    props: true,
  },
  {
    path: '/motivo-impugnacao/edit/:id',
    name: 'edit-motivo-impugnacao',
    component: () => import(/* webpackChunkName: "form-motivo-impugnacao" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Editar motivo de impugnação/recurso',
      helpPage: helpPages['motivo-impugnacao'],
      applicationKey: 'ressus',
      action: 'edit',
    },
    props: true,
  },
  {
    path: '/motivo-impugnacao/view/:id',
    name: 'view-motivo-impugnacao',
    component: () => import(/* webpackChunkName: "form-motivo-impugnacao" */ './form-view.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Visualização do motivo de impugnação/recurso',
      helpPage: helpPages['motivo-impugnacao'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
