import { helpPages } from '@/constants';

export default [
  {
    path: '/integracao',
    name: 'integracao',
    component: () => import(/* webpackChunkName: "parametros-integracao" */ './index.vue'),
    meta: {
      title: 'Parâmetros',
      subtitle: 'Integração',
      helpPage: helpPages.integracao,
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
