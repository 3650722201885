import { helpPages } from '@/constants';

export default [
  {
    path: '/indicadores-ressarcimento',
    name: 'indicadores-ressarcimento',
    component: () =>
      import(
        /* webpackChunkName: "indicadores-ressarcimento" */
        './index.vue'
      ),
    meta: {
      title: 'Relatórios',
      subtitle: 'Indicadores Ressarcimento ao SUS',
      helpPage: helpPages['indicadores-ressarcimento'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
