import Vue from 'vue';

import {
  getQuantidadeNaoLidasPorTipo,
  getNotificacoes,
  getNotificacoesArquivadas,
  arquivarNotificacaoService,
  lerNotificacaoService,
  lerTodasNotificacoesService,
} from '../services';

const setLoading = ({ commit }, isLoading) =>
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    value: isLoading,
  });

const loadTabs = async ({ dispatch, commit }, { changeTab = false, tipo = 'notificacao-ans' }) => {
  commit('RESET_NOTIFICATION_STATE');

  await getQuantidadeNaoLidasPorTipo({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'tabs',
        value: data,
      });

      dispatch('loadNotificacoes', { changeTab, tipo });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
  });
};

const loadNotificacoes = async ({ state, commit, dispatch }, { changeTab = false, tipo }) => {
  dispatch('setLoading', true);

  changeTab && commit('RESET_NOTIFICATION_STATE');

  if (state.notificacoes.length < state.totalElements) {
    state.page += 1;
  } else {
    dispatch('setLoading', false);

    return;
  }

  tipo === 'arquivadas'
    ? dispatch('loadNotificacoesArquivadas', { page: state.page })
    : dispatch('loadNotificacoesPorTipo', { page: state.page, tipo });
};

const loadNotificacoesPorTipo = async ({ state, commit, dispatch }, { page, tipo }) => {
  await getNotificacoes({
    payload: {
      page,
      tipo,
    },
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'notificacoes',
        value: state.notificacoes.concat(data.content),
      });

      commit('GENERIC_MUTATION', {
        property: 'totalElements',
        value: data.totalElements,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => {
      dispatch('setLoading', false);
    },
  });
};

const loadNotificacoesArquivadas = async ({ state, commit, dispatch }, { page }) => {
  await getNotificacoesArquivadas({
    payload: {
      page,
    },
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'notificacoes',
        value: state.notificacoes.concat(data.content),
      });

      commit('GENERIC_MUTATION', {
        property: 'totalElements',
        value: data.totalElements,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => {
      dispatch('setLoading', false);
    },
  });
};

const arquivarNotificacao = async ({ dispatch }, { id, tipo }) => {
  await arquivarNotificacaoService({
    payload: { id },
    onSuccess: () => {
      dispatch('loadTabs', { tipo });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
  });
};

const lerNotificacao = (_, id) => {
  lerNotificacaoService({
    id,
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
  });
};

const lerTodasNotificacoes = ({ dispatch }, tipo) => {
  lerTodasNotificacoesService({
    tipo,
    onSuccess: () => dispatch('loadTabs', { tipo }),
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
  });
};

export default {
  setLoading,
  loadTabs,
  loadNotificacoes,
  loadNotificacoesPorTipo,
  loadNotificacoesArquivadas,
  arquivarNotificacao,
  lerNotificacao,
  lerTodasNotificacoes,
};
