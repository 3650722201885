<template>
  <div
    v-tooltip.right="{
      content: configStatus.content,
      offset: 4,
    }"
    class="h-full"
    :class="configStatus.color"
  />
</template>

<script>
export default {
  name: 'ReStatusTable',
  props: {
    status: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    configStatus() {
      return this.config[this.status];
    },
  },
};
</script>
