<template>
  <v-text-field
    v-model="filters[property]"
    v-mask="`${mask}`"
    v-bind="{ label, rules }"
    clearable
    persistent-placeholder
    persistent-hint
    placeholder="Preencher"
    :data-test="`text-field-${property}`"
    :hint="hintText"
    @input="
      (value) =>
        updateField({
          property,
          value: value || '',
        })
    "
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'InputData',
  props: {
    label: String,
    property: String,
    type: String,
    maxLength: Number,
    rules: Array,
  },
  computed: {
    ...mapState('ressus/drawerFiltros', ['filters']),
    mask() {
      let hashString = '';

      const placeholder = {
        number: '#',
        text: 'X',
      };

      if (this.filters[this.property]) {
        for (let i = 0; i < this.maxLength; i++) {
          hashString += placeholder[this.type];
        }
      }

      return hashString;
    },
    hintText() {
      if (!this.maxLength) return '';

      const hint = {
        number: `Somente números. O tamanho máximo é: ${this.maxLength}`,
        text: `O tamanho máximo é: ${this.maxLength}`,
      };

      return hint[this.type];
    },
  },
  methods: {
    ...mapActions('ressus/drawerFiltros', ['updateField']),
  },
};
</script>
