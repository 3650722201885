import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import initialState from './state';
import drawerDetalhamentoAtendimento from '../drawer-detalhamento-atendimento/store';
import filtrosAvancados from '../drawer-filtros-colunas/store';
import formularioImpugnacao from '../formulario-impugnacao/store';

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
  modules: {
    drawerDetalhamentoAtendimento,
    filtrosAvancados,
    formularioImpugnacao,
  },
};
