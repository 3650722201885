<template>
  <div class="pr-7">
    <section>
      <h1 class="section-title-default mb-5">Informações gerais</h1>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            persistent-placeholder
            return-object
            item-text="descricao"
            item-value="situacao"
            placeholder="Selecionar"
            no-data-text="Situação do processo não encontrada!"
            label="Situação do processo"
            data-test="autocomplete-situacao-processo"
            :readonly="isFormularioAtendimento"
            :append-icon="isFormularioAtendimento ? '' : 'mdi-menu-down'"
            :value="payloadDadosGerais.situacaoProcesso"
            :items="situacaoProcesso"
            @change="updateSituacaoProcesso"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            persistent-placeholder
            readonly
            placeholder="00/0000"
            label="Competência"
            data-test="text-field-competencia"
            :value="dadosGeral.competencia"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            label="Contagem"
            placeholder="00000"
            data-test="text-field-contagem"
            :value="dadosGeral.contagem"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            label="Tipo"
            placeholder="Tipo"
            data-test="text-field-tipo"
            :value="dadosGeral.tipo"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            persistent-placeholder
            placeholder="00/00/0000 a 00/00/0000"
            label="Período de atendimento"
            data-test="text-field-periodo-atendimento"
            :value="dadosGeral.periodoAtendimento"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            persistent-placeholder
            label="Número de atendimento"
            placeholder="000000000000000"
            data-test="text-field-numero-atendimento"
            :value="dadosGeral.numero"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            persistent-placeholder
            label="Valor total do atendimento (R$)"
            placeholder="00.000,00"
            data-test="text-field-valor-total-atendimento"
            :value="dadosGeral.valorTotal"
          />
        </v-col>
      </v-row>
    </section>
    <section>
      <h1 class="section-title-default mb-5">Identificação do beneficiário</h1>
      <v-row>
        <v-col cols="12" md="7">
          <re-popover-text-field
            v-bind="{ dataOnlyView }"
            readonly
            persistent-placeholder
            label="Beneficiário"
            placeholder="Nome beneficiário"
            data-test="text-field-beneficiario"
            element-ref="nome-beneficiario"
            :value="dadosGeral.nomeCompleto"
          />
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            readonly
            persistent-placeholder
            label="CCO"
            placeholder="000000000000000"
            data-test="text-field-cco"
            :value="dadosGeral.codigoCco"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            persistent-placeholder
            label="CPF"
            placeholder="00000000000"
            data-test="text-field-cpf"
            :value="dadosGeral.cpf"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            persistent-placeholder
            placeholder="00/00/0000"
            label="Data de nascimento"
            data-test="text-field-data-nascimento"
            :value="dadosGeral.dataNascimento"
          />
        </v-col>
      </v-row>
    </section>
    <secao-motivos v-bind="{ isFormularioAtendimento }" title="Possível motivo de alegação" />
    <section v-if="!isFormularioAtendimento" class="mb-7">
      <h1 class="section-title-default mb-5">Laudo de auditor de saúde</h1>
      <v-btn
        outlined
        class="btn btn__outlined"
        data-test="btn-gerar-laudo"
        @click="$emit('gerar-laudo')"
      >
        Gerar laudo
      </v-btn>
    </section>
    <section>
      <h1 class="section-title-default mb-2">Críticas de validação</h1>
      <span v-if="!dadosGeral?.listagemCritica?.length" class="text-xs text-primary-blue-base">
        Nenhuma crítica gerada
      </span>
      <div
        v-else
        v-for="(critica, index) in dadosGeral.listagemCritica"
        class="text-primary-blue-base"
        :key="index"
      >
        <span class="text-xs"> {{ critica.nome }} </span>
        <h4 class="text-base ml-3">{{ critica.complemento }}</h4>
      </div>
    </section>
    <portal v-if="activeTab === tab" to="footer-drawer-atendimento">
      <v-btn
        v-for="action in footerActions"
        :key="action.key"
        :outlined="action.outlined"
        :text="action.text"
        :class="action.class"
        :data-test="`btn-${action.key}`"
        @click="handleClick(action)"
      >
        {{ action.label }}
      </v-btn>
    </portal>
    <ui-modal
      type="salvar"
      :show="showModalAtencao"
      @close="onClose"
      @exit="onExit"
      @save="onSave"
    />
    <ui-modal
      type="salvar"
      :show="showModalAtencaoGeral"
      @close="onCloseModalGeral"
      @exit="onExitModalGeral"
      @save="onSaveDadosGeral"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { RePopoverTextField, UiModal } from '@/components';
import { SecaoMotivos } from '../components';
import MixinControlTabs from './mixin-control-tabs';

export default {
  name: 'TabGeral',
  mixins: [MixinControlTabs],
  components: { RePopoverTextField, SecaoMotivos, UiModal },
  data() {
    return {
      tab: 'GERAL',
      controlState: true,
      showModalAtencaoGeral: false,
      targetEvent: '',
    };
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'payloadDadosGerais',
      'situacaoProcesso',
    ]),
    ...mapGetters('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'dadosGeral',
      'isInitialStateDadosGeral',
    ]),
    isInitialState() {
      return this.isInitialStateDadosGeral;
    },
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'updateSituacaoProcesso',
      'updateAtendimento',
      'resetDadosGeral',
    ]),
    async onClose() {
      if (this.sourceProperty === 'tabIndex') {
        await this.updateTabIndex(this.sourceTab);
      }

      this.setActiveClass();
      this.showModalAtencao = false;
    },
    onExit() {
      if (this.sourceProperty === 'tabIndex') {
        this.showModalAtencao = false;

        this.resetDadosGeral();
        this.updateActiveTab(this.targetTab);
      } else {
        this.closeDrawerAtendimentos();
      }

      this.setActiveClass();
    },
    async onSave() {
      if (this.sourceProperty === 'tabIndex') {
        await this.updateTabIndex(this.sourceTab);
        this.setActiveClass();
      }

      this.showModalAtencao = false;
      this.updateAtendimento();
    },
    onClick(action) {
      this.targetEvent = action.event;

      this.isInitialState ? this.$emit(`${action.event}`) : this.onToggleModalAtencaoGeral();
    },
    onToggleModalAtencaoGeral() {
      this.showModalAtencaoGeral = !this.showModalAtencaoGeral;
    },
    onCloseModalGeral() {
      this.onToggleModalAtencaoGeral();
    },
    async onExitModalGeral() {
      await this.resetDadosGeral();
      this.onToggleModalAtencaoGeral();

      this.$emit(`${this.targetEvent}`);
    },
    onSaveDadosGeral() {
      this.sourceTab = 0;

      this.showModalAtencaoGeral = false;
      this.onSave();
    },
  },
};
</script>
