import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { formatDescriptionEditor } from '@/utils/string';
import { formularioPadrao } from '../../constants';
import initialState from './state';

export default {
  RESET_FORM_STATE(state) {
    Object.assign(state, initialState());
  },
  RESET_FORMULARIO(state) {
    Object.assign(state.formulario, {
      ...formularioPadrao,
    });

    Object.assign(state.initialStateFormulario, {
      ...formularioPadrao,
    });

    Object.assign(state, { modalFormularioMode: null });
  },
  UPDATE_FIELD_FORM(state, { property, value }) {
    Vue.set(state.formulario, property, value);
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = cloneDeep(payload);
  },
  GENERIC_MUTATION1(state, { property, payload }) {
    state[property] = cloneDeep(payload);
  },
  SET_INITIAL_STATE(state, value) {
    state.initialStateFormulario = {
      ...state.formulario,
      detalhamento: value?.detalhamento && formatDescriptionEditor(value?.detalhamento),
      documentosComprobatorios:
        value?.documentosComprobatorios && formatDescriptionEditor(value?.documentosComprobatorios),
      memoriaCalculo: value?.memoriaCalculo && formatDescriptionEditor(value?.memoriaCalculo),
    };
  },
};
