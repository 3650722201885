const forms = {
  numeroAbi: '',
  competenciaInicial: '',
  competenciaFinal: '',
  numeroProcesso: '',
  numeroOficio: '',
  dataOficioAbertura: '',
  prazoFinalImpugnacao: '',
  resolucaoNormativa: '',
  vigenciaInicialRolAns: '',
  vigenciaFinalRolAns: '',
  qtdTotalAtendimentos: 0,
  valorTotalAtendimentos: 0,
  qtdTotalAIHs: 0,
  valorTotalAIHs: 0,
  qtdTotalAPACs: 0,
  valorTotalAPACs: 0,
  qtdTotalAtendimentosCO: 0,
  valorTotalAtendimentosCO: 0,
  qtdTotalAtendimentosVD: 0,
  valorTotalAtendimentosVD: 0,
};

export default () => ({
  resumoAbi: [{ ...forms }],
  initialState: [{ ...forms }],
  dadosDecisao: [],
  dadosCobranca: [],
  loading: false,
});
