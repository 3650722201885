<template>
  <v-autocomplete
    v-model="selecteds"
    v-bind="{ label, disabled, readonly, value, rules, items }"
    persistent-placeholder
    return-object
    multiple
    clearable
    attach
    item-value="`id`"
    item-text="descricao"
    placeholder="Selecionar"
    no-data-text="Nenhum registro encontrado"
    data-test="autocomplete-abi"
    :search-input.sync="search"
    @change="onChange"
  >
    <template v-if="!search" #prepend-item>
      <v-list-item @click="onToggleSelectAll">
        <v-list-item-action>
          <v-icon> {{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Selecionar todos </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #selection="{ index }">
      <div v-if="selectedItems">
        <span v-if="index === 0">Todos</span>
      </div>
      <span v-else-if="index === 0" class="w-full">
        <re-popover class="w-full" :element-id="label" :text="moreItems" :lines="1" />
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import RePopover from './re-popover.vue';

export default {
  name: 'ReAutocompleteMultiple',
  props: {
    label: {
      type: String,
      default: 'ABI',
    },
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    readonly: Boolean,
    selectAll: Boolean,
  },
  components: { RePopover },
  data() {
    return {
      selecteds: [],
      search: '',
    };
  },
  computed: {
    selectedItems() {
      return this.selecteds.length === this.items.length;
    },
    selectedSomeItems() {
      return this.selecteds.length > 0 && !this.selectedItems;
    },
    icon() {
      if (this.selectedItems) return 'mdi-close-box';
      if (this.selectedSomeItems) return 'mdi-minus-box';

      return 'mdi-checkbox-blank-outline';
    },
    moreItems() {
      return this.selecteds?.map((selected) => selected.descricao).join('<br/>');
    },
  },
  watch: {
    selectAll: {
      handler(value) {
        value && this.onToggleSelectAll();
      },
    },
  },
  methods: {
    onToggleSelectAll() {
      this.$nextTick(() => {
        if (this.selectedItems) {
          this.selecteds = [];
        } else {
          this.selecteds = this.items.slice();
        }

        this.$emit('change', this.selecteds);
      });
    },
    onChange(option) {
      option ? this.$emit('change', option) : this.$emit('change', null);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-list-item__action) {
  margin-right: 8px !important;
}
</style>
