const listarAtendimentosAssinaveis = ({ idAbi, situacaoProcesso }) => `{
  listarAtendimentosAssinaveis(
    filter: {
      idAbi: ${idAbi}
      situacaoProcesso: ${situacaoProcesso} 
    }
  ){
    id
    contagem
    numero
    statusProtocolo
    situacaoProcesso
    statusAtendimento
    formularioImpugnacao { 
      id
      tipoFormulario 
    }
    formularioRecursao { 
      id
      tipoFormulario 
    }
  }
}`;

export default listarAtendimentosAssinaveis;
