import { formatDate, getTime } from '@/utils/date';
import { tabsConfig } from '../constants';

const payloadTabs = ({ tabs }) =>
  tabs?.map((tab) => ({
    ...tab,
    titulo: tabsConfig[tab.tipo].titulo,
    count: tab.count >= 100 ? '99+' : tab.count,
    placeholder: tabsConfig[tab.tipo].placeholder,
    ordem: tabsConfig[tab.tipo].ordem,
  }));

const payloadNotificacoes = ({ notificacoes }) =>
  notificacoes?.map((notificacao) => ({
    ...notificacao,
    dataCriacao: `${formatDate(notificacao?.dataCriacao)} às ${getTime(notificacao?.dataCriacao)}`,
  }));

export default { payloadTabs, payloadNotificacoes };
