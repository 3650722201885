<template>
  <div class="pr-7 h-screen">
    <re-placeholder-tabs v-if="!dadosCobranca.id" :config-tabs="tabsDrawer" :tab-index="tabIndex" />
    <v-form v-else>
      <v-row>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Instância cobrança"
            data-test="text-field-instancia-cobranca"
            :value="dadosCobranca.instancia"
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Ofício cobrança"
            placeholder="0000-0000"
            data-test="text-field-oficio-cobranca"
            :value="dadosCobranca.numeroOficio"
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Cobrança enviada"
            placeholder="00/00/0000"
            data-test="text-field-cobranca-enviada"
            :value="dadosCobranca.dataRecebimento"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="GRU"
            placeholder="000 0 000000000000000 00000 000000 00000000000000"
            data-test="text-field-gru"
            :value="dadosCobranca.gru"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Ação judicial"
            data-test="text-field-acao-judicial"
            :value="dadosCobranca.acaoJudicial"
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Situação judicial"
            data-test="text-field-situacao-judicial"
            :value="dadosCobranca.situacao"
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Vencimento GRU"
            placeholder="00/00/0000"
            data-test="text-field-vencimento-gru"
            :value="dadosCobranca.vencimentoGru"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Nº processo judicial"
            placeholder="00000000000"
            data-test="text-field-numero-processo-judicial"
            :value="dadosCobranca.numeroProcesso"
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Valor ressarcimento"
            data-test="text-field-valor-ressarcimento"
            :value="dadosCobranca.valorTotalSemJuros"
          />
        </v-col>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Custo incorporado"
            placeholder="00/00/0000"
            data-test="text-field-custo-incorporado"
            :value="dadosCobranca.dataIncorporacaoCusto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            readonly
            hide-details
            persistent-placeholder
            label="Nº da fatura"
            placeholder="000 0 000000000000000 00000 000000 00000000000000"
            data-test="text-field-numero-fatura"
            :value="dadosCobranca.numeroFatura"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { RePlaceholderTabs } from '@/components';
import MixinControlTabs from './mixin-control-tabs';

export default {
  name: 'TabCobranca',
  mixins: [MixinControlTabs],
  components: { RePlaceholderTabs },
  data() {
    return {
      tab: 'COBRANCA',
      controlState: false,
    };
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', ['atendimento']),
    ...mapGetters('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'dadosCobranca',
    ]),
  },
  watch: {
    tabIndex: {
      immediate: true,
      handler() {
        this.onLoad();
      },
    },
    'atendimento.id': {
      immediate: true,
      handler() {
        this.onLoad();
      },
    },
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'loadDadosCobranca',
    ]),
    onLoad() {
      this.activeTab === this.tab && this.loadDadosCobranca(this.atendimento);
    },
  },
};
</script>
