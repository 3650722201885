import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { enumErrors } from '@/constants';
import { ReToastLink } from '@/components';
import {
  getSituacaoProcessoService,
  getIdsAtendimentosParaRelatorioService,
  acompanhamentoAtendimento,
} from '../services';

const getMotivos = async ({ commit, dispatch, getters }) => {
  commit('GENERIC_MUTATION', {
    property: 'motivos',
    payload: [],
  });

  const motivos = await dispatch(
    'ressus/cadastros/motivoImpugnacao/sharedMotivosImpugnacao',
    true,
    { root: true },
  );

  commit('GENERIC_MUTATION', {
    property: 'motivos',
    payload: motivos,
  });

  dispatch('updateValueInitialState', { property: 'motivosId', value: getters.motivos });
};

const getSituacaoProcesso = async ({ commit }) => {
  await getSituacaoProcessoService({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'situacoesProcesso',
        payload: data,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: enumErrors[response.status],
      }),
  });
};

const gerarRelatorio = async ({ dispatch, getters }) => {
  dispatch('setLoading');

  const { payloadDadosRelatorio } = getters;

  const { payload } = await getIdsAtendimentosParaRelatorioService(payloadDadosRelatorio);

  if (payload.listarIdsAtendimentosParaRelatorio.length === 0) {
    Vue.$toast('Não foram encontrados registros que correspondam aos filtros selecionados', {
      type: 'info',
    });

    dispatch('setLoading', false);

    return;
  }

  const payloadAcompanhamentoAtendimento = {
    atendimentoIds: payload.listarIdsAtendimentosParaRelatorio,
    ...getters.imprimirInfos,
  };

  await acompanhamentoAtendimento({
    payload: payloadAcompanhamentoAtendimento,
    onSuccess: async ({ data }) => {
      await dispatch(
        'ressus/processos/documentosGerados/setFilters',
        { id: data.id },
        { root: true },
      );

      Vue.$toast(
        {
          component: ReToastLink,
          props: {
            description: 'O documento está sendo gerado. Para consultar o status do documento',
            linkDescription: 'clique aqui.',
          },
          listeners: {
            onClickLink: () => Vue.router.push({ name: 'documentos-gerados' }),
          },
        },
        { type: 'info' },
      );
    },
    onCustomError: ({ response }) => {
      const errors = response?.data?.errors;

      if (errors) {
        errors.map((error) =>
          Vue.$toast(error.defaultMessage, {
            type: enumErrors[response?.status],
          }),
        );
      } else {
        Vue.$toast(response?.data?.message, {
          type: enumErrors[response.status],
        });
      }
    },
    onFinally: () => {
      dispatch('setLoading', false);
    },
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const updateValueInitialState = ({ commit }, { property, value }) => {
  commit('UPDATE_VALUE_INITIAL_STATE', {
    property,
    value,
  });
};

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

const resetState = ({ state, commit }) => {
  commit('RESET_STATE');

  Object.keys(state.dadosRelatorio.imprimir).forEach((key) => {
    commit('UPDATE_IMPRIMIR_INFOS', {
      index: key,
      property: 'checked',
      value: true,
    });
  });
};

const updateInitialState = ({ commit, state: { dadosRelatorio } }) =>
  commit('GENERIC_MUTATION', {
    property: 'initialState',
    payload: cloneDeep(dadosRelatorio),
  });

export default {
  getMotivos,
  gerarRelatorio,
  updateField,
  setLoading,
  updateValueInitialState,
  resetState,
  getSituacaoProcesso,
  updateInitialState,
};
