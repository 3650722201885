import { helpPages } from '@/constants';

export default [
  {
    path: '/grupo-trabalho',
    name: 'grupo-trabalho',
    component: () => import(/* webpackChunkName: "grupo-trabalho" */ './index.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Grupo de trabalho',
      helpPage: helpPages['grupo-trabalho'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
  {
    path: '/grupo-trabalho/create',
    name: 'create-grupo-trabalho',
    component: () => import(/* webpackChunkName: "form-grupo-trabalho" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Novo grupo de trabalho',
      helpPage: helpPages['grupo-trabalho'],
      applicationKey: 'ressus',
      action: 'create',
    },
    props: true,
  },
  {
    path: '/grupo-trabalho/edit/:id',
    name: 'edit-grupo-trabalho',
    component: () => import(/* webpackChunkName: "form-grupo-trabalho" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Editar grupo de trabalho',
      helpPage: helpPages['grupo-trabalho'],
      applicationKey: 'ressus',
      action: 'edit',
    },
    props: true,
  },
  {
    path: '/grupo-trabalho/view/:id',
    name: 'view-grupo-trabalho',
    component: () => import(/* webpackChunkName: "form-grupo-trabalho" */ './form-view.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Visualização do grupo de trabalho',
      helpPage: helpPages['grupo-trabalho'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
