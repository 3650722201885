import { pagination } from '@/constants';

export default ({
  page = pagination.page,
  size = pagination.size,
  sort = 'id',
  pesquisar = '',
}) => `{
  listarSolicitacaoDocumentos(
    page: ${page - 1}
    size: ${size}
    sort: ["${sort}"]
    filter : {
      pesquisar: "${pesquisar}"
    }) {
    content {
      id
			destinatarioNome
			destinatarioGrupoNome
			assunto
      descricao
      dataSolicitacao
    }
    totalPages
    totalElements
    size
    first
    last
  }
}`;
