import Vue from 'vue';
import { formatMoney, formatMoneyRestore } from '@/utils/currency';
import {
  getDadosAtendimento,
  getAtendimentoDecidido,
  getDadosCobranca,
  updateDadosDecisaoService,
  updateAtendimentoService,
  updateValorDeducaoService,
} from '../services';
import { tabsDrawer } from '../constants';

const getMotivosImpugnacaoCadastrados = async ({ dispatch, commit }, data) => {
  dispatch('setLoading');

  const motivos = data?.content?.map((motivo) => ({
    ...motivo,
    id: +motivo.id,
    descricaoCompleta: `${motivo.codigo} - ${motivo.descricaoAbreviada}`,
  }));

  commit('GENERIC_MUTATION', {
    property: 'motivosImpugnacaoCadastrados',
    payload: motivos,
  });

  dispatch('setLoading', false);
};

const detalhesAtendimento = async ({ commit, dispatch }, { id, isUpdate }) => {
  dispatch('setLoading');

  !isUpdate && dispatch('resetDadosGeral');

  await getDadosAtendimento({
    id,
    onSuccess: async ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'atendimento',
        payload: data?.atendimento,
      });

      commit('GENERIC_MUTATION', {
        property: 'situacaoProcesso',
        payload: data?.situacaoProcessoDisponiveis,
      });

      commit('GENERIC_MUTATION', {
        property: 'parametros',
        payload: data?.parametros,
      });

      await dispatch('setDadosGeral', data?.atendimento);
      await dispatch('setDadosProcedimento', data?.atendimento);
      await dispatch('getMotivosImpugnacaoCadastrados', data?.motivosImpugnacao);
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const setDadosGeral = async ({ commit }, data) => {
  let motivosImpugnacao = data?.listagemMotivoImpugnacao?.map((listaMotivo) => ({
    id: listaMotivo.motivoImpugnacao.id,
    ativo: listaMotivo.ativo,
  }));

  motivosImpugnacao = motivosImpugnacao.length ? motivosImpugnacao : [{ id: null, ativo: true }];

  commit('SET_DADOS_GERAL', {
    id: data.id,
    situacaoProcesso: data.situacaoProcesso,
    motivosImpugnacao,
  });

  commit('GENERIC_MUTATION', {
    property: 'motivosImpugnacao',
    payload: motivosImpugnacao,
  });
};

const setDadosProcedimento = ({ commit }, data) => {
  commit('SET_DADOS_PROCEDIMENTO', {
    id: data.id,
    valorDeducao: formatMoney(data.valorDeducao || 0),
  });
};

const updateSituacaoProcesso = ({ commit }, payload) => {
  const { situacao: value } = payload;
  commit('UPDATE_SITUACAO_PROCESSO', { value });
};

const updateMotivosImpugnacao = ({ commit }, motivos) => commit('SET_MOTIVOS_DADOS_GERAL', motivos);

const updateAtendimento = ({ state, getters, dispatch }) => {
  const situacaoProcessoOrigem = state.initialStateDadosGeral.situacaoProcesso;
  const situacaoProcessoDestino = getters.payloadDadosGerais.situacaoProcesso;

  const situacao =
    situacaoProcessoOrigem !== situacaoProcessoDestino
      ? situacaoProcessoDestino
      : situacaoProcessoOrigem;

  const { salvaSemAlterarSituacao } = tabsDrawer[0][situacao];

  if (!getters.hasNaoRecursadoOuNaoImpugnado) {
    if (salvaSemAlterarSituacao) {
      if (getters.hasEmptyMotivos)
        return Vue.$toast('Adicione um motivo para concluir a ação', {
          type: 'warning',
        });

      if (!getters.hasMotivosAtivos)
        return Vue.$toast('Ative um motivo para concluir a ação', {
          type: 'warning',
        });
    }

    if (situacaoProcessoOrigem !== situacaoProcessoDestino) {
      if (!salvaSemAlterarSituacao && !getters.initialStateMotivosImpugnacao) {
        return Vue.$toast('O motivo não pode ser alterado nessa etapa', {
          type: 'error',
        });
      }
    } else if (!salvaSemAlterarSituacao) {
      return Vue.$toast('O motivo não pode ser alterado nessa etapa', {
        type: 'error',
      });
    }
  }

  dispatch('setLoading');

  return updateAtendimentoService({
    payload: getters.payloadDadosGeraisToSave,
    onSuccess: ({ data: { avisoFormularioAlterado } }) => {
      if (avisoFormularioAlterado?.formularioAlterado) {
        Vue.$toast(avisoFormularioAlterado?.mensagemAvisoFormularioAlterado, { type: 'info' });
      }

      Vue.$toast('Alterações salvas com sucesso', { type: 'success' });

      dispatch('detalhesAtendimento', {
        id: state.atendimento.id,
        isUpdate: true,
      });

      dispatch('updateGridAtendimentos', {
        closeDrawer:
          state.payloadDadosGerais.situacaoProcesso !==
          state.initialStateDadosGeral.situacaoProcesso,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateValorDeducaoProcedimento = ({ getters, dispatch }) => {
  dispatch('setLoading');

  const { id, valorDeducao } = getters.dadosProcedimento;

  updateValorDeducaoService({
    id,
    payload: formatMoneyRestore(valorDeducao),
    onSuccess: ({ data }) => {
      Vue.$toast('Valor dedução salvo com sucesso', {
        type: 'success',
      });
      const { avisoFormularioAlterado } = data;

      if (avisoFormularioAlterado?.formularioAlterado) {
        Vue.$toast(avisoFormularioAlterado?.mensagemAvisoFormularioAlterado, { type: 'info' });
      }

      dispatch('updateGridAtendimentos', {});
      dispatch('detalhesAtendimento', { id });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateValorDeducao = ({ commit }, value) => commit('UPDATE_VALOR_DEDUCAO', value);

const loadDadosDecisao = async ({ commit, dispatch }, atendimento) => {
  dispatch('setLoading');

  await getAtendimentoDecidido({
    atendimento,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'atendimentosDecididos',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialStateAtendimentosDecididos',
        payload: data,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateFieldDecisao = ({ commit }, { field, item, value }) =>
  commit('UPDATE_FIELD_DECISAO', { field, item, value });

const updateDadosDecisao = ({ getters, dispatch }) => {
  dispatch('setLoading');

  const { atendimentoId } = Vue.router.history.current.params;

  const atendimento = {
    numero: getters.dadosDecisao[0].numeroAtendimento,
    competencia: getters.dadosDecisao[0].competencia,
  };

  const payload = getters.dadosDecisao.map((atendimento) => ({
    id: atendimento.id,
    tipoAlegacaoAns: atendimento.tipoAlegacaoAns,
    observacoes: atendimento.observacoes || '',
    valorDeducaoDeferida: formatMoneyRestore(atendimento.valorDeducaoDeferida),
  }));

  updateDadosDecisaoService({
    atendimentoId,
    payload,
    onSuccess: ({ data }) => {
      if (data.atendimentoAlterado.situacaoAlterado) {
        Vue.$toast(data.atendimentoAlterado.mensagem, { type: 'info' });

        dispatch('updateGridAtendimentos', {
          closeDrawer: true,
        });
      } else {
        dispatch('loadDadosDecisao', atendimento);
      }

      Vue.$toast('Dados de decisão salvos com sucesso', { type: 'success' });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const loadDadosCobranca = async ({ commit, dispatch }, atendimento) => {
  dispatch('setLoading');

  await getDadosCobranca({
    atendimento,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'dadosCobrancaAtendimento',
        payload: data[0],
      });
    },
    onCustomError: ({ response }) => {
      Vue.$toast(response?.data?.message, { type: 'error', id: 'dados-cobranca' });

      commit('GENERIC_MUTATION', {
        property: 'dadosCobrancaAtendimento',
        payload: {},
      });
    },
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateGridAtendimentos = async ({ dispatch }, { closeDrawer = false }) => {
  const { query, params } = Vue.router.history.current;

  await dispatch(
    'ressus/processos/atendimentosAbi/loadAtendimentos',
    {
      ...query,
      idAbi: params?.id,
    },
    { root: true },
  );

  closeDrawer && dispatch('closeDrawerAtendimentos');
};

const closeDrawerAtendimentos = ({ dispatch, state }) => {
  const { tabIndex } = state;

  const ACTIONS_TABS = {
    GERAL: 'resetDadosGeral',
    DECISAO: 'resetDadosDecisao',
    PROCEDIMENTO: 'resetDadosProcedimento',
  };

  if (tabIndex >= 0) {
    const tabActive = tabsDrawer[tabIndex].value;

    if (ACTIONS_TABS[tabActive]) dispatch(ACTIONS_TABS[tabActive]);
  }

  dispatch('updateActiveTab', 'GERAL');
  dispatch('updateTabIndex', 0);

  Vue.router
    .replace({
      name: 'atendimentos-abi',
      params: { ...Vue.router.history.current.params },
      query: { ...Vue.router.history.current.query },
    })
    .catch(() => {});
};

const resetDados = ({ commit }, { mutation }) => commit(mutation);

const resetDadosGeral = ({ dispatch }) =>
  dispatch('resetDados', {
    isInitialState: 'isInitialStateDadosGeral',
    message: 'Os dados da aba geral não foram salvos.',
    mutation: 'RESET_DADOS_GERAL',
  });

const resetDadosDecisao = ({ dispatch }) =>
  dispatch('resetDados', {
    isInitialState: 'isInitialStateDadosDecisao',
    message: 'Os dados da aba decisão não foram salvos.',
    mutation: 'RESET_DADOS_DECISAO',
  });

const resetDadosProcedimento = async ({ dispatch }) => {
  dispatch('setLoading');

  await dispatch('resetDados', {
    isInitialState: 'isInitialStateDadosProcedimento',
    message: 'Os dados da aba procedimento não foram salvos.',
    mutation: 'RESET_DADOS_PROCEDIMENTO',
  });

  dispatch('setLoading', false);
};

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

const updateTabIndex = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', { property: 'tabIndex', payload });

const updateActiveTab = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', { property: 'activeTab', payload });

export default {
  getMotivosImpugnacaoCadastrados,
  detalhesAtendimento,
  setDadosGeral,
  setDadosProcedimento,
  updateSituacaoProcesso,
  updateMotivosImpugnacao,
  updateValorDeducao,
  updateValorDeducaoProcedimento,
  updateAtendimento,
  loadDadosDecisao,
  updateFieldDecisao,
  updateDadosDecisao,
  loadDadosCobranca,
  updateGridAtendimentos,
  closeDrawerAtendimentos,
  resetDados,
  resetDadosDecisao,
  resetDadosGeral,
  resetDadosProcedimento,
  setLoading,
  updateTabIndex,
  updateActiveTab,
};
