import { cloneDeep } from 'lodash';
import fields from '../contants';

export default () => ({
  loading: false,
  abisConcluidas: [],
  dadosRelatorio: { ...fields },
  initialState: cloneDeep({ ...fields }),
  motivos: [],
  situacoesProcesso: [],
});
