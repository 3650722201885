import { isEqual } from 'lodash';
import { competencyFullDate } from '@/utils/date';

const payloadLancamentosContabeis = ({ dadosLancamentosContabeis }) => ({
  abiId: dadosLancamentosContabeis?.idAbi,
  processosEventos: dadosLancamentosContabeis?.processoEventoIds?.map((evento) => evento.value),
  competenciaInicial: dadosLancamentosContabeis?.dataCompetenciaInicial
    ? competencyFullDate(dadosLancamentosContabeis?.dataCompetenciaInicial)
    : null,
  competenciaFinal: dadosLancamentosContabeis?.dataCompetenciaFinal
    ? competencyFullDate(dadosLancamentosContabeis?.dataCompetenciaFinal)
    : null,
  dataPeriodoInicial: dadosLancamentosContabeis?.dataPeriodoInicial
    ? dadosLancamentosContabeis?.dataPeriodoInicial
    : null,
  dataPeriodoFinal: dadosLancamentosContabeis?.dataPeriodoFinal
    ? dadosLancamentosContabeis?.dataPeriodoFinal
    : null,
  acoesJudiciais: dadosLancamentosContabeis?.acaoJudicial?.map((abi) => abi.value),
  gru: dadosLancamentosContabeis?.gru,
  instancias: dadosLancamentosContabeis?.instancia?.map((abi) => +abi.id),
  contrato: dadosLancamentosContabeis?.contrato,
  alegacoesAns: dadosLancamentosContabeis?.alegacaoAns?.map((abi) => +abi.id),
});

const isInitialState = ({ initialState, dadosLancamentosContabeis }) =>
  isEqual(initialState, dadosLancamentosContabeis);

export default { payloadLancamentosContabeis, isInitialState };
