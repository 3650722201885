import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import createQueryNotificacoesAns from './queries/notificacoes-ans';

const getOficiosNotificacao = async (queryParameters) =>
  createGraphQLService(createQueryNotificacoesAns(queryParameters));

const getOficioById = ({ idOficio, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/notificacao_ans/id_oficio/${idOficio}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateOficioDecisaoService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/notificacao_ans/${id}/atualizar`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const uploadFileService = ({ notificacaoAnsId, payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/envio_arquivo/upload/${notificacaoAnsId}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getFileInfo = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/envio_arquivo/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getInstancias = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/instancia?exibirNaoRecursado=false`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const exportarRelatorioService = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/documento/oficios_notificados`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getOficiosNotificacao,
  getOficioById,
  uploadFileService,
  getFileInfo,
  getInstancias,
  updateOficioDecisaoService,
  exportarRelatorioService,
};
