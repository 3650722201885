import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getInstancias = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/instancia`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const lancamentosContabeisService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/documento/lancamentos_contabeis`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

export { getInstancias, lancamentosContabeisService };
