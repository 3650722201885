<template>
  <v-form class="pr-7">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Unimed/Contrato"
          data-test="text-field-unimed-contrato"
          :value="dadosContratoPlano.unimedContrato"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Contrato físico"
          data-test="text-field-contrato-fisico"
          :value="dadosContratoPlano.contratoFisico"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <re-popover-text-field
          v-bind="{ dataOnlyView }"
          label="Empresa"
          element-ref="empresa"
          data-test="text-field-empresa"
          :value="dadosContratoPlano.empresa"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="CNPJ contratante (SIB)"
          data-test="text-field-cnpj-contratante-sib"
          :value="dadosContratoPlano.cnpj"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Registro do plano ANS (SIB)"
          data-test="text-field-registro-plano-ans-sib"
          :value="dadosContratoPlano.registroProdutoAns"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <re-popover-text-field
          v-bind="{ dataOnlyView }"
          label="Nome do plano ANS"
          element-ref="nome-plano-ans"
          data-test="text-field-nome-plano"
          :value="dadosContratoPlano.nomeProdutoAns"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Tipo de plano"
          data-test="text-field-tipo-plano"
          :value="dadosContratoPlano.tipoProduto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Data de vigência do contrato"
          data-test="text-field-vigência do contrato"
          :value="dadosContratoPlano.vigencia"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          placeholder="00/00/0000"
          label="Data de término da carência"
          data-test="text-field-data-termino-carencia"
          :value="dadosContratoPlano.dataCarencia"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          placeholder="00/00/0000"
          label="Data de admissão na empresa"
          data-test="text-field-data-admissao"
          :value="dadosContratoPlano.dataAdmissao"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          placeholder="00/00/0000"
          label="Data de adesão ao plano"
          data-test="text-field-data-adesao"
          :value="dadosContratoPlano.dataAdesao"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          placeholder="00/00/0000"
          label="Data de exclusão"
          data-test="text-field-data-exclusao"
          :value="dadosContratoPlano.dataExclusao"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          placeholder="00/00/0000"
          label="Data de reinclusão"
          data-test="text-field-data-reinclusao"
          :value="dadosContratoPlano.dataReinclusao"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          placeholder="00/00/0000"
          label="Data de término da CPT"
          data-test="text-field-data-termino-cpt"
          :value="dadosContratoPlano.dataTerminoCpt"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          placeholder="00/00/0000"
          label="Formação do preço"
          data-test="text-field-formacao-preco"
          :value="dadosContratoPlano.formacaoPreco"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Tipo de contratação"
          data-test="text-field-tipo-contratacao"
          :value="dadosContratoPlano.tipoContratacao"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Segmentação"
          data-test="text-field-segmentacao"
          :value="dadosContratoPlano.segmentacao"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          persistent-placeholder
          hide-details
          label="Coparticipação"
          data-test="text-field-coparticipacao"
          :value="dadosContratoPlano.coparticipacao"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { RePopoverTextField } from '@/components';
import MixinControlTabs from './mixin-control-tabs';

export default {
  name: 'TabContratoPlano',
  mixins: [MixinControlTabs],
  components: { RePopoverTextField },
  data() {
    return {
      tab: 'CONTRATO',
      controlState: false,
    };
  },
  computed: {
    ...mapGetters('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'dadosContratoPlano',
    ]),
  },
};
</script>
