const headers = [
  { text: '', class: 'p-0', cellClass: 'pa-0', width: 0, sortable: false },
  { text: 'SIGLA', value: 'siglaEvento' },
  { text: 'SIGLA DO ESTORNO ', value: 'siglaEventoEstorno' },
  { text: 'QUANDO É PROCESSADO?', value: 'processarEm', width: 217 },
  { text: 'DEDUTÍVEL', value: 'dedutivel', width: 120 },
  { text: 'ESTORNA EVENTO ANTERIOR', value: 'estornaEventoAnterior', width: 234 },
  { text: 'FÓRMULA DO VALOR', value: 'formulaValorFormatado' },
  {
    text: 'EDITAR',
    value: 'editar',
    align: 'center',
    width: 80,
    sortable: false,
  },
  {
    text: 'EXCLUIR',
    value: 'excluir',
    align: 'center',
    width: 80,
    sortable: false,
  },
];

const enumProcessamento = {
  RECEBIMENTO_ABI: 'Recebimento ABI',
  CANCELAMENTO_ABI: 'Cancelamento ABI',
  CANCELAMENTO_ATENDIMENTO: 'Cancelamento atendimento',
  AGUARDANDO_COBRANCA: 'Aguardando cobrança',
  PROCESSO_CONCLUIDO: 'Processo concluído',
  RECEBIMENTO_GRU: 'Recebimento GRU',
  ALEGACAO_ANS: 'Alegação ANS',
  DEFERIDO_PARCIAL: 'Deferido parcial',
  DEFERIDO_TOTAL: 'Deferido Total',
  INDEFERIDO: 'Indeferido',
};

const enumBoolean = {
  SIM: 'Sim',
  NAO: 'Não',
};

const tiposProcessamentos = [
  { id: 1, descricao: 'Recebimento ABI', value: 'RECEBIMENTO_ABI' },
  { id: 2, descricao: 'Cancelamento ABI', value: 'CANCELAMENTO_ABI' },
  { id: 3, descricao: 'Cancelamento atendimento', value: 'CANCELAMENTO_ATENDIMENTO' },
  { id: 4, descricao: 'Aguardando cobrança', value: 'AGUARDANDO_COBRANCA' },
  { id: 5, descricao: 'Processo concluído', value: 'PROCESSO_CONCLUIDO' },
  { id: 6, descricao: 'Recebimento GRU', value: 'RECEBIMENTO_GRU' },
  { id: 7, descricao: 'Alegação ANS', value: 'ALEGACAO_ANS' },
  { id: 8, descricao: 'Deferido Parcial', value: 'DEFERIDO_PARCIAL' },
  { id: 9, descricao: 'Deferido Total', value: 'DEFERIDO_TOTAL' },
  { id: 10, descricao: 'Indeferido', value: 'INDEFERIDO' },
];

const operandos = [
  {
    value: '(',
    class: 'border border-neutral-gray-disabled',
  },
  {
    value: ')',
    class: 'border border-neutral-gray-disabled',
  },
  {
    value: '.',
    class: 'border border-neutral-gray-disabled',
  },
  {
    value: '+',
    class: 'border border-neutral-gray-disabled',
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: '-',
    class: 'border border-neutral-gray-disabled',
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: '/',
    class: 'border border-neutral-gray-disabled',
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: '*',
    class: 'border border-neutral-gray-disabled',
  },
  {
    value: 0,
    class: 'col-span-1 col-start-2',
  },
];

export { headers, enumProcessamento, enumBoolean, tiposProcessamentos, operandos };
