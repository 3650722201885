import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import ListarGruposTrabalho from './queries/listar-grupo-trabalho';

const getGruposTrabalho = (queryParams) => createGraphQLService(ListarGruposTrabalho(queryParams));

const getGrupoTrabalhoById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/grupo_trabalho/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createGrupoTrabalhoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/grupo_trabalho`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateGrupoTrabalhoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/grupo_trabalho/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteGrupoTrabalhoService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/grupo_trabalho/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const findAllUsuariosService = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/usuario_grupo/usuarios`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getGruposTrabalho,
  getGrupoTrabalhoById,
  createGrupoTrabalhoService,
  updateGrupoTrabalhoService,
  deleteGrupoTrabalhoService,
  findAllUsuariosService,
};
