import { isUndefined } from 'lodash';
import { enumTabs, pagination } from '@/constants';

export default ({
  page = pagination.page,
  size = pagination.size,
  sort = 'id',
  tab,
  pesquisar = '',
}) => `{
  listarAlegacaoAns(
    page: ${page - 1}
    size: ${size}
    sort: ["${sort}"]
    filter : { 
      ${isUndefined(tab) ? `` : `ativo: ${enumTabs[tab]}`}      
      pesquisar: "${pesquisar}"
    }) {
    content {
      id
			codigo
      tipo
			ativo
			descricao
			processoAbi
    }
    totalPages
    totalElements
    size
    first
    last
  }
}`;
