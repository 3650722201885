<template>
  <div class="pr-7">
    <re-placeholder-tabs
      v-if="!dadosDecisao.length"
      :config-tabs="tabsDrawer"
      :tab-index="tabIndex"
    />
    <div v-else>
      <re-collapse
        v-for="(item, index) in dadosDecisao"
        :key="index"
        :title="instanciasDecisao[item.instancia].title"
      >
        <template #content>
          <v-form>
            <v-row>
              <v-col cols="12" sm="4">
                <v-select
                  attach
                  persistent-placeholder
                  label="Alegação ANS"
                  item-text="text"
                  item-value="value"
                  :items="tiposAlegacaoAns"
                  :value="item.tipoAlegacaoAns"
                  :readonly="isReadonly(index) || isFormularioAtendimento"
                  :append-icon="isReadonly(index) || isFormularioAtendimento ? '' : 'mdi-menu-down'"
                  :menu-props="{ offsetY: true }"
                  @change="
                    (value) =>
                      updateFieldDecisao({
                        field: 'tipoAlegacaoAns',
                        item,
                        value: value || null,
                      })
                  "
                />
              </v-col>
              <v-col cols="12" sm="4">
                <re-popover-text-field
                  v-bind="{ dataOnlyView }"
                  label="Opinião ANS"
                  breakpoint="sm"
                  nudge-left="160"
                  :element-ref="`opiniao-ans-${index}`"
                  :data-test="`text-field-opiniao-ans-${index}`"
                  :value="item.opiniao"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  readonly
                  persistent-placeholder
                  hide-details
                  label="Ofício"
                  :data-test="`text-field-oficio-${index}`"
                  :value="item.numeroOficio"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  readonly
                  persistent-placeholder
                  hide-details
                  label="Decisão recebida em"
                  :data-test="`text-field-decisao-recebida-${index}`"
                  :value="item.dataRecebimento"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-if="!loading"
                  v-money="money"
                  persistent-placeholder
                  hide-details
                  maxlength="18"
                  prefix="R$"
                  label="Dedução deferida R$"
                  data-test="text-field-deducao-deferida"
                  :readonly="isEncerrado || isFormularioAtendimento"
                  :value="item.valorDeducaoDeferida"
                  @keyup="
                    updateFieldDecisao({
                      field: 'valorDeducaoDeferida',
                      item,
                      value: $event.target.value,
                    })
                  "
                  @keydown="onPreventNegativeNumbers"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <h1 class="section-title-default">Pareceres</h1>
              </v-col>
              <v-col cols="12">
                <v-card flat class="border-primary-blue-base p-3 text-primary-blue-base">
                  <span v-if="!item.pareceres.length">Nenhum parecer encontrado</span>
                  <div v-else>
                    <p
                      v-for="(parecer, index) in item.pareceres"
                      class="last:mb-0 whitespace-pre-line"
                      :key="index"
                    >
                      {{ parecer.descricao }}
                    </p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <h1 class="section-title-default">Observações</h1>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  auto-grow
                  hide-details
                  maxlength="250"
                  placeholder="Preenchimento opcional"
                  :data-test="`textarea-observacoes-${index}`"
                  :value="item.observacoes"
                  :readonly="isFormularioAtendimento"
                  @input="
                    (value) =>
                      updateFieldDecisao({
                        field: 'observacoes',
                        item,
                        value: value || null,
                      })
                  "
                />
              </v-col>
            </v-row>
          </v-form>
        </template>
      </re-collapse>
    </div>
    <portal v-if="showFooterActions" to="footer-drawer-atendimento">
      <v-btn
        v-for="action in footerActions"
        :key="action.key"
        :outlined="action.outlined"
        :text="action.text"
        :class="action.class"
        :data-test="`btn-${action.key}`"
        @click="handleClick(action)"
      >
        {{ action.label }}
      </v-btn>
    </portal>
    <ui-modal
      type="salvar"
      :show="showModalAtencao"
      @close="onClose"
      @exit="onExit"
      @save="onSaveDadosDecisao"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { VMoney } from 'v-money';
import { RePlaceholderTabs, ReCollapse, RePopoverTextField, UiModal } from '@/components';
import { MASKS } from '@/constants';
import MixinControlTabs from './mixin-control-tabs';
import { instanciasDecisao } from '../../constants';
import { tiposAlegacaoAns } from './constants';

export default {
  name: 'TabDecisao',
  directives: { money: VMoney },
  mixins: [MixinControlTabs],
  components: { RePlaceholderTabs, ReCollapse, RePopoverTextField, UiModal },
  data() {
    return {
      instanciasDecisao,
      tab: 'DECISAO',
      controlState: true,
      money: MASKS.currency,
      tiposAlegacaoAns,
    };
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'atendimento',
      'loading',
      'payloadDadosGerais',
    ]),
    ...mapGetters('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'dadosDecisao',
      'isInitialStateDadosDecisao',
    ]),
    isInitialState() {
      return this.isInitialStateDadosDecisao;
    },
    showFooterActions() {
      return this.activeTab === this.tab && this.dadosDecisao.length;
    },
  },
  watch: {
    tabIndex: {
      immediate: true,
      handler() {
        this.onLoadDadosDecisao();
      },
    },
    'atendimento.id': {
      immediate: true,
      handler() {
        this.onLoadDadosDecisao();
      },
    },
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'loadDadosDecisao',
      'updateFieldDecisao',
      'updateDadosDecisao',
      'resetDadosDecisao',
    ]),
    onLoadDadosDecisao() {
      if (this.activeTab === this.tab) {
        this.isInitialState && this.loadDadosDecisao(this.atendimento);
      }
    },
    async onClose() {
      if (this.sourceProperty === 'tabIndex') {
        await this.updateTabIndex(this.sourceTab);
      }

      this.setActiveClass();
      this.showModalAtencao = false;
    },
    onExit() {
      if (this.sourceProperty === 'tabIndex') {
        this.showModalAtencao = false;

        this.resetDadosDecisao();
        this.loadDadosDecisao(this.atendimento);
        this.updateActiveTab(this.targetTab);
      } else {
        this.closeDrawerAtendimentos();
      }

      this.setActiveClass();
    },
    async onSaveDadosDecisao() {
      if (this.sourceProperty === 'tabIndex') {
        await this.updateTabIndex(this.sourceTab);
        this.setActiveClass();
      }

      this.showModalAtencao = false;
      this.updateDadosDecisao();
    },
    onPreventNegativeNumbers(event) {
      return event.key === '-' ? event.preventDefault() : null;
    },
    isReadonly(index) {
      const readonlyStatus = ['ENCERRADO', 'CANCELADO_PELA_ANS'];

      return (
        readonlyStatus.includes(this.payloadDadosGerais?.situacaoProcesso) ||
        this.dadosDecisao.length - 1 !== index
      );
    },
  },
};
</script>
