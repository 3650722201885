import { isEqual } from 'lodash';
import { competencyFullDate } from '@/utils/date';

const imprimirInfos = ({ dadosRelatorio }) => {
  const informacoesAtivas = {};

  Object.keys(dadosRelatorio.imprimir).forEach((key) => {
    informacoesAtivas[key] = dadosRelatorio.imprimir[key].checked;
  });

  return informacoesAtivas;
};

const payloadDadosRelatorio = ({ dadosRelatorio }) => ({
  abiId: dadosRelatorio?.idAbi,
  motivosId: dadosRelatorio?.motivosId.map((motivo) => +motivo.id),
  alegacaoAns: dadosRelatorio?.alegacaoAns.map((alegacao) => alegacao.tipo),
  situacao: dadosRelatorio?.situacao.map(({ situacao }) => situacao),
  competencia:
    dadosRelatorio.competenciaInicial && dadosRelatorio.competenciaFinal
      ? [
          `"${competencyFullDate(dadosRelatorio?.competenciaInicial)}"`,
          `"${competencyFullDate(dadosRelatorio?.competenciaFinal)}"`,
        ]
      : null,
  numeroAtendimento: dadosRelatorio?.numeroAtendimento,
  codigoProcedimento: dadosRelatorio?.codigoProcedimento,
  codigoBeneficiario: dadosRelatorio?.codigoBeneficiario,
  descricaoProcedimento: dadosRelatorio?.descricaoProcedimento,
  gru: dadosRelatorio?.gru,
  numeroOficio: dadosRelatorio?.numeroOficio,
  nomeUnidade: dadosRelatorio?.nomeUnidade,
  codigoUnidade: dadosRelatorio?.codigoUnidade,
  codigoEmpresa: dadosRelatorio?.codigoEmpresa,
  contrato: dadosRelatorio?.contrato,
});

const motivos = ({ motivos }) =>
  motivos.map((motivo) => ({
    ...motivo,
    descricao: motivo.descricaoCompleta,
  }));

const isInitialState = (state) => isEqual(state.initialState, state.dadosRelatorio);

export default {
  payloadDadosRelatorio,
  isInitialState,
  motivos,
  imprimirInfos,
};
