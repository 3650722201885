import VueRouter from 'vue-router';

export default {
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      lastActiveTab: null,
      search: '',
    };
  },
  computed: {
    urlQueries() {
      return {
        page: +this.$route.query.page || 1,
        size: +this.$route.query.size || 10,
        sort: this.$route.query.sort || 'id',
        tab: this.$route.query.tab,
      };
    },
  },
  methods: {
    onSortQuery() {
      const sortArray = this.$route.query?.sort?.split(',') || [];

      [this.sortBy] = sortArray;

      this.sortDesc = sortArray?.length > 1;
    },
    onClickTab(activeTab) {
      if (this.lastActiveTab === activeTab) return;

      this.lastActiveTab = activeTab;
      this.onReplaceQuery({ page: 1, tab: activeTab });
    },
    onChangePage(page) {
      this.onReplaceQuery({ page });
    },
    onChangeItemsPerPage(size) {
      this.onReplaceQuery({ size });
    },
    onSortBy(sort) {
      this.onReplaceQuery({ sort });
    },
    onSortDesc(desc) {
      desc && this.onReplaceQuery({ sort: `${this.$route.query.sort},desc` });
    },
    onReplaceQuery(queryToReplace) {
      this.$router
        .replace({
          name: this.routeName,
          query: {
            ...this.$route.query,
            ...queryToReplace,
          },
          params: {
            ...this.$route.params,
          },
        })
        .catch((error) => {
          if (!VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.duplicated)) {
            throw error;
          }
        });

      this.onLoad();
    },
    onSearch() {
      if (/^[ \t]+|[ \t]+$/.test(this.search)) return;

      this.onChangePage(1);
    },
    onClearSearch() {
      this.search = '';

      this.onSearch();
    },
  },
};
