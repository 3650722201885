const forms = {
  siglaEvento: null,
  siglaEventoEstorno: null,
  processarEm: 'RECEBIMENTO_ABI',
  dedutivel: true,
  estornaEventoAnterior: true,
  formulaValor: null,
};

export default () => ({
  eventosContabeis: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingForm: false,
  camposFormula: [],
  eventoContabil: { ...forms },
  initialState: { ...forms },
});
