var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',_vm._g(_vm._b({staticClass:"elevation-0",class:{ 'border-box-none': !_vm.items.length },attrs:{"no-data-text":"Nenhum registro encontrado","loading-text":"Carregando registros...","show-select":_vm.showSelect,"item-key":_vm.itemKey,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.size,"page":_vm.page,"server-items-length":_vm.totalElements,"loading":_vm.loading,"hide-default-footer":!_vm.items.length,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
    itemsPerPageText: 'Linhas por página:',
    itemsPerPageAllText: 'Todos',
    itemsPerPageOptions: [10, 20, 50, 100],
    pageText: '{0}-{1} de {2}',
    showFirstLastPage: true,
    firstIcon: 'mdi-chevron-double-left',
    lastIcon: 'mdi-chevron-double-right',
  }},on:{"update:sort-by":function($event){return _vm.$emit('onSortBy', $event)},"update:sort-desc":function($event){return _vm.$emit('onSortDesc', $event)},"update:page":function($event){return _vm.$emit('onChangePage', $event)},"update:items-per-page":function($event){return _vm.$emit('onChangeItemsPerPage', $event)},"item-selected":function($event){return _vm.$emit('onItemSelected', $event)},"toggle-select-all":function($event){return _vm.$emit('onToggleSelectAll', $event)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }