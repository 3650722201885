<template>
  <div class="text-xs leading-6 text-primary-blue-base">
    {{ placeholderMessage }}
  </div>
</template>

<script>
export default {
  name: 'RePlaceholderTabs',
  props: {
    configTabs: {
      type: Array,
      default: () => [],
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    placeholderMessage() {
      return this.configTabs[this.tabIndex].placeholder;
    },
  },
};
</script>
