import { TYPE } from 'vue-toastification';

const toastOptions = {
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  transition: 'Vue-Toastification__fade',
  toastDefaults: {
    [TYPE.SUCCESS]: {
      icon: {
        iconClass: 'material-symbols-outlined',
        iconChildren: 'check_circle',
        iconTag: 'span',
      },
    },
    [TYPE.WARNING]: {
      icon: {
        iconClass: 'material-symbols-outlined',
        iconChildren: 'warning ',
        iconTag: 'span',
      },
    },
    [TYPE.INFO]: {
      icon: {
        iconClass: 'material-symbols-outlined',
        iconChildren: 'notifications',
        iconTag: 'span',
      },
    },
    [TYPE.ERROR]: {
      icon: {
        iconClass: 'material-symbols-outlined',
        iconChildren: 'report',
        iconTag: 'span',
      },
    },
  },
};

export default toastOptions;
