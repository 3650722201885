import { currentDate } from '@/utils/date';

const instanciasDecisao = {
  SEM_IMPUGNACAO: {
    title: 'Não impugnados',
    component: 'NaoImpugnado',
    tabRedirect: 'AGUARDANDO_COBRANCA',
    order: 0,
  },
  PRIMEIRA_INSTANCIA: {
    title: '1ª Instância',
    component: 'PrimeiraInstancia',
    tabRedirect: 'EM_PROCESSO',
    order: 1,
  },
  PRIMEIRA_INSTANCIA_RECONSIDERACAO: {
    title: '1ª Instância reconsideração',
    component: 'PrimeiraInstancia',
    tabRedirect: 'EM_PROCESSO',
    order: 2,
  },
  SEM_RECURSAO: {
    title: 'Não recursados',
    component: 'NaoRecursado',
    tabRedirect: 'AGUARDANDO_COBRANCA',
    order: 3,
  },
  SEGUNDA_INSTANCIA: {
    title: '2ª Instância',
    component: 'SegundaInstancia',
    tabRedirect: 'AGUARDANDO_COBRANCA',
    order: 4,
  },
  SEGUNDA_INSTANCIA_RECONSIDERACAO: {
    title: '2ª Instância reconsideração',
    component: 'SegundaInstancia',
    tabRedirect: 'AGUARDANDO_COBRANCA',
    order: 5,
  },
};

const formUploadDocumento = {
  tipo: null,
  data: currentDate(),
  assunto: '',
  anexo: null,
  errorMessages: [],
};

export { instanciasDecisao, formUploadDocumento };
