import { isEqual } from 'lodash';
import omitByAndNil from '@/utils/compare-values';

const payload = ({ grupoTrabalho }) => grupoTrabalho;

const payloadToSaveAndUpdate = ({ grupoTrabalho }) => ({
  ...grupoTrabalho,
  usuariosIds: grupoTrabalho?.usuarios?.map((usuario) => usuario.usuarioId || usuario.id),
});

const gruposTrabalhoTable = ({ gruposTrabalho }) =>
  gruposTrabalho?.map((grupo) => ({
    ...grupo,
    quantidade: grupo?.usuarios?.length,
    status: grupo?.ativo ? 'ATIVO' : 'INATIVO',
  }));

const isInitialState = ({ initialState }, { payload }) =>
  isEqual(initialState, payload.nome ? omitByAndNil(payload) : payload);

export default {
  payload,
  payloadToSaveAndUpdate,
  gruposTrabalhoTable,
  isInitialState,
};
