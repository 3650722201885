import Vue from 'vue';
import omitByAndNil from '@/utils/compare-values';
import {
  getDetalhamentoMotivo,
  getDetalhamentoMotivoById,
  getMotivos,
  createDetalhamentoMotivoService,
  updateDetalhamentoMotivoService,
  deleteDetalhamentoMotivoService,
} from '../services';
import redirectRouteUpdate from '../../utils';

const loadDetalhamentoMotivo = async ({ commit }, query) => {
  commit('RESET_STATE');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const { payload } = await getDetalhamentoMotivo(query);

  commit('GENERIC_MUTATION', {
    property: 'detalhamentoMotivos',
    payload: payload?.listarDetalhamentoMotivo?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarDetalhamentoMotivo?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarDetalhamentoMotivo?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadDetalhamentoMotivoById = async ({ commit, dispatch }, id) => {
  dispatch('setLoadingForm', true);
  dispatch('ressus/updateControlModalAtencao', true, { root: true });

  await getDetalhamentoMotivoById({
    id,
    onSuccess: async ({ data }) => {
      await dispatch('loadMotivos');

      commit('GENERIC_MUTATION', {
        property: 'detalhamentoMotivo',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: omitByAndNil(data),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const loadMotivos = async ({ commit, dispatch }) => {
  dispatch('setLoadingForm', true);

  const { data } = await getMotivos();

  commit('GENERIC_MUTATION', {
    property: 'allMotivos',
    payload: data,
  });

  dispatch('setLoadingForm', false);
};

const createDetalhamentoMotivo = (
  { getters, dispatch, commit },
  { saveAndNew, resetValidation },
) => {
  dispatch('setLoadingForm', true);

  const { payload } = getters;

  createDetalhamentoMotivoService({
    payload,
    onSuccess: async () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      !saveAndNew && Vue.router.push({ name: 'detalhamento-motivo' });

      Vue.$toast('Detalhamento incluído com sucesso.', { type: 'success' });

      resetValidation();
      await commit('RESET_STATE');

      dispatch('loadMotivos');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
      dispatch('setLoadingForm', false);
    },
  });
};

const updateDetalhamentoMotivo = (
  { getters, dispatch, commit },
  { saveAndNew, resetValidation, route },
) => {
  dispatch('setLoadingForm', true);

  const { payload } = getters;

  updateDetalhamentoMotivoService({
    payload,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      redirectRouteUpdate(saveAndNew, 'create-detalhamento-motivo', route);
      Vue.$toast('Detalhamento alterado com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteDetalhamentoMotivo = ({ state, commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  deleteDetalhamentoMotivoService({
    id,
    onSuccess: () => {
      Vue.$toast('Detalhamento excluído com sucesso.', { type: 'success' });

      if (state.detalhamentoMotivos.length === 1) {
        Vue.router.replace({
          name: 'detalhamento-motivo',
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }

      dispatch('loadDetalhamentoMotivo', query);
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoadingForm = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: value,
  });

export default {
  loadDetalhamentoMotivo,
  loadDetalhamentoMotivoById,
  createDetalhamentoMotivo,
  updateDetalhamentoMotivo,
  deleteDetalhamentoMotivo,
  updateField,
  resetState,
  loadMotivos,
  setLoadingForm,
};
