import Vue from 'vue';
import initialState from './state';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  RESET_STATE_FILTERS(state) {
    Object.assign(state.filters, state.initialState);
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD(state, { object, property, value }) {
    Vue.set(object ? state.oficioNotificado[object] : state.oficioNotificado, property, value);
  },
  UPDATE_OFICIO_NOTIFICADO(state, { property, value, item }) {
    state.oficiosNotificacao = state.oficiosNotificacao.map((oficio) => ({
      ...oficio,
      [property]: +oficio.id === item.id ? value : oficio[property],
    }));
  },
};
