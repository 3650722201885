import alegacaoAns from './alegacao-ans/routes';
import caraterAtendimento from './carater-atendimento/routes';
import detalhamentoMotivo from './detalhamento-motivo/routes';
import empresa from './empresa/routes';
import motivoImpugnacao from './motivo-impugnacao/routes';
import eventoContabil from './evento-contabil/routes';
import grupoTrabalho from './grupo-trabalho/routes';

export default [
  ...alegacaoAns,
  ...caraterAtendimento,
  ...detalhamentoMotivo,
  ...empresa,
  ...motivoImpugnacao,
  ...eventoContabil,
  ...grupoTrabalho,
];
