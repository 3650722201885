import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { getParametros, updateParametrosService } from '../services';

const loadParametros = async ({ commit, dispatch }) => {
  dispatch('setLoading');

  await getParametros({
    type: 'INTEGRACAO',
    onSuccess: async ({ data }) => {
      await commit('GENERIC_MUTATION', {
        property: 'parametros',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: cloneDeep(data),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const loadParametrosInternos = ({ commit }) => {
  getParametros({
    type: 'INTERNO',
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'parametrosInternos',
        payload: data,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
  });
};

const updateParametros = ({ getters, dispatch }) => {
  dispatch('setLoading');

  updateParametrosService({
    payload: getters.payloadParametros,
    onSuccess: () => {
      Vue.$toast('Parâmetros atualizados com sucesso!', { type: 'success' });

      dispatch('loadParametros');
    },
    onCustomError: ({ response }) => {
      dispatch('setLoading', false);

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

export default {
  loadParametros,
  loadParametrosInternos,
  updateParametros,
  resetState,
  setLoading,
};
