import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import VTooltip from 'v-tooltip';
import PortalVue from 'portal-vue';
import { UserAppPermissionPlugin, UserPlugin } from '@zitrus/utils-service';
import vuetify from '@/plugins/vuetify';
import loader from 'vue-ui-preloader';
import Toast from 'vue-toastification';
import toastOptions from '@/plugins/toastOptions';
import axios from './services/instanceAxios';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(VueMask);
Vue.use(VTooltip);
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(router);
Vue.use(loader);
Vue.use(UserPlugin);
Vue.use(UserAppPermissionPlugin, { router });
Vue.use(Toast, toastOptions);

Vue.router = router;

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
