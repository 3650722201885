import { helpPages } from '@/constants';

export default [
  {
    path: '/relatorio-atendimentos',
    name: 'relatorio-atendimentos',
    component: () =>
      import(
        /* webpackChunkName: "relatorio-atendimentos" */
        './index.vue'
      ),
    meta: {
      title: 'Relatórios',
      subtitle: 'Atendimentos',
      helpPage: helpPages['relatorio-atendimentos'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
