import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import ListarFormularioPadraoEmpresa from './queries/listar-formulario-padrao-empresa';

const getFormularioPadraoById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/formulario_padrao/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createFormularioService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/formulario_padrao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateFormularioService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/formulario_padrao/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteFormService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/formulario_padrao/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getFormulariosPadraoEmpresa = async (queryParams) =>
  createGraphQLService(ListarFormularioPadraoEmpresa(queryParams));

export {
  createFormularioService,
  deleteFormService,
  getFormularioPadraoById,
  getFormulariosPadraoEmpresa,
  updateFormularioService,
};
