import Vue from 'vue';
import { cloneDeep } from 'lodash';
import initialState from './state';

export default {
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  SET_DADOS_GERAL(state, value) {
    state.payloadDadosGerais = cloneDeep(value);
    state.initialStateDadosGeral = cloneDeep(value);
  },
  SET_MOTIVOS_DADOS_GERAL(state, value) {
    state.payloadDadosGerais.motivosImpugnacao = cloneDeep(value);
  },
  RESET_DADOS_GERAL(state) {
    state.payloadDadosGerais = {
      id: state.atendimento.id,
      situacaoProcesso: state.atendimento.situacaoProcesso,
      motivosImpugnacao: state.motivosImpugnacao,
    };
  },
  SET_DADOS_PROCEDIMENTO(state, value) {
    state.payloadDadosProcedimentos = cloneDeep(value);
    state.initialStateDadosProcedimento = cloneDeep(value);
  },
  RESET_DADOS_PROCEDIMENTO(state) {
    state.atendimento.valorDeducao = state.initialStateDadosProcedimento.valorDeducao;
  },
  RESET_DADOS_DECISAO(state) {
    state.atendimentosDecididos = [];
    state.initialStateAtendimentosDecididos = [];
  },
  UPDATE_FIELD_DECISAO(state, { field, item, value }) {
    state.atendimentosDecididos = state.atendimentosDecididos.map((atendimento) => ({
      ...atendimento,
      [field]:
        atendimento.idAtendimento === item.idAtendimento && atendimento.instancia === item.instancia
          ? value
          : atendimento[field],
    }));
  },
  UPDATE_SITUACAO_PROCESSO(state, { value }) {
    Vue.set(state.payloadDadosGerais, 'situacaoProcesso', value);
  },
  UPDATE_VALOR_DEDUCAO(state, value) {
    Vue.set(state.atendimento, 'valorDeducao', value || null);
  },
};
