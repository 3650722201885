import { isUndefined } from 'lodash';
import { enumTabs, pagination } from '@/constants';

export default ({
  page = pagination.page,
  size = pagination.size,
  sort = 'id',
  tab,
  pesquisar = '',
}) => `{
  listarGrupoTrabalho(
    page: ${page - 1}
    size: ${size}
    sort: ["${sort}"]
    filter : { 
      ${isUndefined(tab) ? `` : `ativo: ${enumTabs[tab]}`}      
      pesquisar: "${pesquisar}"
    }) {
    content {
      id
      nome
			notificarIncorporacaoCusto
			ativo
      usuarios {
        usuarioId
      }
    }
    totalPages
    totalElements
    size
    first
    last
  }
}`;
