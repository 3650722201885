import { pagination } from '@/constants';

export default ({ page = pagination.page, size = pagination.size, sort = 'id', empresaId }) => `{
  listarFormularioPadraoPorEmpresa(
    page: ${page - 1},
    size: ${size},
    empresaId: ${empresaId}
    sort: ["${sort}"]) {
    content {
			id
			contrato
			motivoImpugnacao {
				id
				codigo
        ativo
				descricaoAbreviada
				motivoOficialAns {
					id
					codigo
				}
			}
		}
    totalPages
    totalElements
    size
    first
    last
  }
}`;
