const configProtocolo = {
  key: 'PROTOCOLO',
  label: 'Protocolar na ANS',
  items: [
    {
      key: 'TODOS',
      label: 'Todos os atendimentos',
      event: 'protocolar',
      method: 'onClickProtocolarTodos',
    },
    {
      key: 'SELECIONADOS',
      label: 'Selecionados',
      event: 'protocolar',
      method: 'onClickProtocolarSelecionados',
    },
  ],
};

const configAcoes = {
  key: 'ACOES',
  label: 'Ações',
  items: [
    {
      key: 'CONTESTAR',
      label: 'Contestar',
      event: 'contestar',
      method: 'onClickContestarTodos',
    },
    {
      key: 'EXCLUIR_DATA_CONTESTACAO',
      label: 'Excluir data de contestação',
      event: 'excluir-data-contestacao',
      method: 'onClickExcluirDataContestacaoTodos',
    },
  ],
};

const tabs = [
  {
    id: 0,
    title: 'Impugnação',
    value: 'IMPUGNACAO',
    headerActions: [configProtocolo, configAcoes],
  },
  {
    id: 1,
    title: 'Recurso',
    value: 'RECURSAO',
    headerActions: [configProtocolo, configAcoes],
  },
];

const headers = [
  {
    text: '',
    value: 'statusProtocolo',
    cellClass: 'pa-0',
    sortable: true,
  },
  { text: '', value: 'contagem', width: 42, sortable: true },
  { text: 'TIPO', value: 'tipo', sortable: true, width: 58 },
  { text: 'MOTIVO', value: 'motivos', sortable: false, width: 170 },
  { text: 'Nº ATENDIMENTO', value: 'numero', sortable: true, width: 150 },
  { text: 'BENEFICIÁRIO', value: 'beneficiario', sortable: false },
  { text: 'CCO', value: 'beneficiario.codigoCco', sortable: true },
  { text: 'VALOR TOTAL', value: 'valorTotal', sortable: true, width: 150 },
  { text: 'PERÍODO ATENDIMENTO', value: 'periodoAtendimento', sortable: false },
  { text: 'DOCUMENTOS', value: 'formulario.quantidadeDocumentosComprobatorios', sortable: false },
  {
    text: 'PROTOCOLO ANS',
    value: 'formulario.numeroProtocoloConcluido',
    sortable: false,
  },
  { text: 'DATA DO PROTOCOLO', value: 'formulario.dataAtualizacaoAns', sortable: false },
  { text: 'DATA DE CONTESTAÇÃO', value: 'formulario.dataContestacao', sortable: false },
  { text: 'VER', value: 'ver', align: 'center', width: 86, sortable: false },
  {
    text: '',
    value: 'data-table-select',
    sortable: false,
    width: 50,
    align: 'center',
  },
];

const configStatus = {
  NAO_PROTOCOLADO: {
    content: 'Não protocolado',
    color: 'bg-neutral-gray-disabled',
  },
  REENVIAR_PARA_ANS: {
    content: 'Reenviar para ANS',
    color: 'bg-neutral-gray-disabled',
  },
  PROTOCOLADO: {
    content: 'Protocolado',
    color: 'bg-feedback-blue-high',
  },
  PENDENTE: {
    content: 'Pendente',
    color: 'bg-feedback-yellow',
  },
  FINALIZADO: {
    content: 'Finalizado',
    color: 'bg-feedback-green-high',
  },
};

const configIconDocumentos = {
  class: 'text-feedback-red-high pl-3',
  size: '18',
  icon: 'mdi-alert-circle',
};

const configIconProtocoloAns = {
  class: 'text-feedback-red-high',
  size: '18',
  icon: 'mdi-alert-circle',
};

const defaultFilters = {
  statusProtocolo: null,
  contagem: null,
  tipo: null,
  motivoImpugnacao: null,
  numero: null,
  nomeCompleto: null,
  codigoCco: null,
  valorTotalInicial: null,
  valorTotalFinal: null,
  periodoInicial: null,
  periodoFinal: null,
  protocoloAns: null,
  dataProtocoloInicial: null,
  dataProtocoloFinal: null,
  dataContestacaoInicial: null,
  dataContestacaoFinal: null,
};

const itemsFilter = [
  {
    id: 1,
    title: 'Status',
    component: 'CheckboxItems',
    props: {
      label: 'Status',
      property: 'statusProtocolo',
      items: [
        { key: 1, label: 'Não protocolado', value: 'NAO_PROTOCOLADO' },
        { key: 2, label: 'Reenviar para ANS', value: 'REENVIAR_PARA_ANS' },
        { key: 3, label: 'Protocolado', value: 'PROTOCOLADO' },
        { key: 4, label: 'Pendente', value: 'PENDENTE' },
        { key: 5, label: 'Finalizado', value: 'FINALIZADO' },
      ],
    },
  },
  {
    id: 2,
    title: 'Contagem',
    component: 'InputData',
    props: {
      label: 'Contagem',
      property: 'contagem',
      type: 'number',
      maxLength: 18,
    },
  },
  {
    id: 3,
    title: 'Tipo',
    component: 'CheckboxItems',
    props: {
      label: 'Tipo',
      property: 'tipo',
      items: [
        { key: 1, label: 'APAC', value: 'APAC' },
        { key: 2, label: 'AIH', value: 'AIH' },
      ],
    },
  },
  {
    id: 4,
    title: 'Motivo',
    component: 'CheckboxItems',
    props: {
      label: 'Motivo',
      property: 'motivoImpugnacao',
      items: [],
    },
  },
  {
    id: 5,
    title: 'Número',
    component: 'InputData',
    props: {
      label: 'Número do atendimento',
      property: 'numero',
      type: 'number',
      maxLength: 13,
    },
  },
  {
    id: 6,
    title: 'Beneficiário',
    component: 'InputData',
    props: {
      label: 'Nome do beneficiário',
      property: 'nomeCompleto',
      type: 'text',
      maxLength: 120,
    },
  },
  {
    id: 7,
    title: 'CCO',
    component: 'InputData',
    props: {
      label: 'Código do CCO',
      property: 'codigoCco',
      type: 'number',
      maxLength: 12,
    },
  },
  {
    id: 8,
    title: 'Valor total',
    component: 'DecimalRange',
    props: {
      propertyInicial: 'valorTotalInicial',
      propertyFinal: 'valorTotalFinal',
    },
  },
  {
    id: 9,
    title: 'Período',
    component: 'DateRange',
    props: {
      propertyInicial: 'periodoInicial',
      propertyFinal: 'periodoFinal',
    },
  },
  {
    id: 10,
    title: 'Protocolo ANS',
    component: 'InputData',
    props: {
      label: 'Número do protocolo ANS',
      property: 'protocoloAns',
      type: 'number',
      maxLength: 22,
    },
  },
  {
    id: 11,
    title: 'Data do protocolo',
    component: 'DateRange',
    props: {
      propertyInicial: 'dataProtocoloInicial',
      propertyFinal: 'dataProtocoloFinal',
    },
  },
  {
    id: 12,
    title: 'Data de contestação',
    component: 'DateRange',
    props: {
      propertyInicial: 'dataContestacaoInicial',
      propertyFinal: 'dataContestacaoFinal',
    },
  },
];

export {
  headers,
  tabs,
  configStatus,
  configIconDocumentos,
  configIconProtocoloAns,
  defaultFilters,
  itemsFilter,
};
