const configStatus = {
  CONCLUIDO: {
    content: 'Pronto para baixar',
    color: 'primary',
    actionLabel: 'Baixar',
    actionMethod: 'onClickBaixar',
  },
  PENDENTE: {
    content: 'Pendente',
    color: 'bg-feedback-yellow',
    actionLabel: 'Baixar',
  },
  ERRO: {
    content: 'Falha na geração',
    color: 'bg-feedback-red-high',
    actionLabel: 'Reiniciar',
    actionMethod: 'onClickReiniciar',
  },
};

const headers = [
  {
    text: '',
    value: 'status',
    cellClass: 'pa-0',
    sortable: false,
  },
  {
    text: 'NOME DO ARQUIVO',
    value: 'nome',
    sortable: false,
    width: 453,
  },
  {
    text: 'USUÁRIO',
    value: 'nomeUsuario',
    sortable: false,
  },
  {
    text: 'LOCAL GERADO',
    value: 'localGerado',
    sortable: false,
  },
  {
    text: 'DATA DE EMISSÃO',
    value: 'dataSolicitacao',
    width: 200,
    sortable: false,
  },
  {
    text: 'AÇÕES',
    value: 'actions',
    width: 196,
    align: 'center',
    sortable: false,
  },
];

const tabsDrawer = [
  {
    id: 0,
    title: 'Filtros',
    icon: 'mdi-filter-cog-outline',
    value: 'FILTRO',
    component: 'TabFiltros',
  },
];

const defaultFilters = {
  id: null,
  status: null,
  nome: '',
  nomeUsuario: '',
  localGerado: '',
  dataInicial: '',
  dataFinal: '',
};

const itemsFilter = [
  {
    id: 1,
    title: 'Status',
    component: 'CheckboxItems',
    props: {
      label: 'Status',
      property: 'status',
      items: [
        { key: 1, label: 'Concluído', value: 'CONCLUIDO' },
        { key: 2, label: 'Pendente', value: 'PENDENTE' },
        { key: 3, label: 'Erro', value: 'ERRO' },
      ],
    },
  },
  {
    id: 2,
    title: 'Nome do arquivo',
    component: 'InputData',
    props: {
      label: 'Nome do arquivo',
      property: 'nome',
      type: 'text',
      maxLength: 50,
    },
  },
  {
    id: 3,
    title: 'Usuário',
    component: 'InputData',
    props: {
      label: 'Usuário',
      property: 'nomeUsuario',
      type: 'text',
      maxLength: 50,
    },
  },
  {
    id: 4,
    title: 'Local gerado',
    component: 'InputData',
    props: {
      label: 'Local gerado',
      property: 'localGerado',
      type: 'text',
      maxLength: 50,
    },
  },
  {
    id: 5,
    title: 'Data de emissão',
    component: 'DateRange',
    props: {
      propertyInicial: 'dataInicial',
      propertyFinal: 'dataFinal',
    },
  },
];

export { configStatus, headers, tabsDrawer, defaultFilters, itemsFilter };
