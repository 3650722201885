import { helpPages } from '@/constants';

export default [
  {
    path: '/contabilidade',
    name: 'contabilidade',
    component: () => import(/* webpackChunkName: "parametros-contabilidade" */ './index.vue'),
    meta: {
      title: 'Parâmetros',
      subtitle: 'Contabilidade',
      helpPage: helpPages.contabilidade,
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
