<template>
  <re-container show-back-button show-footer :loading="loadingForm" @back="goTo">
    <template #content>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="3">
            <v-text-field
              v-model="alegacaoAns.codigo"
              v-mask="'##'"
              persistent-placeholder
              label="Código"
              placeholder="00"
              data-test="text-field-codigo"
              :rules="[required]"
              :disabled="isEdit"
              @input="(value) => updateField({ property: 'codigo', value })"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="1">
            <v-switch
              inset
              data-test="switch-ativo-inativo"
              :label="ativoLabel"
              :input-value="alegacaoAns.ativo"
              @change="(value) => updateField({ property: 'ativo', value })"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="3">
            <v-select
              attach
              persistent-placeholder
              label="Tipo"
              placeholder="Selecionar"
              data-test="select-tipo"
              item-value="value"
              item-text="text"
              :menu-props="{ offsetY: true }"
              :items="tipos"
              :value="alegacaoAns.tipo"
              :rules="[required]"
              @change="(value) => updateField({ property: 'tipo', value })"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="5">
            <v-text-field
              persistent-placeholder
              class="text-area__overflow"
              maxlength="50"
              label="Descrição"
              placeholder="Preencher"
              data-test="textarea-descricao"
              :rules="[required]"
              :value="alegacaoAns.descricao"
              @input="(value) => updateField({ property: 'descricao', value })"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col> <h1 class="section-title-default">Processo ABI</h1> </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              mandatory
              hide-details
              class="m-0"
              :value="alegacaoAns.processoAbi"
              @change="(value) => updateField({ property: 'processoAbi', value })"
            >
              <template v-slot:label>
                <span class="text-base">
                  Selecione uma opção do processo que ocorrerá após atualização da decisão
                </span>
              </template>
              <v-radio
                v-for="(option, index) in processosAbi"
                class="mt-2"
                :data-test="`radio-${index}`"
                :key="index"
                :label="option.label"
                :value="index"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #footer>
      <re-footer
        v-bind="{ mode }"
        :loading="loadingForm"
        @back="goTo"
        @cancel="goTo"
        @save="onSave"
      />
      <ui-modal
        type="salvar"
        :show="showModalAtencao"
        @close="onToggleModalAtencao(true)"
        @exit="onConfirm"
        @save="onSave"
      />
    </template>
  </re-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { ReContainer, UiModal } from '@/components';
import MixinCadastros from '../mixin-cadastros';
import ReFooter from '../components';
import { processosAbi, tipos } from './constants';

export default {
  name: 'FormAlegacaoAns',
  mixins: [MixinCadastros],
  components: { ReContainer, ReFooter, UiModal },
  data() {
    return {
      redirectRoute: 'alegacao-ans',
      processosAbi,
      tipos,
    };
  },
  computed: {
    ...mapState('ressus/cadastros/alegacaoAns', ['alegacaoAns', 'loadingForm']),
    ...mapGetters('ressus/cadastros/alegacaoAns', ['isInitialState']),
    ativoLabel() {
      return this.alegacaoAns.ativo ? 'Ativo' : 'Inativo';
    },
  },
  created() {
    this.mode === 'create' && this.resetState();
  },
  methods: {
    ...mapActions('ressus/cadastros/alegacaoAns', [
      'updateField',
      'createAlegacaoAns',
      'loadAlegacaoAnsById',
      'updateAlegacaoAns',
      'resetState',
    ]),
    onLoadById() {
      this.loadAlegacaoAnsById(this.routeId);
    },
    onCreate(options) {
      this.createAlegacaoAns(options);
    },
    onUpdate(options) {
      this.updateAlegacaoAns(options);
    },
  },
};
</script>
