<template>
  <v-dialog persistent :max-width="width" :value="show" @keydown.esc="$emit('close')">
    <v-card>
      <v-card-title class="py-0">
        <v-sheet width="100%" height="56" class="flex items-center">
          <h4 v-if="title" class="text-xl font-bold text-primary-blue-base">{{ title }}</h4>
          <v-spacer />
          <v-btn icon data-test="btn-fechar" @click="$emit('close')">
            <v-icon class="text-primary-blue-base">mdi-close</v-icon>
          </v-btn>
        </v-sheet>
      </v-card-title>
      <v-divider class="bg-neutral-gray-medium" />
      <template v-if="type">
        <section class="flex flex-col items-center my-11">
          <v-icon class="text-primary-blue-base" size="50">{{ showConfigModal.icon }}</v-icon>
          <h6 class="text-primary-blue-base text-xl font-bold mt-5 mb-2">
            {{ showConfigModal.confirmMessage }}
          </h6>
          <p class="text-center text-sm text-primary-blue-base mb-0">
            <span v-html="showConfigModal.warningMessage" />
          </p>
        </section>
        <v-divider class="bg-neutral-gray-medium" />
        <v-card-actions>
          <v-sheet width="100%" height="48" class="flex items-center">
            <div v-for="button in showConfigModal.leftButtons" :key="button.name">
              <v-btn
                :text="button.text"
                :dark="button.dark"
                :outlined="button.outlined"
                :width="button.width"
                :class="button.class"
                :color="fullConfig.theme.colors[button.color]"
                :data-test="button.dataTest"
                @click="$emit(button.event)"
              >
                {{ button.name }}
              </v-btn>
            </div>
            <v-spacer />
            <div v-for="button in showConfigModal.rightButtons" :key="button.name">
              <v-btn
                :text="button.text"
                :dark="button.dark"
                :outlined="button.outlined"
                :width="button.width"
                :class="button.class"
                :color="fullConfig.theme.colors[button.color]"
                :data-test="button.dataTest"
                @click="$emit(button.event)"
              >
                {{ button.name }}
              </v-btn>
            </div>
          </v-sheet>
        </v-card-actions>
      </template>
      <template v-else>
        <slot />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import fullConfig from '@/utils/full-config-tailwind';
import configModal from './constants';

export default {
  name: 'UiModal',
  props: {
    title: String,
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '704',
    },
    type: String,
  },
  data() {
    return {
      fullConfig,
    };
  },
  computed: {
    showConfigModal() {
      return configModal[this.type];
    },
  },
};
</script>
