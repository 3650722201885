import Vue from 'vue';
import { cloneDeep } from 'lodash';
import initialStateEmpresa from './state';
import { formUploadDocumento } from '../constants';

export default {
  RESET_FORM_STATE(state) {
    Object.assign(state, initialStateEmpresa());
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.empresa, property, value);
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = cloneDeep(payload);
  },
  RESET_FORM_UPLOAD_DOCUMENTO(state) {
    Object.assign(state.dadosUploadDocumento, {
      ...formUploadDocumento,
    });
  },
  UPDATE_FIELD_UPLOAD_DOCUMENTO(state, { property, value }) {
    Vue.set(state.dadosUploadDocumento, property, value);
  },
};
