<template>
  <div
    class="container-upload"
    :class="{ 'container-upload--disabled': disabled, 'container-upload--dragging': dragging }"
    @dragover.prevent
    @dragenter.prevent="onToggleDropArea"
    @dragleave.prevent="onToggleDropArea"
    @drop.prevent="onDropFiles"
  >
    <v-icon size="80" :class="{ 'text-feedback-red-high': errorMessages.length }">
      mdi-tray-arrow-up
    </v-icon>
    <div v-if="errorMessages.length">
      <span
        v-for="(message, index) in errorMessages"
        class="text-feedback-red-high font-bold"
        :key="index"
      >
        <span v-html="message" />
      </span>
    </div>
    <span>
      Arraste o arquivo ou
      <label for="upload-files">
        <a class="underline underline-offset-2"> clique aqui para fazer upload </a>
        <input
          v-bind="{ multiple, accept }"
          id="upload-files"
          type="file"
          class="hidden"
          :value="value"
          @change="onChange"
        />
      </label>
    </span>
  </div>
</template>

<script>
import { some, includes } from 'lodash';

export default {
  name: 'UiUpload',
  props: {
    disabled: Boolean,
    multiple: Boolean,
    errorMessages: {
      type: Array,
      default: () => [],
    },
    accept: String,
  },
  data() {
    return {
      dragging: false,
      value: [],
      file: [],
      formatInput: null,
      supportedFormats: [
        '.pdf',
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.xml',
        '.zip',
        '.rar',
        '.doc',
        '.docx',
        '.xls',
        '.xlsx',
        '.csv',
      ],
    };
  },
  computed: {
    isFormatSupported() {
      return some(this.supportedFormats, (el) => includes(this.accept, el));
    },
    hasAccept() {
      return !!this.accept;
    },
  },
  methods: {
    onToggleDropArea() {
      this.dragging = !this.dragging;
    },
    onDropFiles({ dataTransfer }) {
      this.file = this.multiple ? dataTransfer?.files : [dataTransfer.files[0]];
      this.filesIterable(this.file);
    },
    onChange({ target }) {
      this.value = this.multiple ? target?.files : [target?.files[0]];
      this.filesIterable(this.value);
      this.value = '';
    },
    verifyFormatIncludes(param) {
      const paramCustom = param?.split('.')?.pop();

      return includes(this.accept, `.${paramCustom}`);
    },
    filesIterable(files) {
      [...files]?.forEach((file) => {
        this.formatInput = this.verifyFormatIncludes(file?.name);

        (this.formatInput && this.isFormatSupported) || !this.hasAccept
          ? this.$emit('drop-files', file)
          : this.$emit('error-format', `Formato .${file?.type?.split('/').pop()} não suportado!`);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-upload {
  @apply flex
  flex-col
  items-center
  w-full
  p-6
  pt-3
  text-sm
  text-center
  border-dashed
  border-2
  border-primary-blue-base
  text-primary-blue-base;

  &:deep(.v-icon) {
    @apply text-secondary-lemon-medium;
  }

  & span a {
    @apply text-primary-blue-base;
  }

  &--dragging,
  &:hover {
    @apply bg-neutral-gray-low border-secondary-lemon-medium;
  }

  &--disabled {
    @apply pointer-events-none border-neutral-gray-disabled text-neutral-gray-disabled;

    &:deep(.v-icon),
    & span a {
      @apply text-neutral-gray-disabled;
    }
  }
}
</style>
