<template>
  <v-avatar v-bind="{ size }" class="bg-primary-blue-base">
    <span class="text-sm text-white">{{ getInitials }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: 'ReAvatar',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: 36,
    },
  },
  computed: {
    getInitials() {
      const words = this.name.split(' ');

      if (words.length === 0) return null;

      const firstLetter = words[0][0];

      let lastLetter = '';

      if (words.length > 1) {
        [lastLetter] = words[words.length - 1];
      }

      return firstLetter.toUpperCase() + lastLetter.toUpperCase();
    },
  },
};
</script>
