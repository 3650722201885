<template>
  <v-navigation-drawer
    app
    absolute
    stateless
    right
    temporary
    hide-overlay
    :width="724"
    :value="showDrawerVisualizarDocumento"
  >
    <div class="w-full flex items-center justify-between p-6">
      <h1 class="text-xl font-semibold text-primary-blue-base">Visualizar arquivo</h1>
      <div class="flex justify-end gap-4">
        <v-btn icon @click="onClickExpand" data-test="btnExpand">
          <v-icon class="text-primary-blue-base">mdi-arrow-expand-all</v-icon>
        </v-btn>
        <v-btn icon @click="toggleDrawerVisualizarDocumento" data-test="btnClose">
          <v-icon class="text-primary-blue-base">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <embed class="w-full h-[calc(100vh-180px)]" type="application/pdf" :src="fileURL" />
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DrawerVisualizarDocumento',
  computed: {
    ...mapState('ressus/drawerVisualizarDocumento', ['showDrawerVisualizarDocumento', 'fileURL']),
  },
  methods: {
    ...mapActions('ressus/drawerVisualizarDocumento', ['toggleDrawerVisualizarDocumento']),
    onClickExpand() {
      window.open(this.fileURL, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  height: auto !important;
}

:deep(.v-navigation-drawer__content) {
  overflow: hidden !important;
}
</style>
