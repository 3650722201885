export const headers = [{ value: 'destinatario' }];

export const footerProps = {
  itemsPerPageText: 'Linhas por página:',
  itemsPerPageAllText: 'Todas',
  itemsPerPageOptions: [10, 20, 50, 100],
  pageText: '{0}-{1} de {2}',
  showFirstLastPage: true,
  firstIcon: 'mdi-chevron-double-left',
  lastIcon: 'mdi-chevron-double-right',
};

export const formDockModal = {
  destinatario: null,
  assunto: '',
  descricao: '',
};

export const formSolicitacao = {
  destinatarioNome: null,
  dataSolicitacao: null,
  remetenteNome: null,
  assunto: '',
  descricao: '',
  documentos: [],
  status: '',
  errorMessages: '',
};
