import { pagination } from '@/constants';

export default ({
  page = pagination.page,
  size = pagination.size,
  abiId,
  instanciaId,
  cobrancaId,
  statusCobranca = 'TODOS',
  pesquisar = '',
}) => `{
	listarAtendimentosParaCobranca(
		page: ${page - 1}
		size: ${size}
		filter: {
			abiId: ${abiId}
			codigoInstancia: ${instanciaId}
			tipoAlegacaoEnum: ${statusCobranca}
      cobrancaId: ${cobrancaId}
			pesquisar: "${pesquisar}"
		}
	) {
		content {
			id
			contagem
			tipo
			numero
			situacaoProcesso
      statusAtendimento
			valorTotal
			valorDeducao,
      valorDeducaoDeferida
      vinculadoCobranca
      valorRessarcimento
      cobrancasAtendimento {
        id
			}
			beneficiario {
				id
				codigo
				cpf
				nomeCompleto
			}
		}
		number
		totalPages
		totalElements
		size
		first
		last
	}
}
`;
