import { formEmpresa, formUploadDocumento } from '../constants';

export default () => ({
  loadingTable: false,
  loadingForm: false,
  totalElements: 0,
  totalPages: 0,
  empresas: [],
  empresa: { ...formEmpresa },
  initialStateEmpresa: { ...formEmpresa },
  documentosEmpresa: [],
  showModalUploadDocumento: false,
  dadosUploadDocumento: { ...formUploadDocumento },
  tabsDisabled: true,
});
