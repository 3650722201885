<template>
  <span>
    <span class="mx-5 text-xs mt-3"> Salvos: </span>
    <v-chip
      v-for="filtro in filtrosSalvos"
      class="text-xs text-primary-blue-base mr-3 mt-2"
      :data-test="`chip-${filtro.id}`"
      :key="filtro.id"
      :color="toggleColorChip(filtro)"
      @click="toggleChip(filtro)"
    >
      {{ filtro.descricao }}
    </v-chip>
  </span>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ReFiltrosSalvosChip',
  props: {
    filtrosSalvos: Array,
  },
  data() {
    return {
      selectedChip: null,
    };
  },
  watch: {
    visualizacaoFiltrosAvancadoSelecionado: {
      handler() {
        this.selectedChip = this.visualizacaoFiltrosAvancadoSelecionado?.descricao?.toLowerCase();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/filtrosAvancados', [
      'visualizacaoFiltrosAvancadoSelecionado',
    ]),
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/filtrosAvancados', ['applyVisualizacaoFiltro']),
    toggleChip(item) {
      if (this.selectedChip?.toLowerCase() === item.descricao?.toLowerCase()) {
        this.selectedChip = null;
        this.applyVisualizacaoFiltro({
          id: null,
          descricao: null,
          filtro: [],
        });
      } else {
        this.selectedChip = item.descricao?.toLowerCase();
        this.applyVisualizacaoFiltro(item);
      }
    },
    toggleColorChip(filtro) {
      return filtro?.descricao?.toLowerCase() ===
        this.visualizacaoFiltrosAvancadoSelecionado?.descricao?.toLowerCase()
        ? 'bg-secondary-lemon-medium'
        : 'bg-neutral-gray-disabled';
    },
  },
};
</script>
