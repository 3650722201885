import { helpPages } from '@/constants';

export const fatherRouteName = 'atendimentos-abi';

export default [
  {
    path: 'atendimentos-abi/:id?',
    name: fatherRouteName,
    component: () => import(/* webpackChunkName: "atendimentos-abi" */ './index.vue'),
    meta: {
      title: 'Processos',
      subtitle: 'Atendimentos',
      helpPage: helpPages['atendimentos-abi'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
    children: [
      {
        path: 'detalhes-atendimento/:atendimentoId',
        name: 'detalhes-atendimento',
        component: () =>
          import(
            /* webpackChunkName: "drawer-detalhamento-atendimento" */ './drawer-detalhamento-atendimento/index.vue'
          ),
        meta: {
          title: 'Processos',
          subtitle: 'Atendimentos',
          helpPage: helpPages['atendimentos-abi'],
          applicationKey: 'ressus',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        props: true,
      },
      {
        name: 'drawer-filtros-colunas',
        path: 'drawer-filtros-colunas',
        component: () =>
          import(
            /* webpackChunkName: "drawer-filtros-colunas" */ './drawer-filtros-colunas/index.vue'
          ),
        meta: {
          title: 'Processos',
          subtitle: 'Atendimentos',
          helpPage: helpPages['atendimentos-abi'],
          applicationKey: 'ressus',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        props: true,
      },
    ],
  },
  {
    name: 'formulario-atendimento',
    path: '/formulario-atendimento/:atendimentoId',
    component: () =>
      import(/* webpackChunkName: "formulario-atendimento" */ './formulario-impugnacao/index.vue'),
    meta: {
      subtitle: 'Formulário de atendimento',
      helpPage: helpPages['atendimentos-abi'],
      applicationKey: 'ressus',
      action: 'view',
      fatherRoute: fatherRouteName,
    },
    props: true,
  },
  {
    name: 'documentos-comprobatorios',
    path: '/formulario-atendimento/:atendimentoId/documentos-comprobatorios/:formularioId',
    component: () =>
      import(
        /* webpackChunkName: "documentos-comprobatorios" */ './formulario-impugnacao/documentos-comprobatorios.vue'
      ),
    meta: {
      subtitle: 'Formulário de atendimento',
      helpPage: helpPages['atendimentos-abi'],
      applicationKey: 'ressus',
      action: 'view',
      fatherRoute: fatherRouteName,
    },
    props: true,
  },
];
