import Vue from 'vue';

import {
  createFormularioService,
  deleteFormService,
  getFormularioPadraoById,
  getFormulariosPadraoEmpresa,
  updateFormularioService,
} from '../services';

const loadFormulariosEmpresa = async ({ commit }, query) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTableFormularios',
    payload: true,
  });

  const { payload } = await getFormulariosPadraoEmpresa(query);

  commit('GENERIC_MUTATION', {
    property: 'formularios',
    payload: payload?.listarFormularioPadraoPorEmpresa?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElementsFormularios',
    payload: payload?.listarFormularioPadraoPorEmpresa?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPagesFormularios',
    payload: payload?.listarFormularioPadraoPorEmpresa?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTableFormularios',
    payload: false,
  });
};

const loadFormularioPadraoById = async ({ commit, dispatch }, { id, modalFormularioMode }) => {
  await dispatch('ressus/cadastros/empresa/setLoadingForm', true, { root: true });

  await getFormularioPadraoById({
    id,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'formulario',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'modalFormularioMode',
        payload: modalFormularioMode,
      });

      dispatch('setShowModalFormulario', true);
      dispatch('setInitialStateFormulario', data);
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('ressus/cadastros/empresa/setLoadingForm', false, { root: true }),
  });
};

const createFormulario = ({ getters, dispatch }, resetValidation) => {
  dispatch('setLoadingForm', true);

  const { payloadFormulario } = getters;

  createFormularioService({
    payload: payloadFormulario,
    onSuccess: () => {
      dispatch('loadFormulariosEmpresa', {
        ...Vue.router.history.current.query,
        empresaId: payloadFormulario?.empresaId,
        page: 1,
      });

      dispatch('setShowModalFormulario', false);
      dispatch('resetFormulario');

      Vue.router
        .replace({
          name: 'edit-empresa',
          ...Vue.router.history.current,
          query: {
            ...Vue.router.history.current.query,
            page: 1,
          },
        })
        .catch(() => {});

      Vue.$toast('Formulário incluído com sucesso.', { type: 'success' });

      resetValidation();
    },
    onCustomError: ({ response }) => dispatch('setFormErrorMessage', response?.data?.message),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateFormulario = ({ getters, dispatch }, resetValidation) => {
  dispatch('setLoadingForm', true);

  const { payloadFormulario } = getters;

  updateFormularioService({
    payload: payloadFormulario,
    onSuccess: () => {
      dispatch('loadFormulariosEmpresa', {
        ...Vue.router.history.current.query,
        empresaId: payloadFormulario?.empresaId,
        page: Vue.router.history.current.query.page || 1,
      });

      dispatch('setShowModalFormulario', false);
      dispatch('resetFormulario');

      Vue.$toast('Formulário alterado com sucesso.', { type: 'success' });

      resetValidation();
    },
    onCustomError: ({ response }) => dispatch('setFormErrorMessage', response?.data?.message),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteFormulario = ({ state, getters, commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTableFormularios',
    payload: true,
  });

  deleteFormService({
    id,
    onSuccess: () => {
      Vue.$toast('Formulário excluído com sucesso.', { type: 'success' });

      dispatch('loadFormulariosEmpresa', {
        ...query,
        empresaId: getters.payloadFormulario?.empresaId,
      });

      if (state.formularios.length === 1) {
        Vue.router.replace({
          ...Vue.router.history.current,
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
  });
};

const updateFieldForms = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD_FORM', {
    property,
    value,
  });

const setShowModalFormulario = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'showModalFormulario',
    payload: value,
  });

const setFormErrorMessage = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'formErrorMessage',
    payload: value,
  });

const closeModalFormulario = ({ getters, dispatch }, { resetValidation }) => {
  resetValidation();

  !getters.isinitialStateFormulario && Vue.$toast('Os dados não foram salvos.', { type: 'info' });

  dispatch('resetFormulario');
  dispatch('setShowModalFormulario', false);
};

const resetState = ({ commit }) => commit('RESET_FORM_STATE');

const resetFormulario = ({ commit }) => commit('RESET_FORMULARIO');

const setInitialStateFormulario = ({ commit }, value) => commit('SET_INITIAL_STATE', value);

const setLoadingForm = ({ commit }, payload) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload,
  });

export default {
  closeModalFormulario,
  createFormulario,
  deleteFormulario,
  loadFormularioPadraoById,
  loadFormulariosEmpresa,
  resetFormulario,
  resetState,
  setFormErrorMessage,
  setInitialStateFormulario,
  setShowModalFormulario,
  updateFieldForms,
  updateFormulario,
  setLoadingForm,
};
