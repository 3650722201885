import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { getNumber } from '@/utils/numeral';
import {
  instanciarCobrancaService,
  getDadosCobranca,
  getAtendimentosCobranca,
  getResumoCobranca,
  updateRacunhoCobrancaService,
  incorporarCustoService,
  updateValorDeducaoService,
  removeAtendimentoCobrancaService,
} from '../services';

const iniciarCobranca = ({ dispatch }, payload) => {
  dispatch('setLoading');
  const { cobrancaId, instanciaId, notificacaoAnsId } = payload;

  if (cobrancaId) {
    getDadosCobranca({
      cobrancaId,
      onSuccess: ({ data }) => {
        if (+data.instanciaId === +instanciaId) {
          Vue.router.push({
            name: 'cobranca',
            params: { id: cobrancaId },
          });
        } else {
          dispatch('instanciarCobranca', { instanciaId, notificacaoAnsId });
        }
      },
      onCustomError: ({ response }) =>
        Vue.$toast(response?.data?.message, {
          type: 'error',
        }),
      onFinally: () => {
        dispatch('ressus/oficiosNotificacao/resetLocalFilters', null, { root: true });
        dispatch('ressus/drawerFiltros/resetFilters', null, { root: true });
        dispatch('setLoading', false);
      },
    });
  } else {
    dispatch('instanciarCobranca', { instanciaId, notificacaoAnsId });
  }
};

const instanciarCobranca = ({ dispatch }, { instanciaId, notificacaoAnsId }) => {
  dispatch('setLoading');

  instanciarCobrancaService({
    notificacaoAnsId,
    payload: { instanciaId },
    onSuccess: ({ data }) => {
      Vue.router.push({
        name: 'cobranca',
        params: { id: data.cobrancaId },
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const loadDadosCobranca = ({ dispatch, commit }, { cobrancaId, urlQueries }) => {
  dispatch('setLoading');

  getDadosCobranca({
    cobrancaId,
    onSuccess: async ({ data }) => {
      const numeroAbi = getNumber(data.numeroAbi);

      Vue.router.history.current.meta.subtitle = `Tipo Cobrança - ABI ${numeroAbi}`;

      await commit('GENERIC_MUTATION', {
        property: 'dadosCadastroGru',
        payload: data,
      });

      await commit('GENERIC_MUTATION', {
        property: 'initialStateDadosCadastroGru',
        payload: cloneDeep(data),
      });

      dispatch('loadAtendimentosCobranca', urlQueries);
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const loadAtendimentosCobranca = async ({ commit, dispatch, state }, query) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  let filterByTipoInstancia;

  if (state.dadosCadastroGru.instanciaId === 5 || state.dadosCadastroGru.instanciaId === 6) {
    const typeFiltro = {
      5: 'NAO_IMPUGNADO',
      6: 'NAO_RECURSADO',
    };

    filterByTipoInstancia = typeFiltro[state.dadosCadastroGru.instanciaId];
  }

  const { payload } = await getAtendimentosCobranca({
    ...query,
    statusCobranca: filterByTipoInstancia || query?.statusCobranca,
    abiId: state.dadosCadastroGru.abiId,
    instanciaId: state.dadosCadastroGru.instanciaId,
    cobrancaId: state.dadosCadastroGru.id,
  });

  commit('GENERIC_MUTATION', {
    property: 'atendimentos',
    payload: payload?.listarAtendimentosParaCobranca?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarAtendimentosParaCobranca?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarAtendimentosParaCobranca?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });

  await commit('GENERIC_MUTATION', {
    property: 'atendimentosSelecionados',
    payload: payload?.listarAtendimentosParaCobranca?.content.filter(
      (atendimento) => atendimento.vinculadoCobranca && atendimento,
    ),
  });

  dispatch('loadResumoCobranca', state.dadosCadastroGru.id);
};

const loadResumoCobranca = ({ commit }, cobrancaId) => {
  getResumoCobranca({
    cobrancaId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'resumoCobranca',
        payload: data,
      });
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
  });
};

const updateRascunhoCobranca = (
  { dispatch, commit },
  { payload, redirect = false, showToast = true, urlQueries },
) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  updateRacunhoCobrancaService({
    payload,
    onSuccess: () => {
      showToast &&
        Vue.$toast('Rascunho atualizado com sucesso', {
          type: 'success',
          id: 'update-rascunho',
        });

      if (redirect) {
        Vue.router.push({
          name: 'resumo-abi',
          params: { id: `${payload.gru.abiId}` },
          query: { tab: 'COBRANCA' },
        });
      } else {
        dispatch('loadDadosCobranca', {
          cobrancaId: payload.gru.id,
          urlQueries,
        });
      }
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const removeAtendimentoCobranca = ({ commit, dispatch }, { payload, urlQueries, cobrancaId }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  removeAtendimentoCobrancaService({
    cobrancaId,
    payload,
    onSuccess: () => {
      Vue.$toast('Rascunho atualizado com sucesso', {
        type: 'success',
        id: 'update-rascunho',
      });

      dispatch('loadAtendimentosCobranca', urlQueries);
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const incorporarCusto = ({ dispatch }, dadosCadastroGru) => {
  dispatch('setLoading');

  const { id } = dadosCadastroGru;

  incorporarCustoService({
    cobrancaId: id,
    onSuccess: async () => {
      Vue.$toast('Incorporação de custo em processamento', {
        type: 'success',
      });

      Vue.router
        .push({
          name: 'oficios-notificacao',
          query: { tab: 'COBRANCA' },
        })
        .catch(() => {});
    },
    onCustomError: ({ response }) =>
      Vue.$toast(response?.data?.message, {
        type: 'error',
      }),
    onFinally: () => {
      dispatch('ressus/oficiosNotificacao/resetLocalFilters', null, { root: true });
      dispatch('ressus/drawerFiltros/resetFilters', null, { root: true });
      dispatch('setLoading', false);
    },
  });
};

const updateValorDeducao = async ({ commit }, { payload, cobrancaId }, activeLoading) => {
  activeLoading &&
    commit('GENERIC_MUTATION', {
      property: 'loadingTable',
      payload: true,
    });

  await updateValorDeducaoService({
    payload,
    cobrancaId,
    onSuccess: async () => {},
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () =>
      activeLoading &&
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const updateFieldValorDeducao = ({ commit }, { property, value, item }) =>
  commit('UPDATE_FIELD_VALOR_DEDUCAO', {
    property,
    value,
    item,
  });

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

export default {
  iniciarCobranca,
  instanciarCobranca,
  loadDadosCobranca,
  loadAtendimentosCobranca,
  loadResumoCobranca,
  updateRascunhoCobranca,
  removeAtendimentoCobranca,
  incorporarCusto,
  updateValorDeducao,
  updateFieldValorDeducao,
  updateField,
  resetState,
  setLoading,
};
