const fields = {
  idsAbi: [],
  dataPeriodoInicial: null,
  dataPeriodoFinal: null,
};

export default () => ({
  loading: false,
  dadosRelatorio: { ...fields },
  initialState: { ...fields },
});
