import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { getParametros, updateParametrosService } from '../services';

const getMotivosImpugnacao = async ({ dispatch, commit }) => {
  dispatch('setLoading');

  const motivos = await dispatch(
    'ressus/cadastros/motivoImpugnacao/sharedMotivosImpugnacao',
    { codigos: [13, 14] },
    { root: true },
  );

  commit('GENERIC_MUTATION', {
    property: 'motivosImpugnacao',
    payload: motivos,
  });

  dispatch('setLoading', false);
};

const loadParametros = async ({ commit, dispatch }) => {
  await dispatch('getMotivosImpugnacao');

  dispatch('setLoading');

  await getParametros({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'parametros',
        payload: data.content,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: cloneDeep(data.content),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateField = ({ state, commit }, { item, field, value }) => {
  const payload = state.parametros.map((parametro) => ({
    ...parametro,
    [field]: parametro.id === item.id ? value : parametro[field],
  }));

  commit('GENERIC_MUTATION', {
    property: 'parametros',
    payload,
  });
};

const updateParametros = ({ getters, dispatch }) => {
  dispatch('setLoading');

  updateParametrosService({
    payload: getters.payloadParametros,
    onSuccess: () => {
      dispatch('loadParametros');

      Vue.$toast('Parâmetros atualizados com sucesso!', { type: 'success' });
    },
    onCustomError: ({ response }) => {
      dispatch('setLoading', false);

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

export default {
  getMotivosImpugnacao,
  loadParametros,
  updateField,
  updateParametros,
  resetState,
  setLoading,
};
