import { getAbisConcluidasService, getAlegacaoAnsService } from '../services';

const getAbisConcluidas = async ({ dispatch, commit, getters }, typeRelatorio) => {
  dispatch(`ressus/relatorios/${typeRelatorio}/setLoading`, true, {
    root: true,
  });

  commit('GENERIC_MUTATION', {
    property: 'abisConcluidas',
    payload: [],
  });

  const { payload } = await getAbisConcluidasService();

  await commit('GENERIC_MUTATION', {
    property: 'abisConcluidas',
    payload: payload?.listarAbiConcluidas?.content,
  });

  dispatch(
    `ressus/relatorios/${typeRelatorio}/updateInitialState`,
    { property: 'idsAbi', value: getters.payloadAbisConcluidas },
    { root: true },
  );

  dispatch(`ressus/relatorios/${typeRelatorio}/setLoading`, false, {
    root: true,
  });
};

const getAlegacoesAns = async ({ dispatch, commit }, typeRelatorio) => {
  dispatch(`ressus/relatorios/${typeRelatorio}/setLoading`, true, {
    root: true,
  });

  commit('GENERIC_MUTATION', {
    property: 'alegacoesAns',
    payload: [],
  });

  const { payload } = await getAlegacaoAnsService();

  commit('GENERIC_MUTATION', {
    property: 'alegacoesAns',
    payload: payload?.listarAlegacaoAns?.content,
  });

  if (typeRelatorio !== 'lancamentosContabeis') {
    dispatch(
      `ressus/relatorios/${typeRelatorio}/updateInitialState`,
      { property: 'alegacaoAns', value: payload?.listarAlegacaoAns?.content },
      { root: true },
    );
  }

  dispatch(`ressus/relatorios/${typeRelatorio}/setLoading`, false, {
    root: true,
  });
};

export default {
  getAlegacoesAns,
  getAbisConcluidas,
};
