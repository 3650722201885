<template>
  <section>
    <div class="flex items-center mb-5">
      <h1 v-if="title" class="section-title-default">{{ title }}</h1>
      <v-chip v-if="dadosGeral.naoImpugnado"> Não impugnado </v-chip>
      <v-chip v-if="dadosGeral.naoRecursado"> Não recursado </v-chip>
    </div>
    <v-row v-for="(motivo, index) in payloadDadosGerais.motivosImpugnacao" :key="index">
      <v-col cols="12" sm="7">
        <v-autocomplete
          persistent-placeholder
          return-object
          label="Motivo"
          item-value="id"
          item-text="descricaoCompleta"
          placeholder="Selecionar"
          no-data-text="Nenhum motivo encontrado"
          :value="motivo"
          :items="motivosImpugnacaoCadastrados"
          :readonly="isViewOnly"
          :append-icon="isViewOnly ? '' : 'mdi-menu-down'"
          @change="(value) => onChangeMotivo(motivo, value)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="motivo.ativo"
          inset
          data-test="switch-ativo-inativo"
          :readonly="isViewOnly"
          @change="(value) => onChangeAtivo(motivo, value)"
        >
          <template v-slot:label>
            {{ motivo.ativo ? 'Ativo' : 'Inativo' }}
          </template>
        </v-switch>
      </v-col>
      <v-col v-if="!isViewOnly" cols="12" sm="1" align-self="center">
        <span v-if="index === 0">
          <v-btn
            icon
            class="text-primary-blue-base mb-2"
            data-test="btn-adicionar"
            :disabled="isViewOnly"
            @click="onAddMotivo"
          >
            <v-icon> mdi-plus-circle </v-icon>
          </v-btn>
        </span>
        <span v-else>
          <v-menu offset-y nudge-left="60">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                class="text-primary-blue-base mb-2"
                data-test="btn-acao-menu"
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    plain
                    class="m-0 p-0"
                    data-test="btn-excluir"
                    @click="onRemoveMotivo(motivo)"
                  >
                    Excluir
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'SecaoMotivos',
  props: {
    title: String,
    isFormularioAtendimento: Boolean,
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'payloadDadosGerais',
      'motivosImpugnacaoCadastrados',
    ]),
    ...mapGetters('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'hasEmptyMotivos',
      'dadosGeral',
      'maisDeUmMotivoAlegacao',
    ]),
    isSituacaoEncerrado() {
      return this.payloadDadosGerais.situacaoProcesso === 'ENCERRADO';
    },
    isViewOnly() {
      return this.isSituacaoEncerrado || this.isFormularioAtendimento;
    },
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'updateMotivosImpugnacao',
    ]),
    onAddMotivo() {
      if (this.hasEmptyMotivos) {
        return this.$toast('Adicione um motivo por vez', { type: 'warning' });
      }

      const motivos = this.payloadDadosGerais.motivosImpugnacao.map((motivo) => ({
        ...motivo,
        ativo: this.maisDeUmMotivoAlegacao,
      }));

      motivos.push({ id: null, ativo: true });

      return this.updateMotivosImpugnacao(motivos);
    },
    onRemoveMotivo(item) {
      let motivos = this.payloadDadosGerais.motivosImpugnacao;

      item.id ? (motivos = motivos.filter((motivo) => motivo.id !== item.id)) : motivos.pop();

      this.updateMotivosImpugnacao(motivos);
    },
    onChangeMotivo(item, value) {
      let motivos = this.payloadDadosGerais.motivosImpugnacao;

      const motivoDuplicado = motivos.some((motivo) => motivo.id === value?.id);

      if (motivoDuplicado && item.id !== value?.id) {
        if (value.id !== null)
          this.$toast('O motivo selecionado já está em uso', { type: 'error' });
      } else {
        motivos = motivos.map((motivo) => ({
          ...motivo,
          id: motivo.id === item.id ? value?.id || null : motivo.id,
        }));
      }

      this.updateMotivosImpugnacao(motivos);
    },
    onChangeAtivo(item, value) {
      let motivos = this.payloadDadosGerais.motivosImpugnacao;

      if (!this.maisDeUmMotivoAlegacao) {
        motivos = motivos.map((motivo) => ({
          ...motivo,
          ativo: motivo.id === item.id && value,
        }));
      }

      this.updateMotivosImpugnacao(motivos);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
  color: theme('colors.primary-blue-base') !important;
  background-color: theme('colors.feedback-yellow-low') !important;
  font-size: 10px;
  font-weight: 600;
  padding: 0 20px;
  height: 20px;
  margin-left: 20px;
}
</style>
