import { createGraphQLService, createCommandService } from '@/services';
import { ressusURI } from '@/api';
import listarVisualizacaoColuna from './queries/listagem-colunas';
import createQueryListagemFiltros from './queries/listagem-filtros';
import createQueryVisualizacaoFiltros from './queries/visualizacao-filtros-salvos';

const getVisualizacoesColunas = () => createGraphQLService(listarVisualizacaoColuna());

const getListagemFiltros = () => createGraphQLService(createQueryListagemFiltros());

const getVisualizacaoFiltrosSalvos = (queryParameters) =>
  createGraphQLService(createQueryVisualizacaoFiltros(queryParameters));

const getVisualizacaoFiltroById = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    uri: `${ressusURI}/visualizacao_filtro/${id}`,
    onSuccess,
    onCustomError,
  });

const getVisualizacaoById = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    uri: `${ressusURI}/visualizacao_coluna/${id}`,
    onSuccess,
    onCustomError,
  });

const createVisualizacaoColuna = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/visualizacao_coluna`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateVisualizacaoColuna = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/visualizacao_coluna/${payload.id}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteVisualizacaoColuna = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/visualizacao_coluna/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDadosFiltraveisPrestador = ({ prestadorId, onSuccess, onCustomError }) =>
  createCommandService({
    uri: `${ressusURI}/prestador/${prestadorId}/dados_filtraveis`,
    onSuccess,
    onCustomError,
  });

const saveVisualizacaoFiltro = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/visualizacao_filtro`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateVisualizacaoFiltroAvancadoService = ({
  payload,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/visualizacao_filtro/${payload.id}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteVisualizacaoFiltroAvancadoService = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/visualizacao_filtro/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getDataFilterService = ({ payload: { entidade, filter, idAbi }, onSuccess, onCustomError }) =>
  createCommandService({
    uri: `${ressusURI}/${entidade}/valores/campo/${filter}/abi/${idAbi}`,
    onSuccess,
    onCustomError,
  });

export {
  getVisualizacoesColunas,
  getVisualizacaoById,
  getListagemFiltros,
  getVisualizacaoFiltrosSalvos,
  getVisualizacaoFiltroById,
  getDadosFiltraveisPrestador,
  createVisualizacaoColuna,
  updateVisualizacaoColuna,
  deleteVisualizacaoColuna,
  saveVisualizacaoFiltro,
  updateVisualizacaoFiltroAvancadoService,
  deleteVisualizacaoFiltroAvancadoService,
  getDataFilterService,
};
