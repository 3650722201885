import { helpPages } from '@/constants';

export default [
  {
    path: '/detalhamento-motivo',
    name: 'detalhamento-motivo',
    component: () => import(/* webpackChunkName: "detalhamento-motivo" */ './index.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Detalhamento do motivo',
      helpPage: helpPages['detalhamento-motivo'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
  {
    path: '/detalhamento-motivo/create',
    name: 'create-detalhamento-motivo',
    component: () => import(/* webpackChunkName: "form-detalhamento-motivo" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Novo detalhamento do motivo',
      helpPage: helpPages['detalhamento-motivo'],
      applicationKey: 'ressus',
      action: 'create',
    },
    props: true,
  },
  {
    path: '/detalhamento-motivo/edit/:id',
    name: 'edit-detalhamento-motivo',
    component: () => import(/* webpackChunkName: "form-detalhamento-motivo" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Editar detalhamento do motivo',
      helpPage: helpPages['detalhamento-motivo'],
      applicationKey: 'ressus',
      action: 'edit',
    },
    props: true,
  },
  {
    path: '/detalhamento-motivo/view/:id',
    name: 'view-detalhamento-motivo',
    component: () => import(/* webpackChunkName: "form-detalhamento-motivo" */ './form-view.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Visualização do detalhamento do motivo',
      helpPage: helpPages['detalhamento-motivo'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
