<template>
  <v-menu
    v-bind="{ left }"
    offset-y
    :max-width="width"
    :close-on-content-click="false"
    :open-on-click="false"
    :open-on-hover="isTruncated"
  >
    <template v-slot:activator="{ on }">
      <div
        v-on="on"
        class="ellipsis-multiline"
        :id="elementId"
        :style="cssLineProp"
        :class="{ 'cursor-pointer': isTruncated }"
      >
        <div v-if="showTruncatedText" v-html="truncatedText" />
        <div v-else>
          {{ value }}
          <v-icon
            v-if="configIcon"
            :size="configIcon.size"
            :class="configIcon.class"
            @click="onRedirect"
          >
            {{ configIcon.icon }}
          </v-icon>
        </div>
      </div>
    </template>
    <v-card>
      <v-card-text class="text-primary-blue-base">
        <span v-html="text" />
        <a v-if="showRedirectLink" class="ml-1 text-primary-blue-base" @click="onRedirect">
          <span class="underline">Clique aqui</span>
        </a>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'RePopover',
  props: {
    text: String,
    elementId: {
      type: [String, Number],
      required: true,
    },
    lines: {
      type: Number,
      default: 3,
    },
    limit: {
      type: Number,
      default: 50,
    },
    width: {
      type: Number,
      default: 500,
    },
    showTruncatedText: {
      type: Boolean,
      default: true,
    },
    configIcon: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [String, Number],
    },
    left: Boolean,
    showRedirectLink: Boolean,
    routeParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isTruncated: false,
      truncatedText: this.text,
    };
  },
  computed: {
    cssLineProp() {
      return { '--line-clamp': this.lines };
    },
    isLongTextWithoutSpaces() {
      const splittedString = this.text.trim().split(' ', this.text.length);

      return splittedString.some((string) => string.length > this.limit);
    },
  },
  mounted() {
    window.addEventListener('resize', this.onEventHandler);

    this.onTextHandler();
  },
  updated() {
    this.onTextHandler();
  },
  destroyed() {
    window.removeEventListener('resize', this.onEventHandler);
  },
  methods: {
    onTextHandler() {
      this.truncatedText = this.text;

      if (this.isLongTextWithoutSpaces) {
        this.truncateText();
      } else {
        this.onEventHandler();
      }
    },
    onEventHandler() {
      const element = document.getElementById(this.elementId);

      this.$nextTick(() => {
        this.isTruncated =
          element?.offsetWidth < element?.scrollWidth ||
          element?.offsetHeight < element?.scrollHeight;
      });
    },
    truncateText() {
      if (this.text.length > this.limit) {
        this.isTruncated = true;
        this.truncatedText = `${this.text.substring(0, this.limit)}...`;
      }
    },
    onRedirect() {
      this.showRedirectLink &&
        this.$router.push({
          ...this.routeParams,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis-multiline {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: var(--line-clamp);
  -webkit-box-orient: vertical;
}
</style>
