function displayTextWidth(text, font) {
  const canvas =
    displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');

  context.font = font;

  const metrics = context.measureText(text);

  return metrics.width;
}

function capitalizeName(string) {
  return string
    ? string
        .split(' ')
        .map((word) => word?.toLowerCase())
        .map((word) => (word[0]?.toUpperCase() || '') + (word?.substring(1) || ''))
        .join(' ')
    : '';
}

function formatDescriptionEditor(value) {
  return value?.includes('<p>') && value?.includes('</p>') ? value : `<p>${value}</p>`;
}

function removeAspasDuplas(value = null) {
  if (typeof value !== 'string') return value;

  return value?.replaceAll('"', '');
}

export { capitalizeName, displayTextWidth, formatDescriptionEditor, removeAspasDuplas };
