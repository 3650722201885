var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('re-input-money',{attrs:{"label":"Inicial","data-test":"input-money-valor-inicial","value":_vm.filters[_vm.propertyInicial],"rules":_vm.validatePropertyInicial},on:{"keyup":function($event){return _vm.updateField({
          property: _vm.propertyInicial,
          value: $event,
        })}}})],1),_c('v-col',[_c('re-input-money',{attrs:{"label":"Final","data-test":"input-money-valor-final","value":_vm.filters[_vm.propertyFinal],"rules":_vm.validatePropertyFinal},on:{"keyup":function($event){return _vm.updateField({
          property: _vm.propertyFinal,
          value: $event,
        })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }