import Vue from 'vue';

const loadVisualizacaoDocumento = ({ commit, dispatch }, payload) => {
  if (!payload.url) {
    Vue.$toast('Arquivo não encontrado.', { type: 'error' });

    return;
  }
  commit('GENERIC_MUTATION', {
    property: 'fileURL',
    payload: null,
  });

  const findTypeInName = payload?.file?.name?.split('.').pop() || payload?.nome?.split('.').pop();
  const isPdf = findTypeInName === 'pdf';
  const nameFile = payload?.file?.name || payload?.nome;

  if (isPdf) {
    fetch(payload.url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], nameFile, { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        commit('GENERIC_MUTATION', {
          property: 'fileURL',
          payload: fileURL,
        });
      });

    dispatch('toggleDrawerVisualizarDocumento');
  } else {
    commit('GENERIC_MUTATION', {
      property: 'fileURL',
      payload: payload.url,
    });
  }
};

const toggleDrawerVisualizarDocumento = ({ state, commit }) =>
  commit('GENERIC_MUTATION', {
    property: 'showDrawerVisualizarDocumento',
    payload: !state.showDrawerVisualizarDocumento,
  });

export default {
  loadVisualizacaoDocumento,
  toggleDrawerVisualizarDocumento,
};
