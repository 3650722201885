<template>
  <v-btn
    outlined
    class="btn btn__outlined"
    ref="btn-menu-acoes"
    :id="menuId"
    :data-test="`btn-menu-${menuId}`"
    @click="onToggleMenu"
  >
    {{ label }}
    <v-icon right> mdi-chevron-down </v-icon>
    <v-menu
      v-model="isOpened"
      left
      :attach="`#${menuId}`"
      :min-width="240"
      :nudge-bottom="44"
      :nudge-right="buttonWidth"
    >
      <v-list class="p-0">
        <div v-for="item in items" :key="item.key">
          <v-list-item
            v-if="!item.children"
            :disabled="onCheckItemDisabled(item.key)"
            :data-test="`list-item-${item.key}`"
            @click.stop="onHandleClick(item)"
          >
            <v-list-item-title
              class="whitespace-normal text-left font-normal text-primary-blue-base"
            >
              {{ item.label }}
              <span v-if="item.key === 'SELECIONADOS' && selecteds.length > 0">
                ({{ selecteds.length }})
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-expansion-panels v-else flat class="group">
            <v-expansion-panel>
              <v-expansion-panel-header :data-test="`expansion-panel-header-${item.key}`">
                {{ item.label }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item
                  v-for="child in item.children"
                  class="bg-neutral-gray-low"
                  :key="child.key"
                  :disabled="onCheckItemDisabled(child.key)"
                  :data-test="`list-item-${child.key}`"
                  @click.stop="onHandleClick(child)"
                >
                  <v-list-item-title
                    class="whitespace-normal ml-4 text-left font-normal text-primary-blue-base"
                  >
                    {{ child.label }}
                    <span v-if="child.key === 'SELECIONADOS' && selecteds.length > 0">
                      ({{ selecteds.length }})
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script>
export default {
  name: 'ReMenuAcoes',
  props: {
    menuId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selecteds: {
      type: Array,
      default: () => [],
    },
    hasAtendimentoSelected: Boolean,
    gridAtendimentoCobranca: Boolean,
  },
  data() {
    return {
      isOpened: false,
      buttonWidth: 0,
    };
  },
  methods: {
    onToggleMenu() {
      this.buttonWidth = this.$refs['btn-menu-acoes'].$el.clientWidth;

      this.isOpened = !this.isOpened;
    },
    onHandleClick(item) {
      this.$emit(item.event, item);
      this.isOpened = false;
    },
    onCheckItemDisabled(key) {
      if (this.gridAtendimentoCobranca) {
        return key === 'SELECIONADOS' && !this.hasAtendimentoSelected;
      }
      return key === 'SELECIONADOS' && !this.selecteds.length;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.group) {
  .v-expansion-panel {
    margin-bottom: 0;
  }

  .v-expansion-panel-header {
    padding: 16px;
    font-size: 16px;
    font-weight: normal;
    color: theme('colors.primary-blue-base');
  }

  .v-expansion-panel--active > .v-expansion-panel-header {
    background: theme('colors.neutral-gray-low');
    color: theme('colors.primary-blue-base');

    .v-icon {
      color: theme('colors.primary-blue-base');
    }
  }

  .v-expansion-panel-content {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
}

.v-list-item--disabled .v-list-item__title {
  color: theme('colors.neutral-gray-disabled') !important;
}
</style>
