import { numeroOficioRule } from '@/utils/validation-rules';

export const headers = [
  {
    text: '',
    value: 'status',
    cellClass: 'pa-0',
    sortable: false,
  },
  {
    text: 'ABI',
    value: 'abi.numero',
    align: 'center',
    width: 80,
  },
  {
    text: 'DATA DE EMISSÃO',
    value: 'dataEmissao',
  },
  {
    text: 'Nº DO OFÍCIO',
    value: 'numeroOficio',
  },
  {
    text: 'Nº DO PROCESSO',
    value: 'numeroProcesso',
  },
  {
    text: 'DATA LIMITE RECEBIMENTO',
    value: 'dataRecebimento',
  },
  {
    text: 'TIPO DE OFÍCIO',
    value: 'tipoNotificacao',
    align: 'center',
    sortable: false,
  },
  {
    text: 'INSTÂNCIA',
    value: 'instancia',
    class: 'w-1 max-w-[200px]',
    sortable: false,
  },
  {
    text: 'VALOR TOTAL',
    value: 'valorTotal',
  },
  {
    text: 'AÇÕES',
    value: 'actions',
    align: 'center',
    width: 180,
    sortable: false,
  },
];

export const tabs = [
  { id: 0, title: 'Todos', value: undefined },
  { id: 1, title: 'Tipo ABI', value: 'ABI' },
  { id: 2, title: 'Tipo decisão', value: 'DECISAO' },
  { id: 3, title: 'Tipo cobrança', value: 'COBRANCA' },
];

export const configStatus = {
  IMPORTAR_ABI: {
    content: 'Importar ABI',
    color: 'bg-neutral-gray-disabled',
    label: 'Importar',
    method: 'onClickImportarAbi',
  },
  IMPORTANDO_ABI: {
    content: 'Importando',
    color: 'bg-feedback-yellow',
    label: 'Importando',
    disabled: true,
  },
  REIMPORTAR_ABI: {
    content: 'Reimportar',
    color: 'bg-neutral-gray-disabled',
    label: 'Reimportar',
    method: 'onClickImportarAbi',
  },
  FINALIZADA_ABI: {
    content: 'Finalizada',
    color: 'bg-secondary-lemon-medium',
    label: 'Finalizada',
    method: 'onClickRedirectResumo',
    tab: 'ABI',
  },
  ATUALIZAR_DECISAO: {
    content: 'Atualizar decisão',
    color: 'bg-neutral-gray-disabled',
    label: 'Atualizar',
    method: 'onClickAtualizarDecisao',
    tab: 'DECISAO',
  },
  ATUALIZACAO_EM_ANDAMENTO: {
    content: 'Atualizando',
    color: 'bg-feedback-yellow',
    label: 'Atualizando',
    disabled: true,
  },
  ERRO_ATUALIZAR_DECISAO: {
    content: 'Erro na atualização',
    color: 'bg-feedback-red-high',
    label: 'Atualizar',
    method: 'onClickAtualizarDecisao',
    tab: 'DECISAO',
  },
  DECISAO_ATUALIZADA: {
    content: 'Decisão atualizada',
    color: 'bg-secondary-lemon-medium',
    label: 'Atualizada',
    method: 'onClickRedirectResumo',
    tab: 'DECISAO',
  },
  INICIAR_COBRANCA: {
    content: 'Iniciar cobrança',
    color: 'bg-neutral-gray-disabled',
    label: 'Iniciar',
    method: 'onClickIniciarCobranca',
  },
  COBRANCA_INICIADA: {
    content: 'Cobrança iniciada',
    color: 'bg-feedback-yellow',
    label: 'Iniciada',
    method: 'onClickIniciarCobranca',
  },
  INCORPORANDO_CUSTO: {
    content: 'Incorporando o custo',
    color: 'bg-feedback-yellow',
    label: 'Atualizando',
    disabled: true,
  },
  COBRANCA_FINALIZADA: {
    content: 'Cobrança finalizada',
    color: 'bg-secondary-lemon-medium',
    label: 'Finalizada',
    method: 'onClickRedirectResumo',
    tab: 'COBRANCA',
  },
  ERRO_ATUALIZAR_COBRANCA: {
    content: 'Erro na atualização',
    color: 'bg-feedback-red-high',
    label: 'Iniciada',
    method: 'onClickIniciarCobranca',
    tab: 'COBRANCA',
  },
  ERRO_IMPORTAR_ABI: {
    content: 'Erro ao importar ABI',
    color: 'bg-feedback-red-high',
    label: 'Reimportar',
    method: 'onClickImportarAbi',
  },
};

export const tabsDrawer = [
  {
    id: 0,
    title: 'Filtros',
    icon: 'mdi-filter-cog-outline',
    value: 'FILTRO',
    component: 'TabFiltros',
  },
];

export const defaultFilters = {
  id: null,
  status: null,
  abi: null,
  dataEmissaoInicial: null,
  dataEmissaoFinal: null,
  numeroOficio: null,
  numeroProcesso: null,
  dataRecebimentoInicial: null,
  dataRecebimentoFinal: null,
  tipoNotificacao: null,
  instancia: null,
  valorTotalInicial: null,
  valorTotalFinal: null,
};

export const itemsFilter = [
  {
    id: 1,
    title: 'Status',
    component: 'CheckboxItems',
    props: {
      label: 'Status',
      items: [
        { key: 1, label: 'Importar ABI', value: 'IMPORTAR_ABI' },
        { key: 2, label: 'Importando ABI', value: 'IMPORTANDO_ABI' },
        { key: 3, label: 'Reimportar ABI', value: 'REIMPORTAR_ABI' },
        { key: 4, label: 'ABI finalizada', value: 'FINALIZADA_ABI' },
        { key: 5, label: 'Atualizar decisão', value: 'ATUALIZAR_DECISAO' },
        { key: 6, label: 'Atualizando decisão', value: 'ATUALIZACAO_EM_ANDAMENTO' },
        { key: 7, label: 'Erro ao atualizar decisão', value: 'ERRO_ATUALIZAR_DECISAO' },
        { key: 8, label: 'Decisão atualizada', value: 'DECISAO_ATUALIZADA' },
        { key: 9, label: 'Iniciar cobrança', value: 'INICIAR_COBRANCA' },
        { key: 10, label: 'Cobrança iniciada', value: 'COBRANCA_INICIADA' },
        { key: 11, label: 'Cobrança finalizada', value: 'COBRANCA_FINALIZADA' },
        { key: 12, label: 'Incorporando o custo', value: 'INCORPORANDO_CUSTO' },
        { key: 13, label: 'Erro na atualização da cobrança', value: 'ERRO_ATUALIZAR_COBRANCA' },
        { key: 14, label: 'Erro ao importar ABI', value: 'ERRO_IMPORTAR_ABI' },
      ],
      property: 'status',
    },
  },
  {
    id: 2,
    title: 'ABI',
    component: 'InputData',
    props: {
      label: 'Nº do ABI',
      property: 'abi',
      type: 'number',
      maxLength: 20,
    },
  },
  {
    id: 3,
    title: 'Data de emissão',
    component: 'DateRange',
    props: {
      propertyInicial: 'dataEmissaoInicial',
      propertyFinal: 'dataEmissaoFinal',
    },
  },
  {
    id: 4,
    title: 'Nº do ofício',
    component: 'InputData',
    props: {
      label: 'Nº do ofício',
      property: 'numeroOficio',
      type: 'text',
      maxLength: 15,
      rules: [numeroOficioRule],
    },
  },
  {
    id: 5,
    title: 'Nº do processo',
    component: 'InputData',
    props: {
      label: 'Nº do processo',
      property: 'numeroProcesso',
      type: 'number',
      maxLength: 20,
    },
  },
  {
    id: 6,
    title: 'Data limite recebimento',
    component: 'DateRange',
    props: {
      propertyInicial: 'dataRecebimentoInicial',
      propertyFinal: 'dataRecebimentoFinal',
    },
  },
  {
    id: 7,
    title: 'Instância',
    component: 'CheckboxItems',
    props: {
      label: 'Instância',
      property: 'instancia',
      items: [
        { key: 1, label: 'Não impugnados', value: 'Não Impugnados' },
        { key: 2, label: '1ª Instância', value: '1ª Instância' },
        {
          key: 3,
          label: '1ª Instância reconsideração',
          value: '1ª Instância Reconsideração',
        },
        { key: 4, label: 'Não recursados', value: 'Não Recursados' },
        { key: 5, label: '2ª Instância', value: '2ª Instância' },
        { key: 6, label: '2ª Instância reconsideração', value: '2ª Instância Reconsideração' },
      ],
    },
    tabsToShow: ['DECISAO', 'COBRANCA'],
  },
  {
    id: 8,
    title: 'Valor total',
    component: 'DecimalRange',
    props: {
      propertyInicial: 'valorTotalInicial',
      propertyFinal: 'valorTotalFinal',
    },
  },
];

export const menuItems = [
  {
    key: 'EXPORTAR',
    label: 'Exportar',
    items: [
      {
        key: 'OFICIOS_NOTIFICADOS',
        label: 'Ofícios notificados(.xlsx)',
        event: 'exportar-relatorio',
      },
    ],
  },
];
