import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getInstancias = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/instancia?dadoAns=true`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateInstanciasService = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/instancia`,
    onSuccess,
    onCustomError,
  });

export { getInstancias, updateInstanciasService };
