import { createCommandService } from '@/services';
import { ressusURI } from '@/api';

const getResumoAbi = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/abi/${id}/resumo/abi`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getResumoDecisao = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/abi/${id}/resumo_decisao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getResumoCobranca = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/cobranca/abi/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateFieldsResumoAbi = ({ payload, onSuccess, onCustomError }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/abi`,
    onSuccess,
    onCustomError,
  });

const cancelarCobrancaService = ({ cobrancaId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/cobranca/cancelar/${cobrancaId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getResumoAbi,
  updateFieldsResumoAbi,
  getResumoCobranca,
  getResumoDecisao,
  cancelarCobrancaService,
};
