const listarVisualizacaoColuna = () => `{
	listarVisualizacaoColuna(
		filter: { 
			contexto: "atendimento"		
		}
	) {
		id
		descricao		
		contexto
		colunas {
			colunaId
			nome
			descricao
			tituloTabela
			obrigatorio			
			ordem
			ativo
			secao
		}
	}
}`;

export default listarVisualizacaoColuna;
