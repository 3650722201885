import { isEqual } from 'lodash';
import omitByAndNil from '@/utils/compare-values';
import { formatMoney } from '@/utils/currency';
import { formatDateCompetency, formatDate } from '@/utils/date';

const formatadores = {
  competenciaInicial: formatDateCompetency,
  competenciaFinal: formatDateCompetency,
  valorTotalAtendimentos: formatMoney,
  valorTotalAIHs: formatMoney,
  valorTotalAPACs: formatMoney,
  valorTotalAtendimentosCO: formatMoney,
  valorTotalAtendimentosVD: formatMoney,
  dataOficioAbertura: formatDate,
  dataRecebimento: formatDate,
  dataContestacaoImpugnacao: formatDate,
};

const dadosResumoAbi = ({ resumoAbi }) =>
  resumoAbi?.map((resumo) =>
    Object.entries(resumo).reduce((acc, [key, value]) => {
      if (value !== null && formatadores[key]) {
        acc[key] = formatadores[key](value);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {}),
  );

const isInitialState = ({ initialState, resumoAbi }) =>
  isEqual(omitByAndNil(initialState), omitByAndNil(resumoAbi));

const dadosDecisao = ({ dadosDecisao }) => ({
  ...dadosDecisao.map((item) => ({
    ...item,
    dataOficio: formatDate(item?.dataOficio),
    valorTotalAtendimentos: formatMoney(item?.valorTotalAtendimentos || 0),
    prazoContestacao: formatDate(item?.prazoContestacao),
    envioContestacao: formatDate(item?.envioContestacao),
    valorTotalDeferidos: formatMoney(item?.valorTotalDeferidos || 0),
    valorTotalIndeferidos: formatMoney(item?.valorTotalIndeferidos || 0),
    valorTotalDeferidosParcial: formatMoney(item?.valorTotalDeferidosParcial || 0),
  })),
});

const dadosCobranca = ({ dadosCobranca }) =>
  dadosCobranca.map((item) => ({
    ...item,
    dataRecebimento: formatDate(item?.dataRecebimento),
    vencimentoGru: formatDate(item?.vencimentoGru),
    valorTotalComJuros: formatMoney(item?.valorTotalComJuros || 0),
    valorTotalSemJuros: formatMoney(item?.valorTotalSemJuros || 0),
    valorIndeferido: formatMoney(item?.valorIndeferido || 0),
    valorDeferidoParcial: formatMoney(item?.valorDeferidoParcial || 0),
    dataIncorporacaoCusto: formatDate(item?.dataIncorporacaoCusto),
  }));

export default {
  dadosResumoAbi,
  isInitialState,
  dadosDecisao,
  dadosCobranca,
};
