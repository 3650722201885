<template>
  <ui-modal v-bind="{ show }" title="Definir motivo" width="1057" @close="$emit('close')">
    <re-loader :loading="loadingTable" />
    <section class="h-[339px] my-5 pl-11 pr-8 mr-8 overflow-auto">
      <div v-if="maisDeUmMotivoAlegacao">
        <v-row no-gutters class="mb-4">
          <span class="text-sm text-primary-blue-base">
            Selecione qual ou quais motivos de alegação.
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col v-for="(motivo, index) in allMotivos" sm="12" md="6" :key="index">
            <v-checkbox
              v-model="motivosSelecionados"
              multiple
              hide-details
              class="mt-0 pt-0 h-10"
              :label="`${motivo.codigo} - ${motivo.descricaoAbreviada}`"
              :value="motivo"
              :data-test="`checkbox-${motivo.id}`"
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row no-gutters class="mb-4">
          <span class="text-sm text-primary-blue-base"> Selecione qual o motivo de alegação. </span>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-radio-group
              mandatory
              hide-details
              row
              class="ma-0 pa-0"
              :value="motivo"
              @change="(value) => motivoSelecionado(value)"
            >
              <v-radio
                v-for="(motivo, index) in allMotivos"
                class="mt-2"
                :data-test="`radio-${index}`"
                :key="index"
                :label="`${motivo.codigo} - ${motivo.descricaoAbreviada}`"
                :value="motivo"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
    </section>
    <v-divider class="bg-neutral-gray-medium" />
    <v-card-actions>
      <v-sheet width="100%" height="48" class="flex items-center justify-end">
        <v-btn
          outlined
          class="btn btn__outlined mr-6"
          data-test="btn-cancelar"
          @click="$emit('close')"
        >
          Cancelar
        </v-btn>
        <v-btn class="btn btn__primary" data-test="btn-salvar" @click="definirMotivo">
          Salvar
        </v-btn>
      </v-sheet>
    </v-card-actions>
  </ui-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { pagination } from '@/constants';
import { ReLoader, UiModal } from '@/components';

export default {
  name: 'ModalDefinirMotivo',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    selecteds: Array,
    urlQueries: Object,
    idAbi: String,
    todosAtendimentos: Boolean,
    tabSelected: String,
  },
  components: { ReLoader, UiModal },
  data() {
    return {
      allMotivos: [],
      motivosSelecionados: [],
      motivo: {},
    };
  },
  computed: {
    ...mapState('ressus/cadastros/motivoImpugnacao', ['loadingTable', 'motivosImpugnacao']),
    ...mapGetters('ressus/parametros/integracao', ['maisDeUmMotivoAlegacao']),
    motivoImpugnacaoIds() {
      if (this.maisDeUmMotivoAlegacao) {
        return this.motivosSelecionados.map(({ id }) => +id);
      }

      return [this.motivo.id];
    },
    selectedAtendimentoIds() {
      return this.selecteds?.map(({ id }) => +id);
    },
  },
  async created() {
    await this.loadParametrosInternos();
    await this.loadMotivoImpugnacao({
      page: pagination.page,
      size: pagination.maxSize,
      sort: 'id',
      tab: 'ATIVOS',
    });

    this.allMotivos = this.motivosImpugnacao;
  },
  methods: {
    ...mapActions('ressus/parametros/integracao', ['loadParametrosInternos']),
    ...mapActions('ressus/cadastros/motivoImpugnacao', ['loadMotivoImpugnacao']),
    ...mapActions('ressus/processos/atendimentosAbi', ['definirMotivos']),
    motivoSelecionado(motivo) {
      this.motivo = motivo;
    },
    definirMotivo() {
      if (this.todosAtendimentos) {
        this.definirMotivos({
          todosAtendimentos: true,
          motivosImpugnacao: this.motivoImpugnacaoIds,
          idAbi: +this.idAbi,
          situacaoProcesso: this.tabSelected,
          urlQueries: this.urlQueries,
        });
      } else {
        this.definirMotivos({
          todosAtendimentos: false,
          atendimentos: this.selectedAtendimentoIds,
          motivosImpugnacao: this.motivoImpugnacaoIds,
          idAbi: +this.idAbi,
          urlQueries: this.urlQueries,
        });
      }

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-input--radio-group__input) {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
</style>
