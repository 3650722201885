import instancia from '../instancia/store';
import integracao from '../integracao/store';
import procedimentosTussSigtap from '../procedimentos-tuss-sigtap/store';
import criticasValidacao from '../criticas-validacao/store';
import contabilidade from '../contabilidade/store';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    instancia,
    integracao,
    procedimentosTussSigtap,
    criticasValidacao,
    contabilidade,
  },
};
