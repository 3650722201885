import moment from 'moment';

const DEFAULT_BRAZILIAN_FORMAT = 'DD/MM/YYYY';
const DEFAULT_BRAZILIAN_FORMAT_YEAR = 'DD/MM/YY';
const DEFAULT_BRAZILIAN_DATE_TIME_FORMAT = `${DEFAULT_BRAZILIAN_FORMAT} HH:mm:ss`;
const DEFAULT_BRAZILIAN_DATE_COMPETENCY = 'MM/YYYY';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT}[T]HH:mm:ss`;

const currentDate = (format = DEFAULT_DATE_FORMAT) => moment().format(format);

const formatDate = (
  date,
  destinationFormat = DEFAULT_BRAZILIAN_FORMAT,
  originFormat = DEFAULT_DATE_FORMAT,
) =>
  moment(date, originFormat).isValid() ? moment(date, originFormat).format(destinationFormat) : '';

const formatDateYear = (
  date,
  destinationFormat = DEFAULT_BRAZILIAN_FORMAT_YEAR,
  originFormat = DEFAULT_DATE_FORMAT,
) =>
  moment(date, originFormat).isValid() ? moment(date, originFormat).format(destinationFormat) : '';

const formatDateTime = (
  dateTime,
  destinationFormat = DEFAULT_BRAZILIAN_DATE_TIME_FORMAT,
  originFormat = DEFAULT_DATE_TIME_FORMAT,
) =>
  moment(dateTime, originFormat).isValid()
    ? moment(dateTime, originFormat).format(destinationFormat)
    : '';

const formatDateRestore = (date) => formatDate(date, DEFAULT_DATE_FORMAT, DEFAULT_BRAZILIAN_FORMAT);

const getPeriodoDeTempo = (date) => {
  if (!date) {
    return '';
  }

  const data = moment(date);
  const dataAtual = moment();

  const anos = dataAtual.diff(data, 'years');

  data.add(anos, 'years');

  const meses = dataAtual.diff(data, 'months');

  data.add(meses, 'months');

  const dias = dataAtual.diff(data, 'days');

  return `${anos}a, ${meses}m, ${dias}d`;
};

const getTargetDateFromNumberDays = ({ numberDays = 0, format = DEFAULT_BRAZILIAN_FORMAT }) => {
  const today = moment();

  return today.add(numberDays, 'days').format(format);
};

const getTime = (date) => (date ? moment(date).format('HH:mm:ss') : null);

const formatDateCompetency = (
  date,
  originFormat = DEFAULT_DATE_FORMAT,
  destinationFormat = DEFAULT_BRAZILIAN_DATE_COMPETENCY,
) =>
  moment(date, originFormat).isValid() ? moment(date, originFormat).format(destinationFormat) : '';

const formatDateCompetencyRestore = (date) =>
  moment(date, DEFAULT_BRAZILIAN_DATE_COMPETENCY).isValid()
    ? formatDateRestore(`01/${date}`, DEFAULT_DATE_FORMAT, DEFAULT_BRAZILIAN_FORMAT)
    : '';

const competencyFullDate = (competency = '') => {
  if (competency.includes('/')) {
    return competency.split('/').reverse().join('-').concat('-01');
  }

  return competency;
};

export {
  getPeriodoDeTempo,
  formatDate,
  formatDateYear,
  formatDateTime,
  formatDateRestore,
  currentDate,
  getTargetDateFromNumberDays,
  DEFAULT_BRAZILIAN_FORMAT,
  DEFAULT_DATE_FORMAT,
  getTime,
  formatDateCompetency,
  formatDateCompetencyRestore,
  competencyFullDate,
};
