import { formatDateTime } from '@/utils/date';
import { configStatus } from '../constants';

const documentos = ({ documentos }) =>
  documentos?.map((documento) => ({
    ...documento,
    actionLabel: configStatus[documento.status]?.actionLabel,
    actionMethod: configStatus[documento.status]?.actionMethod,
    dataSolicitacao: formatDateTime(documento.dataSolicitacao),
  }));

export default { documentos };
