import Vue from 'vue';
import VueRouter from 'vue-router';
import { configureBeforeEachRouter } from '@zitrus/utils-service';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '/ressus',
  routes: [
    {
      name: 'home',
      path: '/',
      redirect: '/dashboard',
      component: () =>
        import(/* webpackChunkName: "application-home" */ '../views/application-home.vue'),
      children: routes,
    },
    {
      path: '/404',
      component: () => import('../views/empty-state.vue'),
      name: 'not-found',
      meta: { emptyState: true, applicationKey: 'ressus', action: 'view' },
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('../views/empty-state.vue'),
      name: 'empty-state',
      meta: { emptyState: true, applicationKey: 'ressus', action: 'view' },
    },
  ],
});

router.beforeEach((to, from, next) => configureBeforeEachRouter(router, to, from, next));

export default router;
