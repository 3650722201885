import { pagination } from '@/constants';

const listarAtendimentos = ({
  page = pagination.page,
  size = pagination.size,
  sort = 'statusAtendimento',
  filters,
}) => `{
    listarAtendimentos(
      page: ${page - 1}
      size: ${size}
      sort: "${sort}"
        filter: ${filters}
      ) {
      content {
        id
        contagem
        competencia
        tipo
        numero
        valorTotal
        dataInicio
        dataFim
        naoImpugnado
        naoRecursado
        valorDeducaoDeferida
        statusProtocolo
        statusAtendimento
        descricaoStatusAtendimento
        valorRessarcimento
        quantidadeDocumentosComprobatorios
        mensagemDocumentosPendentes
        temDocumentosPendentes
        numeroFatura
        beneficiario {
          admissao
          codigo
          nomeCompleto
          codigoCco
          cpf
          dataNascimento
          contrato
          contratoFisico
          empresaContratante
          nomeEmpresaContratante
          cnpjEmpresaContratante
          registroProdutoAns
          tipoProduto
          vigencia
          adesao
          exclusao
          reinclusao
          formacaoPrecoProduto
          tipoContratacaoProduto
          segmentacaoProduto
          descricaoSegmentacaoProduto
          descricaoCoparticipacao
          unimedResponsavel
          codigoIbge
          dataFinalCarencia
          dataFinalCoberturaParcialTemporaria
          nomeProdutoAns
        }
        prestador {
          cnes
          nomeUps
          endereco {
            rua
            municipio
            codigoUf
            cep
          }
        }
        caraterAtendimento {
          codigo
          descricaoAbreviada
        }
        procedimentoPrincipal {
          valor
          quantidade
          procedimento {
            codigo
            descricao
          }
        }
        listagemMotivoImpugnacao {
          motivoImpugnacao {
            id
            codigo
            descricaoAbreviada
          }
        }
        nomeCriticasValidacao
        formularioImpugnacao {
          id
          statusFormulario
        }
        formularioRecursao {
          id
          statusFormulario
        }
        decisoesAtendimento {
          tipoAlegacaoAns
          decisao {
            numeroOficio
            dataRecebimento
            instancia {
              id
              codigo
              descricao
            }
          }
        }
        cobrancasAtendimento {
          id
          cobranca {
            id
            gru
            numeroOficio
            dataRecebimento
            acaoJudicial
            vencimentoGru
            dataIncorporacaoCusto
            valorDesconto
            observacao
            numeroProcesso
            instancia {
              descricao
            }
          }
        }
      }
      number
      totalPages
      totalElements
      size
      first
      last
    }
  }`;

export default listarAtendimentos;
