const forms = {
  codigo: null,
  ativo: true,
  descricaoAbreviada: null,
  descricaoOficial: null,
};

export default () => ({
  caraterAtendimentos: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingForm: false,
  caraterAtendimento: { ...forms },
  initialState: { ...forms, codigo: 0 },
});
