import { isEqual } from 'lodash';

const payloadDadosRelatorio = ({ dadosRelatorio }) => ({
  idAbi: Number(dadosRelatorio?.idAbi),
  dataPeriodoInicial: dadosRelatorio?.dataPeriodoInicial ? dadosRelatorio?.dataPeriodoInicial : '',
  dataPeriodoFinal: dadosRelatorio?.dataPeriodoFinal ? dadosRelatorio?.dataPeriodoFinal : '',
  nomeEmpresa: dadosRelatorio?.nomeEmpresa,
  codigoEmpresa: dadosRelatorio?.codigoEmpresa,
});

const isInitialState = (state) => isEqual(state.initialState, state.dadosRelatorio);

export default { payloadDadosRelatorio, isInitialState };
