const configProtocoloAnalise = {
  key: 'PROTOCOLO',
  label: 'Protocolos eletrônicos',
  method: 'onClickProtocoloEletronico',
  query: { tab: 'IMPUGNACAO' },
};

const configProtocoloProcesso = {
  key: 'PROTOCOLO',
  label: 'Protocolos eletrônicos',
  method: 'onClickProtocoloEletronico',
  query: { tab: 'RECURSAO' },
};

const configAcoesAnalise = {
  key: 'ACOES',
  label: 'Ações',
  items: [
    { key: 'IMPUGNAR', label: 'Impugnar', event: 'impugnar' },
    {
      key: 'NAO_IMPUGNAR',
      label: 'Não impugnar',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'nao-impugnar',
          method: 'onClickNaoImpugnarTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'nao-impugnar',
          method: 'onClickNaoImpugnarSelecionados',
        },
      ],
    },
    {
      key: 'DEFINIR_MOTIVO',
      label: 'Definir motivo',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'definir-motivo',
          method: 'onClickDefinirMotivoTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'definir-motivo',
          method: 'onClickDefinirMotivoSelecionados',
        },
      ],
    },
    {
      key: 'ASSINATURA_DIGITAL',
      label: 'Assinatura digital',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'assinatura-digital',
          method: 'onClickAssinaturaDigitalTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'assinatura-digital',
          method: 'onClickAssinaturaDigitalSelecionados',
        },
      ],
    },
    {
      key: 'GERAR_LAUDO',
      label: 'Gerar laudo',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'gerar-laudo',
          method: 'onClickGerarLaudoTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'gerar-laudo',
          method: 'onClickGerarLaudoSelecionados',
        },
      ],
    },
  ],
};

const configAcoesProcesso = {
  key: 'ACOES',
  label: 'Ações',
  items: [
    { key: 'RECURSAR', label: 'Recursar', event: 'recursar' },
    {
      key: 'NAO_RECURSAR',
      label: 'Não recursar',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'nao-recursar',
          method: 'onClickNaoRecursarTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'nao-recursar',
          method: 'onClickNaoRecursarSelecionados',
        },
      ],
    },
    {
      key: 'DEFINIR_MOTIVO',
      label: 'Definir motivo',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'definir-motivo',
          method: 'onClickDefinirMotivoTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'definir-motivo',
          method: 'onClickDefinirMotivoSelecionados',
        },
      ],
    },
    {
      key: 'ASSINATURA_DIGITAL',
      label: 'Assinatura digital',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'assinatura-digital',
          method: 'onClickAssinaturaDigitalTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'assinatura-digital',
          method: 'onClickAssinaturaDigitalSelecionados',
        },
      ],
    },
    {
      key: 'GERAR_LAUDO',
      label: 'Gerar laudo',
      children: [
        {
          key: 'TODOS',
          label: 'Todos os atendimentos',
          event: 'gerar-laudo',
          method: 'onClickGerarLaudoTodos',
        },
        {
          key: 'SELECIONADOS',
          label: 'Selecionados',
          event: 'gerar-laudo',
          method: 'onClickGerarLaudoSelecionados',
        },
      ],
    },
  ],
};

const configExportar = {
  key: 'EXPORTAR',
  label: 'Exportar',
  items: [
    {
      key: 'ATENDIMENTOS',
      label: 'Atendimentos (.xlsx)',
      children: [
        {
          key: 'TODAS_ABAS',
          label: 'Todas as abas',
          event: 'exportar-relatorio',
          path: 'atendimentos',
        },
        {
          key: 'ABA_SELECIONADA',
          label: 'Aba selecionada',
          event: 'exportar-relatorio',
          path: 'atendimentos',
        },
      ],
    },
    {
      key: 'DOCUMENTOS_PENDENTES',
      label: 'Documentos pendentes (.xlsx)',
      event: 'exportar-relatorio',
      path: 'documentos_comprobatorios',
    },
  ],
};

export const defaultStatus = {
  AGUARDANDO_IMPUGNACAO: {
    content: 'Aguardando impugnação',
    color: 'bg-neutral-gray-disabled',
  },
  AGUARDANDO_RECURSO: {
    content: 'Aguardando recurso',
    color: 'bg-neutral-gray-disabled',
  },
  AGUARDANDO_ASSINATURA: {
    content: 'Aguardando assinatura',
    color: 'bg-feedback-yellow',
  },
  PROTOCOLO_CANCELADO: {
    content: 'Protocolo cancelado',
    color: 'bg-feedback-red-high',
  },
  FALHA_NA_ASSINATURA: {
    content: 'Falha na assinatura',
    color: 'bg-feedback-red-high',
  },
  FORMULARIO_ASSINADO: {
    content: 'Formulário assinado',
    color: 'bg-feedback-orange-high',
  },
  MOTIVO_ENCONTRADO: {
    content: 'Motivo encontrado',
    color: 'bg-feedback-yellow',
  },
  MOTIVO_NAO_ENCONTRADO: {
    content: 'Motivo não encontrado',
    color: 'neutral-gray-medium',
  },
  FORMULARIO_ANDAMENTO: {
    content: 'Formulário em andamento',
    color: 'bg-feedback-yellow',
  },
  PROTOCOLADO: {
    content: 'Protocolado',
    color: 'bg-feedback-blue-high',
  },
};

const bgColorHiddenStatus = 'bg-neutral-gray-disabled';

export const hiddenStatus = {
  AGUARDANDO_IMPUGNACAO: {
    content: '',
    color: bgColorHiddenStatus,
  },
  AGUARDANDO_RECURSO: {
    content: '',
    color: bgColorHiddenStatus,
  },
  AGUARDANDO_ASSINATURA: {
    content: '',
    color: bgColorHiddenStatus,
  },
  PROTOCOLO_CANCELADO: {
    content: '',
    color: bgColorHiddenStatus,
  },
  FALHA_NA_ASSINATURA: {
    content: '',
    color: bgColorHiddenStatus,
  },
  FORMULARIO_ASSINADO: {
    content: '',
    color: bgColorHiddenStatus,
  },
  MOTIVO_ENCONTRADO: {
    content: '',
    color: bgColorHiddenStatus,
  },
  MOTIVO_NAO_ENCONTRADO: {
    content: '',
    color: bgColorHiddenStatus,
  },
};

export const tabs = [
  {
    id: 0,
    title: 'Impugnações em processo',
    value: 'EM_ANALISE',
    headerActions: [configProtocoloAnalise, configAcoesAnalise, configExportar],
    configStatus: defaultStatus,
  },
  {
    id: 1,
    title: 'Recursos em processo',
    value: 'EM_PROCESSO',
    headerActions: [configProtocoloProcesso, configAcoesProcesso, configExportar],
    configStatus: defaultStatus,
  },
  {
    id: 2,
    title: 'Aguardando cobrança',
    value: 'AGUARDANDO_COBRANCA',
    headerActions: [configExportar],
    configStatus: hiddenStatus,
  },
  {
    id: 3,
    title: 'Encerrado',
    value: 'ENCERRADO',
    headerActions: [configExportar],
    configStatus: hiddenStatus,
  },
  {
    id: 4,
    title: 'Cancelado pela ANS',
    value: 'CANCELADO_PELA_ANS',
    headerActions: [configExportar],
    configStatus: hiddenStatus,
  },
];

export const configHeader = {
  statusAtendimentoOrder: {
    value: 'statusAtendimentoOrder',
    sortable: true,
    hideText: true,
    cellClass: 'pa-0',
  },
  contagem: {
    value: 'contagem',
    sortable: true,
    hideText: true,
    width: 50,
  },
  tipo: { value: 'tipo', sortable: true },
  motivo: { value: 'motivos', sortable: false, width: 170 },
  statusAtendimento: { value: 'statusAtendimento', sortable: true },
  competencia: { value: 'competencia', sortable: true },
  numero: { value: 'numero', sortable: true },
  beneficiario: { value: 'beneficiario', sortable: false },
  codigoCco: { value: 'beneficiario.codigoCco', sortable: true },
  valorTotal: { value: 'valorTotal', sortable: true },
  cpf: { value: 'beneficiario.cpf', sortable: true },
  dataNascimento: { value: 'beneficiario.dataNascimento', sortable: true },
  periodoAtendimento: { value: 'periodoAtendimento', sortable: false },
  critica: { value: 'critica', sortable: false },
  unimedContrato: { value: 'beneficiario.unimedContrato', sortable: false },
  contratoFisico: { value: 'beneficiario.contratoFisico', sortable: true },
  empresaContratante: { value: 'beneficiario.empresa', sortable: false },
  cnpjEmpresaContratante: { value: 'beneficiario.cnpjEmpresaContratante', sortable: true },
  registroProdutoAns: { value: 'beneficiario.registroProdutoAns', sortable: true },
  tipoProdutoAns: { value: 'beneficiario.tipoProduto', sortable: true },
  dataAdmissao: { value: 'beneficiario.vigencia', sortable: true },
  adesao: { value: 'beneficiario.adesao', sortable: true },
  exclusao: { value: 'beneficiario.exclusao', sortable: true },
  reinclusao: { value: 'beneficiario.reinclusao', sortable: true },
  formacaoPrecoProduto: { value: 'beneficiario.formacaoPrecoProduto', sortable: true },
  tipoContratacaoProduto: { value: 'beneficiario.tipoContratacaoProduto', sortable: true },
  segmentacaoProduto: { value: 'beneficiario.segmentacao', sortable: true },
  descricaoCoparticipacao: { value: 'beneficiario.descricaoCoparticipacao', sortable: true },
  nomeProdutoAns: { value: 'beneficiario.nomeProdutoAns', sortable: false },
  cnes: { value: 'prestador.cnesPrestador', sortable: false },
  endereco: { value: 'prestador.endereco', sortable: false },
  municipio: { value: 'prestador.cidadeUf', sortable: false },
  cep: { value: 'prestador.cepCodIbge', sortable: false },
  caraterAtendimento: { value: 'caraterAtendimento', sortable: false },
  codigoProcedimento: { value: 'procedimentoPrincipal.procedimento.codigo', sortable: false },
  descricao: { value: 'procedimentoPrincipal.procedimento.descricao' },
  quantidadeProcedimento: { value: 'procedimentoPrincipal.quantidade', sortable: false },
  valor: { value: 'procedimentoPrincipal.valor', sortable: false },
  valorDeducao: { value: 'valorDeducao', sortable: false },
  valorRetificado: { value: 'valorRetificado', sortable: false },
  documentos: { value: 'documentos', sortable: false },
  alegacaoAns: { value: 'alegacaoAns', sortable: false },
  opiniao: { value: '', sortable: false },
  numeroOficio: { value: 'numeroOficioDecisao', sortable: false },
  instanciaProcesso: { value: 'instanciaProcesso', sortable: false },
  dataRecebimento: { value: 'dataRecebimento', sortable: false },
  valorDeducaoDeferida: { value: 'valorDeducaoDeferida', sortable: false },
  observacoes: { value: '', sortable: false },
  gru: { value: 'gru', sortable: false },
  acaoJudicial: { value: 'acaoJudicial', sortable: false },
  processoJudicial: { value: 'numeroProcesso', sortable: false },
  vencimentoGru: { value: 'vencimentoGru', sortable: false },
  dataCobranca: { value: 'dataCobranca', sortable: false },
  cobranca: { value: 'numeroOficioCobranca', sortable: false },
  instanciaCobranca: { value: 'instanciaCobranca', sortable: false },
  valorDesconto: { value: 'valorDesconto', sortable: false },
  valorRessarcimento: { value: 'valorRessarcimento', sortable: false },
  dataIncorporacaoCusto: { value: 'dataIncorporacaoCusto', sortable: false },
  numeroFatura: { value: 'numeroFatura', sortable: false },
  vigencia: { value: 'dataVigencia', sortable: false },
  dataFinalCarencia: { value: 'dataFinalCarencia', sortable: false },
  dataFinalCoberturaParcialTemporaria: {
    value: 'dataFinalCoberturaParcialTemporaria',
    sortable: false,
  },
  admissao: { value: 'admissao', sortable: false },
};

export const configIconDocumentos = {
  class: 'text-feedback-red-high pl-3',
  size: '18',
  icon: 'mdi-alert-circle',
};
