<template>
  <div>
    <v-form v-model="valid" lazy-validation class="mt-4" ref="form" @submit.prevent>
      <re-container show-footer show-actions>
        <template #actions>
          <v-row justify="space-between">
            <v-col cols="12" md="3">
              <v-btn
                outlined
                class="btn btn__outlined"
                data-test="btn-incluir-novo"
                :disabled="hasClickedOnIncluirNovo || loadingTableProcedimentosTussSigtap"
                @click="addProcedimento"
              >
                Incluir novo
              </v-btn>
            </v-col>
            <v-col cols="12" md="7" align="end" align-self="end">
              <div class="flex items-end justify-end gap-6">
                <v-btn
                  outlined
                  class="btn btn__outlined"
                  data-test="btn-importar"
                  :disabled="hasClickedOnIncluirNovo"
                  @click="toggleModalImportarCSV"
                >
                  <v-icon left class="text-xl">mdi-tray-arrow-up</v-icon>
                  Importar
                </v-btn>
                <re-input-search
                  v-model="search"
                  @search="onSearch"
                  @clear-search="onClearSearch"
                />
              </div>
            </v-col>
          </v-row>
        </template>
        <template #content>
          <v-data-table
            v-bind="{ headers, footerProps }"
            hide-default-header
            no-data-text="Nenhum registro encontrado."
            loading-text="Carregando registros..."
            class="mt-5"
            :items="procedimentosTussSigtap"
            :server-items-length="totalElements"
            :loading="loadingTableProcedimentosTussSigtap"
            :page="urlQueries.page"
            :items-per-page="urlQueries.size"
            :hide-default-footer="!procedimentosTussSigtap.length"
            @update:page="onChangePageProcedimentos"
            @update:items-per-page="onChangeItemsPerPage"
          >
            <template v-slot:[`item.codigoTuss`]="{ item }">
              <v-text-field
                v-model="item.codigoTuss"
                v-mask="'##########'"
                persistent-placeholder
                label="Código TUSS"
                placeholder="0000000000"
                data-test="text-field-codigo-tuss"
                :disabled="onCheckDisableItem(item)"
                :rules="[required]"
                @focus="({ target }) => onFocusField(target.value)"
                @blur="({ target }) => onBlurUpdateField(item, 'codigoTuss', target.value)"
              />
            </template>
            <template v-slot:[`item.termosTuss`]="{ item }">
              <v-text-field
                v-model="item.termosTuss"
                persistent-placeholder
                maxlength="200"
                label="Termos TUSS"
                placeholder="Preencher"
                data-test="text-field-termos-tuss"
                :disabled="onCheckDisableItem(item)"
                @focus="({ target }) => onFocusField(target.value)"
                @blur="({ target }) => onBlurUpdateField(item, 'termosTuss', target.value)"
              />
            </template>
            <template v-slot:[`item.codigoSus`]="{ item }">
              <v-text-field
                v-model="item.codigoSus"
                v-mask="'##########'"
                persistent-placeholder
                label="Código SUS"
                placeholder="0000000000"
                data-test="text-field-codigo-sus"
                :rules="[required]"
                :disabled="onCheckDisableItem(item)"
                @focus="({ target }) => onFocusField(target.value)"
                @blur="({ target }) => onBlurUpdateField(item, 'codigoSus', target.value)"
              />
            </template>
            <template v-slot:[`item.procedimentoSus`]="{ item }">
              <v-text-field
                v-model="item.procedimentoSus"
                persistent-placeholder
                maxlength="200"
                label="Procedimento SUS"
                placeholder="Preencher"
                data-test="text-field-procedimento-sus"
                :disabled="onCheckDisableItem(item)"
                @focus="({ target }) => onFocusField(target.value)"
                @blur="({ target }) => onBlurUpdateField(item, 'procedimentoSus', target.value)"
              />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y left :disabled="onCheckDisableItem(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                    data-test="icon-excluir"
                    class="text-primary-blue-base"
                  >
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="onShowModalExcluir(item.id)">
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </template>
        <template #footer>
          <v-spacer />
          <div class="flex justify-end gap-6">
            <v-btn
              class="btn btn__primary"
              data-test="btn-salvar"
              :disabled="isDisabled"
              @click="onSave"
            >
              Salvar
            </v-btn>
          </div>
        </template>
      </re-container>
    </v-form>
    <ui-modal
      type="excluir"
      :show="showModalExcluir"
      @close="onShowModalExcluir"
      @cancel="onShowModalExcluir"
      @confirm="onDelete"
    />
    <ui-modal
      type="salvar"
      :show="showModalAtencao"
      @close="onShowModalAtencao(true)"
      @exit="onCancel"
      @save="onSave"
    />
    <modal-importar-csv :show="showModalImportarCSV" @close="toggleModalImportarCSV" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { isEqual } from 'lodash';
import MixinGridContext from '@/mixins/mixin-grid-context';
import { required, validDate, minDate, maxDate } from '@/utils/validation-rules';
import { ReContainer, ReInputSearch, UiModal } from '@/components';
import { headers, footerProps } from './constants';
import ModalImportarCsv from './components/modal-importar-csv.vue';
import MixinParametros from '../mixin-parametros';

export default {
  name: 'ProcedimentosTussSigtap',
  mixins: [MixinGridContext, MixinParametros],
  components: {
    ReContainer,
    ReInputSearch,
    ModalImportarCsv,
    UiModal,
  },
  data() {
    return {
      headers,
      footerProps,
      required,
      validDate,
      minDate,
      maxDate,
      valid: true,
      redirectRoute: 'procedimentos-tuss-sigtap',
      showModalExcluir: false,
      procedimentoToDeleteId: '',
      previousValue: null,
    };
  },
  computed: {
    ...mapState('ressus/parametros/procedimentosTussSigtap', [
      'hasUpdatedProcedimento',
      'loadingTableProcedimentosTussSigtap',
      'hasClickedOnIncluirNovo',
      'totalElements',
      'showModalImportarCSV',
      'initialState',
    ]),
    ...mapGetters('ressus/parametros/procedimentosTussSigtap', ['procedimentosTussSigtap']),
    isInitialState() {
      return isEqual(this.initialState, this.procedimentosTussSigtap);
    },
    isDisabled() {
      return (this.isInitialState || this.showModalImportarCSV) && !this.hasUpdatedProcedimento;
    },
  },
  created() {
    this.onLoad();
  },
  methods: {
    ...mapActions('ressus/parametros/procedimentosTussSigtap', [
      'loadProcedimentosTussSigtap',
      'createProcedimentosTussSigtap',
      'deleteProcedimentosTussSigtap',
      'updateProcedimentosTussSigtap',
      'updateProcedimentosField',
      'addNewProcedimento',
      'deleteNewProcedimento',
      'setHasUpdatedProcedimento',
      'toggleModalImportarCSV',
      'resetState',
    ]),
    onChangePageProcedimentos(page) {
      this.deleteNewProcedimento();

      this.onChangePage(page);
    },
    onLoad() {
      this.showModalAtencao = false;
      this.loadProcedimentosTussSigtap({ ...this.urlQueries, pesquisa: this.search || '' });
    },
    onFocusField(value) {
      this.previousValue = value;
    },
    async onBlurUpdateField(item, property, value) {
      if (value === this.previousValue || !item.id || !this.valid) return;

      await this.updateProcedimentosTussSigtap({
        payload: {
          ...item,
          [property]: value,
        },
        query: { ...this.urlQueries, pesquisa: this.search },
      });

      this.search && this.onClearSearch();
    },
    onShowModalExcluir(id = '') {
      this.procedimentoToDeleteId = id;

      this.showModalExcluir = !this.showModalExcluir;
    },
    onShowModalAtencao() {
      this.showModalAtencao = !this.showModalAtencao;
    },
    async onSave() {
      this.showModalAtencao = false;

      await this.$refs.form.validate();

      if (this.valid) {
        if (this.hasClickedOnIncluirNovo) {
          this.createProcedimentosTussSigtap({
            payload: this.procedimentosTussSigtap[0],
            query: this.urlQueries,
          });
        } else {
          this.$toast('Procedimento(s) salvo(s) com sucesso', { type: 'success' });
          this.loadProcedimentosTussSigtap(this.urlQueries);
        }
      }
    },
    async addProcedimento() {
      this.addNewProcedimento();

      await this.$refs.form.resetValidation();
    },
    async onDelete() {
      this.procedimentoToDeleteId
        ? await this.deleteProcedimentosTussSigtap({
            id: this.procedimentoToDeleteId,
            query: this.urlQueries,
          })
        : this.deleteNewProcedimento();

      this.search = '';
      this.onShowModalExcluir();
    },
    onCheckDisableItem(item) {
      return this.hasClickedOnIncluirNovo && !!item?.id;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-data-table__empty-wrapper) {
  background-color: theme('colors.neutral-gray-low');
}

:deep(tbody td) {
  color: theme('colors.primary-blue-base') !important;
  font-size: 12px !important;
}

:deep(.v-data-table__wrapper) {
  overflow-x: hidden !important;
}

:deep(td:first-child) {
  width: 153px;
  border-top: none !important;
  padding-left: 0px !important;
}

:deep(td) {
  border-color: theme('colors.neutral-gray-disabled') !important;
  padding: 24px 20px 10px 0px !important;
}

:deep(td:last-child) {
  padding: 0px !important;
}

:deep(.v-data-footer) {
  border-top: none !important;
}

:deep(tr:hover) {
  background-color: theme('colors.white') !important;
}
</style>
