import initialState from './state';
import { INITIAL_PAGE } from '../constants';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  RESET_NOTIFICATION_STATE(state) {
    state.notificacoes = [];
    state.page = INITIAL_PAGE;
    state.totalElements = 1;
  },
  GENERIC_MUTATION(state, { property, value }) {
    state[property] = value;
  },
};
