import { helpPages } from '@/constants';

export default [
  {
    path: '/resumo-abi/:id?',
    name: 'resumo-abi',
    component: () => import(/* webpackChunkName: "resumo-abi" */ './index.vue'),
    meta: {
      title: 'Processos',
      subtitle: 'Resumo do Aviso de Beneficiário Identificado - ABI',
      helpPage: helpPages['resumo-abi'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
