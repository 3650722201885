import Vue from 'vue';
import omitByAndNil from '@/utils/compare-values';
import {
  getGruposTrabalho,
  getGrupoTrabalhoById,
  createGrupoTrabalhoService,
  updateGrupoTrabalhoService,
  deleteGrupoTrabalhoService,
  findAllUsuariosService,
} from '../services';
import redirectRouteUpdate from '../../utils';

const loadGruposTrabalho = async ({ commit }, query) => {
  commit('RESET_STATE');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const { payload } = await getGruposTrabalho(query);

  commit('GENERIC_MUTATION', {
    property: 'gruposTrabalho',
    payload: payload?.listarGrupoTrabalho?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload?.listarGrupoTrabalho?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarGrupoTrabalho?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadGrupoTrabalhoById = async ({ commit, dispatch }, id) => {
  dispatch('setLoadingForm', true);
  dispatch('ressus/updateControlModalAtencao', true, { root: true });

  await getGrupoTrabalhoById({
    id,
    onSuccess: ({ data }) => {
      const updatedData = {
        ...data,
        quantidadeParticipantes: data?.usuarios?.length,
      };

      commit('GENERIC_MUTATION', {
        property: 'grupoTrabalho',
        payload: omitByAndNil(updatedData),
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: omitByAndNil(updatedData),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const createGrupoTrabalho = ({ getters, dispatch, commit }, { saveAndNew, resetValidation }) => {
  dispatch('setLoadingForm', true);

  const { payloadToSaveAndUpdate } = getters;

  createGrupoTrabalhoService({
    payload: payloadToSaveAndUpdate,
    onSuccess: () => {
      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      !saveAndNew && Vue.router.push({ name: 'grupo-trabalho' });
      Vue.$toast('Grupo de trabalho incluído com sucesso.', { type: 'success' });

      resetValidation();
      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateGrupoTrabalho = (
  { getters, dispatch, commit },
  { saveAndNew, resetValidation, route },
) => {
  dispatch('setLoadingForm', true);

  const { payloadToSaveAndUpdate } = getters;

  updateGrupoTrabalhoService({
    payload: payloadToSaveAndUpdate,
    onSuccess: () => {
      resetValidation();
      commit('RESET_STATE');

      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      redirectRouteUpdate(saveAndNew, 'grupo-trabalho', route);
      Vue.$toast('Grupo de trabalho alterado com sucesso.', { type: 'success' });
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteGrupoTrabalho = ({ commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  deleteGrupoTrabalhoService({
    id,
    onSuccess: () => {
      Vue.$toast('Grupo de trabalho excluído com sucesso.', { type: 'success' });

      dispatch('loadGruposTrabalho', query);
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });
    },
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoadingForm = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: value,
  });

const findAllUsuarios = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: true,
  });

  await findAllUsuariosService({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'participantes',
        payload: data,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingForm',
        payload: false,
      }),
  });
};

export default {
  loadGruposTrabalho,
  loadGrupoTrabalhoById,
  createGrupoTrabalho,
  updateGrupoTrabalho,
  deleteGrupoTrabalho,
  updateField,
  resetState,
  setLoadingForm,
  findAllUsuarios,
};
