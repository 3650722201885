const payloadInstancias = ({ instancias }) =>
  instancias.map((instancia) => ({
    codigo: instancia.codigo,
    prazo: instancia.prazo,
    frequenciaNotificacao: instancia.frequenciaNotificacao,
  }));

export default {
  payloadInstancias,
};
