const pause = (time) =>
  new Promise((resolve) => {
    global.setTimeout(() => resolve(), time);
  });

const startLoading = ({ commit, dispatch, state }, startParams) => {
  if (!state.isActive) {
    commit('RESET_STATE');
    commit('GENERIC_MUTATION', {
      property: 'startParams',
      payload: { ...state.startParams, ...startParams },
    });
    commit('GENERIC_MUTATION', { property: 'isActive', payload: true });

    dispatch('percentageIterator');
  }
};

const percentageIterator = async ({ commit, state, getters, dispatch }) => {
  const { delayByPercentageUnit } = state.startParams;

  const TOTAL_PERCENTAGE_TO_ITERATOR = 99;

  let delay = delayByPercentageUnit;

  for (let percentage = 1; percentage <= TOTAL_PERCENTAGE_TO_ITERATOR; percentage++) {
    commit('GENERIC_MUTATION', {
      property: 'iterationIsActive',
      payload: true,
    });

    if (state.finishProcessWasCalled && getters.canImmediateFinish) {
      break;
    }

    commit('GENERIC_MUTATION', {
      property: 'percentage',
      payload: percentage,
    });

    dispatch('setCurrentStep', percentage);

    delay = getters.getDelay(delay, percentage);

    await pause(delay);
  }

  commit('GENERIC_MUTATION', {
    property: 'iterationIsActive',
    payload: false,
  });

  if (state.finishProcessWasCalled) {
    dispatch('setEndStep');
  }
};

const findStep = (steps, percentage, feedbackCondition) => {
  const filteredSteps = steps?.filter?.((step) => step.percentage === percentage);
  return filteredSteps?.find?.(
    (step) => !step?.feedbackCondition || step?.feedbackCondition === feedbackCondition,
  );
};

const setCurrentStep = ({ commit, state, getters }, percentage) => {
  const { steps = [] } = state.startParams;

  const currentStep = findStep(steps, percentage, state?.endParams?.feedbackCondition);

  if (getters.canRenderCurrentStep(currentStep)) {
    commit('GENERIC_MUTATION', {
      property: 'currentStep',
      payload: currentStep,
    });
  }
};

const finishLoading = async ({ commit, dispatch, state, getters }, endParams) => {
  if (state.isActive) {
    commit('GENERIC_MUTATION', {
      property: 'endParams',
      payload: endParams,
    });

    getters.canImmediateFinish && dispatch('setEndStep');

    commit('GENERIC_MUTATION', {
      property: 'finishProcessWasCalled',
      payload: true,
    });
  }
};

const interruptAndClose = ({ commit }) => commit('RESET_STATE');

const setEndStep = async ({ state, commit, getters }) => {
  const { canEndRenderAnimation } = getters;

  commit('GENERIC_MUTATION', {
    property: 'currentStep',
    payload: state?.endParams?.endStep,
  });

  commit('GENERIC_MUTATION', {
    property: 'percentage',
    payload: 100,
  });

  if (canEndRenderAnimation) {
    state?.endParams?.onFinish?.() ?? state?.startParams?.onFinish?.();
    commit('RESET_STATE');
  }
};

const onClickConfirmButton = ({ commit, state }) => {
  state.currentStep.onClickConfirmButton?.();
  commit('RESET_STATE');
};

const onCloseWithModal = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  startLoading,
  percentageIterator,
  setCurrentStep,
  finishLoading,
  setEndStep,
  onClickConfirmButton,
  interruptAndClose,
  onCloseWithModal,
};
