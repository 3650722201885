import Vue from 'vue';
import jwtDecode from 'jwt-decode';
import router from '@/router';
import reloadUserPermissions from '@/services/permissions';

const toggleNotificationMenu = ({ state, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'showNotificationMenu',
    payload: !state.showNotificationMenu,
  });
};

const updateField = ({ rootState, dispatch }, payload) => {
  const gridType = {
    OFICIOS_NOTIFICADOS: {
      state: rootState?.ressus?.oficiosNotificacao?.oficiosNotificacao,
      action: 'ressus/oficiosNotificacao/updateFieldStatus',
    },
    PROTOCOLOS_ELETRONICOS: {
      state: rootState?.ressus?.processos?.protocolosEletronicos?.atendimentos,
      action: 'ressus/processos/protocolosEletronicos/updateFieldStatus',
    },
    DOCUMENTOS_GERADOS: {
      state: rootState?.ressus?.processos?.documentosGerados?.documentos,
      action: 'ressus/processos/documentosGerados/updateFieldStatus',
    },
  };

  const updatedPayload = gridType[payload?.path]?.state?.map((obj) => {
    const { itens } = payload;

    itens?.forEach((item) => {
      if (item.id === +obj.id) {
        if (item.campo !== 'statusProtocolo' && obj.formularioRecursao) {
          obj.formularioRecursao[item.campo] = item.valor;
        } else if (item.campo !== 'statusProtocolo' && obj.formularioImpugnacao) {
          obj.formularioImpugnacao[item.campo] = item.valor;
        } else {
          obj[item.campo] = item.valor;
        }
      }
    });

    return obj;
  });

  dispatch(gridType[payload?.path]?.action, updatedPayload, { root: true });
};

const updateControlModalAtencao = ({ commit }, payload) => {
  commit('GENERIC_MUTATION', {
    property: 'controlModalAtencao',
    payload,
  });
};

const selectApplication = async () => {
  const applicationKey = router.options.base.substring(1);

  await reloadUserPermissions({
    applicationKey,
    onSuccess: async ({ data }) => {
      const jwt = jwtDecode(data?.permissions);

      localStorage.setItem('permissions', JSON.stringify(jwt.profiles[0].permissions));
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar permissões do usuário');
    },
  });
};

export default {
  toggleNotificationMenu,
  updateField,
  updateControlModalAtencao,
  selectApplication,
};
