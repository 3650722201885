import { formDockModal, formSolicitacao } from '../constants';

export default () => ({
  loading: false,
  solicitacaoDocumentos: [],
  totalElements: 0,
  totalPages: 0,
  showDockModal: false,
  showModalConfirmacao: false,
  loadingDockModal: false,
  dadosDockModal: { ...formDockModal },
  initialStateDadosDockModal: { ...formDockModal },
  formSolicitacao: { ...formSolicitacao },
  showModalSolicitacao: false,
  loadingDocumentos: false,
  destinatarios: [],
  queryParams: {},
  temDocumentos: false,
  pertenceGrupo: false,
});
