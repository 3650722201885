export default () => ({
  startParams: {
    withModal: false,
    defaultModalTitle: '',
    delayByPercentageUnit: 26,
    immediate: false,
    onFinish: null,
    steps: [],
  },
  endParams: {
    immediate: false,
    onFinish: null,
    endStep: {},
  },
  isActive: false,
  finishProcessWasCalled: false,
  iterationIsActive: false,
  endStep: {},
  percentage: 1,
  currentStep: {},
});
