<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    hide-details
    dense
    clearable
    placeholder="Pesquisar"
    data-test="text-field-pesquisar"
    class="w-auto max-w-[265px]"
    @keyup.enter="$emit('search')"
    @click:clear.stop="$emit('clear-search')"
  >
    <template v-slot:append>
      <v-icon class="text-primary-blue-base" @click="$emit('search')"> mdi-magnify </v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'ReInputSearch',
};
</script>

<style lang="scss" scoped>
:deep(fieldset) {
  border: 1px solid theme('colors.primary-blue-base') !important;
}
</style>
