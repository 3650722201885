import { pagination } from '@/constants';

export default ({ page = pagination.page, size = pagination.size, id }) => `{
  listarDocumentoComprobatorioPorEmpresa(
    page: ${page - 1}
    size: ${size}
    empresaId: ${+id} ) {
        content {
          id
          assunto
          dataDocumento
          tipoDocumento {
            id
            codigo
            nome
            ativo
          }
          documento {
            id
            nome
            chave
            url
            tipo
            status
          }
        }
        number
        totalPages
        totalElements
        size
        first
        last
      }
    }`;
