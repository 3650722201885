<template>
  <v-row>
    <v-col>
      <re-input-money
        label="Inicial"
        data-test="input-money-valor-inicial"
        :value="filters[propertyInicial]"
        :rules="validatePropertyInicial"
        @keyup="
          updateField({
            property: propertyInicial,
            value: $event,
          })
        "
      />
    </v-col>
    <v-col>
      <re-input-money
        label="Final"
        data-test="input-money-valor-final"
        :value="filters[propertyFinal]"
        :rules="validatePropertyFinal"
        @keyup="
          updateField({
            property: propertyFinal,
            value: $event,
          })
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, minValue, maxValue } from '@/utils/validation-rules';
import ReInputMoney from '../re-input-money.vue';

export default {
  name: 'DecimalRange',
  props: {
    propertyInicial: String,
    propertyFinal: String,
  },
  components: { ReInputMoney },
  data() {
    return {
      required,
      minValue,
      maxValue,
    };
  },
  computed: {
    ...mapState('ressus/drawerFiltros', ['filters']),
    startValue() {
      return this.filters[this.propertyInicial];
    },
    endValue() {
      return this.filters[this.propertyFinal];
    },
    validatePropertyInicial() {
      if (this.startValue && this.endValue) {
        return [minValue(this.startValue, this.endValue)];
      }

      if (this.endValue) {
        return [required];
      }

      return [];
    },
    validatePropertyFinal() {
      if (this.startValue && this.endValue) {
        return [maxValue(this.startValue, this.endValue)];
      }

      return [];
    },
  },
  methods: {
    ...mapActions('ressus/drawerFiltros', ['updateField']),
  },
};
</script>
