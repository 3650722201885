const configModal = {
  salvar: {
    icon: 'mdi-information-outline',
    confirmMessage: 'Deseja salvar as alterações?',
    warningMessage:
      'Existem alterações em andamento que podem ser perdidas caso <br /> não forem salvas agora',
    rightButtons: [
      {
        name: 'Sair sem salvar',
        event: 'exit',
        dataTest: 'btn-sair',
        class: 'btn btn__outlined mr-6',
        outlined: true,
      },
      {
        name: 'Salvar',
        event: 'save',
        dataTest: 'btn-salvar',
        class: 'btn btn__primary',
      },
    ],
  },
  excluir: {
    icon: 'mdi-information-outline',
    confirmMessage: 'Deseja realmente excluir?',
    warningMessage: 'Ao excluir todos os dados serão apagados permanentemente',
    rightButtons: [
      {
        name: 'Excluir',
        event: 'confirm',
        dataTest: 'btn-excluir',
        class: 'btn btn__outlined mr-6',
        outlined: true,
      },
      {
        name: 'Cancelar',
        event: 'cancel',
        dataTest: 'btn-cancelar',
        class: 'btn btn__primary',
      },
    ],
  },
};

export default configModal;
