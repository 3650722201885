import { pagination } from '@/constants';

const getFilters = (filters) => `{
  ${filters.id ? `id: ${filters.id}` : ``}
  ${filters.nome ? `nome: "${filters.nome}"` : ``}
  ${filters.nomeUsuario ? `nomeUsuario: "${filters.nomeUsuario}"` : ``}
  ${filters.localGerado ? `localGerado: "${filters.localGerado}"` : ``}
  ${filters.status ? `status: [${filters.status}]` : ``}
  ${
    filters.dataInicial
      ? `dataSolicitacaoEntre: ["${filters.dataInicial}", "${filters.dataFinal}"]`
      : ``
  }
}`;

export default ({
  page = pagination.page,
  size = pagination.size,
  sort = 'id,desc',
  pesquisar = '',
  filters,
}) => `{
  listarDocumentos(
    page: ${page - 1}
    size: ${size}
    sort: ["${sort}"]
    filter: ${pesquisar ? `{ pesquisar: "${pesquisar}" }` : getFilters(filters)}
  ) {
    content {
      id
      nomeUsuario
      nome
      tipo
      status
      localGerado
      dataSolicitacao
      url
      expirado
    }
    number
    totalPages
    totalElements
    size
    first
    last
  }
}`;
