import { mapActions, mapState, mapGetters } from 'vuex';
import { tabsDrawer } from './constants';

export default {
  props: {
    closeDrawer: Boolean,
    isFormularioAtendimento: Boolean,
  },
  data() {
    return {
      tabsDrawer,
      showModalAtencao: false,
    };
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'loading',
      'tabIndex',
      'activeTab',
    ]),
    ...mapGetters('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'dataOnlyView',
    ]),
    targetTab() {
      return this.tabsDrawer[this.tabIndex]?.value;
    },
    footerActions() {
      return this.tabsDrawer[this.tabIndex]?.[this.$route.query.tab]?.footerActions;
    },
    isEncerrado() {
      return this.payloadDadosGerais?.situacaoProcesso === 'ENCERRADO';
    },
  },
  watch: {
    tabIndex: {
      async handler(newValue, oldValue) {
        this.removeActiveClass();
        this.sourceTab = oldValue;

        await this.onTargetAction({
          source: 'tabIndex',
          action: 'updateActiveTab',
          param: this.targetTab,
        });
      },
    },
    closeDrawer: {
      async handler() {
        await this.onTargetAction({
          source: 'closeDrawer',
          action: 'closeDrawerAtendimentos',
        });
      },
    },
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'updateTabIndex',
      'updateActiveTab',
      'closeDrawerAtendimentos',
    ]),
    onTargetAction({ source, action, param }) {
      if (this.activeTab === this.tab) {
        if (this.controlState) {
          this.isInitialState ? this[action](param) : this.onToggleModalAtencao(source);
        } else {
          this.setActiveClass();
          this[action](param);
        }
      }
    },
    onToggleModalAtencao(source) {
      if (source !== 'closeDrawer') {
        this.removeActiveClass();
      }

      this.sourceProperty = source;
      this.showModalAtencao = !this.showModalAtencao;
    },
    async handleClick(action) {
      await this[action.method](action);
    },
    isViewMode() {
      if (this.dataOnlyView?.somenteVisualizacao) {
        this.$toast(this.dataOnlyView?.messageToast, { type: 'info', id: 'view-mode' });
      } else {
        this.$toast('Somente visualização', { type: 'info', id: 'view-mode' });
      }
    },
    removeActiveClass() {
      const selectedTab = document.querySelector('.v-tabs--vertical .v-tab--active');

      selectedTab?.classList.remove('v-tab--active');
      selectedTab?.setAttribute('aria-selected', 'false');
    },
    setActiveClass() {
      const element = document.getElementById(`${this.activeTab}`);

      element?.classList.add('v-tab--active');
      element?.setAttribute('aria-selected', 'true');
    },
  },
};
