const btnNaoImpugnarOutlined = {
  key: 'NAO_IMPUGNAR',
  label: 'Não impugnar',
  outlined: true,
  class: 'btn btn__outlined',
  method: 'onClick',
  event: 'nao-impugnar',
};

const btnNaoRecursarOutlined = {
  key: 'NAO_RECURSAR',
  label: 'Não recursar',
  outlined: true,
  class: 'btn btn__outlined',
  method: 'onClick',
  event: 'nao-recursar',
};

const btnImpugnarOutlined = {
  key: 'IMPUGNAR',
  label: 'Impugnar',
  outlined: true,
  class: 'btn btn__outlined',
  method: 'onClick',
  event: 'impugnar',
};

const btnRecursarOutlined = {
  key: 'RECURSAR',
  label: 'Recursar',
  outlined: true,
  class: 'btn btn__outlined',
  method: 'onClick',
  event: 'recursar',
};

const btnSalvarGeral = {
  key: 'SALVAR',
  label: 'Salvar',
  class: 'btn btn__primary',
  method: 'updateAtendimento',
};

const btnSalvarProcedimento = {
  key: 'SALVAR',
  label: 'Salvar',
  class: 'btn btn__primary',
  method: 'onSaveDadosProcedimento',
};
const btnSalvarDecisao = {
  key: 'SALVAR',
  label: 'Salvar',
  class: 'btn btn__primary',
  method: 'updateDadosDecisao',
};

const tabsDrawer = [
  {
    id: 0,
    name: 'DadosGeral',
    title: 'Geral',
    icon: 'mdi-gamepad-circle-outline',
    value: 'GERAL',
    component: 'TabGeral',
    showFooter: true,
    save: 'onSaveDadosGeral',
    reset: 'resetDadosGeral',
    initialState: 'isInitialStateDadosGeral',
    EM_ANALISE: {
      footerActions: [btnNaoImpugnarOutlined, btnImpugnarOutlined, btnSalvarGeral],
      salvaSemAlterarSituacao: true,
    },
    EM_PROCESSO: {
      footerActions: [btnNaoRecursarOutlined, btnRecursarOutlined, btnSalvarGeral],
      salvaSemAlterarSituacao: true,
    },
    AGUARDANDO_COBRANCA: {
      footerActions: [btnSalvarGeral],
      salvaSemAlterarSituacao: false,
    },
    ENCERRADO: {
      footerActions: [btnSalvarGeral],
      salvaSemAlterarSituacao: false,
    },
    CANCELADO_PELA_ANS: {
      footerActions: [btnSalvarGeral],
      salvaSemAlterarSituacao: false,
    },
  },
  {
    id: 1,
    name: 'DadosContratoPlano',
    title: 'Contrato e plano',
    icon: 'mdi-file-sign',
    value: 'CONTRATO',
    component: 'TabContratoPlano',
    initialState: true,
    showFooter: false,
  },
  {
    id: 2,
    name: 'DadosProcedimento',
    title: 'Procedimento',
    icon: 'mdi-expand-all-outline',
    value: 'PROCEDIMENTO',
    component: 'TabProcedimento',
    showFooter: true,
    save: 'onSaveDadosProcedimento',
    reset: 'resetDadosProcedimento',
    initialState: 'isInitialStateDadosProcedimento',
    EM_ANALISE: {
      footerActions: [btnSalvarProcedimento],
    },
    EM_PROCESSO: {
      footerActions: [btnSalvarProcedimento],
    },
    AGUARDANDO_COBRANCA: {
      footerActions: [btnSalvarProcedimento],
    },
    CANCELADO_PELA_ANS: {
      footerActions: [btnSalvarProcedimento],
    },
  },
  {
    id: 3,
    name: 'DadosDecisao',
    title: 'Dados de decisão',
    icon: 'mdi-card-account-details-outline',
    value: 'DECISAO',
    component: 'TabDecisao',
    showFooter: true,
    save: 'onSaveDadosDecisao',
    reset: 'resetDadosDecisao',
    initialState: 'isInitialStateDadosDecisao',
    placeholder:
      'ABI sem retorno da ANS ou os dados de decisão não foram incluídos em “Ofícios notificados”',
    EM_ANALISE: {
      footerActions: [btnSalvarDecisao],
    },
    EM_PROCESSO: {
      footerActions: [btnSalvarDecisao],
    },
    AGUARDANDO_COBRANCA: {
      footerActions: [btnSalvarDecisao],
    },
    ENCERRADO: {
      footerActions: [btnSalvarDecisao],
    },
    CANCELADO_PELA_ANS: {
      footerActions: [btnSalvarDecisao],
    },
  },
  {
    id: 4,
    name: 'DadosCobranca',
    title: 'Dados de cobrança',
    icon: 'mdi-wallet-plus-outline',
    value: 'COBRANCA',
    component: 'TabCobranca',
    initialState: true,
    showFooter: false,
    placeholder:
      'ABI sem retorno da ANS ou os dados de cobrança não foram incluídos em “Ofícios notificados”',
  },
];

const enumAcaoJudicial = {
  S: 'Sim',
  N: 'Não',
};

const enumSituacaoJudicial = {
  S: 'Em trâmite',
  N: 'Encerrado-Indeferido',
  O: 'Encerrado-Deferido',
};

const enumTipoAlegacaoAns = {
  DEFERIDO: 'Deferido total',
  INDEFERIDO: 'Indeferido',
  DEFERIDO_PARCIAL: 'Deferido parcial',
};

const tiposAlegacaoAns = [
  { text: 'Deferido total', value: 'DEFERIDO' },
  { text: 'Deferido parcial', value: 'DEFERIDO_PARCIAL' },
  { text: 'Indeferido', value: 'INDEFERIDO' },
];

export {
  tabsDrawer,
  enumAcaoJudicial,
  enumSituacaoJudicial,
  enumTipoAlegacaoAns,
  tiposAlegacaoAns,
};
