const defaults = {
  locale: 'pt-br',
  currency: 'BRL',
  precision: 2,
};

const formatMoney = (value, options = defaults) =>
  value?.toLocaleString(options.locale, { minimumFractionDigits: options.precision });

const formatPrefixedMoney = (value, options = defaults) =>
  value?.toLocaleString(options.locale, { style: 'currency', currency: options.currency });

function formatMoneyRestore(value) {
  const valueWithoutDots = value?.replace(/\./g, '');
  const valueWithDots = valueWithoutDots?.replace(',', '.');
  const decimalValue = parseFloat(valueWithDots);

  return decimalValue ? decimalValue.toFixed(2) : parseFloat('0.00').toFixed(2);
}

export { formatMoney, formatPrefixedMoney, formatMoneyRestore };
