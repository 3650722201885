import { isEqual } from 'lodash';
import omitByAndNil from '@/utils/compare-values';

const payloadFormulario = ({ formulario }, _, rootState) => {
  const empresaId = rootState?.ressus?.cadastros?.empresa?.empresa?.id;

  return { ...formulario, titulo: 'Novo formulario', empresaId };
};

const isinitialStateFormulario = ({ initialStateFormulario, formulario }) =>
  isEqual(omitByAndNil(initialStateFormulario), omitByAndNil(formulario));

const formularios = ({ formularios }) =>
  formularios.map((formulario) => {
    const { descricaoAbreviada, ativo } = formulario.motivoImpugnacao;

    return {
      ...formulario,
      motivoImpugnacao: {
        ...formulario.motivoImpugnacao,
        descricaoAbreviada: ativo ? descricaoAbreviada : `Motivo inativado - ${descricaoAbreviada}`,
      },
    };
  });

export default {
  isinitialStateFormulario,
  payloadFormulario,
  formularios,
};
