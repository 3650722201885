<template>
  <div>
    <section class="pr-7">
      <h1 class="section-title-default mb-5">Informações prestador</h1>
      <v-row>
        <v-col cols="12" md="6">
          <re-popover-text-field
            v-bind="{ dataOnlyView }"
            label="CNES/prestador"
            element-ref="cnes-prestador"
            data-test="text-field-cnes-prestador"
            :value="dadosProcedimento.cnes"
          />
        </v-col>
        <v-col cols="12" md="6">
          <re-popover-text-field
            v-bind="{ dataOnlyView }"
            label="Caráter atendimento"
            element-ref="carater-atendimento"
            data-test="text-field-carater-atendimento"
            breakpoint="md"
            nudge-left="150"
            :value="dadosProcedimento.caraterAtendimento"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <re-popover-text-field
            v-bind="{ dataOnlyView }"
            label="Endereço"
            element-ref="endereco"
            data-test="text-field-endereco"
            :value="dadosProcedimento.endereco"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Cidade/UF"
            data-test="text-field-unidade-uf"
            :value="dadosProcedimento.municipio"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="CEP"
            data-test="text-field-cep"
            :value="dadosProcedimento.cep"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Cód. IBGE"
            data-test="text-field-ibge"
            :value="dadosProcedimento.codigoIbge"
          />
        </v-col>
      </v-row>
      <h1 class="section-title-default my-5">Procedimentos</h1>
      <v-row
        v-for="(procedimento, index) in dadosProcedimento.procedimentos"
        :key="procedimento.id"
      >
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Procedimento"
            data-test="text-field-codigo-procedimento"
            :value="procedimento.codigo"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Tipo"
            data-test="text-field-tipo-procedimento"
            :value="procedimento.tipo"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Quantidade"
            data-test="text-field-quant-procedimento"
            :value="procedimento.quantidade"
          />
        </v-col>
        <v-col cols="12" md="9">
          <re-popover-text-field
            v-bind="{ dataOnlyView }"
            label="Descrição"
            data-test="text-field-descricao-procedimento"
            :element-ref="`descricao-${index}`"
            :value="procedimento.descricao"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Valor (R$)"
            data-test="text-field-valor-procedimento"
            :value="procedimento.valorProcedimento"
          />
        </v-col>
        <v-col cols="12">
          <v-divider class="bg-neutral-gray-disabled" />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Quantidade total"
            data-test="text-field-quant-total"
            :value="dadosProcedimento.quantidadeTotal"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Valor total (R$)"
            data-test="text-field-valor-total"
            :value="dadosProcedimento.valorTotal"
          />
        </v-col>
        <v-col cols="12" md="3">
          <re-input-money
            v-if="!loading"
            hide-details
            label="Valor dedução (R$)"
            data-test="re-input-money-valor-deducao"
            :readonly="isEncerrado || isFormularioAtendimento"
            :value="dadosProcedimento.valorDeducao"
            @keyup="updateValorDeducao($event)"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            readonly
            persistent-placeholder
            hide-details
            label="Retificado (R$)"
            data-test="text-field-retificacao-valor"
            :value="valorRetificadoCalculado"
          />
        </v-col>
      </v-row>
    </section>
    <portal v-if="activeTab === tab" to="footer-drawer-atendimento">
      <v-btn
        v-for="action in footerActions"
        :key="action.key"
        :outlined="action.outlined"
        :text="action.text"
        :class="action.class"
        :data-test="`btn-${action.key}`"
        @click="handleClick(action)"
      >
        {{ action.label }}
      </v-btn>
    </portal>
    <ui-modal
      type="salvar"
      :show="showModalAtencao"
      @close="onClose"
      @exit="onExit"
      @save="onSaveDadosProcedimento"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { RePopoverTextField, ReInputMoney, UiModal } from '@/components';
import { formatMoneyRestore, formatMoney } from '@/utils/currency';
import MixinControlTabs from './mixin-control-tabs';

export default {
  name: 'TabProcedimento',
  mixins: [MixinControlTabs],
  components: { RePopoverTextField, ReInputMoney, UiModal },
  data() {
    return {
      tab: 'PROCEDIMENTO',
      controlState: true,
    };
  },
  computed: {
    ...mapState('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'loading',
      'situacaoProcesso',
      'payloadDadosGerais',
    ]),
    ...mapGetters('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'dadosProcedimento',
      'isInitialStateDadosProcedimento',
    ]),
    isInitialState() {
      return this.isInitialStateDadosProcedimento;
    },
    valorRetificadoCalculado() {
      return formatMoneyRestore(this.dadosProcedimento.valorDeducao) > 0
        ? formatMoney(
            formatMoneyRestore(this.dadosProcedimento.valorTotal) -
              formatMoneyRestore(this.dadosProcedimento.valorDeducao),
          )
        : formatMoney(0);
    },
  },
  methods: {
    ...mapActions('ressus/processos/atendimentosAbi/drawerDetalhamentoAtendimento', [
      'updateValorDeducao',
      'updateValorDeducaoProcedimento',
      'resetDadosProcedimento',
    ]),
    async onClose() {
      if (this.sourceProperty === 'tabIndex') {
        await this.updateTabIndex(this.sourceTab);
      }

      this.setActiveClass();
      this.showModalAtencao = false;
    },
    onExit() {
      if (this.sourceProperty === 'tabIndex') {
        this.showModalAtencao = false;

        this.resetDadosProcedimento();
        this.updateActiveTab(this.targetTab);
      } else {
        this.closeDrawerAtendimentos();
      }

      this.setActiveClass();
    },
    async onSaveDadosProcedimento() {
      if (this.sourceProperty === 'tabIndex') {
        await this.updateTabIndex(this.sourceTab);
        this.setActiveClass();
      }

      if (formatMoneyRestore(this.valorRetificadoCalculado) < 0) {
        this.$toast('O Valor dedução não pode ser maior que o Valor total', {
          type: 'error',
        });
      } else {
        this.updateValorDeducaoProcedimento();
      }

      this.showModalAtencao = false;
    },
  },
};
</script>
