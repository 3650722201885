<template>
  <ui-drawer
    show-left-column
    show-footer
    hide-overlay
    :value="showDrawerFiltros"
    @close="toggleDrawerFiltros"
  >
    <template #drawer-left-column>
      <v-tabs vertical slider-size="0" :value="tabIndex">
        <v-tab>
          <v-icon size="18"> mdi-filter-cog-outline </v-icon>
        </v-tab>
      </v-tabs>
    </template>
    <template #drawer-body>
      <re-loader v-bind="{ loading }" />
      <v-tabs-items :value="tabIndex">
        <v-tab-item>
          <h1 class="drawer-title-default">Filtros</h1>
          <section class="h-[calc(100vh-234px)] overflow-auto overflow-x-hidden">
            <v-form v-model="valid" lazy-validation ref="form" @submit.prevent>
              <re-collapse
                v-for="item in itemsFilter"
                v-show="onCheckShowComponent(item)"
                class="max-w-[592px] group"
                :key="item.id"
                :title="item.title"
                :data-test="`collapse-${item.id}`"
                :badge="getCounter(item)"
              >
                <template #content>
                  <component v-bind="{ ...item.props }" :is="item.component" />
                </template>
              </re-collapse>
            </v-form>
          </section>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template #drawer-footer>
      <div class="w-full flex justify-end gap-6">
        <v-btn text class="btn btn__text" data-test="btn-cancelar" @click="toggleDrawerFiltros">
          Cancelar
        </v-btn>
        <v-btn
          outlined
          class="btn btn__outlined"
          data-test="btn-limpar-filtros"
          @click="onClickLimparFiltros"
        >
          Limpar filtros
        </v-btn>
        <v-btn class="btn btn__primary" data-test="btn-aplicar" @click="onClickAplicar">
          Aplicar
        </v-btn>
      </div>
    </template>
  </ui-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UiDrawer from '../ui/ui-drawer.vue';
import ReLoader from '../re-loader.vue';
import ReCollapse from '../re-collapse.vue';
import InputData from './input-data.vue';
import DateRange from './date-range.vue';
import DecimalRange from './decimal-range.vue';
import CheckboxItems from './checkbox-items.vue';

export default {
  name: 'ReDrawerFiltros',
  props: {
    initialFiltersState: {
      type: Object,
      default: () => {},
    },
    defaultFilters: {
      type: Object,
      default: () => {},
    },
    itemsFilter: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    UiDrawer,
    ReLoader,
    ReCollapse,
    InputData,
    DateRange,
    DecimalRange,
    CheckboxItems,
  },
  data() {
    return {
      tabIndex: 0,
      valid: true,
      activeTab: this.$route.query.tab,
    };
  },
  computed: {
    ...mapState('ressus/drawerFiltros', ['showDrawerFiltros', 'loading', 'filters']),
  },
  mounted() {
    this.setFilterData({
      initialFiltersState: this.initialFiltersState,
      defaultFilters: this.defaultFilters,
      itemsFilter: this.itemsFilter,
    });
  },
  methods: {
    ...mapActions('ressus/drawerFiltros', ['toggleDrawerFiltros', 'setFilterData', 'resetFilters']),
    async onClickAplicar() {
      await this.$refs.form.validate();

      if (this.valid) {
        this.$emit('apply', this.filters);
        this.toggleDrawerFiltros();
      }
    },
    async onClickLimparFiltros() {
      await this.resetFilters();
      this.toggleDrawerFiltros();

      this.$emit('clear', {});
    },
    onCheckShowComponent(item) {
      if (item.tabsToShow) {
        return item.tabsToShow.includes(this.activeTab);
      }

      return true;
    },
    getCounter({ component, props }) {
      const property = this.filters[props.property] || 0;
      const propertyInicial = this.filters[props.propertyInicial] || 0;
      const propertyFinal = this.filters[props.propertyFinal] || 0;

      const counter = {
        CheckboxItems: property.length || 0,
        DecimalRange:
          (propertyInicial.length > 0 && propertyInicial !== '0,00' ? 1 : 0) +
          (propertyFinal.length > 0 && propertyFinal !== '0,00' ? 1 : 0),
        DateRange: (propertyInicial.length > 0 ? 1 : 0) + (propertyFinal.length > 0 ? 1 : 0),
        InputData: property.length > 0 ? 1 : 0,
      };

      return counter[component];
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  height: auto !important;
}

:deep(.v-navigation-drawer__content) {
  overflow: hidden !important;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 28px;
}

:deep(.v-tabs--vertical > .v-tabs-bar .v-tabs-bar__content) {
  align-items: center;
  gap: 30px;
  background-color: theme('colors.feedback-green-low');
}

.v-tabs {
  margin-top: 24px;

  .v-tab {
    min-width: 28px;
    max-width: 28px;

    &--active {
      border-radius: 4px;
      background-color: theme('colors.feedback-green-high');
    }

    .v-icon {
      color: theme('colors.primary-blue-base') !important;
    }
  }
}

:deep(.group) {
  .v-expansion-panel {
    margin-bottom: 0;
  }

  .v-expansion-panel-header {
    border: none;
    padding: 0;

    > div {
      font-weight: 400 !important;
    }
  }

  .v-expansion-panel--active > .v-expansion-panel-header {
    background: none;
    color: theme('colors.primary-blue-base');

    > div {
      font-weight: bold !important;
    }

    .v-icon {
      color: theme('colors.primary-blue-base');
    }
  }

  .v-expansion-panel-content {
    border: none;
    border-top: 1px solid;
    border-color: theme('colors.neutral-gray-disabled');

    .v-expansion-panel-content__wrap {
      padding: 20px 0 !important;
    }
  }
}
</style>
