import { formularioPadrao } from '../../constants';

export default () => ({
  loadingTableFormularios: false,
  loadingForm: false,
  totalElementsFormularios: 0,
  totalPagesFormularios: 0,
  formularios: [],
  formulario: { ...formularioPadrao },
  initialStateFormulario: { ...formularioPadrao },
  formErrorMessage: null,
  showModalFormulario: false,
  modalFormularioMode: null,
});
