import { helpPages } from '@/constants';

export default [
  {
    path: '/declaracao-beneficiarios',
    name: 'declaracao-beneficiarios',
    component: () =>
      import(
        /* webpackChunkName: "declaracao-beneficiarios" */
        './index.vue'
      ),
    meta: {
      title: 'Relatórios',
      subtitle: 'Declaração de vínculo de beneficiários',
      helpPage: helpPages['declaracao-beneficiarios'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
