import Vue from 'vue';
import omitByAndNil from '@/utils/compare-values';
import { formatDate } from '@/utils/date';
import {
  createEmpresaService,
  deleteEmpresaService,
  getEmpresaById,
  getEmpresas,
  updateEmpresaService,
  uploadDocumentoComprobatorioService,
  listarDocumentoComprobatorioPorEmpresaService,
  deleteDocumentoComprobatorioEmpresaService,
} from '../services';
import redirectRouteUpdate from '../../utils';

const loadEmpresas = async ({ commit, dispatch }, query) => {
  dispatch('resetState');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  const { payload } = await getEmpresas(query);

  commit('GENERIC_MUTATION', {
    property: 'empresas',
    payload: payload?.listarEmpresas?.content,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalElements',
    payload: payload.listarEmpresas?.totalElements,
  });

  commit('GENERIC_MUTATION', {
    property: 'totalPages',
    payload: payload?.listarEmpresas?.totalPages,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadEmpresaById = async ({ commit, dispatch }, { id, ...query }) => {
  dispatch('setLoadingForm', true);
  dispatch('ressus/updateControlModalAtencao', true, { root: true });

  dispatch('listarDocumentoComprobatorioPorEmpresa', { id });

  await getEmpresaById({
    id,
    onSuccess: async ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'empresa',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialStateEmpresa',
        payload: omitByAndNil(data),
      });

      await dispatch(
        'ressus/cadastros/empresa/formularioPadrao/loadFormulariosEmpresa',
        {
          empresaId: data?.id,
          ...query,
        },
        { root: true },
      );
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const createEmpresaAdicionarFormulario = ({ state, dispatch, commit }) => {
  dispatch('setLoadingForm', true);

  createEmpresaService({
    payload: state.empresa,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'empresa',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialStateEmpresa',
        payload: omitByAndNil(data),
      });

      Vue.router.replace({
        name: 'edit-empresa',
        params: { id: data?.id },
      });

      dispatch('ressus/cadastros/empresa/formularioPadrao/setShowModalFormulario', true, {
        root: true,
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateEmpresaAdicionarFormulario = ({ state, dispatch, getters, commit }) => {
  dispatch('setLoadingForm', true);

  if (getters.isInitialStateEmpresa) {
    dispatch('ressus/cadastros/empresa/formularioPadrao/setShowModalFormulario', true, {
      root: true,
    });
  } else {
    updateEmpresaService({
      payload: state.empresa,
      onSuccess: ({ data }) => {
        commit('GENERIC_MUTATION', {
          property: 'empresa',
          payload: data,
        });

        commit('GENERIC_MUTATION', {
          property: 'initialStateEmpresa',
          payload: omitByAndNil(data),
        });

        dispatch('ressus/cadastros/empresa/formularioPadrao/setShowModalFormulario', true, {
          root: true,
        });
      },
      onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
      onFinally: () => dispatch('setLoadingForm', false),
    });
  }
};

const createEmpresa = ({ commit, state, dispatch }, { saveAndNew, resetValidation }) => {
  dispatch('setLoadingForm', true);

  createEmpresaService({
    payload: state.empresa,
    onSuccess: ({ data }) => {
      dispatch('resetState');

      if (!saveAndNew) {
        commit('GENERIC_MUTATION', {
          property: 'tabsDisabled',
          payload: false,
        });
        Vue.router.replace({
          name: 'edit-empresa',
          params: { id: data?.id },
        });
      }

      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      Vue.$toast('Empresa incluída com sucesso.', { type: 'success' });

      resetValidation();
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const updateEmpresa = ({ state, dispatch }, { saveAndNew, resetValidation, route }) => {
  dispatch('setLoadingForm', true);

  const redirectList = {
    ...route,
    name: 'empresa',
  };

  updateEmpresaService({
    payload: state.empresa,
    onSuccess: () => {
      dispatch('resetState');

      dispatch('ressus/updateControlModalAtencao', !!saveAndNew, { root: true });
      redirectRouteUpdate(saveAndNew, 'create-empresa', redirectList);
      Vue.$toast('Empresa alterada com sucesso.', { type: 'success' });

      resetValidation();
    },
    onCustomError: ({ response }) => {
      dispatch('ressus/updateControlModalAtencao', true, { root: true });
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const deleteEmpresa = async ({ state, commit, dispatch }, { id, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await deleteEmpresaService({
    id,
    onSuccess: () => {
      Vue.$toast('Empresa excluída com sucesso.', { type: 'success' });

      if (state.empresas.length === 1) {
        Vue.router.replace({
          name: 'empresa',
          query: {
            ...query,
            page: query.page - 1,
          },
        });
      }

      dispatch('loadEmpresas', query);
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const updateField = ({ commit }, { property, value }) =>
  commit('UPDATE_FIELD', {
    property,
    value,
  });

const resetState = ({ commit, dispatch }) => {
  dispatch('ressus/cadastros/empresa/formularioPadrao/resetState', null, {
    root: true,
  });

  commit('RESET_FORM_STATE');
};

const setLoadingForm = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: value,
  });

const toggleModalUploadDocumento = ({ state, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'showModalUploadDocumento',
    payload: !state.showModalUploadDocumento,
  });

  commit('RESET_FORM_UPLOAD_DOCUMENTO');
};

const updateFieldUploadDocumento = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD_UPLOAD_DOCUMENTO', {
    property,
    value,
  });
};

const uploadDocumento = async ({ state, commit, dispatch }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingUploadDocumento',
    payload: true,
  });

  const formularioId = Vue.router.currentRoute.params.id;
  const { anexo, assunto, data, tipo } = state.dadosUploadDocumento;

  const request = {
    tipoDocumentoId: +tipo.id,
    assunto,
    dataDocumento: data,
  };

  const payload = new FormData();

  payload.append(
    'file',
    new Blob([anexo], {
      type: anexo.type,
    }),
    anexo.name,
  );

  payload.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  await uploadDocumentoComprobatorioService({
    formularioId,
    payload,
    onSuccess: ({ data }) => {
      dispatch('setDocumentosEmpresa', data);
      Vue.$toast('Documento adicionado com sucesso', { type: 'success' });
    },
    onCustomError: ({ response }) => {
      if (response?.data?.errors) {
        response.data.errors.map((error) =>
          Vue.$toast(error.defaultMessage, {
            type: 'error',
          }),
        );
      } else {
        Vue.$toast(response?.data?.message, {
          type: 'error',
        });
      }
    },
    onFinally: async () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingUploadDocumento',
        payload: false,
      });

      await dispatch('toggleModalUploadDocumento');
    },
  });
};

const updateDisabledTab = ({ commit }, value) =>
  commit('GENERIC_MUTATION', {
    property: 'tabsDisabled',
    payload: value,
  });

const listarDocumentoComprobatorioPorEmpresa = async ({ dispatch }, { id }) => {
  dispatch('setLoadingForm', true);

  const { payload } = await listarDocumentoComprobatorioPorEmpresaService({ id });

  dispatch('setDocumentosEmpresa', payload?.listarDocumentoComprobatorioPorEmpresa?.content);
  dispatch('setLoadingForm', false);
};

const excluirDocumentoComprobatorio = async ({ dispatch }, { empresaId, anexoId }) => {
  dispatch('setLoadingForm', true);

  await deleteDocumentoComprobatorioEmpresaService({
    empresaId,
    anexoId,
    onSuccess: () => {
      Vue.$toast('Documento excluído com sucesso.', { type: 'success' });
      dispatch('listarDocumentoComprobatorioPorEmpresa', { id: empresaId });
    },
    onCustomError: ({ response }) => {
      Vue.$toast(response?.data?.message, { type: 'error' });
    },
    onFinally: () => dispatch('setLoadingForm', false),
  });
};

const setDocumentosEmpresa = ({ commit }, value) => {
  const updatedContent = value?.map((item) => ({
    ...item,
    data: formatDate(item.documento.dataConclusao),
    tipo: item.documento.tipo,
    anexo: {
      name: item.documento.nome,
    },
  }));

  commit('GENERIC_MUTATION', {
    property: 'documentosEmpresa',
    payload: updatedContent,
  });
};

export default {
  createEmpresa,
  createEmpresaAdicionarFormulario,
  deleteEmpresa,
  loadEmpresaById,
  loadEmpresas,
  resetState,
  updateEmpresa,
  updateEmpresaAdicionarFormulario,
  updateField,
  setLoadingForm,
  toggleModalUploadDocumento,
  updateFieldUploadDocumento,
  uploadDocumento,
  updateDisabledTab,
  listarDocumentoComprobatorioPorEmpresa,
  excluirDocumentoComprobatorio,
  setDocumentosEmpresa,
};
