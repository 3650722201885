import { helpPages } from '@/constants';
import ProcedimentosTussSigtap from './index.vue';

export default [
  {
    path: '/procedimentos-tuss-sigtap',
    name: 'procedimentos-tuss-sigtap',
    component: ProcedimentosTussSigtap,
    meta: {
      title: 'Parâmetros',
      subtitle: 'Procedimentos TUSS x SIGTAP',
      helpPage: helpPages['procedimentos-tuss-sigtap'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
];
