<template>
  <v-menu
    open-on-hover
    offset-y
    min-width="auto"
    data-test="re-popover-text-field"
    :max-width="maxWidth"
    :nudge-top="nudgeTop"
    :nudge-right="nudgeRight"
    :nudge-bottom="nudgeBottom"
    :nudge-left="nudgeLeftPosition"
  >
    <template #activator="{ on }">
      <v-text-field
        v-on="on"
        v-bind="{ label, value }"
        persistent-placeholder
        readonly
        hide-details
        :ref="elementRef"
        @mouseover="controlPopoverShow(elementRef, value)"
        @focus="controlPopoverShow(elementRef, value)"
        @click="isViewMode"
      />
    </template>
    <v-card v-if="showPopover">
      <v-card-text class="text-primary-blue-base">
        {{ value }}
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { displayTextWidth } from '@/utils/string';

const FONT_USED = '16px Sora';

export default {
  name: 'RePopoverTextField',
  props: {
    elementRef: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '425',
    },
    dataOnlyView: {
      type: Object,
      default: () => {},
    },
    value: [Number, String],
    nudgeTop: [Number, String],
    nudgeRight: [Number, String],
    nudgeBottom: [Number, String],
    nudgeLeft: [Number, String],
    breakpoint: String,
  },
  data() {
    return {
      showPopover: false,
    };
  },
  computed: {
    nudgeLeftPosition() {
      let breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
      const breakpointsSelecteds = {};

      breakpoints = breakpoints.slice(breakpoints.indexOf(this.breakpoint), breakpoints.length);

      breakpoints.forEach((key) => {
        breakpointsSelecteds[key] = this.nudgeLeft;
      });

      return breakpointsSelecteds[this.$vuetify.breakpoint.name];
    },
  },
  watch: {
    '$vuetify.breakpoint.name': {
      immediate: true,
      handler() {
        this.nudgeLeftPosition;
      },
    },
  },
  methods: {
    controlPopoverShow(elementRef, text) {
      this.$nextTick(() => {
        const element = this.$refs[elementRef] || this.$refs[elementRef][0];

        this.showPopover =
          displayTextWidth(text, FONT_USED) > element?.$el?.children[0]?.clientWidth;
      });
    },
    isViewMode() {
      if (this.dataOnlyView?.somenteVisualizacao) {
        this.$toast(this.dataOnlyView?.messageToast, { type: 'info', id: 'view-mode' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-text-field__slot > input) {
  @apply text-ellipsis overflow-hidden;
}
</style>
