import { pagination } from '@/constants';

const getFilters = ({
  id,
  idAbi,
  tipoFormulario,
  statusFormulario,
  statusProtocolo,
  contagem,
  tipo,
  motivoImpugnacao,
  numero,
  nomeCompleto,
  codigoCco,
  valorTotalInicial,
  valorTotalFinal,
  periodoInicial,
  periodoFinal,
  numeroProtocoloImpugnacao,
  numeroProtocoloRecursao,
  dataProtocoloImpugnacaoInicial,
  dataProtocoloImpugnacaoFinal,
  dataProtocoloRecursaoInicial,
  dataProtocoloRecursaoFinal,
  dataContestacaoImpugnacaoInicial,
  dataContestacaoImpugnacaoFinal,
  dataContestacaoRecursaoInicial,
  dataContestacaoRecursaoFinal,
}) => `{
  ${id ? `id: ${id}` : ``}
  ${idAbi ? `idAbi: ${idAbi}` : ``}
  ${tipoFormulario ? `tipoFormulario: ${tipoFormulario}` : ``}
  ${statusFormulario ? `statusFormulario: ${statusFormulario}` : ``}
  ${statusProtocolo ? `statusProtocolo: [${statusProtocolo}]` : ``}
  ${contagem ? `contagem: "${contagem}"` : ``}
  ${tipo ? `tipo: [${tipo}]` : ``}
  ${motivoImpugnacao ? `motivoImpugnacao: [${motivoImpugnacao.map((item) => `"${item}"`)}]` : ``}
	${numero ? `numero: "${numero}"` : ``}
  ${nomeCompleto ? `nomeCompleto: "${nomeCompleto}"` : ``}
  ${codigoCco ? `codigoCco: "${codigoCco}"` : ``}
  ${valorTotalInicial ? `valorTotalAtendimento: [${valorTotalInicial}, ${valorTotalFinal}]` : ``}
  ${periodoInicial && periodoFinal ? `periodo: ["${periodoInicial}", "${periodoFinal}"]` : ``}
  ${periodoInicial && !periodoFinal ? `periodo: ["${periodoInicial}", null]` : ``}
  ${numeroProtocoloImpugnacao ? `numeroProtocoloImpugnacao: "${numeroProtocoloImpugnacao}"` : ``}
  ${numeroProtocoloRecursao ? `numeroProtocoloRecursao: "${numeroProtocoloRecursao}"` : ``}
  ${
    dataProtocoloImpugnacaoInicial && dataProtocoloImpugnacaoFinal
      ? `dataProtocoloImpugnacao: ["${dataProtocoloImpugnacaoInicial}", "${dataProtocoloImpugnacaoFinal}"]`
      : ``
  }
  ${
    dataProtocoloImpugnacaoInicial && !dataProtocoloImpugnacaoFinal
      ? `dataProtocoloImpugnacao: ["${dataProtocoloImpugnacaoInicial}", null]`
      : ``
  }
  ${
    dataProtocoloRecursaoInicial && dataProtocoloRecursaoFinal
      ? `dataProtocoloRecursao: ["${dataProtocoloRecursaoInicial}", "${dataProtocoloRecursaoFinal}"]`
      : ``
  }
  ${
    dataProtocoloRecursaoInicial && !dataProtocoloRecursaoFinal
      ? `dataProtocoloRecursao: ["${dataProtocoloRecursaoInicial}", null]`
      : ``
  }
  ${
    dataContestacaoImpugnacaoInicial && dataContestacaoImpugnacaoFinal
      ? `dataContestacaoImpugnacao: ["${dataContestacaoImpugnacaoInicial}", "${dataContestacaoImpugnacaoFinal}"]`
      : ``
  }
  ${
    dataContestacaoImpugnacaoInicial && !dataContestacaoImpugnacaoFinal
      ? `dataContestacaoImpugnacao: ["${dataContestacaoImpugnacaoInicial}", null]`
      : ``
  }
  ${
    dataContestacaoRecursaoInicial && dataContestacaoRecursaoFinal
      ? `dataContestacaoRecursao: ["${dataContestacaoRecursaoInicial}", "${dataContestacaoRecursaoFinal}"]`
      : ``
  }
  ${
    dataContestacaoRecursaoInicial && !dataContestacaoRecursaoFinal
      ? `dataContestacaoRecursao: ["${dataContestacaoRecursaoInicial}", null]`
      : ``
  }
}`;

const listarProtocolos = ({
  page = pagination.page,
  size = pagination.size,
  sort = 'statusProtocoloOrder',
  filters,
}) => `{
  listarAtendimentos(
    page: ${page - 1}
    size: ${size}
    sort: ["${sort}"]
    filter:  ${getFilters(filters)}
  ) {
    content {
      id
      contagem
      tipo
      numero
      valorTotal
      dataInicio
      dataFim
      naoImpugnado
      naoRecursado
      statusProtocolo
      statusAtendimento
      temDocumentosPendentes
      mensagemDocumentosPendentes
      temDocumentosNaoAdicionadoAoProtocolo
      abi {
        numero
      }
      beneficiario {
        codigo
        nomeCompleto
        codigoCco
      }
      listagemMotivoImpugnacao {
        motivoImpugnacao {
          id
          codigo
          descricaoAbreviada
        }
      }
      formularioImpugnacao {
        id
        quantidadeDocumentosComprobatorios
        foiContestado
        dataContestacao
        numeroProtocoloConcluido
        erroProtocolo
        situacaoAns
        dataAtualizacaoAns
        documento {
          nome
          url
        }
      }
      formularioRecursao {
        id
        quantidadeDocumentosComprobatorios
        foiContestado
        dataContestacao
        dataAtualizacaoAns
        situacaoAns
        numeroProtocoloConcluido
        erroProtocolo
        documento {
          nome
          url
        }
      }
      nomeCriticasValidacao
    }
    number
    totalPages
    totalElements
    size
    first
    last
  }
}`;

export default listarProtocolos;
