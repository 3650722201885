import initialState from './state';

export default {
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
};
