import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { getParametroContabilidade, updateParametroContabilidadeService } from '../services';

const loadParametroContabilidade = async ({ commit, dispatch }) => {
  dispatch('setLoading');

  await getParametroContabilidade({
    onSuccess: async ({ data }) => {
      await commit('GENERIC_MUTATION', {
        property: 'contabilidade',
        payload: data,
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: cloneDeep(data),
      });
    },
    onCustomError: ({ response }) => Vue.$toast(response?.data?.message, { type: 'error' }),
    onFinally: () => dispatch('setLoading', false),
  });
};

const updateParametros = async ({ getters, dispatch }) => {
  dispatch('setLoading');

  await updateParametroContabilidadeService({
    payload: getters.payloadContabilidade,
    onSuccess: () => {
      Vue.$toast('Parâmetro atualizado com sucesso!', { type: 'success' });

      dispatch('loadParametroContabilidade');
    },
    onCustomError: ({ response }) => {
      dispatch('setLoading', false);

      Vue.$toast(response?.data?.message, { type: 'error' });
    },
  });
};

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

const resetState = ({ commit }) => commit('RESET_STATE');

const setLoading = ({ commit }, payload = true) =>
  commit('GENERIC_MUTATION', {
    property: 'loading',
    payload,
  });

export default {
  loadParametroContabilidade,
  updateParametros,
  resetState,
  setLoading,
  updateField,
};
