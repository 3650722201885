<template>
  <v-app
    id="ressus"
    :class="{ 'is-formulario-atendimento': isFormularioAtendimento }"
    :style="containerDefault"
  >
    <div class="flex">
      <router-view />
      <div v-if="isFormularioAtendimento" class="pl-3">
        <drawer-formulario-atendimento />
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DrawerFormularioAtendimento from './views/processos/atendimentos/formulario-impugnacao/drawer-formulario-atendimento.vue';

export default {
  name: 'App',
  components: {
    DrawerFormularioAtendimento,
  },
  computed: {
    ...mapState('ressus/loadingWithProgress', ['isActive']),
    ...mapGetters('ressus', ['containerDefault']),
    isFormularioAtendimento() {
      return this.$route.name === 'formulario-atendimento';
    },
  },
};
</script>

<style lang="scss">
#ressus {
  .v-input--is-readonly .v-input__slot:before {
    border: 0;
  }

  .v-input--is-readonly.v-input--is-focused .v-input__slot:after {
    border: 0;
  }

  .v-input--is-readonly input::placeholder {
    color: transparent !important;
  }

  .v-input__slot:after {
    transition: none !important;
  }
}

#ressus .v-application--wrap {
  background-color: theme('colors.neutral-gray-low');
  padding: 12px;
}

.is-formulario-atendimento .v-application--wrap {
  padding-right: 0 !important;
}
</style>
