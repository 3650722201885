<template>
  <v-navigation-drawer
    v-bind="$attrs"
    v-on="$listeners"
    app
    absolute
    stateless
    right
    temporary
    :width="width"
  >
    <div class="flex">
      <div v-if="showLeftColumn" class="h-screen min-w-[44px] w-[44px] bg-feedback-green-low">
        <slot name="drawer-left-column" />
      </div>
      <div class="w-full pl-11 pr-4 py-6">
        <v-btn icon class="absolute top-2 right-2 z-[1]" @click="$emit('close')">
          <v-icon class="text-primary-blue-base">mdi-close</v-icon>
        </v-btn>
        <slot name="drawer-body" />
      </div>
      <v-footer
        v-if="showFooter"
        app
        class="px-[44px] bg-white"
        :class="{
          'border-t border-neutral-gray-disabled': showFooterBorder,
          'ml-[44px]': showLeftColumn,
        }"
        :height="footerHeight"
      >
        <slot name="drawer-footer" />
      </v-footer>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'UiDrawer',
  props: {
    width: {
      type: [Number, String],
      default: 724,
    },
    footerHeight: {
      type: [Number, String],
      default: 72,
    },
    showFooter: Boolean,
    showFooterBorder: Boolean,
    showLeftColumn: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  z-index: 4;
}
</style>
