<template>
  <re-container v-bind="{ loading }" show-footer>
    <template #content>
      <v-form v-model="valid" lazy-validation ref="form">
        <section v-for="(parametro, index) in parametros" :key="parametro.id">
          <v-row>
            <v-col cols="12" md="5">
              <h3 class="text-sm font-semibold text-primary-blue-base mb-2">
                {{ parametro.nome }}
              </h3>
              <p class="text-xs text-primary-blue-base mb-0">
                {{ parametro.descricao }}
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                persistent-placeholder
                return-object
                clearable
                attach
                label="Motivo"
                item-value="id"
                item-text="descricaoCompleta"
                placeholder="Selecionar"
                no-data-text="Nenhum motivo encontrado"
                :data-test="`autocomplete-${index}`"
                :value="parametro.motivoImpugnacao"
                :items="motivosImpugnacao"
                @change="(value) => onChangeMotivo(parametro, value)"
              />
            </v-col>
            <v-col cols="12" md="2" align-self="center">
              <v-switch
                inset
                :label="parametro.ativo ? 'Ativo' : 'Inativo'"
                :input-value="parametro.ativo"
                :data-test="`switch-ativo-inativo-${index}`"
                @change="(value) => onChangeAtivo(parametro, value)"
              />
            </v-col>
          </v-row>
          <v-row v-if="!isLastItem(index)">
            <v-col cols="12" md="10">
              <v-divider class="mb-3" />
            </v-col>
          </v-row>
        </section>
      </v-form>
    </template>
    <template #footer>
      <v-spacer />
      <div class="flex justify-end gap-6">
        <v-btn class="btn btn__primary" data-test="btn-salvar" :disabled="loading" @click="onSave">
          Salvar
        </v-btn>
      </div>
      <ui-modal
        type="salvar"
        :show="showModalAtencao"
        @close="onToggleModalAtencao(true)"
        @exit="onCancel"
        @save="onSave"
      />
    </template>
  </re-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { isEqual } from 'lodash';
import { ReContainer, UiModal } from '@/components';
import MixinParametros from '../mixin-parametros';

export default {
  name: 'CriticasValidacao',
  mixins: [MixinParametros],
  components: { ReContainer, UiModal },
  computed: {
    ...mapState('ressus/parametros/criticasValidacao', [
      'parametros',
      'loading',
      'initialState',
      'motivosImpugnacao',
    ]),
    ...mapGetters('ressus/parametros/criticasValidacao', ['countMotivosDuplicados']),
    isInitialState() {
      return isEqual(this.initialState, this.parametros);
    },
  },
  created() {
    this.onLoad();
  },
  methods: {
    ...mapActions('ressus/parametros/criticasValidacao', [
      'loadParametros',
      'updateField',
      'updateParametros',
      'resetState',
    ]),
    onLoad() {
      this.showModalAtencao = false;
      this.loadParametros();
    },
    onChangeAtivo(param, value) {
      const BENEFICIARIO_POSSUI_OUTRAS_AIHS = 10;
      const BENEFICIARIO_POSSUI_OUTRAS_APACS = 11;
      const BENEFICIARIO_POSSUI_OUTROS_ATENDIMENTOS = 12;

      if (value) {
        if (param.id === BENEFICIARIO_POSSUI_OUTROS_ATENDIMENTOS) {
          this.onDisableFieldAtivo(BENEFICIARIO_POSSUI_OUTRAS_AIHS);
          this.onDisableFieldAtivo(BENEFICIARIO_POSSUI_OUTRAS_APACS);
        } else if (
          param.id === BENEFICIARIO_POSSUI_OUTRAS_AIHS ||
          param.id === BENEFICIARIO_POSSUI_OUTRAS_APACS
        ) {
          this.onDisableFieldAtivo(BENEFICIARIO_POSSUI_OUTROS_ATENDIMENTOS);
        }
      }

      this.updateField({ item: param, field: 'ativo', value });
    },
    onDisableFieldAtivo(id) {
      this.updateField({ item: { id }, field: 'ativo', value: false });
    },
    onChangeMotivo(parametro, value) {
      this.updateField({
        item: parametro,
        field: 'motivoImpugnacao',
        value: value?.id ? value : null,
      });

      if (this.countMotivosDuplicados > 0) {
        this.$toast('Motivo já utilizado em outra crítica', { type: 'error' });

        this.updateField({
          item: parametro,
          field: 'motivoImpugnacao',
          value: { id: null },
        });
      }
    },
  },
};
</script>
