import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import createQueryEventoContabil from './queries/evento-contabil';

const getEventoContabil = async (queryParams) =>
  createGraphQLService(createQueryEventoContabil(queryParams));

const getEventoContabilById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/evento_contabil/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createEventoContabilService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/evento_contabil`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateEventoContabilService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    uri: `${ressusURI}/evento_contabil/${payload.id}`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteEventoContabilService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/evento_contabil/${id}`,
    onSuccess,
    onCustomError,
  });

const loadCamposFormulaEventoContabilService = ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${ressusURI}/evento_contabil/formula/campos`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  createEventoContabilService,
  deleteEventoContabilService,
  getEventoContabil,
  getEventoContabilById,
  updateEventoContabilService,
  loadCamposFormulaEventoContabilService,
};
