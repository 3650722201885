<template>
  <div>
    <p class="text-base">
      <span v-html="description" />
      <span
        class="cursor-pointer underline text-primary"
        data-test="a-redirect-documento"
        @click.stop="onClickLink"
      >
        {{ linkDescription }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ReToastLink',
  props: {
    description: {
      type: String,
      required: true,
    },
    linkDescription: String,
  },
  methods: {
    onClickLink() {
      this.$emit('onClickLink');
    },
  },
};
</script>
