/* eslint-disable */

import Vue from 'vue';
import { graphQLServiceURI } from '../api';
import { resolveGraphqlResponse, applyGraphqlCatch, applyCatchError } from './interceptors';

const httpMethods = {
  get: 'get',
  post: 'post',
  patch: 'patch',
  put: 'put',
  delete: 'delete',
};

const resolveEscapeChar = (str = '') => str.replace(/\\/g, '');

export const createGraphQLService = (
  queryString = '',
  config = {
    debug: false,
    showErrorToast: false,
    delay: 0,
  },
) => {
  try {
    const queryStringObjectType = typeof queryString;

    if (!queryString) {
      throw new Error('O parâmetro "queryString" é requerido para criar a instância do serviço.');
    }

    if (queryStringObjectType !== 'string') {
      throw new Error(`O parâmetro "queryString" não pode ser do tipo ${queryStringObjectType}.`);
    }

    queryString = resolveEscapeChar(queryString);

    config.debug && console.warn(queryString);

    return Vue.prototype.$http['post'](graphQLServiceURI, { query: queryString })
      .then(resolveGraphqlResponse(config.showErrorToast, queryString))
      .catch(applyGraphqlCatch);
  } catch (e) {
    console.error(e);
  }
};

export const createCommandService = async ({
  method = httpMethods.get,
  uri,
  payload,
  onStart,
  onSuccess,
  onCustomError,
  onFinally,
  config = null,
}) => {
  try {
    config?.debug && console.warn(method, uri, payload);

    if (!httpMethods[method]) {
      throw new Error(`Método HTTP inválido. Use: ${Object.values(httpMethods)}.`);
    }

    if (!uri) {
      throw new Error('O parâmetro "uri" é requerido para criação da instância do serviço.');
    }

    if (method === httpMethods.get && payload) {
      throw new Error('O método "get" não pode enviar conteúdo no corpo da requisição.');
    }

    onStart?.();

    return Vue.prototype.$http[method](uri, payload, config)
      .then(onSuccess)
      .catch(onCustomError || applyCatchError)
      .finally(onFinally);
  } catch (thrown) {
    console.error(thrown);
  }
};
