import { ressusURI } from '@/api';
import { createCommandService, createGraphQLService } from '@/services';
import listarIdsAtendimentosParaRelatorio from './queries/listar-ids-atendimentos-para-relatorio';

const getSituacaoProcessoService = ({ onSuccess, onCustomError }) =>
  createCommandService({
    uri: `${ressusURI}/atendimento/situacao_processo`,
    onSuccess,
    onCustomError,
  });

const acompanhamentoAtendimento = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${ressusURI}/documento/acompanhamento_atendimento`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getIdsAtendimentosParaRelatorioService = (payloadDadosRelatorio) =>
  createGraphQLService(listarIdsAtendimentosParaRelatorio(payloadDadosRelatorio));

export {
  getSituacaoProcessoService,
  getIdsAtendimentosParaRelatorioService,
  acompanhamentoAtendimento,
};
