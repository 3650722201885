const headers = [
  {
    text: '',
    value: 'status',
    cellClass: 'pa-0',
    sortable: false,
  },
  { text: 'CÓDIGO', value: 'codigo', width: 140 },
  { text: 'TIPO', value: 'tipo', width: 170 },
  { text: 'DESCRIÇÃO', value: 'descricao' },
  { text: 'PROCESSO ABI', value: 'processoAbi' },
  {
    text: 'EDITAR',
    value: 'editar',
    align: 'center',
    width: 80,
    sortable: false,
  },
  {
    text: 'EXCLUIR',
    value: 'excluir',
    align: 'center',
    width: 80,
    sortable: false,
  },
];

const processosAbi = {
  AGUARDANDO_COBRANCA: { label: 'Aguardando cobrança' },
  ENCERRADO: { label: 'Encerrado' },
  EM_PROCESSO: { label: 'Recursos em processo' },
  CANCELADO_PELA_ANS: { label: 'Cancelado pela ANS' },
};

const tipos = [
  { id: 1, text: 'Deferido total', value: 'DEFERIDO' },
  { id: 2, text: 'Deferido parcial', value: 'DEFERIDO_PARCIAL' },
  { id: 3, text: 'Indeferido', value: 'INDEFERIDO' },
];

const enumTipos = {
  DEFERIDO: 'Deferido total',
  DEFERIDO_PARCIAL: 'Deferido parcial',
  INDEFERIDO: 'Indeferido',
};

export { headers, processosAbi, tipos, enumTipos };
