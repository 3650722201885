import { createCommandService, createGraphQLService } from '@/services';
import { ressusURI } from '@/api';
import ListarDetalhamentoMotivo from './queries/listar-detalhamento-motivo';

const getDetalhamentoMotivo = (queryParams) =>
  createGraphQLService(ListarDetalhamentoMotivo(queryParams));

const getDetalhamentoMotivoById = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/detalhamento_motivo/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getMotivos = () =>
  createCommandService({
    method: 'get',
    uri: `${ressusURI}/motivo_oficial_ans?removerNaoImpugnado=true`,
  });

const createDetalhamentoMotivoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${ressusURI}/detalhamento_motivo`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateDetalhamentoMotivoService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${ressusURI}/detalhamento_motivo/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteDetalhamentoMotivoService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${ressusURI}/detalhamento_motivo/${id}`,
    onSuccess,
    onCustomError,
  });

export {
  getDetalhamentoMotivo,
  getDetalhamentoMotivoById,
  createDetalhamentoMotivoService,
  updateDetalhamentoMotivoService,
  deleteDetalhamentoMotivoService,
  getMotivos,
};
