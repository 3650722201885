import { helpPages } from '@/constants';
import FormAlegacaoAns from './form.vue';

export default [
  {
    path: '/alegacao-ans',
    name: 'alegacao-ans',
    component: () => import(/* webpackChunkName: "alegacao-ans" */ './index.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Alegação ANS',
      helpPage: helpPages['alegacao-ans'],
      applicationKey: 'ressus',
      action: 'view',
    },
    props: true,
  },
  {
    path: '/alegacao-ans/create',
    name: 'create-alegacao-ans',
    component: () => import(/* webpackChunkName: "form-alegacao-ans" */ './form.vue'),
    meta: {
      title: 'Cadastros',
      subtitle: 'Nova alegação ANS',
      helpPage: helpPages['alegacao-ans'],
      applicationKey: 'ressus',
      action: 'create',
    },
    props: true,
  },
  {
    path: '/alegacao-ans/edit/:id',
    name: 'edit-alegacao-ans',
    component: FormAlegacaoAns,
    meta: {
      title: 'Cadastros',
      subtitle: 'Editar alegação ANS',
      helpPage: helpPages['alegacao-ans'],
      applicationKey: 'ressus',
      action: 'edit',
    },
    props: true,
  },
];
