var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"clearable":"","persistent-placeholder":"","label":"Inicial","placeholder":"00/00/0000","rules":_vm.validatePropertyInicial,"data-test":`text-field-${_vm.propertyInicial}`},on:{"input":(value) =>
          _vm.updateField({
            property: _vm.propertyInicial,
            value,
          }),"click:clear":(value) =>
          _vm.updateField({
            property: _vm.propertyFinal,
            value: '',
          })},model:{value:(_vm.filters[_vm.propertyInicial]),callback:function ($$v) {_vm.$set(_vm.filters, _vm.propertyInicial, $$v)},expression:"filters[propertyInicial]"}})],1),_c('v-col',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"clearable":"","persistent-placeholder":"","label":"Final","placeholder":"(Opcional)","rules":_vm.validatePropertyFinal,"data-test":`text-field-${_vm.propertyFinal}`},on:{"input":(value) =>
          _vm.updateField({
            property: _vm.propertyFinal,
            value,
          })},model:{value:(_vm.filters[_vm.propertyFinal]),callback:function ($$v) {_vm.$set(_vm.filters, _vm.propertyFinal, $$v)},expression:"filters[propertyFinal]"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }