import { isEqual } from 'lodash';

const payload = ({ caraterAtendimento }) => ({
  ...caraterAtendimento,
  codigo: Number(caraterAtendimento.codigo),
});

const caraterAtendimentosTable = ({ caraterAtendimentos }) =>
  caraterAtendimentos.map((caraterAtendimento) => ({
    ...caraterAtendimento,
    status: caraterAtendimento.ativo ? 'ATIVO' : 'INATIVO',
  }));

const isInitialState = (state, getters) => isEqual(state.initialState, getters.payload);

export default {
  payload,
  caraterAtendimentosTable,
  isInitialState,
};
