/** @type {import('tailwindcss').Config} */
const preset = require('./src/plugins/preset');

module.exports = {
  important: true,
  content: ['./src/**/*.{html,js,vue}'],
  theme: {
    extend: {},
  },
  plugins: [],
  presets: [preset],
};
