<template>
  <v-autocomplete
    multiple
    persistent-placeholder
    return-object
    placeholder="Selecionar"
    no-data-text="Nenhum item encontrado"
    :attach="attach"
    :label="label"
    :items="items"
    :value="selecteds"
    :item-value="itemValue"
    :item-text="itemText"
    :readonly="readonly"
    :rules="rules"
    :search-input.sync="search"
    :append-icon="readonly ? '' : 'mdi-menu-down'"
    :menu-props="{ top, maxHeight: 256, offsetY: true }"
    @change="onChange"
    @focus="onFocus"
  >
    <template v-if="showSelectAll && !search" #prepend-item>
      <v-list-item @click="onToggleSelectAll">
        <v-list-item-action>
          <v-icon> {{ selectAllIcon }} </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Selecionar todos </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="!readonly" v-slot:selection="{ item, index }">
      <div v-if="isAllItemsSelected">
        <span v-if="index === 0">Todos</span>
      </div>
      <div v-else>
        <v-chip v-if="index < limitToShow" small outlined class="border-primary-blue-base">
          <span class="text-primary-blue-base"> {{ item[itemText] }} </span>
        </v-chip>
        <span v-if="index === limitToShow" class="text-primary-blue-base text-caption">
          <v-menu open-on-hover offset-y max-width="500">
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ moreItemsText }}</div>
            </template>
            <v-card>
              <v-card-text class="text-primary-blue-base">
                {{ popoverText }}
              </v-card-text>
            </v-card>
          </v-menu>
        </span>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ReAutocompleteMultipleV2',
  props: {
    label: {
      type: String,
      required: true,
    },
    limitToShow: {
      type: Number,
      default: 3,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selecteds: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'descricao',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    attach: {
      type: Boolean,
      default: true,
    },
    top: Boolean,
    readonly: Boolean,
    showSelectAll: Boolean,
    selectAll: Boolean,
  },
  data() {
    return {
      options: [],
      search: '',
    };
  },
  computed: {
    moreItemsText() {
      const isSingleItem = this.selecteds.length - this.limitToShow === 1;

      return `(+ ${this.selecteds.length - this.limitToShow} ${isSingleItem ? 'outro' : 'outros'})`;
    },
    popoverText() {
      const onlyHiddenItems = this.selecteds.filter(
        (selected, index) => index >= this.limitToShow && selected[this.itemText],
      );

      return onlyHiddenItems.map((item) => item[this.itemText]).join(', ');
    },
    selectAllIcon() {
      return this.selecteds.length === this.items.length
        ? 'mdi-checkbox-marked'
        : 'mdi-checkbox-blank-outline';
    },
    isAllItemsSelected() {
      return this.selecteds.length === this.items.length;
    },
  },
  watch: {
    selectAll: {
      handler() {
        this.onToggleSelectAll();
      },
    },
  },
  methods: {
    onChange(value) {
      this.$emit('select-options', value);
    },
    onFocus() {
      this.search = '';
    },
    onToggleSelectAll() {
      if (this.isAllItemsSelected) {
        this.options = [];
      } else {
        this.options = this.items.slice();
      }

      this.$emit('select-options', this.options);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-list-item__action) {
  margin-right: 8px !important;
}

:deep(.v-list-item--active),
:deep(.v-list-item--highlighted) {
  background-color: theme('colors.white') !important;
  color: theme('colors.white') !important;

  .v-list-item__content {
    .v-list-item__title {
      color: theme('colors.primary-blue-base') !important;
    }
  }

  &:hover {
    background-color: theme('colors.primary-blue-disabled') !important;
  }

  .v-list-item__action {
    .v-input--selection-controls__input i {
      &::before {
        color: theme('colors.primary-blue-base') !important;
      }
    }
  }
}
</style>
