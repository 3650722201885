import notificacoes from '@/views/notification-menu/store';
import cadastros from '@/views/cadastros/store';
import oficiosNotificacao from '@/views/oficios-notificacao/store';
import parametros from '@/views/parametros/store';
import processos from '@/views/processos/store';
import relatorios from '@/views/relatorios/store';
import drawerFiltros from '@/components/re-drawer-filtros/store';
import drawerVisualizarDocumento from '@/components/drawer-visualizar-documento/store';
import loadingWithProgress from '../loading-progress';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    cadastros,
    oficiosNotificacao,
    parametros,
    processos,
    relatorios,
    drawerFiltros,
    loadingWithProgress,
    notificacoes,
    drawerVisualizarDocumento,
  },
};
