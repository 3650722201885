import { isEqual } from 'lodash';

const payload = ({ detalhamentoMotivo }) => ({
  ...detalhamentoMotivo,
  motivos: detalhamentoMotivo?.motivos?.map((motivo) => ({
    id: motivo?.id,
    codigo: motivo?.codigo,
    descricao: motivo?.descricao,
  })),
});

const detalhamentoMotivosTable = ({ detalhamentoMotivos }) =>
  detalhamentoMotivos.map((detalhamentoMotivo) => ({
    ...detalhamentoMotivo,
    countMotivos:
      detalhamentoMotivo.motivoOficialAns.length > 1
        ? `${detalhamentoMotivo.motivoOficialAns.length} Motivos`
        : `${detalhamentoMotivo.motivoOficialAns.length} Motivo`,
    motivos: detalhamentoMotivo.motivoOficialAns
      .map((motivo) => `${motivo.id}. ${motivo.descricao}`)
      .join('<br/>'),
    status: detalhamentoMotivo.ativo ? 'ATIVO' : 'INATIVO',
  }));

const isInitialState = (state, getters) => isEqual(state.initialState, getters.payload);

export default {
  payload,
  detalhamentoMotivosTable,
  isInitialState,
};
